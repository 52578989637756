import { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import anoService from "../../service/anoService";
import Modal from "react-bootstrap/Modal";
import { mensagemAlerta, mensagemSucesso } from "../../components/avisos/toastr";

const serviceAno = new anoService();
const anoSalvar = {
		ano: 0
}

function AnoLetivo() {
	const [ano, setAno] = useState([]);
	const [anoAdicionar, setAnoAdicionar] = useState(2021)

	//Modal Adicionar ano
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	useEffect(() => {
		async function CarregarAnos() {
			const response = await serviceAno.listar();
			setAno(response.data);
		}

		CarregarAnos();
		//console.log(ano);
	}, [show]);

	function adicionarAno(novoAno){
		//console.log(novoAno)
		anoSalvar.ano = novoAno
		//console.log(anoSalvar)
	 serviceAno.salvar(anoSalvar)
	 .then((e) => {
			 mensagemSucesso("Ano Salvo com sucesso.")
			 handleClose()
	 })
	 .catch((r) => {
			 mensagemAlerta(r.response.data)
	 })
		
	}

	return (
		<>
			<div className="container mt-4">
				<div className="form-group">
					<div className="card md-3">
						<h4 className="card-header">Cadastramento de Ano Letivo</h4>

						<div className="card-body">
							<div
								style={{
									textAlign: "right",
								}}
							>
								<button onClick={handleShow} type="button" className="btn btn-primary">
									Adicionar
								</button>
							</div>

							<Container>
								<Row className="justify-content-md-center">
									<Col md={4}>
							<table className="table table-hover">
								<thead>
									<tr>
										<th style={{ textAlign: 'center' }}>Anos Cadastrado:</th>
									</tr>
								</thead>
								<tbody>
									{ano.map((a) => {
										return (
											<tr key={a.id}>
												<td style={{ textAlign: 'center' }}>{a.ano}</td>
											</tr>
										);
									})}
								</tbody>
							</table>
							</Col>
							</Row>
							</Container>
						</div>
					</div>
				</div>
			</div>

			<Modal
				closeButton={true}
				show={show}
				onHide={handleClose}
				backdrop="static"
				centered
				keyboard={false}
			>
				<Modal.Header className="card-header" closeButton>
					<Modal.Title>Cadastrar Novo Ano Letivo</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Row></Row>

					<div className="container">
						<Row className="justify-content-md-center">
							<Col md={3}>
								<label
									className="col-form-label"
									htmlFor="ano"
								>
									Ano Letivo
								</label>
								<input
									id="ano"
									name="ano"
									type="number"
									max={2050}
									min={2022}
									className="form-control"
									value={anoAdicionar}
									onChange={(e) => setAnoAdicionar(e.target.value)}
								/>
							</Col>
						</Row>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button
						className="btn btn-primary"
						type="button"
						onClick={() => adicionarAno(anoAdicionar)}
					>
						Salvar
					</button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default AnoLetivo;

import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { mensagemErro } from "../../components/avisos/toastr";
import Card from "../../components/card";
import { Link } from "react-router-dom";
import assinaturaQuadrosService from "../../service/assinaturaQuadrosService";
import LocalStorageService from "../../service/localStorageService";
import { descriptografar } from "../../components/criptografar";

const serviceAssinatura = new assinaturaQuadrosService()

const DadosCodigoVerificador = ({cod}) => {

    const s = "SERVIDOR"
    const e = "ESTAGIARIO"
    const c = "COOPERADO"
    const t = "TEMPORARIO"

    const [assinatura, setAssinatura] = useState([])
    const [numeroValido, setNumeroValido] = useState(false)
    const [numeroNaoLocalizado, setNumeroNaoLocalizado] = useState(true)

    useEffect(() => {
        if (cod) {
            if (cod === 0 || cod <= 999999999 || cod === null) {
                setNumeroValido(false)
            } else {
                setNumeroValido(true)
                serviceAssinatura.validarCodigoVerificador(cod)
                .then((s) => {
                    setAssinatura(s.data)
                    setNumeroNaoLocalizado(false)
                })
                .catch((e) => {
                    mensagemErro(e.response.data)
                    setNumeroNaoLocalizado(true)
                })
            }
        }
    },[])
    
    const usuarioCriptografado = LocalStorageService.obterItem('2')
    const usuario = usuarioCriptografado ? JSON.parse(descriptografar(usuarioCriptografado)) : null
    const [usuarioLogado, setUsuarioLogado] = useState(false)
    useEffect(() => {
        if (usuario) {
            setUsuarioLogado(true)
        }
    },[])

    function descricaoQuadro(tipo){
        if(tipo) {
            if (tipo === s) {
                return "Servidores"
            }
            if (tipo === e) {
                return "Estagiários"
            }
            if (tipo === c) {
                return "Cooperados"
            }
            if (tipo === t) {
                return "Substitutos Temporários"
            }
        }
    }

    const formatarData = (dataF) => {
        if(dataF){
            let data = new Date(dataF),
            dia  = data.getUTCDate().toString().padStart(2, '0'),
            mes  = (data.getUTCMonth()+1).toString().padStart(2, '0'),
            ano  = data.getUTCFullYear();
            return dia+"/"+mes+"/"+ano;
        }
    }

    return(
        <>
            <Container>
            {usuarioLogado ? null : 
                <div style={{paddingBottom: '10px', textAlign: 'center'}} className="container">
                                <a className="navbar-brand" href="#/login">
                                    <img src="/img/Prefeitura_.png" alt="RH Semec" width="80px"/>
                                </a>
                </div>
            }
                <Card title="Verificação/Validação de assinatura digital">
                        
                    <br/>
                        <Row style={{textAlign: "center"}} className="justify-content-md-center">
                            <Col md={5}>
                                <h5 style={{textAlign: "center"}}> Dados do Código Verificador: <label style={{color: '#0704b8'}}><b>{cod}</b></label> </h5>    
                            </Col>
                        </Row>
                            <br/>
                        {numeroValido 
                        ?
                            numeroNaoLocalizado 
                            ?
                                <Row style={{textAlign: "center"}} className="justify-content-md-center">
                                    <Col>
                                        <label>O Código Verificador não existe na base de dados. <b style={{color: "#c70909"}} > Assinatura é inválida.</b></label>
                                    </Col>
                                </Row> 
                            :
                                <>
                                    <Row style={{textAlign: "center"}} className="justify-content-md-center">
                                        <Col>
                                            <label><b> Tipo de Quadro: </b></label><br/>
                                            <p >{assinatura ? descricaoQuadro(assinatura.tipoQuadroAssinado) : null} </p>
                                        </Col>
                                    </Row> 
                                    <Row style={{textAlign: "center"}} className="justify-content-md-center">
                                        <Col>
                                            <label><b> Unidade: </b></label><br/>
                                            <p >{assinatura ? assinatura.unidade : null} </p>
                                        </Col>
                                    </Row>
                                    <Row style={{textAlign: "center"}} className="justify-content-md-center">
                                        <Col>
                                            <label><b> Mês/Ano: </b></label><br/>
                                            <p >{assinatura ? assinatura.mesLancamento+"/"+assinatura.anoLancamento : null} </p>
                                        </Col>
                                    </Row> 
                                    <Row style={{textAlign: "center"}} className="justify-content-md-center">
                                        <Col>
                                            <label><b> Assinado Por: </b></label><br/>
                                            <p >{assinatura ? assinatura.assinadoPor : null} </p>
                                        </Col>
                                    </Row>
                                    <Row style={{textAlign: "center"}} className="justify-content-md-center">
                                        <Col>
                                            <label><b> Data da Assinatura: </b></label><br/>
                                            <p >{assinatura ? formatarData(assinatura.dataAssinatura) : null} </p>
                                        </Col>
                                    </Row>    
                                    <Row style={{textAlign: "center"}} className="justify-content-md-center">
                                        <Col>
                                            <label><b> Assinatura: </b></label><br/>
                                            <p >{assinatura ? assinatura.assinaturaDigital : null} </p>
                                        </Col>
                                    </Row> 
                                    <Row style={{textAlign: "center"}} className="justify-content-md-center">
                                        <Col>
                                            <label><b> Status: </b></label><br/>
                                            <p style={{color: "#0e8a15"}} > <b> Assinatura Válida </b></p>
                                        </Col>
                                    </Row> 
                                </>
                        :
                            <Row style={{textAlign: "center"}} className="justify-content-md-center">
                                <Col>
                                    <label>O número para verificação é inválido. </label>
                                </Col>
                            </Row> 
                        }
                        <br/>
                        <Row style={{textAlign: "center"}} className="justify-content-md-center">
                            <Col>
                                <button type="button" className="btn btn-primary">
                                    <Link style={{ textDecoration: "none", color: "#fff" }} to={'/verificacao'}>
                                        Nova Verificação                        
                                    </Link>   
                                </button>
                            </Col>
                        </Row> 
                </Card>
            </Container>
        </>
    )
}
export default DadosCodigoVerificador
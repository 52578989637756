/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import calendarioPersonalizado from "../../components/calendario/calendariomulti";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Row, Col, Container, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import LancamentoServidorService from "../../service/lancamentoServidorService";
import lancamentoPrestadorService from "../../service/lancamentoPrestadorService";
import MotivosSubstituicaoService from "../../service/motivosSubstituicaoService";
import ServidorMotivoSub from "../../service/servidorMotivoSubService";
import dadosSubPrestador from "../../service/dadosSubPrestadorService";
import { mensagemErro, mensagemSucesso } from "../../components/avisos/toastr";
import calculosRecibosService from "../../service/calculosRecibosService";
import dadosSubRecibos from "../../service/dadosSubRecibosService";
import ServidorService from "../../service/servidorService";
import LocalStorageService from "../../service/localStorageService";
import { descriptografar } from "../../components/criptografar";
import assinaturaQuadrosService from "../../service/assinaturaQuadrosService";


const lancamentoPrestadorEditar = {
  id: null,
  prestadorServicos: [],
  unidade: [],
  statusLancamento: null,
  anoLancamento: "",
  mesLancamento: "",
};

const edicaoAddServMotivo = {
  id: null,
  lancamentoPrestador: [],
  servidor: [],
  motivosSubstituicao: [],
  unidade: [],
};

const datasAulas = {
  servidorMotivoSubstituicao: [],
  lancamentoPrestador: [],
  prestadorServicos: [],
  totalAulas: 0,
  data: [],
  anoLancamento: "",
  mesLancamento: "",
  unidade: [],
};

const aulasParaSomar = {
  id: null,
  servidorMotivoSubstituicao: [],
  lancamentoPrestador: [],
  totalAulas: 0,
  data: Date(),
};

const dadosEdicaoSubRecibos = {
  //id: null,
  lancamentoPrestador: [],
  prestadorServico: [],
  unidade: [],
  servidor: [],
  motivosSubstituicao: [],
  mesLancamento: "",
  anoLancamento: "",
  descricaoUnidade: "",
  servidorSubstituido: "",
  motivo: "",
  totalAulas: 0,
  horaAula: 0,
};

const serviceSM = new ServidorMotivoSub();
const serviceDSP = new dadosSubPrestador();
const serviceDSR = new dadosSubRecibos();
const serviceLP = new lancamentoPrestadorService();
const serviceCR = new calculosRecibosService();
const serviceS = new ServidorService();
const serviceM = new MotivosSubstituicaoService();
const serviceAssinaturaDigital = new assinaturaQuadrosService()


const EdicaoLancamentosPrestador = ({ id }) => {
   // PROTRGER A ROTA PARA EDIÇÃO DE PRESTADORES QUE NÃO PERTENCEM A UNIDADE DO USUARIO LOGADO
  const unidadeCriptografado = LocalStorageService.obterItem('3')
  const unidadeUsuarioLogado = descriptografar(unidadeCriptografado)

  
  const [lancamentoPrestador, setLancamentoPrestador] = useState(
    id ? lancamentoPrestadorEditar : null
  );

  const [loading, setLoading] = useState(false);

  const [somaAulasPorId, setSomaAulasPorId] = useState(
    id ? [aulasParaSomar] : []
  );

  //carregar lista de servidores motivos
  const [servidoresMotivos, setServidoresMotivos] = useState([]);
  const [atualizarLista, setAtualizarLista] = useState(false);

  const [liberarEdicao, setLiberarEdicao] = useState(null)

  //Exibir modal de Adicionar Datas
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // eslint-disable-next-line no-unused-vars
  const [datasAulasSub, setDatasAulas] = useState([datasAulas]);

  //Exibir modal de Servidor e Motivo
  const [showSerMot, setShowSerMot] = useState(false);
  const handleCloseSerMot = () => setShowSerMot(false);
  const handleShowSerMot = () => setShowSerMot(true);

  //Exibir modal de Editar Servidor e Motivo
  const [showSerMotAtualizar, setShowSerMotAtualizar] = useState(false);
  const handleCloseSerMotAtualizar = () => setShowSerMotAtualizar(false);
  const handleShowSerMotAtualizar = () => setShowSerMotAtualizar(true);
  const [addServMotivo, setAddServMotivo] = useState(edicaoAddServMotivo);
  const [botaoAdicionar, setBotaoAdicionar] = useState(false);

  //modal deletar
  const [showModalDeletar, setShowModalDeletar] = useState(false);
  const handleCloseDeletar = () => setShowModalDeletar(false);
  const handleShowModalDeletar = () => setShowModalDeletar(true);
  const [idDeletar, setIdDeletar] = useState(0);

  //Dados para modal de Adicionar Datas
  const [datas, setDatas] = useState([]);
  const [aulas, setAulas] = useState(0);

  //Dados para modal de Servidor e Motivo
  const [listaServidores, setListaServidores] = useState([]);
  const [listarMotivos, setListarMotivos] = useState([]);
  const [idServidor, setIdServidor] = useState([]);
  const [idMotivo, setIdMotivo] = useState([]);
  const [aulasLivres, setAulasLivres] = useState(false)

  const alterarEstadoAulasLivres =  () => {
    setAulasLivres(!aulasLivres)
    if (aulasLivres) {
      setIdServidor([])
    }
}

  //Manipulação das substituições para os recibos
  const [dadosParaSubRecibos, setDadosParaSubRecibos] = useState(
    dadosEdicaoSubRecibos
  );

  let redirecionar = useHistory();

  useEffect(() => {
    if (id) {
      async function carregarLancamentoParaEditar() {
        serviceLP.buscarPorId(id)
        .then((p) => {
          setLancamentoPrestador(p.data);
          setBotaoAdicionar(p.data.statusLancamento)         
        })
      }
      
      async function carregarServidoresMotivos() {
        const response = await serviceSM.listarPorIdLancamento(id);
        setServidoresMotivos(response.data);
      }
      async function carregarAulasParaSomar() {
        const response = await serviceDSP.somaAulas(id);
        setSomaAulasPorId(response.data);
      }
      
      carregarLancamentoParaEditar();
      carregarServidoresMotivos();
      carregarAulasParaSomar();

      
      //console.log(liberarEdicao);
      //console.log("lancamentoPrestador")
      //console.log(lancamentoPrestador)
      //console.log("servidoresMotivos")
      //console.log(servidoresMotivos)
      //console.log("somaAulasPorId")
      //console.log(somaAulasPorId)

    }
    if (Number(lancamentoPrestador.unidade.id) === Number(unidadeUsuarioLogado) && lancamentoPrestador.statusLancamento) {
      setLiberarEdicao(true)
    } else {
      setLiberarEdicao(false)
    }
  }, [
    lancamentoPrestador.prestadorServicos.nome,
    show,
    showSerMot,
    showSerMotAtualizar,
    atualizarLista,
    showModalDeletar,
    idMotivo,
    idServidor,
    addServMotivo,
    edicaoAddServMotivo,
    somaAulasPorId.length,
    loading,
  ]);

 

  let idUnidade = lancamentoPrestador.unidade.id;
  let ano = lancamentoPrestador.anoLancamento;
  let mes = lancamentoPrestador.mesLancamento;

  useEffect(() => {
    if (lancamentoPrestador.id) {
      const serviceLS = new LancamentoServidorService();
      async function carregarListaServidoresDoMes() {
        const response = await serviceLS.listarPorUnidAnoMes(
          idUnidade,
          ano,
          mes
        );
        setListaServidores(response.data);
      }

      const serviceMS = new MotivosSubstituicaoService();
      async function carregarMotivosSubstituicao() {
        const response = await serviceMS.listarAtivos();
        setListarMotivos(response.data);
      }
      carregarMotivosSubstituicao();

      carregarListaServidoresDoMes();
      //console.log("Lista Servidores do mes:");
      //console.log(listaServidores);
    } else {
    }
  }, [
    showSerMot,
    showSerMotAtualizar,
    idMotivo,
    datasAulas,
    idServidor,
    atualizarLista,
    loading,
  ]);

  /* useEffect(() => {
  }, [
    lancamentoPrestador,
    showSerMot,
    showSerMotAtualizar,
    idMotivo,
    datasAulas,
    idServidor,
    somaAulasPorId,
    atualizarLista,
    loading,
  ]);
 */
  let idSmEditar;
  let ime;
  let ise;

  function servidorMotivoAtualizar(e, idm, ids) {
    ime = idm;
    ise = ids;
    idSmEditar = e;
    //console.log(idSmEditar)
    serviceSM.buscarSM(idSmEditar).then((res) => {
      setAddServMotivo(res.data);
      //console.log(res.data)
      edicaoAddServMotivo.lancamentoPrestador = res.data.lancamentoPrestador;
      edicaoAddServMotivo.motivosSubstituicao = res.data.motivosSubstituicao;
      edicaoAddServMotivo.servidor = res.data.servidor;
      edicaoAddServMotivo.unidade = res.data.unidade;
      edicaoAddServMotivo.id = res.data.id;

      serviceDSR
        .localizar(
          ano,
          mes,
          Number(id),
          Number(lancamentoPrestador.prestadorServicos.id),
          Number(lancamentoPrestador.unidade.id),
          Number(res.data.servidor.id),
          Number(res.data.motivosSubstituicao.id)
        )
        .then((e) => {
          //console.log(e.data)
          dadosEdicaoSubRecibos.id = e.data.id;
          dadosEdicaoSubRecibos.unidade = { id: Number(e.data.unidade.id) };
          dadosEdicaoSubRecibos.lancamentoPrestador = {
            id: Number(e.data.lancamentoPrestador.id),
          };
          dadosEdicaoSubRecibos.prestadorServico = {
            id: Number(e.data.prestadorServico.id),
          };
          dadosEdicaoSubRecibos.mesLancamento = e.data.mesLancamento;
          dadosEdicaoSubRecibos.anoLancamento = e.data.anoLancamento;
          dadosEdicaoSubRecibos.descricaoUnidade = e.data.descricaoUnidade;
          dadosEdicaoSubRecibos.servidor = { id: Number(e.data.servidor.id) };
          dadosEdicaoSubRecibos.servidorSubstituido =
            e.data.servidorSubstituido;
          dadosEdicaoSubRecibos.motivosSubstituicao = {
            id: Number(e.data.motivosSubstituicao.id),
          };
          dadosEdicaoSubRecibos.motivo = e.data.motivo;
          dadosEdicaoSubRecibos.totalAulas = e.data.totalAulas;
          setDadosParaSubRecibos(dadosEdicaoSubRecibos);
          //console.log("para atualizar dadosParaSubRecibos")
          //console.log(dadosParaSubRecibos)
        });

      //setAddServMotivo(edicaoAddServMotivo)
      //console.log("addServMotivo")
      //console.log(addServMotivo)
    });
    setIdMotivo(Number(ime));
    //console.log("IdMotivo")
    //console.log(idMotivo)
    setIdServidor(Number(ise));
    //console.log("IdServidor")
    //console.log(idServidor)
    mudarstatus();
    handleShowSerMotAtualizar();
  }

  function atualizarServidorMotivo() {
    setLoading(true);
    edicaoAddServMotivo.motivosSubstituicao = { id: Number(idMotivo) };
    edicaoAddServMotivo.servidor = { id: Number(idServidor) };

    //console.log("Servidor Motivo Atualizado")
    //console.log(idMotivo, idServidor)
    //console.log("Dados para atualizar")
    //console.log(edicaoAddServMotivo)
    serviceSM
      .atualizar(edicaoAddServMotivo)
      .then(() => {
        setLoading(false);
        mudarstatus();

        serviceM.buscarId(idMotivo).then((m) => {
          dadosParaSubRecibos.motivo = m.data.descricao;
          dadosParaSubRecibos.motivosSubstituicao = { id: Number(m.data.id) };
          serviceS.buscarPorId(idServidor).then((s) => {
            dadosParaSubRecibos.servidorSubstituido = s.data.nomeCompleto;
            dadosParaSubRecibos.servidor = { id: Number(s.data.id) };
            serviceDSR.atualizarRecibos(dadosParaSubRecibos);
            //console.log("atualizado dadosParaSubRecibos:");
            //console.log(dadosParaSubRecibos);
            dadosEdicaoSubRecibos.id = null;
            dadosEdicaoSubRecibos.lancamentoPrestador = [];
            dadosEdicaoSubRecibos.prestadorServico = [];
            dadosEdicaoSubRecibos.unidade = [];
            dadosEdicaoSubRecibos.servidor = [];
            dadosEdicaoSubRecibos.motivosSubstituicao = [];
            dadosEdicaoSubRecibos.mesLancamento = "";
            dadosEdicaoSubRecibos.anoLancamento = "";
            dadosEdicaoSubRecibos.descricaoUnidade = "";
            dadosEdicaoSubRecibos.servidorSubstituido = "";
            dadosEdicaoSubRecibos.motivo = "";
            dadosEdicaoSubRecibos.totalAulas = 0;
          });
        });

        handleCloseSerMotAtualizar();
      })
      .catch((e) => {
        mensagemErro(e.response.data);
        setLoading(false);
        mudarstatus();
        handleCloseSerMotAtualizar();
      });
  }

  function fecharModalSMLimpar() {
    setAddServMotivo(edicaoAddServMotivo);
    mudarstatus();
    handleCloseSerMotAtualizar();
  }

  function adicionarDatasAula(e, Mot, Serv) {
    serviceAssinaturaDigital.verificarSeAssinado(Number(idUnidade), ano, mes, "TEMPORARIO")
    .then((s) => {
        if (s.data > 0) {
            mensagemErro("O Quadro de Substituto Temporário está ASSINADO, para prosseguir com alterações nos Lançamentos de Substitutos Temporários deve apagar a assinatura.")
        }
        if (s.data === 0) {
    
            idSmEditar = e;
            datasAulas.servidorMotivoSubstituicao = { id: idSmEditar };
            datasAulas.lancamentoPrestador = { id: id };
            datasAulas.prestadorServicos = {
              id: lancamentoPrestador.prestadorServicos.id,
            };
            datasAulas.unidade = { id: lancamentoPrestador.unidade.id };
            //console.log(dadosParaSubRecibos)
            serviceDSR
              .localizar(
                ano,
                mes,
                Number(id),
                Number(lancamentoPrestador.prestadorServicos.id),
                Number(lancamentoPrestador.unidade.id),
                Number(Serv),
                Number(Mot)
              )
              .then((e) => {
                //console.log(e.data)
                dadosEdicaoSubRecibos.id = e.data.id;
                dadosEdicaoSubRecibos.unidade = { id: Number(e.data.unidade.id) };
                dadosEdicaoSubRecibos.lancamentoPrestador = {
                  id: Number(e.data.lancamentoPrestador.id),
                };
                dadosEdicaoSubRecibos.prestadorServico = {
                  id: Number(e.data.prestadorServico.id),
                };
                dadosEdicaoSubRecibos.mesLancamento = e.data.mesLancamento;
                dadosEdicaoSubRecibos.anoLancamento = e.data.anoLancamento;
                dadosEdicaoSubRecibos.descricaoUnidade = e.data.descricaoUnidade;
                dadosEdicaoSubRecibos.servidor = e.data.servidor !== null ? { id: Number(e.data.servidor.id) } : null
                dadosEdicaoSubRecibos.servidorSubstituido = e.data.servidorSubstituido;
                dadosEdicaoSubRecibos.motivosSubstituicao = {
                  id: Number(e.data.motivosSubstituicao.id),
                };
                dadosEdicaoSubRecibos.motivo = e.data.motivo;
                setDadosParaSubRecibos(dadosEdicaoSubRecibos);
                //console.log("para Adicionar aulas dadosParaSubRecibos")
                //console.log(dadosParaSubRecibos)
              });

            //console.log(idSmEditar)
            //console.log("Aulas por dia:");
            //console.log(aulas);
            //console.log("datas:");
            //console.log(datas.toString().split(","));
            handleShow()
          }
        })

  }

  function salvarDatasAulas() {
    datasAulas.totalAulas = Number(aulas);
    datasAulas.data =
      datas.length === 0 ? "01-01-1999" : datas.toString().split(",");
    datasAulas.anoLancamento = ano.toString();
    datasAulas.mesLancamento = mes.toString();
    //console.log(datasAulas);
    serviceDSP
      .cadastrarDatas(
        datasAulas.lancamentoPrestador.id,
        datasAulas.servidorMotivoSubstituicao.id,
        datasAulas.totalAulas,
        datasAulas.data,
        datasAulas.prestadorServicos.id,
        datasAulas.anoLancamento,
        datasAulas.mesLancamento,
        datasAulas.unidade.id
      )
      .then(() => {
        serviceCR.atualizar(
          datasAulas.prestadorServicos.id,
          datasAulas.anoLancamento,
          datasAulas.mesLancamento
        );
        dadosParaSubRecibos.totalAulas =
          datas.length * aulas +
          (0 + somarAulasDoId(datasAulas.servidorMotivoSubstituicao.id));
        serviceDSR.salvarRecibos(dadosParaSubRecibos);
        //console.log("salvo os dadosParaSubRecibos:");
        //console.log(dadosParaSubRecibos);
        dadosEdicaoSubRecibos.id = null;
        dadosEdicaoSubRecibos.lancamentoPrestador = [];
        dadosEdicaoSubRecibos.prestadorServico = [];
        dadosEdicaoSubRecibos.unidade = [];
        dadosEdicaoSubRecibos.servidor = [];
        dadosEdicaoSubRecibos.motivosSubstituicao = [];
        dadosEdicaoSubRecibos.mesLancamento = "";
        dadosEdicaoSubRecibos.anoLancamento = "";
        dadosEdicaoSubRecibos.descricaoUnidade = "";
        dadosEdicaoSubRecibos.servidorSubstituido = "";
        dadosEdicaoSubRecibos.motivo = "";
        dadosEdicaoSubRecibos.totalAulas = 0;
        //console.log("limpo apos salvo dadosParaSubRecibos:");
        //console.log(dadosParaSubRecibos);

        //console.log(somarAulasDoId(datasAulas.servidorMotivoSubstituicao.id))
        //console.log("Após salvar aulas dadosParaSubRecibos")
        //console.log(dadosParaSubRecibos)
        //multiplicar tamanho do array das data pelo numero de aulas

        handleClose();
      })
      .catch((f) => {
        mensagemErro(f.response.data);
        handleClose();
      });

    //adicionar um SetTimeOut para o aguardo do salvamento do array no banco
  }

  function mudarstatus() {
    if (atualizarLista) {
      setAtualizarLista(false);
    } else if (!atualizarLista) {
      setAtualizarLista(true);
    }
  }

  function adicionarServidorMotivo() {
    setLoading(true);
    if (!aulasLivres) {
      if (idServidor === null) {
        mensagemErro('O servidor deve ser Informado.')
        return false
      }
    }
    edicaoAddServMotivo.id = null;
    edicaoAddServMotivo.lancamentoPrestador = { id: Number(id) };
    edicaoAddServMotivo.motivosSubstituicao = { id: Number(idMotivo) };
    edicaoAddServMotivo.servidor = { id: aulasLivres ? 0 : Number(idServidor) };
    edicaoAddServMotivo.unidade = {
      id: Number(lancamentoPrestador.unidade.id),
    };
    serviceSM
      .salvar(edicaoAddServMotivo)
      .then((s) => {
        setLoading(false);
        mudarstatus();
        adicionarDadosRecibo(idServidor, idMotivo);
        mensagemSucesso("Servidor e Motivo adicionado.");
        handleCloseSerMot();
      })
      .catch((e) => {
        setLoading(false);
        mensagemErro(e.response.data);
      });
  }

  function adicionarDadosRecibo(servidor, motivo) {
    dadosParaSubRecibos.unidade = { id: Number(lancamentoPrestador.unidade.id)}
    dadosParaSubRecibos.lancamentoPrestador = { id: Number(id) };
    dadosParaSubRecibos.prestadorServico = { id: Number(lancamentoPrestador.prestadorServicos.id)}
    dadosParaSubRecibos.mesLancamento = mes;
    dadosParaSubRecibos.anoLancamento = ano;
    dadosParaSubRecibos.descricaoUnidade = lancamentoPrestador.unidade.nome;

    serviceM.buscarId(motivo).then((m) => {
      dadosParaSubRecibos.motivo = m.data.descricao;
      dadosParaSubRecibos.motivosSubstituicao = { id: Number(m.data.id) };
      if (servidor !== null || servidor > 0) {
        serviceS.buscarPorId(servidor).then((s) => {
          dadosParaSubRecibos.servidorSubstituido = s.data.nomeCompleto
          dadosParaSubRecibos.servidor = s.data !== null ? { id: Number(s.data.id) } : null
          serviceDSR.salvarRecibos(dadosParaSubRecibos);
          dadosEdicaoSubRecibos.id = null;
          dadosEdicaoSubRecibos.lancamentoPrestador = [];
          dadosEdicaoSubRecibos.prestadorServico = [];
          dadosEdicaoSubRecibos.unidade = [];
          dadosEdicaoSubRecibos.servidor = [];
          dadosEdicaoSubRecibos.motivosSubstituicao = [];
          dadosEdicaoSubRecibos.mesLancamento = "";
          dadosEdicaoSubRecibos.anoLancamento = "";
          dadosEdicaoSubRecibos.descricaoUnidade = "";
          dadosEdicaoSubRecibos.servidorSubstituido = "";
          dadosEdicaoSubRecibos.motivo = "";
          dadosEdicaoSubRecibos.totalAulas = 0;
        });
      } else
      {
        dadosParaSubRecibos.servidorSubstituido = 'Aulas Livres'
        dadosParaSubRecibos.servidor = null
        serviceDSR.salvarRecibos(dadosParaSubRecibos);
        dadosEdicaoSubRecibos.id = null;
        dadosEdicaoSubRecibos.lancamentoPrestador = [];
        dadosEdicaoSubRecibos.prestadorServico = [];
        dadosEdicaoSubRecibos.unidade = [];
        dadosEdicaoSubRecibos.servidor = [];
        dadosEdicaoSubRecibos.motivosSubstituicao = [];
        dadosEdicaoSubRecibos.mesLancamento = "";
        dadosEdicaoSubRecibos.anoLancamento = "";
        dadosEdicaoSubRecibos.descricaoUnidade = "";
        dadosEdicaoSubRecibos.servidorSubstituido = "";
        dadosEdicaoSubRecibos.motivo = "";
        dadosEdicaoSubRecibos.totalAulas = 0;
      }
      
    });
  }

  //limpa os IDs para selecionar novos para salvar
  function addNovoServidorMotivo() {
    serviceAssinaturaDigital.verificarSeAssinado(Number(idUnidade), ano, mes, "TEMPORARIO")
    .then((s) => {
        if (s.data > 0) {
            mensagemErro("O Quadro de Substituto Temporário está ASSINADO, para prosseguir com alterações nos Lançamentos de Substitutos Temporários deve apagar a assinatura.")
        }
        if (s.data === 0) {
        setIdMotivo(null);
        setIdServidor(null);
        setAulasLivres(false)
        dadosEdicaoSubRecibos.id = null;
        dadosEdicaoSubRecibos.lancamentoPrestador = [];
        dadosEdicaoSubRecibos.prestadorServico = [];
        dadosEdicaoSubRecibos.unidade = [];
        dadosEdicaoSubRecibos.servidor = [];
        dadosEdicaoSubRecibos.motivosSubstituicao = [];
        dadosEdicaoSubRecibos.mesLancamento = "";
        dadosEdicaoSubRecibos.anoLancamento = "";
        dadosEdicaoSubRecibos.descricaoUnidade = "";
        dadosEdicaoSubRecibos.servidorSubstituido = "";
        dadosEdicaoSubRecibos.motivo = "";
        dadosEdicaoSubRecibos.totalAulas = 0;
        mudarstatus()
        handleShowSerMot()
        }
    })
  }

  function somarAulasDoId(idSomar) {
    let soma = 0;
    for (let i = 0; i < somaAulasPorId.length; i++) {
      if (idSomar === somaAulasPorId[i].servidorMotivoSubstituicao.id) {
        soma += somaAulasPorId[i].totalAulas;
      }
    }

    return soma;
  }

  function exibirTotalGeral() {
    let soma = 0;
    for (let i = 0; i < somaAulasPorId.length; i++) {
      soma += somaAulasPorId[i].totalAulas;
    }
    return soma;
  }

  function deletarServLanc() {
    //console.log(idDeletar)
    serviceDSP.excluirTodosIdServidorMotivo(idDeletar).then(() => {
      serviceSM.excluir(idDeletar).then((response) => {
        mensagemSucesso(response.data);
        serviceCR.atualizar(
          lancamentoPrestador.prestadorServicos.id,
          lancamentoPrestador.anoLancamento,
          lancamentoPrestador.mesLancamento
        );
        //console.log("excluir dadosParaSubRecibos")
        //console.log(dadosParaSubRecibos)
        //console.log(dadosParaSubRecibos.id)
        serviceDSR.deletarRecibo(dadosParaSubRecibos.id);
        dadosEdicaoSubRecibos.id = null;
        dadosEdicaoSubRecibos.lancamentoPrestador = [];
        dadosEdicaoSubRecibos.prestadorServico = [];
        dadosEdicaoSubRecibos.unidade = [];
        dadosEdicaoSubRecibos.servidor = [];
        dadosEdicaoSubRecibos.motivosSubstituicao = [];
        dadosEdicaoSubRecibos.mesLancamento = "";
        dadosEdicaoSubRecibos.anoLancamento = "";
        dadosEdicaoSubRecibos.descricaoUnidade = "";
        dadosEdicaoSubRecibos.servidorSubstituido = "";
        dadosEdicaoSubRecibos.motivo = "";
        dadosEdicaoSubRecibos.totalAulas = 0;
        //console.log("limpo apos excluir dadosParaSubRecibos")
        //console.log(dadosParaSubRecibos)
        handleCloseDeletar();
      });
    });
  }

  function deletar(idDel, idMot, idServ) {
    setIdDeletar(idDel);
    serviceAssinaturaDigital.verificarSeAssinado(Number(idUnidade), ano, mes, "TEMPORARIO")
    .then((s) => {
        if (s.data > 0) {
            mensagemErro("O Quadro de Substituto Temporário está ASSINADO, para prosseguir com alterações nos Lançamentos de Substitutos Temporários deve apagar a assinatura.")
        }
        if (s.data === 0) {
          serviceDSR
            .localizar(
              ano,
              mes,
              Number(id),
              Number(lancamentoPrestador.prestadorServicos.id),
              Number(lancamentoPrestador.unidade.id),
              Number(idServ),
              Number(idMot)
            )
            .then((e) => {
              //console.log(e.data)
              dadosEdicaoSubRecibos.id = e.data.id;
              dadosEdicaoSubRecibos.unidade = { id: Number(e.data.unidade.id) };
              dadosEdicaoSubRecibos.lancamentoPrestador = {
                id: Number(e.data.lancamentoPrestador.id),
              };
              dadosEdicaoSubRecibos.prestadorServico = {
                id: Number(e.data.prestadorServico.id),
              };
              dadosEdicaoSubRecibos.mesLancamento = e.data.mesLancamento;
              dadosEdicaoSubRecibos.anoLancamento = e.data.anoLancamento;
              dadosEdicaoSubRecibos.descricaoUnidade = e.data.descricaoUnidade;
              dadosEdicaoSubRecibos.servidor = e.data.servidor !== null ? { id: Number(e.data.servidor.id) } : null
              dadosEdicaoSubRecibos.servidorSubstituido = e.data.servidorSubstituido;
              dadosEdicaoSubRecibos.motivosSubstituicao = {
                id: Number(e.data.motivosSubstituicao.id),
              };
              dadosEdicaoSubRecibos.motivo = e.data.motivo;
              setDadosParaSubRecibos(dadosEdicaoSubRecibos);
              //console.log("dadosParaSubRecibos")
              //console.log(dadosParaSubRecibos)
              handleShowModalDeletar();
            })
          }
    })
  }

  function voltarTelaLancamentos() {
    redirecionar.push("/prestador/lanc");
  }

  function somarAulasPorMotivoServidor(idSomar){
    serviceDSP.somaAulasPorMotivoServidor(idSomar)  
  }

  return (
    <>
      {liberarEdicao ? 
        <div className="container mt-6">
          <div className="form-group">
            <div className="card md-3">
              <h4 className="card-header">
                Edição de Lançamentos do Substituto Temporário
              </h4>

              <div>
                <Container>
                  <Row className="justify-content-md-center">
                    <Col md={4}>
                      <label className="col-form-label" htmlFor="nomePrestador">
                        Substituto Temporário
                      </label>
                      <input
                        id="nomePrestador"
                        name="nomePrestador"
                        type="text"
                        value={lancamentoPrestador.prestadorServicos.nome}
                        disabled="true"
                        className="form-control"
                      />
                    </Col>

                    <Col md={2}>
                      <label className="col-form-label" htmlFor="mesAno">
                        Mês/Ano:
                      </label>
                      <input
                        id="mesAno"
                        name="mesAno"
                        type="text"
                        disabled="true"
                        className="form-control"
                        value={`${lancamentoPrestador.mesLancamento}/${lancamentoPrestador.anoLancamento}`}
                      />
                    </Col>
                    <Col md={3}>
                      <br />
                      {botaoAdicionar &&
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={addNovoServidorMotivo}
                      >
                        Adicionar Substituição
                      </button>}
                    </Col>
                    <Col md={1}>
                      <br />
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={voltarTelaLancamentos}
                      >
                        Voltar
                      </button>
                    </Col>
                  </Row>
                </Container>
                <hr />
              </div>

              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col" style={{ textAlign: "center" }}>
                      Servidor Substituído
                    </th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      Motivo
                    </th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      Total Aulas
                    </th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      Ações
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {servidoresMotivos.map((sm) => {
                    return (
                      <tr key={sm.id}>
                        <td style={{ maxWidth: "300px", textAlign: "center" }}>
                          {sm.servidor !== null ? sm.servidor.nomeCompleto : 'Aulas Avulsas'}
                        </td>

                        <td style={{ textAlign: "center" }}>
                          {sm.motivosSubstituicao.descricao}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {somarAulasDoId(sm.id)} 
                        </td>
                        <td style={{ textAlign: "center" }}>
                        {sm.lancamentoPrestador.statusLancamento === true ?
                        (<>
                          <button
                            style={{ height: "30px", paddingTop: "0px" }}
                            className="btn btn-primary"
                            type="button"
                            onClick={(e) =>
                              adicionarDatasAula(
                                sm.id,
                                sm.motivosSubstituicao.id,
                                sm.servidor !== null ? sm.servidor.id : null
                              )
                            }
                            title="Adicionar datas e quantidades de aulas"
                          >
                            Datas
                          </button>{" "}
                          <button
                            style={{
                              width: "70px",
                              height: "30px",
                              padding: "1px",
                            }}
                            className="btn btn-primary"
                            title="Editar datas e quantidades de aulas"
                          >
                            <Link
                              style={{
                                textDecoration: "none",
                                color: "#fff",
                              }}
                              to={`/prestador/eddatas/${sm.id}`}
                            >
                              Editar
                            </Link>
                          </button>{" "}
                          {/* {sm.servidor !== null &&
                          <button
                            style={{ height: "30px", paddingTop: "0px" }}
                            className="btn btn-primary"
                            type="button"
                            onClick={(e) =>
                              servidorMotivoAtualizar(
                                sm.id,
                                sm.motivosSubstituicao.id,
                                sm.servidor.id
                              )
                            }
                            title="Alterar Servidor Substituído ou Motivo"
                          >
                            Servidor
                          </button>
                          } */}
                          <button
                            style={{ height: "30px", paddingTop: "0px" }}
                            className="btn btn-danger"
                            type="button"
                            title="AVISO: Será excluído todas as datas vínculadas a esta substituição!"
                            onClick={(e) =>
                              deletar(
                                sm.id,
                                sm.motivosSubstituicao.id,
                                sm.servidor !== null ? sm.servidor.id : null
                              )
                            }
                          >
                            Excluir
                          </button>
                          </>)
                        : <></>}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div style={{ textAlign: "center" }}>
                Total de Substituições: <b>{exibirTotalGeral()} </b>{" "}
              </div>
            </div>
          </div>
        </div>
        : <h5 style={{textAlign: "center", color: "#f80707"}}>Edição não permitida</h5>}
        <Modal
          closeButton={true}
          show={show}
          onHide={handleClose}
          backdrop="static"
          centered
          keyboard={false}
          size="lg"
        >
          <Modal.Header className="card-header" closeButton>
            <Modal.Title>Datas da Substituição</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row></Row>

            <div className="container">
              <Row></Row>
              <Row>
                <Col>
                  <label className="col-form-label" htmlFor="datas">
                    Datas das substituições:
                  </label>
                  <Row>
                    <DatePicker
                      className="mb-3"
                      onChange={setDatas}
                      id="datas"
                      name="datas"
                      numberOfMonths={2}
                      multiple
                      
                      mapDays={({ date }) => {
                        let props = {};
                        let isWeekend = [0, 6].includes(date.weekDay.index);
                        /* if (isWeekend)
                        props.className = "highlight highlight-red";
                        return props */
                        
                        /* DESABILITADO FINAIS DE SEMANAS PARA SUBSTITUTOS TEMPORÁRIOS */
                          if (isWeekend) return {
                            disabled: true,
                            style: { color: "#ccc" }
                          }
                      }}
                      sort
                      format="DD-MM-YYYY"
                      locale={calendarioPersonalizado}
                      plugins={[<DatePanel />]}
                      style={{
                        width: "100%",
                        height: "36px",
                        padding: "12px 20px",
                        boxSizing: "border-box",
                      }}
                    />
                  </Row>
                </Col>
                <Col md={2}>
                  <label className="col-form-label" htmlFor="aulas">
                    Aulas por dia:
                  </label>
                  <input
                    id="aulas"
                    name="aulas"
                    type="number"
                    min={1}
                    max={8}
                    className="form-control"
                    onChange={(e) => setAulas(e.target.value)}
                  />
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              type="button"
              onClick={(e) => salvarDatasAulas()}
            >
              Salvar
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          closeButton={true}
          show={showSerMot}
          onHide={handleCloseSerMot}
          backdrop="static"
          centered
          keyboard={false}
          size="lg"
        >
          <Modal.Header className="card-header" closeButton>
            <Modal.Title>Servidor e Motivo</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row></Row>

            <div className="container">
              <Row>
                  <Col md={2}>
                  <label style={{fontWeight: 500}} className="col-form-label" htmlFor="diariaAvulsa">Aulas Livres?:</label><br/>
                  {['right'].map((placement) => (
                      <OverlayTrigger
                      key={placement}
                      placement={placement}
                      overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                          Marcar esse campo quando o Substituto Temporário não substituíu outro servidor.
                          </Tooltip>
                      }
                      >
                          <input type="checkbox"
                              style={{width: 70, height: 36}} 
                              id="diariaAvulsa" 
                              checked={aulasLivres}
                              onChange={alterarEstadoAulasLivres}
                          />
                      </OverlayTrigger>
                  ))}
              </Col>
              {!aulasLivres &&
              <>
                <Col>
                  <label className="col-form-label" htmlFor="servidor.id">
                    Substituiu o Servidor:
                  </label>
                  {}
                  <Form.Select
                    id="servidor.id"
                    name="servidor.id"
                    value={idServidor}
                    onChange={(e) => setIdServidor(e.target.value)}
                  >
                    <option value="invalido">Selecione o Servidor</option>

                    {listaServidores.map((ls) => (
                      <option value={ls.servidor.id} key={ls.id}>
                        {ls.servidor.nomeCompleto}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                </>}
                <Col>
                  <label
                    className="col-form-label"
                    htmlFor="motivosSubstituicao.id"
                  >
                    {aulasLivres ? 'Motivo das Aulas Livres' : 'Motivo da Substituição'}:
                  </label>
                  <Form.Select
                    id="motivosSubstituicao.id"
                    name="motivosSubstituicao.id"
                    value={idMotivo}
                    onChange={(e) => setIdMotivo(e.target.value)}
                  >
                    <option value="invalido">Selecione o Motivo</option>
                    {listarMotivos.map((m) => (
                      <option value={m.id} key={m.id}>
                        {m.descricao}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              type="button"
              onClick={(e) => adicionarServidorMotivo()}
            >
              Adicionar Substituição
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          closeButton={true}
          show={showSerMotAtualizar}
          onHide={handleCloseSerMotAtualizar}
          backdrop="static"
          centered
          keyboard={false}
          size="lg"
        >
          <Modal.Header className="card-header" closeButton>
            <Modal.Title>Editar Servidor e Motivo</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row></Row>

            <div className="container">
              <Row></Row>
              <Row>
                <Col>
                  <label className="col-form-label" htmlFor="servidor.id">
                    Substituiu o Servidor:
                  </label>
                  {}
                  <Form.Select
                    id="servidor.id"
                    name="servidor.id"
                    value={idServidor}
                    onChange={(e) => setIdServidor(e.target.value)}
                  >
                    <option>{edicaoAddServMotivo.servidor.nomeCompleto}</option>

                    {listaServidores.map((ls) => (
                      <option value={ls.servidor.id} key={ls.id}>
                        {ls.servidor.nomeCompleto}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col>
                  <label
                    className="col-form-label"
                    htmlFor="motivosSubstituicao.id"
                  >
                    Motivo da substituição:
                  </label>
                  <Form.Select
                    id="motivosSubstituicao.id"
                    name="motivosSubstituicao.id"
                    value={idMotivo}
                    onChange={(e) => setIdMotivo(e.target.value)}
                  >
                    <option>
                      {edicaoAddServMotivo.motivosSubstituicao.descricao}
                    </option>
                    {listarMotivos.map((m) => (
                      <option value={m.id} key={m.id}>
                        {m.descricao}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              type="button"
              onClick={atualizarServidorMotivo}
            >
              Salvar Alteração
            </button>
            <button
              className="btn btn-danger"
              type="button"
              onClick={fecharModalSMLimpar}
            >
              Cancelar
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showModalDeletar}
          onHide={handleCloseDeletar}
          backdrop="static"
          keyboard={false}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Excluir Substituição</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6>Tem certeza que deseja excluir a substituição e suas datas?</h6>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              type="button"
              onClick={handleCloseDeletar}
            >
              Não
            </button>
            <button
              onClick={(e) => deletarServLanc()}
              className="btn btn-danger"
              type="button"
            >
              Sim
            </button>
          </Modal.Footer>
        </Modal>
    </>
  );
};

export default EdicaoLancamentosPrestador;

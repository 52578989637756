import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";
import Form from "react-bootstrap/Form";
import { Row, Col } from "react-bootstrap";
import {
  mensagemErro,
  mensagemSucesso,
  mensagemAlerta,
} from "../../components/avisos/toastr";
import {MultiSelect} from 'primereact/multiselect'
import UsuarioService from "../../service/usuarioService";
import UnidadeService from "../../service/unidadeService";
import LocalStorageService from "../../service/localStorageService";
import posLoginService from "../../service/posLoginService";
import { descriptografar } from "../../components/criptografar";

const dadosUsuario = {
  nomeCompleto: '',
  matricula: '',
  unidade: [],
  email: '',
  telefone: '',
  login: '',
  senha: '',
  senhaRepetida: '',
  tipoUsuario: 'Selecione'
}

const dadosUsuarioUnidade = {
  usuario: [],
  matricula: 0,
  unidade: []
}

const servicePosLog = new posLoginService()
const usuarioS = new UsuarioService()
function Cadastrar() {

  
    const usuarioCriptografado = LocalStorageService.obterItem('2')
    const usuarioLogado = JSON.parse(descriptografar(usuarioCriptografado))
    const tipoUsuario = usuarioLogado ? usuarioLogado.role : ""
    const userAdmin = "ADMINISTRADOR"

    const [usuario, setUsuario] = useState(dadosUsuario);
    const [unidade, setUnidade] = useState([]);
    const [unidadeSelecionada, setUnidadeSelecionada] = useState([]);
    const [usuarioUnidade, setUsuarioUnidade] = useState(dadosUsuarioUnidade)

    let redirecionar = useHistory();

    useEffect(() => {
    const unidades = new UnidadeService();
    async function carregarUnidades() {
      const response = await unidades.listar();
      setUnidade(response.data);
    }
    carregarUnidades()
    }, [])

    function onChange(e) {
        const { name, value } = e.target;
        setUsuario({ ...usuario, [name]: value });
        //console.log(usuario)
    }

    function validar() {

    const msgs = [];
    if (usuario.nomeCompleto === '' || usuario.nomeCompleto.length < 5) {
      msgs.push("Nome Completo é obrigatório, esta vazío ou incompleto!");
      //console.log(usuario.nomeCompleto)
    }

    if (usuario.matricula === null || usuario.matricula.length < 2) {
      msgs.push("Matricula é inválida ou campo esta em branco!");
    }

    if (usuario.unidade.id === 'Selecione a Unidade' || usuario.unidade.id === null) {
      msgs.push("Local de Atuação é obrigatório!");
    }

    if (usuario.email === null) {
      msgs.push("E-mail é obrigatório!");
    } else if (
      !usuario.email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      )
    ) {
      msgs.push("E-mail inválido, favor verificar.");
    }

    if (usuario.telefone === '' || !usuario.telefone) {
      msgs.push("Telefone é obrigatório!");
    }

    if (usuario.login === '' || !usuario.login) {
      msgs.push("Login é obrigatório!");
    }

    if (usuario.tipoUsuario === null || usuario.tipoUsuario === 'Selecione') {
      msgs.push("Tipo de Usuário é obrigatório!");
    }

    if (usuario.senha === '' || !usuario.senha) {
      msgs.push("Senha é obrigatório!");
    } else if (usuario.senha.length < 4) {
        msgs.push("A Senha deve conter no mínimo 4 caracteres!");
    }

    if (usuario.senhaRepetida === '' || !usuario.senhaRepetida) {
      msgs.push("Repita sua senha de Usuário é obrigatório!");
    } else if (usuario.senha !== usuario.senhaRepetida) {
      msgs.push("Senhas não são iguais, favor redigitar.");
    }

    return msgs;
  }

  function salvarUsuarioUnidades(){
    let matricula = Number(usuario.matricula)
    if(unidadeSelecionada.length >0){
      const usuario = usuarioLogado.id
      for (let i = 0; i < unidadeSelecionada.length; i++) {
        usuarioUnidade.usuario = {id: usuario}
        usuarioUnidade.unidade = {id: unidadeSelecionada[i].id}
        usuarioUnidade.matricula = matricula
        servicePosLog.salvar(usuarioUnidade)
        .catch((e) => {
          mensagemErro(e.response.data + " Unidade: " + unidadeSelecionada[i].nome)
        })
      }
    }
  }

  function cancelar() {
    redirecionar.push("/usuario/listar");
  }

  function salvar() {
    const msgs = validar();
      if (msgs && msgs.length > 0) {
        msgs.forEach((msg, index) => {
            mensagemAlerta(msg)
        });
        return;
    } 

    usuario.telefone = RetiraMascara(usuario.telefone)
    usuario.unidade = {id: unidadeSelecionada[0].id}
    usuarioS
      .salvar(usuario)
      .then((response) => {
        mensagemSucesso("Usuário cadastrado com sucesso.");
        salvarUsuarioUnidades()
        redirecionar.push("/usuario/listar");
      })
      .catch((error) => {
        mensagemErro(error.response.data);
      });
  }
  function RetiraMascara(Obj) {
    return Obj.replace(/\D/g, '');
  }

  return (
    <div>
      <div className="container mt-4">
        <div className="form-group">
          <div className="card md-3">
            <h4 className="card-header">Cadastar Usuários</h4>
            <div className="card-body">
                <Row>
                  <Col md={2}>
                    <label className="col-form-label" htmlFor="matricula">
                      Matrícula:
                    </label>
                    <input
                      type="number"
                      min={1}
                      className="form-control"
                      placeholder="Matrícula"
                      id="matricula"
                      name="matricula"
                      autoComplete="false"
                      onChange={onChange}
                    />
                  </Col>
                  <Col md={9}>
                    <label className="col-form-label" htmlFor="nomeCompleto">
                      Nome Completo:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Nome Completo"
                      id="nomeCompleto"
                      name="nomeCompleto"
                      onChange={onChange}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={11}>
                    <label className="col-form-label" htmlFor="unidade">
                      Local de Atuação:
                    </label> <br/>
                    <div>
                        <MultiSelect value={unidadeSelecionada} options={unidade} optionLabel="nome" showSelectAll={false} style={{maxWidth: "1150px", textColor: "blue"}}
                        onChange={(u) => setUnidadeSelecionada(u.value)} display={"chip"} />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <label className="col-form-label" htmlFor="email">
                      E-mail:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Digite um email"
                      id="email"
                      name="email"
                      autoComplete="false"
                      onChange={onChange}
                    />
                  </Col>
                  <Col md={3}>
                    <label className="col-form-label" htmlFor="telefone">
                      Telefone:
                    </label>
                    <InputMask
                      mask="(99) 9 9999-9999"
                      type="text"
                      className="form-control"
                      placeholder="(00) 0 0000-0000"
                      id="telefone"
                      name="telefone"
                      onChange={onChange}
                    />
                  </Col>
                  <Col md={4}>
                    <label className="col-form-label" htmlFor="tipoUsuario">
                      Tipo de Usuário:
                    </label>
                    <Form.Select
                      defaultValue="Selecione"
                      id="tipoUsuario"
                      name="tipoUsuario"
                      onChange={onChange}
                    >
                      <option>Selecione o Tipo</option>
                      {tipoUsuario === userAdmin && <option>ADMINISTRADOR</option>}
                      <option>DIRETOR</option>
                      <option>RH</option>
                    </Form.Select>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <label className="col-form-label" htmlFor="login">
                      Nome para login:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="login"
                      id="login"
                      name="login"
                      autoComplete="false"
                      onChange={onChange}
                    />
                  </Col>
                  <Col md={3}>
                    <label className="col-form-label" htmlFor="senha">
                      Defina uma senha:
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Senha"
                      id="senha"
                      name="senha"
                      autoComplete="false"
                      onChange={onChange}
                    />
                  </Col>
                  <Col md={4}>
                    <label className="col-form-label" htmlFor="senhaRepetida">
                      Repita sua senha:
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Digite a senha novamente"
                      id="senhaRepetida"
                      name="senhaRepetida"
                      onChange={onChange}
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={1}>
                    <button
                      onClick={salvar}
                      type="button"
                      className="btn btn-primary"
                    >
                      {" "}
                      Salvar{" "}
                    </button>
                  </Col>

                  <Col md={1}>
                    <button
                      onClick={cancelar}
                      type="button"
                      className="btn btn-danger"
                    >
                      {" "}
                      Cancelar{" "}
                    </button>
                  </Col>
                </Row>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cadastrar;

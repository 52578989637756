import Form from "react-bootstrap/Form";
import { Row, Col } from "react-bootstrap";
import anoService from "../../service/anoService";
import { useEffect, useState } from "react";
//Entidade ANO já esta criada, verificar para adicionar a busca do ano no banco

const serviceAno = new anoService()
function AnoMes(props) {

  const [ano, setAno] = useState([]);

  useEffect(() => {
    async function carregarAnoLetivo() {
      const response = await serviceAno.listar()
      setAno(response.data)
    }
    carregarAnoLetivo()
  },[])

    return (
        <div>
            <Row>
          <Col style={{ maxWidth: "180px", minWidth: "180px" }}>
            <label className="col-form-label" htmlFor={props.htmlForMes}>
              Mês:
            </label>
            <Form.Select
              id={props.idMes}
              name={props.nameMes}
              value={props.valueMes}
              onChange={props.onChangeMes}
            >
              <option value="1">Janeiro</option>
              <option value="2">Fevereiro</option>
              <option value="3">Março</option>
              <option value="4">Abril</option>
              <option value="5">Maio</option>
              <option value="6">Junho</option>
              <option value="7">Julho</option>
              <option value="8">Agosto</option>
              <option value="9">Setembro</option>
              <option value="10">Outubro</option>
              <option value="11">Novembro</option>
              <option value="12">Dezembro</option>
            </Form.Select>
          </Col>

          <Col style={{ maxWidth: "120px", minWidth: "120px" }}>
            <label className="col-form-label" htmlFor={props.htmlForAno}>
              Ano:
            </label>
            <Form.Select
              id={props.idAno}
              name={props.nameAno}
              value={props.valueAno}
              onChange={props.onChangeAno}
            >
              
                    {ano.map((a, index) => (
                      <option key={index} value={a.ano}>{a.ano}</option>
                    ))}

            </Form.Select>
          </Col>
          </Row>
        </div>
    );
}

export default AnoMes;
import ApiService from "../app/apiservice";


class dadosSubRecibosService extends ApiService {

    constructor(){
        super('/dadossubrecibos')
    }

    localizar(ano, mes, idLancamento, idPrestador, idUnidade, idServidor, idMotivo){
        return this.get(`/localizar/${ano}/${mes}/${idLancamento}/${idPrestador}/${idUnidade}/${idServidor}/${idMotivo}`)
    }
    
    salvarRecibos(dados){
        return this.post('/salvar', dados)
    }

    atualizarRecibos(dados){
        return this.put('/atualizar', dados)
    }

    deletarRecibo(idDados){
        return this.delete(`/del/${idDados}`)
    }
}

export default dadosSubRecibosService;
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import LocalStorageService from "../../service/localStorageService";
import { useHistory } from "react-router-dom"
import {
  mensagemAlerta,
  mensagemErro,
  mensagemSucesso,
} from "../../components/avisos/toastr";
import inssIrrfVhService from "../../service/inssIrrfVhService";
import logsAtualizacaoesValoresService from "../../service/logsAtualizacaoesValoresService";
import gerarPdfService from "../../service/gerarPdfService";
import LoadingPage from "../landing/LandingPage";
import { descriptografar } from "../../components/criptografar";

const TabelaValores = {
  id: 1,
  inssFinal75: 0,
  inssInicial9: 0,
  inssFinal9: 0,
  inssInicial12: 0,
  inssFinal12: 0,
  inssInicial14: 0,
  inssFinal14: 0,
  irrfIsento: 0,
  irrfInicial75: 0,
  irrfFinal75: 0,
  irrfInicial15: 0,
  irrfFinal15: 0,
  irrfInicial225: 0,
  irrfFinal225: 0,
  irrfInicial275: 0,
  deducaoIrrfIsento: 0,
	deducaoIrrf75: 0,
	deducaoIrrf15: 0,
	deducaoIrrf225: 0,
  deducaoIrrf275: 0,
  valorPorDepententeIrrf: 0,
  horaAula: 0
};

const LogsAtualizar = {
    inssFinal75: 0,
    inssInicial9: 0,
    inssFinal9: 0,
    inssInicial12: 0,
    inssFinal12: 0,
    inssInicial14: 0,
    inssFinal14: 0,
    irrfIsento: 0,
    irrfInicial75: 0,
    irrfFinal75: 0,
    irrfInicial15: 0,
    irrfFinal15: 0,
    irrfInicial225: 0,
    irrfFinal225: 0,
    irrfInicial275: 0,
    deducaoIrrfIsento: 0,
	  deducaoIrrf75: 0,
	  deducaoIrrf15: 0,
	  deducaoIrrf225: 0,
    deducaoIrrf275: 0,
    valorPorDepententeIrrf: 0,
    horaAula: 0,
    dataAtualizacao: new Date(),
    usuario: ''
  };

const serviceValores = new inssIrrfVhService();
const serviceLogs = new logsAtualizacaoesValoresService()

function InssIrrfVh() {
  const [valores, setValores] = useState(TabelaValores);
  const [logAtualizacao, setLogAtualizacao] = useState(LogsAtualizar)
  const usuarioCriptografado = LocalStorageService.obterItem('2')
  const usuarioLogado = JSON.parse(descriptografar(usuarioCriptografado))
  const [loading, setLoading] = useState(false)
  const [habilitarDesabilitar, setHabilitarDesabilitar] = useState(true);

  let redirecionar = useHistory()

  function ativarDesativar() {
    if (habilitarDesabilitar) {
      setHabilitarDesabilitar(false);
    } else if (!habilitarDesabilitar) {
      setHabilitarDesabilitar(true);
    }
  }

  function salvarAlteracoes() {
    const v = serviceValores.carregarValores(1);
    if (v === null) {
      valores.id = 1;
      serviceValores.salvar(valores).then(() => {
        mensagemSucesso("Dados slavos com sucesso.");
        ativarDesativar();
        setTimeout(() => {
          window.location.reload()          
        }, 1000)
      });
    } else {
      ativarDesativar()
      let dataAtual = new Date()
      let dataAtualFormatada = (dataAtual.getFullYear() + "-" + ((dataAtual.getMonth()+1) <=9 ? "0" : "")+((dataAtual.getMonth() + 1)) + "-" + (dataAtual.getDate()<=9 ? "0" : "")+(dataAtual.getDate() ))
      logAtualizacao.dataAtualizacao = dataAtualFormatada
      logAtualizacao.inssFinal75 = valores.inssFinal75
      logAtualizacao.inssInicial9 = valores.inssInicial9
      logAtualizacao.inssFinal9 = valores.inssFinal9
      logAtualizacao.inssInicial12 = valores.inssInicial12
      logAtualizacao.inssFinal12 = valores.inssFinal12
      logAtualizacao.inssInicial14 = valores.inssInicial14
      logAtualizacao.inssFinal14 = valores.inssFinal14
      logAtualizacao.irrfIsento = valores.irrfIsento
      logAtualizacao.irrfInicial75 = valores.irrfInicial75
      logAtualizacao.irrfFinal75 = valores.irrfFinal75
      logAtualizacao.irrfInicial15 = valores.irrfInicial15
      logAtualizacao.irrfFinal15 = valores.irrfFinal15
      logAtualizacao.irrfInicial225 = valores.irrfInicial225
      logAtualizacao.irrfFinal225 = valores.irrfFinal225
      logAtualizacao.irrfInicial275 = valores.irrfInicial275
      logAtualizacao.horaAula = valores.horaAula
      logAtualizacao.usuario = usuarioLogado.nome
      logAtualizacao.deducaoIrrfIsento = valores.deducaoIrrfIsento
      logAtualizacao.deducaoIrrf75 = valores.deducaoIrrf75
      logAtualizacao.deducaoIrrf15 = valores.deducaoIrrf15
      logAtualizacao.deducaoIrrf225 = valores.deducaoIrrf225
      logAtualizacao.deducaoIrrf275 = valores.deducaoIrrf275
      logAtualizacao.valorPorDepententeIrrf = valores.valorPorDepententeIrrf
      //console.log(logAtualizacao)
      serviceLogs.salvar(logAtualizacao)
      serviceValores.atualizar(valores)

      mensagemSucesso("Dados slavos com sucesso.");
      setTimeout(() => {
        window.location.reload()          
      }, 1000)
      //console.log(valores);
    }
  }

  useEffect(() => {
    if (valores.id === null) {
      valores.id = 1
      valores.inssFinal75 = 0
      valores.inssInicial9 = 0
      valores.inssFinal9 = 0
      valores.inssInicial12 = 0
      valores.inssFinal12 = 0
      valores.inssInicial14 = 0
      valores.inssFinal14 = 0
      valores.irrfIsento = 0
      valores.irrfInicial75 = 0
      valores.irrfFinal75 = 0
      valores.irrfInicial15 = 0
      valores.irrfFinal15 = 0
      valores.irrfInicial225 = 0
      valores.irrfFinal225 = 0
      valores.irrfInicial275 = 0
      valores.deducaoIrrfIsento = 0
      valores.deducaoIrrf75 = 0
      valores.deducaoIrrf15 = 0
      valores.deducaoIrrf225 = 0
      valores.deducaoIrrf275 = 0
      valores.valorPorDepententeIrrf = 0
      valores.horaAula = 0
      serviceValores.salvar(valores)
      //setValores(TabelaValores);
      mensagemAlerta("Ainda não há valores definido, por favor preencher.");
      async function CarregarValores() {
        serviceValores
          .carregarValores(1)
          .then((r) => {
            //console.log(r.data);
            setValores(r.data);
           // console.log(valores);
          })
          .catch((r2) => {
            mensagemErro(r2);
          });
      }
      CarregarValores()
    } else {
      async function CarregarValores() {
        serviceValores
          .carregarValores(1)
          .then((r) => {
           // console.log(r.data);
            setValores(r.data);
           // console.log(valores);
          })
          .catch((r2) => {
            mensagemErro(r2);
          });
      }
      CarregarValores();
    }
  }, []);


  function gerarPdf(){
    setLoading(true)
    gerarPdfService("/parampag/rel/tabelageral")
    .then(() => {
			setLoading(false)
		})
  }

  function verLogs(){
      redirecionar.push('/params/logs')
  }

  function onChange(e) {
    const { name, value } = e.target;
    setValores({ ...valores, [name]: Number(value) });
  }

  return (
    <>
    {loading && <LoadingPage/> }
      <div className="container mt-6">
        <div className="form-group">
          <div className="card md-3">
            <h4 className="card-header">Tabelas de valores para cálculos</h4>

            <Container>
              <br />
              <div style={{ textAlign: "center" }}>
                {habilitarDesabilitar ? (
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={(e) => ativarDesativar()}
                  >
                    Atualizar Valores
                  </button>
                ) : (
                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={(e) => salvarAlteracoes()}
                  >
                    Salvar Alterações
                  </button>
                )}{" "}
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={(e) => gerarPdf()}
                >
                  Gerar PDF
                </button>
                {" "}
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={(e) => verLogs()}
                >
                  Histórico de Alterações
                </button>
              </div>

              <br />
              <Row className="justify-content-md">
                <Col>
                  <h5 style={{ textAlign: "center" }}>Tabela de INSS</h5>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Salário de Contribuição (R$)
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Alíquota
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ border: "1px solid" }}>
                        <td
                          style={{
                            display: "inline-flex",
                          }}
                        >
                          <div
                            style={{ paddingLeft: "30%", paddingRight: "3%" }}
                          >
                            Até
                          </div>
                          <input
                            style={{ textAlign: "center" }}
                            id="inssFinal75"
                            name="inssFinal75"
                            value={!valores ? null : valores.inssFinal75}
                            onChange={onChange}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <label htmlFor="nomePrestador">7,5%</label>
                        </td>
                      </tr>
                      <tr style={{ border: "1px solid" }}>
                        <td
                          style={{
                            display: "inline-flex",
                          }}
                        >
                          <div style={{ paddingRight: "3%" }}>De</div>
                          <input
                            style={{ textAlign: "center" }}
                            id="inssInicial9"
                            name="inssInicial9"
                            value={!valores ? null : valores.inssInicial9}
                            onChange={onChange}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                          <div
                            style={{ paddingRight: "3%", paddingLeft: "3%" }}
                          >
                            até
                          </div>
                          <input
                            style={{ textAlign: "center" }}
                            id="inssFinal9"
                            name="inssFinal9"
                            value={!valores ? null : valores.inssFinal9}
                            onChange={onChange}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <label htmlFor="nomePrestador">9%</label>
                        </td>
                      </tr>
                      <tr style={{ border: "1px solid" }}>
                        <td
                          style={{
                            display: "inline-flex",
                          }}
                        >
                          <div style={{ paddingRight: "3%" }}>De</div>
                          <input
                            style={{ textAlign: "center" }}
                            id="inssInicial12"
                            name="inssInicial12"
                            value={!valores ? null : valores.inssInicial12}
                            onChange={onChange}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                          <div
                            style={{ paddingRight: "3%", paddingLeft: "3%" }}
                          >
                            até
                          </div>
                          <input
                            style={{ textAlign: "center" }}
                            id="inssFinal12"
                            name="inssFinal12"
                            value={!valores ? null : valores.inssFinal12}
                            onChange={onChange}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <label htmlFor="nomePrestador">12%</label>
                        </td>
                      </tr>
                      <tr style={{ border: "1px solid" }}>
                        <td
                          style={{
                            display: "inline-flex",
                          }}
                        >
                          <div style={{ paddingRight: "3%" }}>De</div>
                          <input
                            style={{ textAlign: "center" }}
                            id="inssInicial14"
                            name="inssInicial14"
                            value={!valores ? null : valores.inssInicial14}
                            onChange={onChange}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                          <div
                            style={{ paddingRight: "3%", paddingLeft: "3%" }}
                          >
                            até
                          </div>
                          <input
                            style={{ textAlign: "center" }}
                            id="inssFinal14"
                            name="inssFinal14"
                            value={!valores ? null : valores.inssFinal14}
                            onChange={onChange}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <label htmlFor="nomePrestador">14%</label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>

                <Col >
                  <h5 style={{ textAlign: "center" }}>Tabela de IRRF</h5>
                  <table className="table" style={{ width: "500px" }}>
                    <thead>
                      <tr>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Rendimentos (R$)
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Alíquota
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Dedução
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ border: "1px solid" }}>
                        <td
                          style={{
                            display: "inline-flex",
                          }}
                        >
                          <div
                            style={{ paddingLeft: "30%", paddingRight: "3%" }}
                          >
                            Até
                          </div>
                          <input
                            style={{ textAlign: "center" }}
                            id="irrfIsento"
                            name="irrfIsento"
                            value={!valores ? null : valores.irrfIsento}
                            onChange={onChange}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <label htmlFor="nomePrestador">Isento</label>
                        </td>
                        <td
                          style={{
                            display: "inline-flex",
                          }}
                        >
                          <input
                            style={{ textAlign: "center", width: "100px" }}
                            id="deducaoIrrfIsento"
                            name="deducaoIrrfIsento"
                            value={!valores ? null : valores.deducaoIrrfIsento}
                            onChange={onChange}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr style={{ border: "1px solid" }}>
                        <td
                          style={{
                            display: "inline-flex",
                          }}
                        >
                          <div style={{ paddingRight: "3%" }}>De</div>
                          <input
                            style={{ textAlign: "center" }}
                            id="irrfInicial75"
                            name="irrfInicial75"
                            value={!valores ? null : valores.irrfInicial75}
                            onChange={onChange}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                          <div
                            style={{ paddingRight: "3%", paddingLeft: "3%" }}
                          >
                            até
                          </div>
                          <input
                            style={{ textAlign: "center" }}
                            id="irrfFinal75"
                            name="irrfFinal75"
                            value={!valores ? null : valores.irrfFinal75}
                            onChange={onChange}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <label htmlFor="nomePrestador">7,5%</label>
                        </td>
                        <td
                          style={{
                            display: "inline-flex",
                          }}
                        >
                          <input
                            style={{ textAlign: "center" }}
                            id="deducaoIrrf75"
                            name="deducaoIrrf75"
                            value={!valores ? null : valores.deducaoIrrf75}
                            onChange={onChange}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr style={{ border: "1px solid" }}>
                        <td
                          style={{
                            display: "inline-flex",
                          }}
                        >
                          <div style={{ paddingRight: "3%" }}>De</div>
                          <input
                            style={{ textAlign: "center" }}
                            id="irrfInicial15"
                            name="irrfInicial15"
                            value={!valores ? null : valores.irrfInicial15}
                            onChange={onChange}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                          <div
                            style={{ paddingRight: "3%", paddingLeft: "3%" }}
                          >
                            até
                          </div>
                          <input
                            style={{ textAlign: "center" }}
                            id="irrfFinal15"
                            name="irrfFinal15"
                            value={!valores ? null : valores.irrfFinal15}
                            onChange={onChange}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <label htmlFor="nomePrestador">15%</label>
                        </td>
                        <td
                          style={{
                            display: "inline-flex",
                          }}
                        >
                          <input
                            style={{ textAlign: "center" }}
                            id="deducaoIrrf15"
                            name="deducaoIrrf15"
                            value={!valores ? null : valores.deducaoIrrf15}
                            onChange={onChange}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr style={{ border: "1px solid" }}>
                        <td
                          style={{
                            display: "inline-flex",
                          }}
                        >
                          <div style={{ paddingRight: "3%" }}>De</div>
                          <input
                            style={{ textAlign: "center" }}
                            id="irrfInicial225"
                            name="irrfInicial225"
                            value={!valores ? null : valores.irrfInicial225}
                            onChange={onChange}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                          <div
                            style={{ paddingRight: "3%", paddingLeft: "3%" }}
                          >
                            até
                          </div>
                          <input
                            style={{ textAlign: "center" }}
                            id="irrfFinal225"
                            name="irrfFinal225"
                            value={!valores ? null : valores.irrfFinal225}
                            onChange={onChange}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <label htmlFor="nomePrestador">22,5%</label>
                        </td>
                        <td
                          style={{
                            display: "inline-flex",
                          }}
                        >
                          <input
                            style={{ textAlign: "center" }}
                            id="deducaoIrrf225"
                            name="deducaoIrrf225"
                            value={!valores ? null : valores.deducaoIrrf225}
                            onChange={onChange}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr style={{ border: "1px solid" }}>
                        <td
                          style={{
                            display: "inline-flex",
                          }}
                        >
                          <div
                            style={{
                              paddingLeft: "10%",
                              paddingRight: "10%",
                              height: "30px",
                              width: "150px",
                            }}
                          >
                            Acima de
                          </div>

                          <div style={{ textAlign: "left" }}>
                            <input
                              style={{ textAlign: "center" }}
                              id="irrfInicial275"
                              name="irrfInicial275"
                              value={!valores ? null : valores.irrfInicial275}
                              onChange={onChange}
                              type="number"
                              disabled={habilitarDesabilitar}
                              className="form-control"
                            />
                          </div>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <label htmlFor="nomePrestador">27,5%</label>
                        </td>
                        <td
                          style={{
                            display: "inline-flex",
                          }}
                        >
                          <input
                            style={{ textAlign: "center" }}
                            id="deducaoIrrf275"
                            name="deducaoIrrf275"
                            value={!valores ? null : valores.deducaoIrrf275}
                            onChange={onChange}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr style={{ border: "1px solid"}}>
                        <td style={{
 
                              height: "20px",
                              width: "300px",
                            }}>
                          <div
                            style={{
 
                              textAlign: "center"
                            }}
                          >
                            Valor de dependentes
                          </div>
                        </td>

                        <td colSpan={2} style={{
                             
                              
                             
                             width: "200px",
                           }}>
                        
                          
                          <input
                            style={{ textAlign: "center" }}
                            id="valorPorDepententeIrrf"
                            name="valorPorDepententeIrrf"
                            value={!valores ? null : valores.valorPorDepententeIrrf}
                            onChange={onChange}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          /></td>
                        </tr>
                    </tbody>
                  </table>
                </Col>

                <Col md={2}>
                  <h5 style={{ textAlign: "center" }}>Valor Hora Aula</h5>
                  <label className="col-form-label" htmlFor="horaAula">
                    Valor Hora Aula R$
                  </label>
                  <input
                    style={{ textAlign: "center" }}
                    id="horaAula"
                    name="horaAula"
                    value={!valores ? null : valores.horaAula}
                    onChange={onChange}
                    type="number"
                    disabled={habilitarDesabilitar}
                    className="form-control"
                  />
                </Col>
              </Row>
            </Container>
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default InssIrrfVh;

import { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Card from "../../components/card";
import LogTabelasService from "../../service/logTabelasService";
import UnidadeService from "../../service/unidadeService";

function LogsTabelas() {

//paginação de Estagiários
const [paginaAtual, setPaginaAtual] = useState(0);
const [numeroDePaginas, setNumeroDePaginas] = useState(0);

const [unidades, setUnidades] = useState([]);

const [idUnidade, setIdUnidade] = useState(0);
const [dataInicial, setDataInicial] = useState();
const [dataFinal, setDataFinal] = useState();

const [nomeColaborador, setNomeColaborador] = useState("");
const pesquisou = () => setPaginaAtual(0);
const [logs, setLogs] = useState([]);

//Modal de exclusão de faltas e horas 
const [showNomeColaborador, setShowNomeColaborador] = useState(false);
const handleNomeColaboradorClose = () => setShowNomeColaborador(false);
const handleNomeColaboradorShow = () => setShowNomeColaborador(true);

const service = new LogTabelasService();
const serviceUnidade = new UnidadeService();

useEffect(() => {

        async function buscarUnidades() {
            const response = await serviceUnidade.listar();
            setUnidades(response.data);
        }
        buscarUnidades();

        async function loadLogs() {
            const response = await service.listarTodos(paginaAtual);
            setLogs(response.data.content);
            setNumeroDePaginas(response.data.totalPages);

            const arrayPaginas = [];
            //cria um array de paginas na quantidade vinda do backend
            for (let p = 1; p <= numeroDePaginas; p++) {
              arrayPaginas.push(p);
            }
        }

        if (idUnidade > 0 || typeof dataInicial !== "undefined" && typeof dataFinal !== "undefined") {
           // setUnidades([]);
            buscarComParametros();
        } else {
            //setUnidades([]);
            loadLogs();
        }
}, [paginaAtual, idUnidade, dataInicial, dataFinal])

function handlePageClick(data) {
    setPaginaAtual(data.selected);
}

const buscarComParametros = () => {
    service.buscarPorUnidade(idUnidade, dataInicial === "" ? "undefined": dataInicial, dataFinal === "" ? "undefined": dataFinal, paginaAtual).then((response) => {
        setLogs(response.data.content);
        setNumeroDePaginas(response.data.totalPages);
    })

    const arrayPaginas = [];
    //cria um array de paginas na quantidade vinda do backend
    for (let p = 1; p <= numeroDePaginas; p++) {
        arrayPaginas.push(p);
    }
}

const formatarData = (dataF) => {
    if(dataF){
        var data = new Date(dataF),
        dia  = data.getUTCDate().toString().padStart(2, '0'),
        mes  = (data.getUTCMonth()+1).toString().padStart(2, '0'),
        ano  = data.getUTCFullYear();

        let hora = data.getHours();
        let minutos = data.getMinutes();
        return dia+"/"+mes+"/"+ano + ' - ' + hora+':'+minutos;
    }
}

const handleChangeUnidade = (e) => {
    setIdUnidade(e.target.value)
}

const abrirDetalhesColaborador = (origem, idColaborador) => {
    console.log(origem, idColaborador);

    service.detalhesColaborador(origem, idColaborador).then((response) => {
        console.log(response.data);
        setNomeColaborador(response.data);
    })
    handleNomeColaboradorShow();
}

return (
    <div>
        <Card title="Parâmetros da Consulta">
            <Row >
                <Col md={4} lg={4}>
                    <label className="col-form-label" htmlFor="unidade">Unidade:</label>
                    <Form.Select
                        defaultValue="Selecione"
                        id="unidade"
                        name="unidade"
                        value={idUnidade}
                        onChange={handleChangeUnidade}
                    >
                        <option value={0}>Selecione a unidade</option>
                        {unidades.map((u) => (
                            <option value={u.id} key={u.id}>{u.nome}</option>
                        ))}
                    </Form.Select>

                </Col>
                <Col md={4} lg={3}>
                    <label className="col-form-label" htmlFor="dataInicial">Data Inicial:</label>
                    <input type="datetime-local" 
                        className="form-control" 
                        id="dataInicial"
                        name="dataInicial"
                        value={dataInicial}
                        onChange={(e) => setDataInicial(e.target.value)}></input>    
                </Col>
                 <Col md={4} lg={3}>
                    <label className="col-form-label" htmlFor="dataFinal">Data Final:</label>
                    <input type="datetime-local" 
                        className="form-control" 
                        id="dataFinal"
                        name="dataFinal"
                        value={dataFinal}
                        onChange={(e) => setDataFinal(e.target.value)}></input>    
                </Col>
            </Row>
        </Card>
        <br></br>
        <Card title="Listagem de logs">
            <table id="tabela_logs" className="table table-hover">
                <thead>
                    <tr>
                        <th>Ação</th>
                        <th>Origem da Ação</th>
                        <th>Colaborador</th>
                        <th>Data da Ação</th>
                        <th>Usuário da Ação</th>
                        <th>Unidade da Ação</th>
                    </tr>
                </thead>
                <tbody>
                    {logs.map((l) => {
                        return (
                            <tr key={l.id}>
                                <td>{l.acao}</td>
                                <td>{l.origem}</td>
                                <td>
                                    {
                                        <button className="btn btn-success" onClick={() => abrirDetalhesColaborador(l.origem, l.idColaboradorOrigem)}>
                                            <i className='pi pi-search-plus'></i>
                                        </button>
                                    }
                                </td>
                                <td>{formatarData(l.dataHoraAcao)}</td>
                                <td>{l.usuarioAcao.nomeCompleto}</td>
                                <td>{l.unidadeAcao.nome}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {logs.length > 0 &&
                <ReactPaginate
                    previousLabel={"Voltar"}
                    nextLabel={"Avançar"}
                    breakLabel={"..."}
                    pageCount={numeroDePaginas}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                    initialPage={pesquisou}
                />
            }
        </Card>

        <Modal show={showNomeColaborador} onHide={handleNomeColaboradorClose}  keyboard={false} >
            <Modal.Header closeButton>
                <Row>
                    <Col>
                        <label className="col-form-label" style={{fontSize: '1.4em'}}>Nome do colaborador:</label><br></br>
                        <span><strong>{nomeColaborador}</strong></span>
                    </Col>
                </Row>
            </Modal.Header>
        </Modal>
    </div>
)

}

export default LogsTabelas
import { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import AnoMes from "../../../components/mesAno/anoMes";
import gerarPdfService from "../../../service/gerarPdfService";
import LoadingPage from "../../landing/LandingPage";


function AnexosMensais() {
	let dataAtual = new Date();
	const [mes, setMes] = useState(dataAtual.getMonth() + 1);
	const [ano, setAno] = useState(dataAtual.getFullYear());

	const [loading, setLoading] = useState(false)

	useEffect(() => {}, [ano, mes]);

	function gerarCoordenadores() {
		setLoading(true)
		gerarPdfService(`/lancamentoservidor/anexomensal/coord/${ano}/${mes}`)
		.then(() => {
			setLoading(false)
		})
	}

	function gerarCoordenadoresSemec() {
		setLoading(true)
		gerarPdfService(`/lancamentoservidor/anexomensal/coordsemec/${ano}/${mes}`)
		.then(() => {
			setLoading(false)
		})
	}

	function gerarDificilAcesso() {
		setLoading(true)
		gerarPdfService(`/lancamentoservidor/anexomensal/da/${ano}/${mes}`)
		.then(() => {
			setLoading(false)
		})
	}

	function gerarDiretores() {
		setLoading(true)
		gerarPdfService(`/lancamentoservidor/anexomensal/dir/${ano}/${mes}`)
		.then(() => {
			setLoading(false)
		})
	}

	function gerarExcedentes() {
		setLoading(true)
		gerarPdfService(`/lancamentoservidor/anexomensal/excd/${ano}/${mes}`)
		.then(() => {
			setLoading(false)
		})
	}

	function gerarExcedentesMetade() {
		setLoading(true)
		gerarPdfService(`/lancamentoservidor/anexomensal/excedentesmetade/${ano}/${mes}`)
		.then(() => {
			setLoading(false)
		})
	}

	function gerarExcedentesBaseDataInicioTermino() {
		setLoading(true)
		gerarPdfService(`/lancamentoservidor/anexomensal/excedentescombasedatas/${ano}/${mes}`)
		.then(() => {
			setLoading(false)
		})
	}

	function gerarExcedentesComDatas() {
		setLoading(true)
		gerarPdfService(`/lancamentoservidor/anexomensal/excedentescomdatas/${ano}/${mes}`)
		.then(() => {
			setLoading(false)
		})
	}

	function gerarFaltas() {
		setLoading(true)
		//gerarPdfService(`/lancamentoservidor/anexomensal/faltas/${ano}/${mes}`); //Relatório desativado devido escolha do RH lançar as faltas e não os diretores
		gerarPdfService(`/faltasrh/relatorio/${ano}/${mes}`)
		.then(() => {
			setLoading(false)
		})
	}

	function gerarFaltasRhEstagiarios() {
		setLoading(true)
		gerarPdfService(`/lancamentoservidor/anexomensal/faltasrhestagiarios/${ano}/${mes}`)
		.then(() => {
			setLoading(false)
		})
	}

	function gerarFaltasRhServidores() {
		setLoading(true)
		gerarPdfService(`/lancamentoservidor/anexomensal/faltasrhservidores/${ano}/${mes}`)
		.then(() => {
			setLoading(false)
		})
	}

	function gerarOrientador() {
		setLoading(true)
		gerarPdfService(`/lancamentoservidor/anexomensal/ori/${ano}/${mes}`)
		.then(() => {
			setLoading(false)
		})
	}

	function gerarSecretarios() {
		setLoading(true)
		gerarPdfService(`/lancamentoservidor/anexomensal/sec/${ano}/${mes}`)
		.then(() => {
			setLoading(false)
		})
	}

	function gerarSubstituicoes() {
		setLoading(true)
		gerarPdfService(`/lancamentoservidor/anexomensal/sub/${ano}/${mes}`)
		.then(() => {
			setLoading(false)
		})
	}

	function gerarEstagiarios() {
		setLoading(true)
		gerarPdfService(`/lancamentoestagiario/anexomensal/${ano}/${mes}`)
		.then(() => {
			setLoading(false)
		})
	}

	function gerarVerbaIdenizatoria() {
		setLoading(true)
		gerarPdfService(`/lancamentoservidor/anexomensal/verbaidenizatoria/${ano}/${mes}`)
		.then(() => {
			setLoading(false)
		})
	}

	function gerarEstagiariosConferencia() {
		setLoading(true)
		gerarPdfService(`/lancamentoestagiario/anexomensal/conferencia/${ano}/${mes}`)
		.then(() => {
			setLoading(false)
		})
	}

	function gerarEstagiariosConferenciaAtivos() {
		setLoading(true)
		gerarPdfService(`/lancamentoestagiario/anexomensal/conferenciaativos/${ano}/${mes}`)
		.then(() => {
			setLoading(false)
		})
	}

	function gerarCooperadosConferencia() {
		setLoading(true)
		gerarPdfService(`/lancamentocooperado/relatorios/conferenciageral/${ano}/${mes}`)
		.then(() => {
			setLoading(false)
		})
	}

	function gerarTerceirizadosConferencia() {
		setLoading(true)
		gerarPdfService(`/lancamentoterceirizadohoras/relatorios/conferenciageral/${ano}/${mes}`)
		.then(() => {
			setLoading(false)
		})
	}

	return (
		<>
			{loading ? <LoadingPage/> : 
			<div className="container mt-6">
				<div className="form-group">
					<div className="card md-3">
						<h4 className="card-header">Geração de Anexos Mensais</h4>

						<Container>
							<>
								<br />
								<h5 style={{ textAlign: "center" }}>
									Selecione Mês e Ano para emissão do relatório
								</h5>
								<Row className="justify-content-md-center">
									<Col xs lg="4" style={{ textAlign: "center", paddingLeft: "50px" }}>
										<AnoMes
											valueMes={mes}
											onChangeMes={(e) => setMes(e.target.value)}
											valueAno={ano}
											onChangeAno={(e) => setAno(e.target.value)}
										/>
									</Col>
								</Row>
								<br />
								<Row style={{ textAlign: "center" }} className="justify-content-md-center" >
									<Col xs lg="5">
										<table className="table table-hover">
											<thead>
												<tr>
													<th scope="col" style={{ textAlign: "center" }}>
														Anexos
													</th>
												</tr>
											</thead>

											<tbody>
												<tr>
													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														Coordenadores
													</td>

													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														<button
															className="btn btn-primary"
															type="button"
															onClick={(e) => gerarCoordenadores()}
														>
															Gerar
														</button>
													</td>
												</tr>
												<tr>
													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														Coordenadores Semec
													</td>

													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														<button
															className="btn btn-primary"
															type="button"
															onClick={(e) => gerarCoordenadoresSemec()}
														>
															Gerar
														</button>
													</td>
												</tr>
												<tr>
													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														Difícil Acesso
													</td>

													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														<button
															className="btn btn-primary"
															type="button"
															onClick={(e) => gerarDificilAcesso()}
														>
															Gerar
														</button>
													</td>
												</tr>
												<tr>
													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														Diretores
													</td>

													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														<button
															className="btn btn-primary"
															type="button"
															onClick={(e) => gerarDiretores()}
														>
															Gerar
														</button>
													</td>
												</tr>
												<tr>
													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														Orientadores
													</td>

													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														<button
															className="btn btn-primary"
															type="button"
															onClick={(e) => gerarOrientador()}
														>
															Gerar
														</button>
													</td>
												</tr>
											<tr>
													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														Secretários
													</td>

													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														<button
															className="btn btn-primary"
															type="button"
															onClick={(e) => gerarSecretarios()}
														>
															Gerar
														</button>
													</td>
												</tr>
												<tr>
													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														Substituições
													</td>

													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														<button
															className="btn btn-primary"
															type="button"
															onClick={(e) => gerarSubstituicoes()}
														>
															Gerar
														</button>
													</td>
												</tr>
												<tr>
													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														Verba Idenizatória
													</td>

													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														<button
															className="btn btn-primary"
															type="button"
															onClick={(e) => gerarVerbaIdenizatoria()}
														>
															Gerar
														</button>
													</td>
												</tr>
												<tr>
													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														Excedentes
													</td>

													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														<button
															className="btn btn-primary"
															type="button"
															onClick={(e) => gerarExcedentes()}
														>
															Gerar
														</button>
													</td>
												</tr>
												<tr>
													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														Excedentes - Convertida em Aulas Normais <br/> (Com base nas datas de início e término)
													</td>

													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														<button
															className="btn btn-primary"
															type="button"
															onClick={(e) => gerarExcedentesBaseDataInicioTermino()}
														>
															Gerar
														</button>
													</td>
												</tr>
												<tr>
													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														Excedentes Metade - Convertida em Aulas Normais <br/> (Julho e Dezembro)
													</td>

													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														<button
															className="btn btn-primary"
															type="button"
															onClick={(e) => gerarExcedentesMetade()}
														>
															Gerar
														</button>
													</td>
												</tr>
												<tr style={{backgroundColor: "#bdb6b6"}}>
													<td></td>
													<td></td>
												</tr>
												<tr>
													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														Excedentes Geral Com Datas
													</td>

													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														<button
															className="btn btn-primary"
															type="button"
															onClick={(e) => gerarExcedentesComDatas()}
														>
															Gerar
														</button>
													</td>
												</tr>
												
												{/* <tr>
													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														Faltas
													</td>

													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														<button
															className="btn btn-primary"
															type="button"
															onClick={(e) => gerarFaltas()}
														>
															Gerar
														</button>
													</td>
												</tr> */}
												<tr>
													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														Faltas Estagiários
													</td>

													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														<button
															className="btn btn-primary"
															type="button"
															onClick={(e) => gerarFaltasRhEstagiarios()}
														>
															Gerar
														</button>
													</td>
												</tr>
												<tr>
													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														Faltas Servidores
													</td>

													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														<button
															className="btn btn-primary"
															type="button"
															onClick={(e) => gerarFaltasRhServidores()}
														>
															Gerar
														</button>
													</td>
												</tr>
												
												<tr>
													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														Estagiários
													</td>

													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														<button
															className="btn btn-primary"
															type="button"
															onClick={(e) => gerarEstagiarios()}
														>
															Gerar
														</button>
													</td>
												</tr>
												<tr>
													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														Estagiários Ativos
													</td>

													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														<button
															className="btn btn-primary"
															type="button"
															onClick={(e) => gerarEstagiariosConferenciaAtivos()}
														>
															Gerar
														</button>
													</td>
												</tr>
												<tr>
													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														Conferência Estagiários
													</td>

													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														<button
															className="btn btn-primary"
															type="button"
															onClick={(e) => gerarEstagiariosConferencia()}
														>
															Gerar
														</button>
													</td>
												</tr>
												
												
												<tr>
													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														Conferência Cooperados
													</td>

													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														<button
															className="btn btn-primary"
															type="button"
															onClick={(e) => gerarCooperadosConferencia()}
														>
															Gerar
														</button>
													</td>
												</tr>
												<tr>
													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														Conferência Terceirizados
													</td>

													<td
														style={{ maxWidth: "300px", textAlign: "center" }}
													>
														<button
															className="btn btn-primary"
															type="button"
															onClick={(e) => gerarTerceirizadosConferencia()}
														>
															Gerar
														</button>
													</td>
												</tr>
											</tbody>
										</table>
									</Col>
								</Row>
							</>
						</Container>
					</div>
				</div>
			</div>
			}
		</>
	);
}

export default AnexosMensais;
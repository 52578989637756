/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Container } from "react-bootstrap";
import ServidorMotivoSub from "../../service/servidorMotivoSubService";
import datasSubPrestadorService from "../../service/datasSubPrestadorService";
import calculosRecibosService from "../../service/calculosRecibosService";
import { mensagemErro, mensagemSucesso } from "../../components/avisos/toastr";
import dadosSubRecibosService from "../../service/dadosSubRecibosService";
import dadosSubPrestador from "../../service/dadosSubPrestadorService";
import assinaturaQuadrosService from "../../service/assinaturaQuadrosService";
import LocalStorageService from "../../service/localStorageService";
import { descriptografar } from "../../components/criptografar";

const edicaoAddServMotivo = {
  id: null,
  lancamentoPrestador: [],
  servidor: [],
  motivosSubstituicao: [],
};

const edicaoDatas = {
  id: null,
  data: null,
  totalAulas: 0,
  lancamentoPrestador: [],
  servidorMotivoSubstituicao: [],
  prestadorServico: [],
  anoLancamento: "",
  mesLancamento: "",
  unidade: [],
};

const dadosEdicaoSubRecibos = {
  //id: null,
  lancamentoPrestador: [],
  prestadorServico: [],
  unidade: [],
  servidor: [],
  motivosSubstituicao: [],
  mesLancamento: "",
  anoLancamento: "",
  descricaoUnidade: "",
  servidorSubstituido: "",
  motivo: "",
  totalAulas: 0,
  horaAula: 0,
};

const serviceSM = new ServidorMotivoSub();
const serviceDSP = new datasSubPrestadorService();
const serviceCR = new calculosRecibosService();
const serviceDSR = new dadosSubRecibosService();
const serviceDP = new dadosSubPrestador();
const serviceAssinaturaDigital = new assinaturaQuadrosService()

const EdicaoDatas = ({ id }) => {
  const idExibir = id;
  const unidadeCriptografado = LocalStorageService.obterItem('3')
    let idUnidade = descriptografar(unidadeCriptografado)
  // eslint-disable-next-line no-unused-vars
  const [smExibir, setSmExibir] = useState(edicaoAddServMotivo);
  const [nome, setNome] = useState(null);
  const [ano, setAno] = useState(null);
  const [mes, setMes] = useState(null);
  const [servidorSubstituido, setServidorSubstituido] = useState(null);
  const [motivoSubstituicao, setMotivoSubstituicao] = useState(null);
  const [idVoltar, setIdVoltar] = useState(null);
  //const [idDataEditar, setIdDataEditar] = useState(0)

  const [dadosDatas, setDadosDatas] = useState([]);

  //Exibir modal de Editar Aulas
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // eslint-disable-next-line no-unused-vars
  const [editarData, setEditarData] = useState(edicaoDatas);
  const [numeroAulas, setNumeroAulas] = useState(null);

  //Exibir modal de Deletar
  const [showModalDeletar, setShowModalDeletar] = useState(false);
  const handleCloseDeletar = () => setShowModalDeletar(false);
  const handleShowModalDeletar = () => setShowModalDeletar(true);
  const [idDeletar, setIdDeletar] = useState(0);
  const [dataDeletar, setDataDeletar] = useState(new Date());

  //Manipulação das substituições para os recibos
  const [dadosParaSubRecibos, setDadosParaSubRecibos] = useState(
    dadosEdicaoSubRecibos
  );

  const dados = () => {
    if (id) {
      serviceSM.buscarSM(idExibir).then((r) => {
        smExibir.servidor = r.data !== null ? r.data.servidor : 'Aulas Livres'
        smExibir.id = r.data.id;
        smExibir.lancamentoPrestador = r.data.lancamentoPrestador;
        smExibir.motivosSubstituicao = r.data.motivosSubstituicao;
        //console.log(r.data);
        //console.log(smExibir);
        setNome(smExibir.lancamentoPrestador.prestadorServicos.nome);
        setMes(smExibir.lancamentoPrestador.mesLancamento);
        setAno(smExibir.lancamentoPrestador.anoLancamento);
        setIdVoltar(smExibir.lancamentoPrestador.id);
        setServidorSubstituido(smExibir.servidor !== null ? smExibir.servidor.nomeCompleto : 'Aulas Livres');
        setMotivoSubstituicao(smExibir.motivosSubstituicao.descricao);
        serviceDSR
          .localizar(
            r.data.lancamentoPrestador.anoLancamento,
            r.data.lancamentoPrestador.mesLancamento,
            r.data.lancamentoPrestador.id,
            r.data.lancamentoPrestador.prestadorServicos.id,
            r.data.unidade.id,
            r.data.servidor !== null ? r.data.servidor.id : 0,
            r.data.motivosSubstituicao.id
          )
          .then((e) => {
            dadosEdicaoSubRecibos.id = e.data.id;
            dadosEdicaoSubRecibos.unidade = { id: Number(e.data.unidade.id) };
            dadosEdicaoSubRecibos.lancamentoPrestador = {
              id: Number(e.data.lancamentoPrestador.id),
            };
            dadosEdicaoSubRecibos.prestadorServico = {
              id: Number(e.data.prestadorServico.id),
            };
            dadosEdicaoSubRecibos.mesLancamento = e.data.mesLancamento;
            dadosEdicaoSubRecibos.anoLancamento = e.data.anoLancamento;
            dadosEdicaoSubRecibos.descricaoUnidade = e.data.descricaoUnidade;
            dadosEdicaoSubRecibos.servidor = e.data.servidor !== null ? { id: Number(e.data.servidor.id) } : null;
            dadosEdicaoSubRecibos.servidorSubstituido =
              e.data.servidorSubstituido;
            dadosEdicaoSubRecibos.motivosSubstituicao = {
              id: Number(e.data.motivosSubstituicao.id),
            };
            dadosEdicaoSubRecibos.motivo = e.data.motivo;
            dadosEdicaoSubRecibos.totalAulas = e.data.totalAulas;
            setDadosParaSubRecibos(dadosEdicaoSubRecibos);
            //console.log("para atualizar dadosParaSubRecibos")
            //console.log(dadosParaSubRecibos)
          });
      });
    }
  };

  useEffect(() => {
    dados();
  }, [idExibir, dadosParaSubRecibos]);

  useEffect(() => {
    serviceDSP.listarPorServidorMotivo(idExibir).then((l) => {
      //console.log(l.data)
      setDadosDatas(l.data);
      //console.log(dadosDatas)
    });
  }, [
    dadosDatas.length,
    show,
    showModalDeletar,
    dadosParaSubRecibos,
    editarData,
  ]);

  useEffect(() => {
    //console.log("")
  }, [dadosDatas, show, showModalDeletar]);

  function alterarAulas(idD) {
    serviceAssinaturaDigital.verificarSeAssinado(Number(idUnidade), ano, mes, "TEMPORARIO")
    .then((s) => {
        if (s.data > 0) {
            mensagemErro("O Quadro de Substituto Temporário está ASSINADO, para prosseguir com alterações nos Lançamentos de Substitutos Temporários deve apagar a assinatura.")
        }
        if (s.data === 0) {
          //console.log(idD)
          serviceDSP.editarData(idD).then((e) => {
            //console.log(e.data)
            editarData.id = e.data.id;
            editarData.data = e.data.data;
            editarData.lancamentoPrestador = e.data.lancamentoPrestador;
            editarData.servidorMotivoSubstituicao = e.data.servidorMotivoSubstituicao;
            editarData.totalAulas = e.data.totalAulas;
            editarData.prestadorServico = e.data.prestadorServico;
            editarData.anoLancamento = e.data.anoLancamento;
            editarData.mesLancamento = e.data.mesLancamento;
            editarData.unidade = e.data.unidade;
            setNumeroAulas(editarData.totalAulas);
            //setEditarData(e.data)
            //console.log(editarData)
            //console.log(numeroAulas)
          });

          handleShow()
        }
      })
  }

  function salvarNumeroAulas() {
    edicaoDatas.id = editarData.id;
    edicaoDatas.data = editarData.data;
    edicaoDatas.lancamentoPrestador = editarData.lancamentoPrestador;
    edicaoDatas.servidorMotivoSubstituicao =
      editarData.servidorMotivoSubstituicao;
    edicaoDatas.totalAulas = Number(numeroAulas);
    edicaoDatas.prestadorServico = editarData.prestadorServico;
    edicaoDatas.anoLancamento = editarData.anoLancamento;
    edicaoDatas.mesLancamento = editarData.mesLancamento;
    edicaoDatas.unidade = editarData.unidade;
    serviceDSP
      .atualizarDatas(edicaoDatas.id, edicaoDatas)
      .then((e) => {
        mensagemSucesso("Número de aulas atualizado com sucesso.");
        serviceCR.atualizar(
          edicaoDatas.prestadorServico.id,
          edicaoDatas.anoLancamento,
          edicaoDatas.mesLancamento
        );
        serviceDP
          .somaParaAtualizarRecibo(
            edicaoDatas.anoLancamento,
            edicaoDatas.mesLancamento,
            edicaoDatas.prestadorServico.id,
            edicaoDatas.servidorMotivoSubstituicao.id,
            edicaoDatas.lancamentoPrestador.id
          )
          .then((a) => {
            //console.log("Total aulas")
            //console.log(a.data)
            //console.log("aulas atualizar dadosParaSubRecibos")
            //console.log(dadosParaSubRecibos)
            dadosEdicaoSubRecibos.totalAulas = a.data;
            serviceDSR.atualizarRecibos(dadosEdicaoSubRecibos);
            handleClose();
          });
      })
      .catch((e) => {
        mensagemErro(e.data);
      });
    //console.log(edicaoDatas)
  }

  function abrirModalDeletar() {
    serviceAssinaturaDigital.verificarSeAssinado(Number(idUnidade), ano, mes, "TEMPORARIO")
    .then((s) => {
        if (s.data > 0) {
            mensagemErro("O Quadro de Substituto Temporário está ASSINADO, para prosseguir com alterações nos Lançamentos de Substitutos Temporários deve apagar a assinatura.")
        }
        if (s.data === 0) {
          //console.log(idDeletar)
          //console.log(editarData)
          handleShowModalDeletar()
        }
      })
  }

  let idDel;
  function deletarData() {
    //console.log(idDeletar)
    idDel = idDeletar;
    serviceDSP
      .editarData(idDel)
      .then((e) => {
        editarData.id = e.data.id;
        editarData.data = e.data.data;
        editarData.lancamentoPrestador = e.data.lancamentoPrestador;
        editarData.servidorMotivoSubstituicao =
          e.data.servidorMotivoSubstituicao;
        editarData.totalAulas = e.data.totalAulas;
        editarData.prestadorServico = e.data.prestadorServico;
        editarData.anoLancamento = e.data.anoLancamento;
        editarData.mesLancamento = e.data.mesLancamento;
        editarData.unidade = e.data.unidade;
        //console.log(e.data)

        serviceDSP.excluir(idDel).then((e) => {
          //console.log(e.data)
          //console.log(editarData)
          serviceCR.atualizar(
            editarData.prestadorServico.id,
            editarData.anoLancamento,
            editarData.mesLancamento
          );
          serviceDP
            .somaParaAtualizarRecibo(
              editarData.anoLancamento,
              editarData.mesLancamento,
              editarData.prestadorServico.id,
              editarData.servidorMotivoSubstituicao.id,
              editarData.lancamentoPrestador.id
            )
            .then((a) => {
              //console.log("Total aulas")
              //console.log(a.data)
              dadosEdicaoSubRecibos.totalAulas = a.data;
              //console.log("aulas atualizar apos excluir dadosParaSubRecibos")
              //console.log(dadosParaSubRecibos)
              serviceDSR.atualizarRecibos(dadosEdicaoSubRecibos);
            });
          mensagemSucesso(e.data);
          handleClose();
        });

        handleCloseDeletar();
        setTimeout(() => {
            window.location.reload()
        }, 1000)
      })
      .catch((r) => {
        mensagemErro(r.response.data);
      });
  }

  return (
    <>
      <div className="container">
        <div className="form-group">
          <div className="card md-3">
            <h4 className="card-header">
              Edição de Lançamentos do Substituto Temporário
            </h4>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "right",
                textAlign: "right",
                margin: "10px",
              }}
            >
              <br />
              <button
                className="btn btn-primary"
                title="Editar datas e quantidades de aulas"
              >
                <Link
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                  }}
                  to={`/prestador/edlanc/${idVoltar}`}
                >
                  Voltar
                </Link>
              </button>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Container>
                <Row>
                  <Col md={7}>
                    <label className="col-form-label" htmlFor="nomePrestador">
                      Substituto Temporário
                    </label>
                    <input
                      style={{ textAlign: "center" }}
                      id="nomePrestador"
                      name="nomePrestador"
                      type="text"
                      value={nome}
                      disabled="true"
                      className="form-control"
                    />
                  </Col>

                  <Col md={3}>
                    <label className="col-form-label" htmlFor="mesAno">
                      Mês/Ano:
                    </label>
                    <input
                      style={{ textAlign: "center" }}
                      id="mesAno"
                      name="mesAno"
                      type="text"
                      disabled="true"
                      className="form-control"
                      value={mes + "/" + ano}
                    />
                  </Col>

                  <Col md={7}>
                    <label className="col-form-label" htmlFor="nomePrestador">
                      Servidor Substituído
                    </label>
                    <input
                      style={{ textAlign: "center" }}
                      id="nomePrestador"
                      name="nomePrestador"
                      type="text"
                      value={servidorSubstituido}
                      disabled="true"
                      className="form-control"
                    />
                  </Col>
                  <Col md={3}>
                    <label className="col-form-label" htmlFor="mesAno">
                      Motivo da Substituição:
                    </label>
                    <input
                      style={{ textAlign: "center" }}
                      id="mesAno"
                      name="mesAno"
                      type="text"
                      disabled="true"
                      className="form-control"
                      value={motivoSubstituicao}
                    />
                  </Col>
                </Row>
                <hr />
              </Container>
            </div>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col" style={{ textAlign: "center" }}>
                    Data
                  </th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    Aulas
                  </th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    Ações
                  </th>
                </tr>
              </thead>
              <tbody>
                {dadosDatas.map((d) => {
                  return (
                    <tr key={d.id}>
                      <td style={{ textAlign: "center" }}>
                        <input
                          style={{ textAlign: "center" }}
                          type="date"
                          disabled="true"
                          className="form-control"
                          value={d.data}
                        />
                      </td>

                      <td style={{ textAlign: "center" }}>
                        <input
                          style={{ textAlign: "center" }}
                          type="number"
                          disabled="true"
                          className="form-control"
                          value={d.totalAulas}
                        />
                      </td>

                      <td style={{ textAlign: "center" }}>
                        <button
                          style={{ height: "30px", paddingTop: "0px" }}
                          className="btn btn-primary"
                          type="button"
                          onClick={() => alterarAulas(d.id)}
                          title="Alterar a Quantidade de Aulas"
                        >
                          Editar
                        </button>

                        <button
                          style={{ height: "30px", paddingTop: "0px" }}
                          className="btn btn-danger"
                          type="button"
                          title="Excluir data"
                          onClick={(e) =>
                            abrirModalDeletar(
                              setIdDeletar(d.id),
                              setDataDeletar(d.data)
                            )
                          }
                        >
                          Excluir
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal
        closeButton={true}
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        keyboard={false}
      >
        <Modal.Header className="card-header" closeButton>
          <Modal.Title>Alteração de Quantidade de Aulas</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row></Row>

          <div className="container">
            <Row>
              <Col>
                <label className="col-form-label" htmlFor="datas">
                  Data da substituição:
                </label>
                <input
                  type="date"
                  name="data"
                  id="data"
                  value={editarData.data}
                  disabled="true"
                  className="form-control"
                />
              </Col>
              <Col md={3}>
                <label
                  className="col-form-label"
                  htmlFor="editarData.totalAulas"
                >
                  Aulas:
                </label>
                <input
                  id="editarData.totalAulas"
                  name="editarData.totalAulas"
                  type="number"
                  min={1}
                  max={8}
                  className="form-control"
                  value={numeroAulas}
                  onChange={(e) => setNumeroAulas(e.target.value)}
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => salvarNumeroAulas()}
          >
            Salvar
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModalDeletar}
        onHide={handleCloseDeletar}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Excluir Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>
            Tem certeza que deseja excluir a data
            <input
              style={{
                textAlign: "center",
                border: "none",
                width: "150px",
                fontWeight: "bold",
              }}
              disabled="true"
              type="date"
              value={dataDeletar}
            />{" "}
            ?
          </h6>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            type="button"
            onClick={handleCloseDeletar}
          >
            Não
          </button>
          <button
            onClick={(e) => deletarData()}
            className="btn btn-danger"
            type="button"
          >
            Sim
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EdicaoDatas;

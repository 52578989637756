import React from "react"
import AuthenticatorService from "./service/authenticatorService";
import jwt from 'jsonwebtoken';


export const AuthContext = React.createContext()
export const AuthConsumer = AuthContext.Consumer;
const AuthProvider = AuthContext.Provider;

class ProvedorAutenticacao extends React.Component {

    state = {
        usuarioAutenticado: null,
        isAutenticado: false,
        isLoading: true
    }

    iniciarSessao = (tokenDTO) => {
        const token = tokenDTO.token
        const claims = jwt.decode(token)
        const usuario = {
            id: claims.userid,
            nome: claims.nome,
            unidade: claims.unidadeid,
            role: claims.role
        }
        
        AuthenticatorService.logar(usuario, token);
        this.setState({ isAutenticado: true, usuarioAutenticado: usuario })
    }

    encerrarSessao = () => {
        AuthenticatorService.removerUsuarioAutenticado();
        this.setState({ isAutenticado: false, usuarioAutenticado: null })
    }

    componentDidMount(){
        const isAutenticado = AuthenticatorService.isUsuarioAutenticado();
        if(isAutenticado){
            const usuario = AuthenticatorService.refreshSession();
            this.setState({
                isAutenticado: true,
                usuarioAutenticado: usuario,
                isLoading: false
            })
        }else{
            AuthenticatorService.removerUsuarioAutenticado()
            this.setState( previousState => {
                return {
                    ...previousState,
                    isLoading: false,
                }
            })
        }
    }

    render(){

        if(this.state.isLoading){
            return null;
        }

        const contexto = {
            usuarioAutenticado: this.state.usuarioAutenticado,
            isAutenticado: this.state.isAutenticado,
            iniciarSessao: this.iniciarSessao,
            encerrarSessao: this.encerrarSessao
        }

        return(
            <AuthProvider value={contexto}>
                {this.props.children}
            </AuthProvider>
        )
    }
}

export default ProvedorAutenticacao
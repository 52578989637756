import axios from "axios"
import { descriptografar } from "../components/criptografar"
import LocalStorageService from "./localStorageService"

const baseUrl = process.env.REACT_APP_API_URL

export default async function gerarPdfService(url){
	
	const tokenCriptografado = LocalStorageService.obterItem('4')
	//const token = tokenCriptografado ? JSON.parse(descriptografar(tokenCriptografado)) : null
	const token = tokenCriptografado ? descriptografar(tokenCriptografado) : null

	const passarToken = axios.create({
		baseURL: baseUrl,
		headers: {
				Authorization: `Bearer ${token}`
			}
	})

	const pdf = await passarToken.get(url, {responseType: 'arraybuffer'})
	let file = new Blob([pdf.data], {type: 'application/pdf'})
	let fileURL = URL.createObjectURL(file)
	window.open(fileURL,"_blank")
}
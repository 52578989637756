import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";
import Form from "react-bootstrap/Form";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-teal/theme.css";
import "primeicons/primeicons.css";

import "react-datepicker/dist/react-datepicker.css";

import { mensagemErro, mensagemSucesso } from "../../components/avisos/toastr";
import PrestadorService from "../../service/prestadorService";

const dadosPrestadorAtualizar = {
  nome: '',
  dataNascimento: '',
  email: '',
  cpf: '',
  pis: '',
  disponibilidade: '',
  turnoDisponibilidade: '',
  formacao: '',
  telefone1: '',
  telefone2: '',
  banco: '',
  agencia: '',
  conta: ''
};


const DadosEdicao = ({ id }) => {

  const [prestador, setPrestador] = useState(id ? dadosPrestadorAtualizar : null);

  const [outraFormacao, setOutraFormacao] = useState(false)
  const handleOnChange = () => {
    setOutraFormacao(!outraFormacao)
  }
  
  let redirecionar = useHistory();

  useEffect(() => {

    if (id) {
      //Executa um atraso e exibe uma menssagem antes do carregamento
      setTimeout(() => {
        const service = new PrestadorService();

        async function carregarPrestador() {
          const resposta = await service.buscarPorId(id);

          setPrestador(resposta.data);

         

          //console.log(prestador);

          
        }

        carregarPrestador();
      }, 300);
    }

    
    

    //console.log(prestador);
  }, []);

  function onChange(e) {
    const { name, value } = e.target;
    setPrestador({ ...prestador, [name]: value });
    //console.log(servidor)
  }

  
  function salvar() {

    if(prestador.dataNascimento !== null){
      if(checarData(prestador.dataNascimento) === false){
        mensagemErro('Data de nascimento tem o formato inválido.');
        return;
      }
    }

    const prestadorP = new PrestadorService();
    prestador.cpf = RetiraMascara(prestador.cpf)
    prestador.pis = RetiraMascara(prestador.pis)
    prestador.telefone1 = RetiraMascara(prestador.telefone1)
    prestador.telefone2 = RetiraMascara(prestador.telefone2)
    prestadorP
      .atualizar(id, prestador)
      .then((response) => {
        mensagemSucesso("Prestador cadastrado com sucesso.");
        redirecionar.push("/prestador/listar");
      })
      .catch((error) => {
        mensagemErro(error.response.data);
        //console.log(error.response.data);
      });
      //console.log(prestador);
  }

  function RetiraMascara(Obj) {
    return Obj.replace(/\D/g, '');
  }

  function checarData(data) {
    let dataAno = new Date(data);
    if(data !== ""){
        if(dataAno.getFullYear().toString().length === 4){
            return true;
        }else{
            return false;
        }
    }
  }

  return (
    <div>
      <div className="container mt-4">
        <div className="form-group">
          <div className="card md-3">
            <h4 className="card-header">Editar Substituto temporário</h4>
            <div className="card-body">
              <Form>
                <Row>
                  <Col md={9}>
                    <label className="col-form-label" htmlFor="nome">
                      Nome Completo:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Nome Completo"
                      id="nome"
                      name="nome"
                      value={prestador.nome}
                      onChange={onChange}
                    />
                  </Col>
                
                  
                  <Col md={3}>
                    <label className="col-form-label" htmlFor="dataNascimento">
                      Data de Nascimento:
                    </label>
                    <input
                      id="dataNascimento"
                      name="dataNascimento"
                      type="date"
                      className="form-control"
                      value={prestador.dataNascimento}
                      onChange={onChange}
                      />
                  </Col>
                  
                </Row>

                <Row>
                  <Col md={5}>
                    <label className="col-form-label" htmlFor="email">
                      E-mail:
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Digite um email"
                      id="email"
                      name="email"
                      autoComplete="false"
                      value={prestador.email}
                      onChange={onChange}
                    />
                  </Col>
                  <Col md={3}>
                    <label className="col-form-label" htmlFor="cpf">
                      CPF:
                    </label>
                    <InputMask
                      mask="999.999.999-99"
                      type="text"
                      className="form-control"
                      placeholder="000.000.000-00"
                      id="cpf"
                      name="cpf"
                      value={prestador.cpf}
                      onChange={onChange}
                    />
                  </Col>
                  <Col md={3}>
                    <label className="col-form-label" htmlFor="pis">
                      PIS:
                    </label>
                    <InputMask
                      mask="999.99999.99-9"
                      type="text"
                      className="form-control"
                      placeholder="000.00000.00-0"
                      id="pis"
                      name="pis"
                      value={prestador.pis}
                      onChange={onChange}
                    />
                  </Col>
                </Row>
                {outraFormacao === false ?
                <Row>
                  <Col md={6}>
                    <label className="col-form-label" htmlFor="formacao">
                      Área de Formação/Atuação:
                    </label>
                    <Form.Select
                    id="formacao"
                    name="formacao"
                    value={prestador.formacao}
                    onChange={onChange}           
                  >
                    <option>{prestador.formacao}</option>
                    <option>PORTUGUÊS</option>
                    <option>INGLÊS</option>
                    <option>MATEMÁTICA</option>
                    <option>CIÊNCIAS</option>
                    <option>EDUCAÇÃO FÍSICA</option>
                    <option>GEOGRAFIA</option>
                    <option>HISTÓRIA</option>
                    <option>ARTES</option>
                    
                  </Form.Select>
                  </Col>
                  </Row>
                :
                <Row>

                  <Col>{""}</Col>
                </Row>
                
              
              }
                
               
                <Row>
                  <Col>
                  
                  <input 
                  style={{ marginTop: "10px" }}
                  className="form-check-input"
                  type="checkbox"
                  id="outraformacao"
                  name="outraformacao"
                  checked={outraFormacao}
                  onChange={handleOnChange}
                /> {" "}
                <label className="col-form-label" htmlFor="formacao">
                    Outra Formação
                  </label>
                  </Col>
                  
                </Row>
                

                {outraFormacao === true?

                <Row>
                <Col>



                <label className="col-form-label" htmlFor="formacao">
                    Informe a Formação:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Informe a Licenciatura do Substituto Temporário"
                    id="formacao"
                    name="formacao"
                    value={prestador.formacao}
                    onChange={onChange}
                  />

                </Col>

                  

                

                </Row>
                :
                <Col>      {""}</Col>
              }
              <Row>
                <Col md={4}>
                      <label className="col-form-label" htmlFor="disponibilidade">
                        Disponibilidade:
                      </label>
                      <Form.Select
                      id="disponibilidade"
                      name="disponibilidade"
                      value={prestador.disponibilidade}
                      onChange={onChange}
                      style={prestador.disponibilidade === "DISPONÍVEL" ? {backgroundColor: "#242fd4", color: "#fff"} : prestador.disponibilidade === "INDISPONÍVEL" ? {backgroundColor: "#fa0f0f", color: "#fff"} : null}           
                    >
                      <option value="">Selecione</option>
                      <option style={{backgroundColor: "#242fd4", color: "#fff"}}>DISPONÍVEL</option>
                      <option style={{backgroundColor: "#fa0f0f", color: "#fff"}} >INDISPONÍVEL</option>
                    </Form.Select>
                    </Col>
                    {prestador.disponibilidade === 'DISPONÍVEL' &&
                      <Col md={4}>
                          <label className="col-form-label" htmlFor="turnoDisponibilidade">
                            Turno de Disponibilidade:
                          </label>
                          <Form.Select
                          id="turnoDisponibilidade"
                          name="turnoDisponibilidade"
                          value={prestador.turnoDisponibilidade}
                          onChange={onChange}
                        >
                          <option value="">Selecione</option>
                          <option>MATUTINO</option>
                          <option>VESPERTINO</option>
                          <option>INTEGRAL</option>
                        </Form.Select>
                      </Col>
                    }
              </Row>
                
             
                <br />
                <Row>
                  <div className="form-group">
                    <div className="card md-12">
                      <h6 className="card-header">Telefones para contato</h6>
                      <div className="card-body">
                      
                      <Row>
                        <Col md={3}>
                          <label  htmlFor="telefone1">
                            Contato 1:
                          </label>
                          <InputMask
                            mask="(99) 9 9999-9999"
                            type="text"
                            className="form-control"
                            placeholder="(00) 0 0000-0000"
                            id="telefone1"
                            name="telefone1"
                            value={prestador.telefone1}
                            onChange={onChange}
                          />
                        </Col>
                        
                        
                        <Col md={3}>
                          <label  htmlFor="telefone2">
                            Contato 2:
                          </label>
                          <InputMask
                            mask="(99) 9 9999-9999"
                            type="text"
                            className="form-control"
                            placeholder="(00) 0 0000-0000"
                            id="telefone2"
                            name="telefone2"
                            value={prestador.telefone2}
                            onChange={onChange}
                          />
                        </Col>
                        
                        </Row>

                        
                        </div>
                      </div>
                    </div>
                  
                </Row>
                    <br />
                <Row>
                  <div className="form-group">
                    <div className="card md-12">
                      <h6 className="card-header">Dados Bancários</h6>
                      <div className="card-body">
                      
                      <Row>
                        <Col md={3}>
                          <label  htmlFor="banco">
                            Nome do Banco:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Digite o nome do Banco"
                            id="banco"
                            name="banco"
                            value={prestador.banco}
                            onChange={onChange}
                          />
                        </Col>
                        
                        
                        <Col md={3}>
                          <label  htmlFor="agencia">
                            Número da Agência:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Agência"
                            id="agencia"
                            name="agencia"
                            value={prestador.agencia}
                            onChange={onChange}
                          />
                        </Col>

                        <Col md={3}>
                          <label  htmlFor="conta">
                            Número da Conta:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Conta"
                            id="conta"
                            name="conta"
                            value={prestador.conta}
                            onChange={onChange}
                          />
                        </Col>
                        
                        </Row>
                        
                        </div>
                      </div>
                    </div>
                  
                </Row>
                <br />
                <Row>
                  <Col md={1}>
                    <button
                      onClick={salvar}
                      type="button"
                      className="btn btn-primary"
                    >
                      Atualizar
                    </button>
                  </Col>

                  <Col md={1} style={{paddingLeft: '5%'}}>
                  <button type="button" className="btn btn-danger">
                      <Link
                        style={{ textDecoration: "none", color: "#ffffff" }}
                        to="/prestador/listar"
                      >
                        Cancelar
                      </Link>
                  </button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DadosEdicao;

import ApiService from '../app/apiservice'


class UnidadeService extends ApiService {

constructor(){
    super('/unidades')
}

buscarPorNome(nome){
    return this.get(nome)
}

listarTodos(pagina){
    return this.get(pagina)
}

obterPorId(id){
    return this.get(`/${id}`)
}

salvar(unidade){
    return this.post('/', unidade)
}

listar() {
    return this.get('/')
}

deletar(id){
    return this.delete(`/${id}`)
}

atualizar(unidade){
    return this.put(`/${unidade.id}`, unidade)
}

listarUnidadesAvulsas(ano, mesAtual){
    return this.get(`/buscarunidadesavulsas/ano/${ano}/mes/${mesAtual}`);
}

}


export default UnidadeService;
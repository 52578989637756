import Button from 'react-bootstrap/Button'
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import InputMask from "react-input-mask";
import Card from "../../components/card";
import ReactPaginate from "react-paginate";

import styles from '../unidade/listarUnidade.module.css'
import CooperadoService from "../../service/cooperadoService";
import { mensagemErro, mensagemSucesso } from "../../components/avisos/toastr";
import LancamentoCooperadoService from '../../service/lancamentoCooperadoService';

const ListarCooperados = () => {

    const [show, setShow] = useState(false);
    const [showConf, setShowConf] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleConfclose = () => setShowConf(false);
    const handleConfShow = () => setShowConf(true);

    const [showModalHistoricoCooperado, setShowModalHistoricoCooperado] = useState(false);
    const handleCloseHistoricoCooperado = () => setShowModalHistoricoCooperado(false);
    const handleShowHistoricoCooperado = () => setShowModalHistoricoCooperado(true);

    const [nomeExibicao, setNomeExibicao] = useState();
    const [lancamentosCooperado, setLancamentosCooperado] = useState([]);

    const [coop, setCoop] = useState([]);
    //const [ch, setCh] = useState([])
    //const [imputId, setImputId] = useState();

    const initialValue = {
        nome: '',
        cpf: null
    }

    const [values, setValues] = useState(initialValue);

    function onChange(e) {
        const { name, value } = e.target;
        setValues({...values, [name]: value })
    }

    const service = new CooperadoService();
    const serviceLancamentoCooperado = new LancamentoCooperadoService();

    //Parte relacionada a paginação e busca por nome 
    //const [totalCooperados, setTotalCooperados] = useState(0);
    //const [totalPaginas, setTotalPaginas] = useState([]);
    const [paginaAtual, setPaginaAtual] = useState(0);
    const [nomeBuscar, setNomeBuscar] = useState("");
    const [numeroDePaginas, setNumeroDePaginas] = useState(0);

    //implementar futuramente função para quando pesquisar setar a primeira pagina
    const pesquisou = () => setPaginaAtual(0);

    useEffect(() => {

        async function loadCooperadosPaginacao(){
            const response = await service.buscarPorNome(`/cooperadopornome/${nomeBuscar}/page/${paginaAtual}`);
            //setTotalCooperados(response.data.totalElements);
            setCoop(response.data.content);
            setNumeroDePaginas(response.data.totalPages);

            const arrayPaginas = [];
            //cria um array de paginas na quantidade vinda do backend
            for (let p = 1; p <= numeroDePaginas; p++) {
                arrayPaginas.push(p);
            }
            //setTotalPaginas(arrayPaginas);

        }

        async function carregarCooperados() {
            const response = await service.listarTodos(`/pag/${paginaAtual}`);
            //setTotalPaginas(response.totalPages)
            //setTotalCooperados(response.data.totalElements);
            setCoop(response.data.content);
            setNumeroDePaginas(response.data.totalPages);
        
            const arrayPaginas = [];
            //cria um array de paginas na quantidade vinda do backend
            for (let p = 1; p <= numeroDePaginas; p++) {
                arrayPaginas.push(p);
            }
            //setTotalPaginas(arrayPaginas);
        
        }

        if (nomeBuscar === "") {
            carregarCooperados();
        } else {
            loadCooperadosPaginacao();
        }

        /* async function loadCargaHoraria() {
            const response = await service.listarCh();
            setCh(response.data);
        }

        loadCargaHoraria(); */

    }, [paginaAtual, nomeBuscar, showConf, show]);

    function handlePageClick(data) {
        setPaginaAtual(data.selected);
    }

    const limparDados = () => {
        setValues({
            nome: '',
            cpf: ''
        })
        handleClose();
    }

    const abrirEditModal = (id) => {
        handleShow();

        if(id){
            service.obterPorId(id)
            .then((response) => {
               setValues(response.data)
            })
        }
    }

    const cadastrar = () => {
        try {
            service.validar(values)
        } catch (erro) {
            const mensagens = erro.mensagens;
            mensagens.forEach(msg => mensagemErro(msg));
            return false;
        } 
        
        values.cpf = RetiraMascara(values.cpf)
        service.salvar(values)
        .then(() => {
            mensagemSucesso('Cooperado cadastrado com sucesso.')
            limparDados();
        }).catch((error) => {
            mensagemErro(error.response.data)
        })
    }

    const atualizar = () => {
         try {
            service.validar(values)
        } catch (erro) {
            const mensagens = erro.mensagens;
            mensagens.forEach(msg => mensagemErro(msg));
            return false;
        } 

        values.cpf = RetiraMascara(values.cpf)
        service.atualizar(values)
          .then(() => {
            mensagemSucesso('Cooperado atualizado com sucesso.')
            limparDados();
        }).catch((error) => {
            mensagemErro(error.response.data)
        })
      }


      function RetiraMascara(Obj) {
        return Obj.replace(/\D/g, '');
      }

      function mascaraCpf(c){
        return c.match(/.{1,3}/g).join(".").replace(/\.(?=[^.]*$)/,"-")
      }

    function buscarHistoricoCooperado(id, nome){
        setNomeExibicao(nome);
        serviceLancamentoCooperado.buscarHistoricoCooperado(id).then((response) => {
            setLancamentosCooperado(response.data);
        
        })
    }

    /* const deletar = (id) => {
        service.deletar(id)
        .then(() => {
            mensagemSucesso('Cooperado deletado com sucesso.')
            handleConfclose();
        }).catch( error => {
            mensagemErro(error.response.data.mensagem);
        })
    } */

    return(
        <Container className="mt-4">
            <Card title="Listagem de Cooperados">
                <div className={styles.divBusca}>
                    <div className="input-group "> 
                        <div >
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Pesquisar pelo nome"
                                aria-label="Recipient's username"
                                aria-describedby="button-addon2"
                                value={nomeBuscar}
                                onChange={(buscar) => setNomeBuscar(buscar.target.value)}
                            />
                        </div>
                    </div>
                    <div className={styles.btnAdd}>
                        <button onClick={handleShow} type="button" className="btn btn-primary">Adicionar</button>
                    </div>
                </div>
               
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Nome</th>
                            <th>CPF</th>
                        </tr>
                    </thead>
                    <tbody>
                    
                        {coop.map((c, index) => {
                            const cpf = mascaraCpf(c.cpf)
                            return(
                                <tr key={c.id}>
                                    <td>{index + 1}</td>
                                    <td>{c.nome}</td>
                                    <td>{cpf}</td>
                                    <td style={{textAlign: 'center', padding: '3px'}}>
                                        <button className="btn btn-primary" onClick={() => abrirEditModal(c.id)} style={{marginRight: '5px'}} title='Editar'>
                                            <i className='pi pi-pencil'></i>
                                        </button>
                                        <button onClick={() => handleShowHistoricoCooperado(buscarHistoricoCooperado(c.id, c.nome))}
                                            className="btn btn-success"
                                            type="button" title="Histórico do Cooperado"
                                        >
                                            <i className='pi pi-history'></i>
                                        </button>
                                        {/* <button onClick={() => handleConfShow(setImputId(`${c.id}`))} className="btn btn-danger" title='Excluir'>
                                            <i className='pi pi-trash'></i>
                                        </button> */}
                                    </td>
                                </tr>
                            )
                        })}
                        
                    </tbody>
                </Table>
                {coop.length > 0 &&
                    <ReactPaginate
                        previousLabel={"Voltar"}
                        nextLabel={"Avançar"}
                        breakLabel={"..."}
                        pageCount={numeroDePaginas}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                        initialPage={pesquisou}
                    />
                }
            </Card>    

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Body>
                    <Container className="mt-4">
                        <Card title="Cadastro de Cooperados"> 
                            <Form>
                                <Row>
                                    <Col>
                                        <label className="col-form-label" htmlFor="nome">Nome do Cooperado:</label>
                                        <input type="text" 
                                            className="form-control" 
                                            id="nome" 
                                            name="nome"
                                            value={values.nome}
                                            onChange={onChange}
                                            placeholder="Informe o nome do cooperado"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    <label className="col-form-label" htmlFor="cpf">CPF:</label>
                                    <InputMask
                                        mask="999.999.999-99"
                                        type="text" 
                                        className="form-control" 
                                        placeholder="CPF" 
                                        id="cpf" 
                                        name="cpf"
                                        value={values.cpf} 
                                        onChange={onChange} />
                                    </Col>
                                </Row>
                            </Form>
                        </Card>

                        <div className="mt-4">
                            <div className="text-center">
                                {values.id > 0 ?
                                    <button 
                                        onClick={atualizar} 
                                        className="btn btn-primary">
                                        Atualizar
                                    </button> :
                                    <button 
                                        onClick={cadastrar} 
                                        className="btn btn-primary">
                                        Cadastrar
                                    </button> 
                                }
                            </div>
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-danger" onClick={limparDados}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModalHistoricoCooperado} onHide={handleCloseHistoricoCooperado} backdrop="static" keyboard={false} size="lg">
                <Modal.Body>
                    <Card title="Histórico do Cooperado">
                        <h3 style={{color: 'gray'}}>{nomeExibicao}</h3>
                        <Table id="tabela_lancamentos_faltas" striped bordered hover size="md">
                            <thead>
                                <tr>
                                    <th>Ano</th>
                                    <th>Mês</th>
                                    <th>Unidade</th>
                                    <th>Turno</th>
                                </tr>
                            </thead>
                            <tbody>
                                {lancamentosCooperado.map((lc) => {
                                    return (
                                        <tr key={lc.id}>
                                            <td>{lc.anoLancamento}</td>
                                            <td>{lc.mesLancamento}</td>
                                            <td>{lc.unidade.nome}</td>
                                            <td>{lc.turno}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-secondary" onClick={handleCloseHistoricoCooperado}>
                        Fechar
                    </Button>
                </Modal.Footer>
		    </Modal>

            {/* <Modal show={showConf} onHide={handleConfclose} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <h3>Confirmação</h3>
                </Modal.Header>
                <Modal.Body>
                    <h5>Você realmente deseja excluir o registro?</h5>
                </Modal.Body>
                <Modal.Footer>
                <Button className="btn btn-danger" onClick={() => deletar(imputId)}>
                    Confirmar
                </Button>
                <Button className="btn btn-secondary" onClick={handleConfclose}>
                    Cancelar
                </Button>
                </Modal.Footer>
            </Modal> */}
        </Container>  
    )
}

export default ListarCooperados
import ApiService from "../app/apiservice";

export default class CalendarioLetivoService extends ApiService {

    constructor(){
        super('/calendarioletivo')
    }

    salvar(dados) {
        return this.post('/salvar', dados)
    }

    listarPorAnoSecretaria(ano, secretaria) {
        return this.get(`/${ano}/${secretaria}`)
    }

    deletarData(idData) {
        return this.delete(`/${idData}`)
    }
}
import Button from 'react-bootstrap/Button'
import React, { useEffect, useState } from "react"
import { Col, Container, Form, Modal, Row, Table } from "react-bootstrap"
import { mensagemErro, mensagemSucesso } from "../../components/avisos/toastr";
import Card from "../../components/card"
import CargoService from "../../service/cargoService";
import ReactPaginate from "react-paginate";

import styles from '../unidade/listarUnidade.module.css'

function ListarCargos() {

    const initialValue = {
        nome: ''
    }
    
    const [values, setValues] = useState(initialValue);

    function onChange(e) {
        const { name, value } = e.target;
        setValues({...values, [name]: value })
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showConf, setShowConf] = useState(false);
    const handleConfclose = () => setShowConf(false);
    const handleConfShow = () => setShowConf(true);

    const [cargo, setCargos] = useState([]);
    const [imputId, setImputId] = useState();

    const service = new CargoService();

    //Parte relacionada a paginação e busca por nome 
    //const [totalCargos, setTotalCargos] = useState(0);
    //const [totalPaginas, setTotalPaginas] = useState([]);
    const [paginaAtual, setPaginaAtual] = useState(0);
    const [nomeBuscar, setNomeBuscar] = useState("");
    const [numeroDePaginas, setNumeroDePaginas] = useState(0);

    //implementar futuramente função para quando pesquisar setar a primeira pagina
    const pesquisou = () => setPaginaAtual(0);

    useEffect(() => {

        async function loadCargosPaginacao(){
            const response = await service.buscarPorNome(`/cargopornome/${nomeBuscar}/page/${paginaAtual}`);
            //setTotalCargos(response.data.totalElements);
            setCargos(response.data.content);
            setNumeroDePaginas(response.data.totalPages);

            const arrayPaginas = [];
            //cria um array de paginas na quantidade vinda do backend
            for (let p = 1; p <= numeroDePaginas; p++) {
                arrayPaginas.push(p);
            }
            //setTotalPaginas(arrayPaginas);

        }

        async function carregarCargos() {
            const response = await service.listarTodos(`/pag/${paginaAtual}`);
            //setTotalCargos(response.data.totalElements);
            setCargos(response.data.content);
            setNumeroDePaginas(response.data.totalPages);
        
            const arrayPaginas = [];
            //cria um array de paginas na quantidade vinda do backend
            for (let p = 1; p <= numeroDePaginas; p++) {
                arrayPaginas.push(p);
            }
            //setTotalPaginas(arrayPaginas);
        
        }
            
        if (nomeBuscar === "") {
            carregarCargos();
        } else {
            loadCargosPaginacao();
        }
        
    }, [paginaAtual, nomeBuscar, showConf, show]);

    function handlePageClick(data) {
        setPaginaAtual(data.selected);
    }

    const limparDados = () => {
        setValues({
            nome: '',
            imputId: null
        })
        handleClose();
    }

    const abrirEditModal = (id) => {
        handleShow()

        if(id){
            service.obterPorId(id)
            .then((response) => {
               setValues(response.data)
            })
        }
    }

    const cadastrar = () => {

        if(!values.nome){
            mensagemErro('O campo nome é obrigatório.')
            return false;
        }

        service.salvar(values)
            .then(() => {
                handleClose();
                mensagemSucesso("Cargo cadastrado com sucesso!")
                limparDados();
            }).catch( error => {
                mensagemErro(error.response.data.erros.nome)
            })
    }

    const atualizar = () => {

        if(!values.nome || values.nome.trim() === ''){
            mensagemErro('O campo nome é obrigatório.')
            return false;
        }

        service.atualizar(values)
          .then(() => {
              handleClose();
            mensagemSucesso('Cargo atualizado com sucesso.')
            limparDados();
          
          }).catch(error => {
            mensagemErro(error.response.data.message)
          })
      }

    const deletar = (id) => {
        
        service.delete(id)
            .then(() => {
                mensagemSucesso('Cargo deletado com sucesso.')
                handleConfclose()
            }).catch( error => {
                mensagemErro(error.response.data.mensagem);
            })
    }
   
    return(
        <Container className="mt-4">
            <Card title="Listagem de Cargos">
                <div className={styles.divBusca}>
                    <div className="input-group "> 
                        <div >
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Pesquisar pelo nome"
                                aria-label="Recipient's username"
                                aria-describedby="button-addon2"
                                value={nomeBuscar}
                                onChange={(buscar) => setNomeBuscar(buscar.target.value)}
                            />
                        </div>
                    </div>
                    <div className={styles.btnAdd}>
                        <button onClick={handleShow} type="button" className="btn btn-primary">Adicionar</button>
                    </div>
                </div>

                <Table striped bordered hover size="md">
                    <thead>
                        <tr>
                            <th>##</th>
                            <th>Nome do Cargo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cargo.map((c, index) =>{
                            return(
                                <tr key={c.id}>
                                    <td>{index+1}</td>
                                    <td className='col-md-10'>{c.nome}</td>
                                    <td style={{textAlign: 'center', padding: '3px'}}>
                                        {/* <button className="btn btn-primary" title='Editar' onClick={() => abrirEditModal(c.id)} style={{marginRight: '5px'}}>
                                            <i className='pi pi-pencil'></i>
                                        </button>
                                   
                                        <button onClick={() => handleConfShow(setImputId(`/${c.id}`))} className="btn btn-danger" title='Excluir'>
                                            <i className='pi pi-trash'></i>
                                        </button> */}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                {cargo.length > 0 &&
                    <ReactPaginate
                        previousLabel={"Voltar"}
                        nextLabel={"Avançar"}
                        breakLabel={"..."}
                        pageCount={numeroDePaginas}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                        initialPage={pesquisou}
                    />
                }
            </Card> 

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Body>
                    <Container>
                        <Card title="Cadastro de Cargos">
                            <Form>
                                <Row>
                                    <Col>
                                        <label className="col-form-label" htmlFor="nome">Nome do Cargo:</label>
                                        <input type="text" 
                                            className="form-control" 
                                            placeholder="Informe o nome do cargo"
                                            value={values.nome} 
                                            onChange={onChange}
                                            id="nome" 
                                            name="nome"
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </Card>

                        <div className="mt-4">
                            <div className="text-center">
                                {values.id > 0 ?
                                    <button onClick={atualizar} className="btn btn-primary">Atualizar</button> 
                                    :
                                    <button onClick={cadastrar} className="btn btn-primary">Cadastrar</button> 
                                }
                            </div>
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-secondary" onClick={limparDados}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showConf} onHide={handleConfclose} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <h3>Confirmação</h3>
                </Modal.Header>
                <Modal.Body>
                    <h5>Você realmente deseja excluir o registro?</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-danger" onClick={() => deletar(imputId)}>
                        Confirmar
                    </Button>
                    <Button className="btn btn-secondary" onClick={handleConfclose}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>  
    )
}

export default ListarCargos
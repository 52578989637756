import ApiService from "../app/apiservice";

export default class faltasHorasSubsTerceirizadoHorasService extends ApiService{
    constructor(){
        super('/faltashrsubterceirizadoshoras')
    }

    listarHorasSubFaltasPorLancamento(idLancamento){
        return this.get(`/listarporlancamento/${idLancamento}`)
    }

    salvarFaltasHrSubstituicao(dados){
        return this.post('/salvar', dados)
    }

    editarHoraFaltaSub(idEditar){
        return this.get(`/editar/${idEditar}`)
    }

    atualizarHoras(dados){
        return this.put('/atualizar', dados)
    }

    apagarData(idApagar){
        return this.delete(`/deletar/${idApagar}`)
    }
}
import ApiService from "../app/apiservice";

class LancamentoTerceirizadoService extends ApiService{
    constructor() {
        super('/lancamentoterceirizado')
    }

     buscarLancamentoPorTerceirizado(id){
        return this.get(`/buscarporterceirizado/${id}`)
    }

    obterPorId(id){
        return this.get(`/${id}`)
    }

    salvar(lancamentoTerceirizado){
        return this.post('/', lancamentoTerceirizado)
    }

    deletar(id){
        return this.delete(`/${id}`)
    }

    listar(){
        return this.get('/');
    }

    atualizar(lancamentoTerceirizado){
        return this.put(`/${lancamentoTerceirizado.id}`, lancamentoTerceirizado)
    }

    listarPorUnidAnoMes(idUnid, ano, mes){
        return this.get(`/unidade/${idUnid}/ano/${ano}/mes/${mes}`)
    }

    countLancamentosMesSeguinte(idUnidade, ano, mesSeguinte){
        return this.get(`/countlancamentosmesseguinte/unidade/${idUnidade}/ano/${ano}/mes/${mesSeguinte}`)
    }

    countLancamentosMesAtual(idUnidade, ano, mes){
        return this.get(`/countlancamentosmesseguinte/unidade/${idUnidade}/ano/${ano}/mes/${mes}`)
    }

    replicarLancamentos(idUnidade, ano, mes){
        return this.get(`/replicarlancamentos/unidade/${idUnidade}/ano/${ano}/mes/${mes}`)
    }
}

export default LancamentoTerceirizadoService;
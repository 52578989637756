import ApiService from "../app/apiservice";

class numAlunosService extends ApiService {

    constructor(){
        super('/numalunos')
    }

    listarHistoricoDaUnidade(idUnidade, ano){
        return this.get(`/listar/${idUnidade}/${ano}`)
    }

    verificarPeriodo(){
        return this.get('/verificar')
    }

    listaUnidade(ano){
        return this.get(`/listunidades/${ano}`)
    }

    verificarAbertaOuFechada(idUnidade, ano, mes){
        return this.get(`/verificar/${idUnidade}/${ano}/${mes}`)
    }

    salvarNumeroAlunos(dados){
        return this.post('/salvar', dados)
    }

    adicionarNovaUnidade(ano, idUnidade){
        return this.post(`/novaunidade/${ano}/${idUnidade}`)
    }

    liberarPorUnidade(idUnidade, ano, mes){
        return this.put(`/liberar/${idUnidade}/${ano}/${mes}`)
    }

    atualizarNumAluno(idUnidade, ano, mes, qnt){
        return this.put(`/atualizar/${idUnidade}/${ano}/${mes}/${qnt}`)
    }

    liberarTodos(ano, mes){
        return this.put(`/abrirtodos/${ano}/${mes}`)
    }

    fecharTodos(ano, mes){
        return this.put(`/fechartodos/${ano}/${mes}`)
    }

}

export default numAlunosService
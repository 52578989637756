import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";
import Form from "react-bootstrap/Form";
import { Row, Col } from "react-bootstrap";

import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-teal/theme.css";
import "primeicons/primeicons.css";


import "react-datepicker/dist/react-datepicker.css";
import CargoService from "../../service/cargoService";

import ServidorService from "../../service/servidorService";
import { mensagemErro, mensagemSucesso } from "../../components/avisos/toastr";

const dadosServidor = {
  matricula: 0,
  nomeCompleto: "",
  sexo: "",
  email: "",
  rg: "",
  cpf: "",
  pis: "",
  vinculo: "",
  cargaHoraria: "",
  situacao: "",
  dataAdmissao: "",
  dataNascimento: "",
  cargo: {id: 0},
  telefone1: "",
  descTel1: "",
  telefone2: "",
  descTel2: ""
};



function Cadastrar() {


  const [servidor, setServidor] = useState(dadosServidor);
  const [cargo, setCargo] = useState([]);
  

  let redirecionar = useHistory();

  function cancelar(){
    redirecionar.push("/servidor/listar")
  }

  useEffect(() => {

    

    const cargos = new CargoService();
    async function carregarCargos() {
      const response = await cargos.listar();
      setCargo(response.data);
      //console.log(cargo)
    }

    carregarCargos();
   

    //console.log(servidor);
  }, [servidor.length]);

  function onChange(e) {
    const { name, value } = e.target;
    setServidor({ ...servidor, [name]: value });
    //console.log(servidor)
  }


  function obterIdCargo(e) {
    const id = Number(e.target.value);
    servidor.cargo = { id };
  }

  function salvar() {
    //console.log(servidor);

    if(servidor.dataAdmissao !== null){
        if(checarData(servidor.dataAdmissao) === false){
            mensagemErro('Data de admissão tem o formato inválido.');
            return;
        }
    }

    if(servidor.dataNascimento !== null){
        if(checarData(servidor.dataNascimento) === false){
            mensagemErro('Data de nascimento tem o formato inválido.');
            return;
        }
    }

    const servidorS = new ServidorService();
    servidor.cpf = RetiraMascara(servidor.cpf)
    servidor.pis = RetiraMascara(servidor.pis)
    servidor.telefone1 = RetiraMascara(servidor.telefone1)
    servidor.telefone2 = RetiraMascara(servidor.telefone2)
    servidorS
      .salvar(servidor)
      .then((response) => {
        mensagemSucesso("Servidor cadastrado com sucesso.");
        redirecionar.push("/servidor/listar");
      })
      .catch((e) => {
        mensagemErro(e.response.data);
        //console.log(e.response.data);
      });
  }

  function RetiraMascara(Obj) {
    return Obj.replace(/\D/g, '');
}

function checarData(data) {
    let dataAno = new Date(data);
    if(data !== ""){
        if(dataAno.getFullYear().toString().length === 4){
            return true;
        }else{
            return false;
        }
    }
}

  return (
    <div>
      <div className="container mt-4">
        <div className="form-group">
          <div className="card md-3">
            <h4 className="card-header">Cadastrar Servidor</h4>
            <div className="card-body">
              <Form>
                <Row>
                  <Col md={2}>
                    <label className="col-form-label" htmlFor="matricula">
                      Matrícula:
                    </label>
                    <input
                      value={servidor.matricula}
                      type="number"
                      className="form-control"
                      placeholder="Matrícula"
                      id="matricula"
                      name="matricula"
                      autoComplete="false"
                      onChange={onChange}
                    />
                  </Col>
                  <Col md={9}>
                    <label className="col-form-label" htmlFor="nomeCompleto">
                      Nome Completo:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Nome Completo"
                      id="nomeCompleto"
                      name="nomeCompleto"
                      onChange={onChange}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={11}>
                    <label className="col-form-label" htmlFor="cargo">
                      Cargo:
                    </label>
                    <Form.Select
                      defaultValue="Selecione"
                      id="cargo"
                      name="cargo"
                      onChange={obterIdCargo}
                    >
                      <option value={0}>Selecione o Cargo</option>
                      {cargo.map((c) => (
                        <option value={c.id} key={c.id}>
                          {c.nome}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <label className="col-form-label" htmlFor="email">
                      E-mail:
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Digite um email"
                      id="email"
                      name="email"
                      autoComplete="false"
                      onChange={onChange}
                    />
                  </Col>
                  <Col md={3}>
                    <label className="col-form-label" htmlFor="rg">
                      RG/Orgão Emissor/UF:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Ex.: 0000000000 AAA UF"
                      id="rg"
                      name="rg"
                      autoComplete="false"
                      onChange={onChange}
                    />
                  </Col>
                  <Col md={2}>
                    <label className="col-form-label" htmlFor="cpf">
                      CPF:
                    </label>
                    <InputMask
                      mask="999.999.999-99"
                      type="text"
                      className="form-control"
                      placeholder="000.000.000-00"
                      id="cpf"
                      name="cpf"
                      onChange={onChange}
                    />
                  </Col>
                  <Col md={2}>
                    <label className="col-form-label" htmlFor="pis">
                      PIS:
                    </label>
                    <InputMask
                      mask="999.99999.99-9"
                      type="text"
                      className="form-control"
                      placeholder="000.00000.00-0"
                      id="pis"
                      name="pis"
                      onChange={onChange}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={3}>
                    <label className="col-form-label" htmlFor="vinculo">
                      Vínculo:
                    </label>
                    <Form.Select
                      id="vinculo"
                      name="vinculo"
                      onChange={onChange}
                    >
                      <option>Selecione</option>
                      <option value="EFETIVO">Efetivo</option>
                      <option value="CONTRATADO">Contratado</option>
                      <option value="COMISSIONADO">Comissionado</option>
                      <option value="TERCEIRIZADO">Terceirizado</option>
                    </Form.Select>
                  </Col>
                  <Col md={2}>
                    <label className="col-form-label" htmlFor="cargaHoraria">
                      Carga Horária:
                    </label>
                    <Form.Select
                      id="cargaHoraria"
                      name="cargaHoraria"
                      onChange={onChange}
                    >
                      <option>Selecione</option>
                      <option value="20">20 h/s</option>
                      <option value="30">30 h/s</option>
                      <option value="40">40 h/s</option>
                    </Form.Select>
                  </Col>
                  <Col md={2}>
                    <label className="col-form-label" htmlFor="situacao">
                      Situação:
                    </label>
                    <Form.Select
                      id="situacao"
                      name="situacao"
                      onChange={onChange}
                    >
                      <option>Selecione</option>
                      <option value="ATIVO">Ativo</option>
                      <option value="INATIVO">Inativo</option>
                    </Form.Select>
                  </Col>
                  <Col md={4}>
                    <label className="col-form-label" htmlFor="sexo">
                      Sexo:
                    </label>
                    <Form.Select
                      defaultValue="Selecione"
                      id="sexo"
                      name="sexo"
                      onChange={onChange}
                    >
                      <option value="">Selecione</option>
                      <option value="M">Masculino</option>
                      <option value="F">Feminino</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <label className="col-form-label" htmlFor="dataNascimento">
                      Data de Nascimento:
                    </label>
                    <input
                      id="dataNascimento"
                      name="dataNascimento"
                      type="date"
                      className="form-control"
                      onChange={onChange}
                      />
                  </Col>

                  <Col md={3}>
                    <label className="col-form-label" htmlFor="dataAdmissao">
                      Data de Admissão:
                    </label>
                    <input
                      id="dataAdmissao"
                      name="dataAdmissao"
                      type="date"
                      className="form-control"
                      onChange={onChange}
                      />
                  </Col>
                </Row>
                <br />
                <Row>
                  <div className="form-group">
                    <div className="card md-12">
                      <h6 className="card-header">Telefones</h6>
                      <div className="card-body">
                      
                      <Row>
                        <Col md={3}>
                          <label  htmlFor="telefone1">
                            Número 1:
                          </label>
                          <InputMask
                            mask="(99) 9 9999-9999"
                            maskChar={null}
                            type="text"
                            className="form-control"
                            placeholder="(00) 0 0000-0000"
                            id="telefone1"
                            name="telefone1"
                            onChange={onChange}
                          />
                        </Col>
                        <Col md={5}>
                          <label  htmlFor="descTel1">
                            Descrição do telefone 1:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Digite uma descrição para o número."
                            id="descTel1"
                            name="descTel1"       
                            onChange={onChange}
                            
                          />

                          
                        </Col>
                        </Row>
                        <br/>
                        <Row>
                        <Col md={3}>
                          <label  htmlFor="telefone2">
                            Número 2:
                          </label>
                          <InputMask
                            mask="(99) 9 9999-9999"
                            maskChar={null}
                            type="text"
                            className="form-control"
                            placeholder="(00) 0 0000-0000"
                            id="telefone2"
                            name="telefone2"
                            onChange={onChange}
                          />
                        </Col>
                        <Col md={5}>
                          <label  htmlFor="descTel2">
                            Descrição do telefone 2:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Digite uma descrição para o número."
                            id="descTel2"
                            name="descTel2"       
                            onChange={onChange}
                            
                          />

                          
                        </Col>
                        </Row>
                        
                        </div>
                      </div>
                    </div>
                  
                </Row>
                <br />
                <Row>
                  <Col md={1}>
                    <button
                      onClick={salvar}
                      type="button"
                      className="btn btn-primary"
                    >
                      Salvar
                    </button>
                  </Col>

                  <Col md={1}>
                    <button
                      onClick={() => cancelar()}
                      type="button"
                      className="btn btn-danger"
                    >
                      Cancelar
                    </button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cadastrar;

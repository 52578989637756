import ApiService from "../app/apiservice";

export default class posLoginService extends ApiService {

    constructor(){
        super("/poslogin")
    }

    salvar(dados){
        return this.post("/salvar", dados)
    }

    atualizar(dados){
        return this.put("/atualizar", dados)
    }

    listarUnidades(idUsuario){
        return this.get(`/unidades/${idUsuario}`)
    }

    deletarUnidade(id){
        return this.delete(`/delete/${id}`)
    }

}
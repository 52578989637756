import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import Card from '../../components/card';
import { Link } from   'react-router-dom';
import LocalStorageService from '../../service/localStorageService'
import { descriptografar } from '../../components/criptografar'

import "react-picky/dist/picky.css";
import ParametrosLancamentosService from '../../service/parametrosLancamentoService';

function Home() {

    const [listaMeses, setListaMeses] = useState([]);
    

    const serviceParametros = new ParametrosLancamentosService();

    const usuarioCriptografado = LocalStorageService.obterItem('2')
    const usuarioLogado = JSON.parse(descriptografar(usuarioCriptografado))
    const userRH = "RH"
    const userAdm = "ADMINISTRADOR"

    const dataCriptografada = LocalStorageService.obterItem('1')
    let dataAtual = new Date(descriptografar(dataCriptografada));
    const mes = dataAtual.getUTCMonth() + 1;
    const ano = dataAtual.getUTCFullYear();

    const [key, setKey] = useState(mes);

    useEffect(() => {
        async function loadMeses() {
            const response = await serviceParametros.listarPorAno(ano);
            setListaMeses(response.data);
        }
        loadMeses();
    }, []);

    const formatarMes =(mes) => {
        return ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"][mes -1];
    }

    return (
        <Container className="mt-4">

            <Row className="mb-2">
                <p style={{fontSize: '2em'}}>
                    Olá <strong>{usuarioLogado.nome.split(' ')[0]}</strong>, seja bem-vindo(a) ao sistema RH Semec
                </p>
            </Row>
            <Card title="Datas de abertura e fechamento do lançamentos">
                <Tabs style={{display: 'flex', justifyContent: 'center', marginTop: '15px'}} activeKey={key} onSelect={(k) => setKey(k)}>
                    {listaMeses.map((m) => {
                        return (
                            <Tab eventKey={m.mesLancamento} title={formatarMes(m.mesLancamento)} key={m.id}>
                                <div className="text-center mt-4">
                                    <span style={{fontSize: '2em'}}><b>Datas de abertura e fechamento do mês de {formatarMes(m.mesLancamento)}</b></span>
                                </div>

                                <Row className="justify-content-md-center mt-4" style={{ textAlign: "center" }}>
                                    <Col lg={4} md={12}>
                                        <Card title="Servidores e Estagiários">
                                            <h6>Abertura dos lançamentos:</h6>
                                                <span style={{fontSize: '20px', fontWeight: "bold"}}>Dia {m.diaAberturaServidoresEstagiarios}</span> 
                                            <h6>Fechamento dos lançamentos:</h6>
                                                <span style={{fontSize: '20px', fontWeight: "bold"}}>Dia {m.diaFechamentoServidoresEstagiarios}</span>
                                        </Card>
                                    </Col>

                                    <Col lg={3} md={12}>
                                        <Card title="Cooperados">
                                            <h6>Abertura dos lançamentos:</h6>
                                                <span style={{fontSize: '20px', fontWeight: "bold"}}>Dia {m.diaAberturaCooperados}</span> 
                                            <h6>Fechamento dos lançamentos:</h6>
                                                <span style={{fontSize: '20px', fontWeight: "bold"}}>Dia {m.diaFechamentoCooperados}</span>
                                        </Card>
                                    </Col>

                                    <Col lg={3} md={12}>
                                        <Card title="Prestadores">
                                            <h6>Abertura dos lançamentos:</h6>
                                                <span style={{fontSize: '20px', fontWeight: "bold"}}>Dia {m.diaAberturaPrestadores}</span> 
                                            <h6>Fechamento dos lançamentos:</h6>
                                                <span style={{fontSize: '20px', fontWeight: "bold"}}>Dia {m.diaFechamentoPrestadores}</span>
                                        </Card>
                                    </Col>
                                </Row><br/>
                                <Row className="justify-content-md-center" style={{ textAlign: "center" }}>
                                {/*        <Col lg={3} md={12}>
                                        <Card title="Terceirizados - Dia">
                                            <h6>Abertura dos lançamentos:</h6>
                                                <span style={{fontSize: '20px', fontWeight: "bold"}}>Dia {m.diaAberturaTerceirizadoDia}</span> 
                                            <h6>Fechamento dos lançamentos:</h6>
                                                <span style={{fontSize: '20px', fontWeight: "bold"}}>Dia {m.diaFechamentoTerceirizadoDia}</span>
                                        </Card>
                                    </Col> */}

                                    <Col lg={3} md={12}>
                                        <Card title="Terceirizados">
                                            <h6>Abertura dos lançamentos:</h6>
                                                <span style={{fontSize: '20px', fontWeight: "bold"}}>Dia {m.diaAberturaTerceirizadoHora}</span> 
                                            <h6>Fechamento dos lançamentos:</h6>
                                                <span style={{fontSize: '20px', fontWeight: "bold"}}>Dia {m.diaFechamentoTerceirizadoHora}</span>
                                        </Card>
                                    </Col>

                                </Row>
                            </Tab>
                        )
                    })}
                </Tabs>
            </Card>
             <br></br><br></br>
            <Card title="Lançamentos">
                <Row className="justify-content-md-center mt-4">
                    <Col sm={12} md={6} style={{marginBottom: "10px"}}>
                        <Card title="Servidores">
                            <Link style={{ textDecoration: "none"}} to="/lancamentos/ListarLancamentosServidores">
                                Visualizar Lançamentos
                            </Link>{" "}
                        </Card>
                    </Col>
                
                    <Col sm={12} md={6} style={{marginBottom: "10px"}}>
                        <Card title="Estagiários">
                            <Link style={{ textDecoration: "none" }} to="/lancamentos/ListarLancamentosEstagiario">
                            Visualizar Lançamentos
                            </Link>{" "}
                        </Card>
                    </Col>
                    <Col sm={12} md={6} style={{marginBottom: "10px"}}>
                        <Card title="Cooperados">
                            <Link style={{ textDecoration: "none" }} to="/lancamentos/ListarLancamentosCooperado">
                            Visualizar Lançamentos
                            </Link>{" "}
                        </Card>
                    </Col>

                    <Col sm={12} md={6}>
                        <Card title="Substitutos Temporários">
                            <Link style={{ textDecoration: "none" }} to="/prestador/lanc">
                            Visualizar Lançamentos
                            </Link>{" "}
                        </Card>
                    </Col>
                    <Col sm={12} md={6}>
                        <Card title="Terceirizados">
                            <Link style={{ textDecoration: "none" }} to="/lancamentos/terceirizadosporhora">
                            Visualizar Lançamentos
                            </Link>{" "}
                        </Card>
                    </Col>    
                </Row>
            </Card>
            <br></br><br></br>
            <Card title="Relatórios">
                <Row className="mt-4">
                    {(usuarioLogado.role === userRH || usuarioLogado.role === userAdm) &&
                    <Col sm={12} md={6} style={{marginBottom: "10px"}}>
                        <Card title="Anexos Mensais">
                            <Link style={{ textDecoration: "none" }} to="/rel/anexos">
                                Visualizar Anexos
                            </Link>{" "}
                        </Card>
                    </Col>}
                    <Col sm={12} md={6}>
                        <Card title="Quadros Mensais">
                            <Link style={{ textDecoration: "none" }} to="/rel/quadros-mensais">
                                Visualizar Quadros
                            </Link>{" "}
                        </Card>
                    </Col>
                </Row>
            </Card>
        </Container>
    );
}

export default Home;
import ApiService from "../app/apiservice"

class DataFaltaSubstituicaoService extends ApiService {

    constructor(){
        super('/datafaltasubstituicao')
    }

    buscarPorNome(nome){
        return this.get(nome)
    }
    
    listarTodos(pagina){
        return this.get(pagina)
    }

    obterPorId(id){
        return this.get(`/${id}`)
    }
    
    salvar(dataFaltaSub){
        return this.post('/', dataFaltaSub)
    }

    atualizar(dataFaltaSub){
        return this.put(`/${dataFaltaSub.id}`, dataFaltaSub)
    }

    deletar(id){
        return this.delete(`/${id}`)
    }

    buscarFaltasEHorasPorLancamentoEstagiario(id){
        return this.get(`/buscarporlancestagiario/${id}`)
    }

    buscarFaltasEHorasPorLancamentoServidor(id){
        return this.get(`/buscarporlancservidor/${id}`)
    }

}

export default DataFaltaSubstituicaoService
import Rotas from './Rotas';
import BarraMenus from './components/menu/BarraMenus';

import 'toastr/build/toastr.min.js'
import 'bootswatch/dist/cerulean/bootstrap.css'
import 'toastr/build/toastr.css'
import ProvedorAutenticacao from './provedorAutenticacao';

function App() {
  return (
    <ProvedorAutenticacao> 
          <BarraMenus />
          <div className='container' style={{marginTop: '100px', marginBottom: "100px"}}>
               <Rotas/>
          </div>  
    </ProvedorAutenticacao>
  );
}

export default App;
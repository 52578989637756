import React from 'react';

import { Switch, Route, Redirect, HashRouter } from 'react-router-dom';
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import CadastrarU from './pages/usuario/Cadastar';
import EditarU from './pages/usuario/Editar';
import CadastrarS from './pages/servidor/Cadastar';
import EditarS from './pages/servidor/Editar';
import ListaUnidades from './pages/unidade/listarUnidades';
import CadastrarEstagiario from './pages/estagiario/Estagiario';
import ListarEstagiarios from './pages/estagiario/ListarEstagiarios';
import ListarCooperados from './pages/cooperado/ListarCooperados';
import ListarLancamentosServidores from './pages/lancamentos/ListarLancamentosServidores';
import ListarUsuarios from './pages/usuario/ListarUsuarios';
import ListarServidores from './pages/servidor/ListarServidores';
import ListarCargos from './pages/cargo/ListarCargos';
import ListarLancamentosEstagiario from './pages/lancamentos/ListarLancamentoEstagiario';
import LancamentoPrestadores from './pages/lancamentoPrestador/LancamentoPrestadores';
import ListarPrestadores from './pages/prestadores/ListarPrestadores';
import CadastrarP from './pages/prestadores/Cadastar'
import EditarP from './pages/prestadores/Editar'
import ListarLancamentosCooperados from './pages/lancamentos/ListarLancamentoCooperados';
import ListarFuncoes from './pages/funcao/ListarFuncoes';
import EditarLancamentoPrestador from './pages/lancamentoPrestador/IdPrestadorEditar';
import DatasEditar from './pages/lancamentoPrestador/IdDatasEditar';
import InssIrrfVh from './pages/InssIrrfVh/InssIrrfVh';
import logsAtualizacoes from './pages/logsAtualizacoesValores/logsAtualizacoes';
import ParametrosLancamentos from './pages/parametrosLancamentos/ParametrosLancamentos';
import Recibos from './pages/relatorios/substitutosTemporarios/recibos';
import anoLetivo from './pages/anoLetivo/anoLetivo';
import ListaGeral from './pages/relatorios/substitutosTemporarios/listaGeral';
import parametrosFgs from './pages/fg/fg';
import NumeroDeAlunos from './pages/numeroDeAlunos/NumeroDeAlunos';
import PeriodoNumAlunos from './pages/periodoNumeroAlunos/periodoNumAlunos';
import AnexosMensais from './pages/relatorios/anexosMensais/anexosMensais'
import QuadrosMensais from './pages/relatorios/quadrosMensais/QuadrosMensais';
import AlterarSenhaPropria from './pages/alterarSenhaPropria/alterarSenhaPropria';
import Motivos from './pages/motivos/motivos'
import PosLogin from './pages/posLogin/PosLogin';
import FaltasRh from './pages/faltas/faltas'
import AssinaturaQuadros from './pages/assinaturaQuadros/assinaturaQuadros';
import VerificacaoCodigo from './pages/verificarCodigoAssinatura/verificarCodigoAssinatura';
import codigoVerificar from './pages/verificarCodigoAssinatura/codigoVerificar';
import assinaturaPainel from './pages/assinaturaPainel/assinaturaPainel';
import CalendarioDiasLetivos from './pages/calendarioDiasLetivos/calendarioDiasLetivos';
import { AuthConsumer } from './provedorAutenticacao';
import LocalStorageService from './service/localStorageService';
import { descriptografar } from './components/criptografar';
import ListarLancamentosTerceirizados from './pages/lancamentos/ListarLancamentoTerceirizados';
import ListarTerceirizados from './pages/terceirizado/ListarTerceirizado';
import ListarLancamentoTerceirizadoHoras from './pages/lancamentos/ListarLancamentoTerceirizadoHora';
import LogsTabelas from './pages/relatorios/ListarLogsTabelas';




function RotaAutenticada( {component: Component, isUsuarioAutenticado, ...props}){
    return (
        <Route exact {...props} render={ (componentProps) => {
            if (isUsuarioAutenticado) {
                return(
                    <Component {...componentProps}/>
                )
            } else{
                return(
                    <Redirect to={{pathname: '/login', state: {from: componentProps.location }}} />
                )
            }
        }} />
    )
}
            
function Rotas(props) {
    
    const usuarioCriptografado = LocalStorageService.obterItem('2')
    const usuarioLogado = usuarioCriptografado ? JSON.parse(descriptografar(usuarioCriptografado)) : null
    const rolerUser = usuarioLogado ? usuarioLogado.role : ""
    const r = "RH"
    const a = "ADMINISTRADOR"

    
    return (
        <HashRouter basename="/">
            <Switch>
                <Route exact path="/" component={(props.isUsuarioAutenticado ? Home : Login)}/>
                <Route exact path="/login" component={Login}/>
                <Route exact path="/verificacao" component={VerificacaoCodigo}/>
                <Route exact path="/dadosverificacao/:cod" component={codigoVerificar}/>

                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/home" component={Home}/>
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/inicio" component={Home}/>
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/ini" component={PosLogin}/>

                <RotaAutenticada isUsuarioAutenticado={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} path="/usuario/listar" component={ListarUsuarios}/>
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/usuario/alterpass" component={AlterarSenhaPropria}/>
                <RotaAutenticada isUsuarioAutenticado={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} path="/usuario/cad" component={CadastrarU}/>
                <RotaAutenticada isUsuarioAutenticado={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} path="/usuario/edit/:id" component={EditarU}/>

                {/* <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/usuario/listar" component={ListarUsuarios}/>
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/usuario/cad" component={CadastrarU}/>
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/usuario/edit/:id" component={EditarU}/> */}

                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/assinatura/quadros" component={AssinaturaQuadros}/>
                <RotaAutenticada isUsuarioAutenticado={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} path="/assinatura/painel" component={assinaturaPainel}/>

                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/servidor/listar" component={ListarServidores}/>
                <RotaAutenticada isUsuarioAutenticado={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} path="/servidor/cad" component={CadastrarS}/>
                <RotaAutenticada isUsuarioAutenticado={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} path="/servidor/edit/:id" component={EditarS}/>

                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/prestador/listar" component={ListarPrestadores}/>
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/prestador/cad" component={CadastrarP}/>
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/prestador/lanc" component={LancamentoPrestadores}/>
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/prestador/edlanc/:id" component={EditarLancamentoPrestador} />
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/prestador/eddatas/:id" component={DatasEditar} />

                {/* <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/lancamentos/LancamentoServidor/:idLan?" component={LancamentosServidores}/> */}

                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/prestador/edit/:id" component={EditarP}/>

                <RotaAutenticada isUsuarioAutenticado={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} path="/params/tab" component={InssIrrfVh}/>
                <RotaAutenticada isUsuarioAutenticado={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} path="/params/fgs" component={parametrosFgs}/>
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/params/numalunos" component={NumeroDeAlunos}/> 
                <RotaAutenticada isUsuarioAutenticado={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} path="/params/periodonumalunos" component={PeriodoNumAlunos}/>
                <RotaAutenticada isUsuarioAutenticado={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} path="/params/logs" component={logsAtualizacoes}/>
                <RotaAutenticada isUsuarioAutenticado={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} path="/params/calendario" component={CalendarioDiasLetivos}/>

                <RotaAutenticada isUsuarioAutenticado={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} path="/rel/recibos" component={Recibos}/>
                <RotaAutenticada isUsuarioAutenticado={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} path="/rel/quadroprsmes" component={ListaGeral}/>
                <RotaAutenticada isUsuarioAutenticado={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} path="/rel/anexos" component={AnexosMensais}/>
                <RotaAutenticada isUsuarioAutenticado={(props.isUsuarioAutenticado && (rolerUser===a))} path="/rel/logstabelas" component={LogsTabelas}/>
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/rel/quadros-mensais" component={QuadrosMensais}/>

                <RotaAutenticada isUsuarioAutenticado={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} path="/ano" component={anoLetivo}/>

                

                {/* <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/home" component={Home}/> */}
                <RotaAutenticada isUsuarioAutenticado={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} path="/unidade/listarUnidades" component={ListaUnidades} />
                <RotaAutenticada isUsuarioAutenticado={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} path="/estagiario/Estagiario/:id?" component={CadastrarEstagiario} />
                <RotaAutenticada isUsuarioAutenticado={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} path="/estagiario/ListarEstagiarios" component={ListarEstagiarios} />
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/cooperado/ListarCooperados" component={ListarCooperados} />
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/lancamentos/ListarLancamentosServidores" component={ListarLancamentosServidores} />
                {/* <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/lancamentos/LancamentoEstagiario/:idEsta?/:idLan?" component={LancamentoEstagiario} /> */}
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/lancamentos/ListarLancamentosEstagiario" component={ListarLancamentosEstagiario} />
                {/* <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/lancamentos/LancamentoCooperado/:idCoop?/:idLan?" component={LancamentoCooperado} /> */}
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/lancamentos/ListarLancamentosCooperado" component={ListarLancamentosCooperados} />
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/lancamentos/terceirizadosporhora" component={ListarLancamentoTerceirizadoHoras} />
                <RotaAutenticada isUsuarioAutenticado={(props.isUsuarioAutenticado && rolerUser===a)} path="/lancamentos/ListarLancamentosTerceirizados" component={ListarLancamentosTerceirizados}/>
                <RotaAutenticada isUsuarioAutenticado={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} path="/lancamentos/faltas" component={FaltasRh} />
                <RotaAutenticada isUsuarioAutenticado={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} path="/cargo/ListarCargos" component={ListarCargos} />
                <RotaAutenticada isUsuarioAutenticado={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} path="/funcao/ListarFuncoes" component={ListarFuncoes} />
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/terceirizado/ListarTerceirizados" component={ListarTerceirizados} />
                <RotaAutenticada isUsuarioAutenticado={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} path="/motivos/listarmotivos" component={Motivos} />
                <RotaAutenticada isUsuarioAutenticado={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} path="/parametrosLancamentos/ParametrosLancamentos" component={ParametrosLancamentos} />
            </Switch>
        </HashRouter>
    );
}

export default () => (
    <AuthConsumer>
        { (context) => (<Rotas isUsuarioAutenticado={context.isAutenticado} />) }
    </AuthConsumer>
)
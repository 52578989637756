import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { mensagemErro, mensagemSucesso } from "../../components/avisos/toastr";
import MotivosSubstituicaoService from "../../service/motivosSubstituicaoService";

const motivoDados = {
  id: null,
  descricao: "",
  status: true,
}

const motivoModificar = {
    id: null,
    descricao: "",
    status: null,
  }
const serviceMotivo = new MotivosSubstituicaoService();

export default function Motivos() {
  const [motivosListar, setMotivoListar] = useState([]);
  const [motivoAdicionar, setMotivoAdicionar] = useState(motivoDados);

  //modal adicionar Motivo
  const [showAdicionarMotivo, setCadastrarMotivo] = useState(false);
  const handleCloseAdicionarMotivo = () => setCadastrarMotivo(false);
  const handleAdicionarMotivo = () => setCadastrarMotivo(true);

  //modalModificar status do motivo
  const [showModificarStatus, setshowModificarStatus] = useState(false)
  const handleCloseModificarStatus = () => setshowModificarStatus(false)
  const handleModificarStatus = () => setshowModificarStatus(true)
  const [motivoAtualizar, setMotivoAtualizar] = useState(motivoModificar)
  const [descricao, setDescricao] = useState('')
  const [situacao, setSituacao] = useState();

  useEffect(() => {
    async function CarregarMotivos() {
      const m = await serviceMotivo.listar();
      setMotivoListar(m.data);
    }
    CarregarMotivos();
  }, [showAdicionarMotivo, showModificarStatus, motivosListar.length]);

  function cadastrarMotivo() {
    serviceMotivo
      .salvar(motivoAdicionar)
      .then((s) => {
        mensagemSucesso("Motivo cadastrado com sucesso.");
        handleCloseAdicionarMotivo();
        setMotivoAdicionar(motivoDados)
      })
      .catch((e) => {
        mensagemErro(e.data);
      });
  }

  function carregarMotivoParaAtualizacao(id, descricao, situacao){
        setDescricao(descricao)
        setSituacao(situacao)
        serviceMotivo.buscarId(id)
        .then((e) => {
            setMotivoAtualizar(e.data)
            handleModificarStatus()
        })
  }

  function AtualizarStatusAtivo(){
    motivoAtualizar.status = true
    serviceMotivo.atualizar(motivoAtualizar)
    .then((s) => {
        mensagemSucesso("Motivo habilitado com sucesso.")
        handleCloseModificarStatus()
    })
  }

  function AtualizarStatusInativo(){
    motivoAtualizar.status = false
    serviceMotivo.atualizar(motivoAtualizar)
    .then((s) => {
        mensagemSucesso("Motivo desabilitado com sucesso.")
        handleCloseModificarStatus()
    })
  }

  return (
    <>
      <div className="container mt-6">
        <div className="form-group">
          <div className="card md-3">
            <h4 className="card-header">Cadastro de Motivos</h4>
            <Container>
              <br />
              <Row className="justify-content-md-center">
                <Col style={{ textAlign: "end" }}>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleAdicionarMotivo()}
                  >
                    Adicionar
                  </button>
                </Col>
              </Row>
              <br />
              <h5 style={{ textAlign: "center" }}>Motivos de substituições</h5>

              <br />
              <Row
                style={{ textAlign: "center" }}
                className="justify-content-md-center"
              >
                <Col xs lg="4">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Descrição
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Status
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Ações
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {motivosListar.map((m) => (
                        <tr key={m.id}>
                          <td
                            style={{ maxWidth: "300px", textAlign: "center" }}
                          >
                            {m.descricao}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {m.status === true ? (
                              <label>ATIVO</label>
                            ) : (
                                <label>INATIVO</label>
                            )}
                          </td>

                          <td style={{ textAlign: "center" }}>
                            {m.status === true ?
                                <button
                                title="Desabilitar"
                                className="btn btn-danger"
                                type="button"
                                onClick={() => carregarMotivoParaAtualizacao(m.id, m.descricao, m.status)}
                                >
                                <i className="pi pi-power-off"></i>
                                </button>
                                :
                                <button
                                title="Habilitar"
                                className="btn btn-primary"
                                type="button"
                                onClick={() => carregarMotivoParaAtualizacao(m.id, m.descricao, m.status)}
                                >
                                <i className="pi pi-unlock"></i>
                                </button>}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <Modal
        show={showAdicionarMotivo}
        onHide={handleCloseAdicionarMotivo}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Adicionar Motivo de Substituições</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-md-center">
            <Col xs lg="7">
              <label className="col-form-label">Digite o motivo:</label>
              <input
                value={motivoAdicionar.descricao}
                onChange={(m) =>
                  setMotivoAdicionar({
                    ...motivoAdicionar,
                    descricao: m.target.value,
                  })
                }
                type="text"
                className="form-control"
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            type="button"
            onClick={(e) => cadastrarMotivo()}
          >
            Adicionar
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModificarStatus}
        onHide={handleCloseModificarStatus}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{situacao === true ? 'Desabilitar' : 'Habilitar'} Motivo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row >
            <Col>
                <h5>Deseja realmente {situacao === true ? 'desabilitar' : 'habilitar'} o motivo: {descricao}</h5>
              <label className="col-form-label">Motivos desabilitados não ficarão visíveis para utilização nos Lançamentos.</label>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
            {situacao === true ?
                <button
                    className="btn btn-danger"
                    type="button"
                    onClick={() => AtualizarStatusInativo()}
                >
                    Desabilitar
                </button>
            :
                <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => AtualizarStatusAtivo()}
                >
                    Habilitar
                </button>
            }
        </Modal.Footer>
      </Modal>
    </>
  );
}

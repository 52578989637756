import ApiService from "../app/apiservice";

 export default class DiasLetivosService extends ApiService {

    constructor(){
        super('/diasletivos')
    }

    criarDiasLetivos(ano, idUnidade){
        return this.post(`/inserir/${ano}/${idUnidade}`)
    }

    listarPorAnoSecretaria(ano, idSecretaria){
        return this.get(`/listar/${ano}/${idSecretaria}`)
    }

    atualizarDiasLetivos(ano, mes, idSecretaria, tipo, dataSalvar){
        return this.put(`/atualizar/${ano}/${mes}/${idSecretaria}/${tipo}/${dataSalvar}`)
    }

    atualizarApagandoDiasLetivos(ano, mes, idSecretaria, tipo, dataSalvar){
        return this.put(`/atualizardeletando/${ano}/${mes}/${idSecretaria}/${tipo}/${dataSalvar}`)
    }

}
import ApiService from "../app/apiservice";

class CargoService extends ApiService {

    constructor(){
        super('/cargos')
    }

    buscarPorNome(nome){
        return this.get(nome)
    }
    
    listarTodos(pagina){
        return this.get(pagina)
    }
    
    obterPorId(id){
        return this.get(`/${id}`)
    }
    
    salvar(cargo){
        return this.post('/', cargo)
    }
    
    listar() {
        return this.get('/')
    }
    
    deletar(id){
        return this.delete(`/${id}`)
    }
    
    atualizar(cargo){
        return this.put(`/${cargo.id}`, cargo)
    }
}

export default CargoService 
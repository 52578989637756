import Button from 'react-bootstrap/Button'
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import { mensagemErro, mensagemSucesso } from "../../components/avisos/toastr";
import Card from "../../components/card";
import UnidadeService from "../../service/unidadeService";
import ReactPaginate from "react-paginate";

import styles from './listarUnidade.module.css';

function ListaUnidades() {

    const initialValue = {
        nome: '',
        secretaria: '',
        unidadeEscolar: false
    }
    
    const [values, setValues] = useState(initialValue);

    function onChange(e) {
        const { name, value } = e.target;
        setValues({...values, [name]: value })
    }

    const [show, setShow] = useState(false);
    const [showConf, setShowConf] = useState(false);
    const [unidadeEnsino, setUnidadeEnsino] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleConfclose = () => setShowConf(false);
    const handleConfShow = () => setShowConf(true);

    const [unidade, setUnidades] = useState([]);
    const [imputId, setImputId] = useState();
    
    const service = new UnidadeService();

    //Parte relacionada a paginação e busca por nome 
    //const [totalUnidades, setTotalUnidades] = useState(0);
    //const [totalPaginas, setTotalPaginas] = useState([]);
    const [paginaAtual, setPaginaAtual] = useState(0);
    const [nomeBuscar, setNomeBuscar] = useState("");
    const [numeroDePaginas, setNumeroDePaginas] = useState(0);

    //implementar futuramente função para quando pesquisar setar a primeira pagina
    const pesquisou = () => setPaginaAtual(0);

    useEffect(() => {

        async function loadUnidadesPaginacao(){
            const response = await service.buscarPorNome(`/unidadepornome/${nomeBuscar}/page/${paginaAtual}`);
            //setTotalUnidades(response.data.totalElements);
            setUnidades(response.data.content);
            setNumeroDePaginas(response.data.totalPages);

            const arrayPaginas = [];
            //cria um array de paginas na quantidade vinda do backend
            for (let p = 1; p <= numeroDePaginas; p++) {
                arrayPaginas.push(p);
            }
            //setTotalPaginas(arrayPaginas);

        }

        async function carregarUnidades() {
            const response = await service.listarTodos(`/pag/${paginaAtual}`);
            //setTotalUnidades(response.data.totalElements);
            setUnidades(response.data.content);
            setNumeroDePaginas(response.data.totalPages);
        
            const arrayPaginas = [];
            //cria um array de paginas na quantidade vinda do backend
            for (let p = 1; p <= numeroDePaginas; p++) {
                arrayPaginas.push(p);
            }
            //setTotalPaginas(arrayPaginas);
        
        }
            
        if (nomeBuscar === "") {
            carregarUnidades();
        } else {
            loadUnidadesPaginacao();
        }

    }, [paginaAtual, nomeBuscar, showConf, show]);

    function handlePageClick(data) {
        setPaginaAtual(data.selected);
    }

    const limparDados = () => {
        setValues({
            nome: '',
            secretaria: '',
            unidadeEscolar: false,
            imputId: null
        })
        setUnidadeEnsino(false)
        handleClose();
    }

    const abrirEditModal = (id) => {
        handleShow()

        if(id){
            service.obterPorId(id)
            .then((response) => {
               setValues(response.data)
               setUnidadeEnsino(response.data.unidadeEscolar)
            })
        }
    }

    const cadastrar = () => {
        const msgs = []
    
        if(!values.nome){
          msgs.push('O campo nome é obrigatório.')
          mensagemErro(msgs)
          return false;
        }

        if(values.secretaria === '' || values.secretaria === null){
            msgs.push('O campo secretaria é obrigatório.')
            mensagemErro(msgs)
            return false;
          }
        values.unidadeEscolar = unidadeEnsino
        service.salvar(values)
          .then(() => {
            mensagemSucesso('Unidade cadastrada com sucesso.')
            limparDados()
          }).catch(error => {
              msgs.push(error.response.data.erros.nome)
            mensagemErro(msgs)
          })
    }
    
    const atualizar = () => {
        const msgs = []
    
        if(!values.nome || values.nome.trim() === ''){
          msgs.push('O campo nome é obrigatório.')
          mensagemErro(msgs)
          return false;
        }

        if(values.secretaria === '' || values.secretaria === null){
            msgs.push('O campo secretaria é obrigatório.')
            mensagemErro(msgs)
            return false;
          }
        values.unidadeEscolar = unidadeEnsino
        service.atualizar(values)
          .then(() => {
            mensagemSucesso('Unidade atualizada com sucesso.')
            limparDados()
          }).catch(error => {
            mensagemErro(error.response.data.mensagem)
          })
      }

    const deletar = (id) => {
        service.deletar(id)
            .then(() => {
                mensagemSucesso('Unidade deletada com sucesso.')
                handleConfclose()
            }).catch( error => {
                mensagemErro(error.response.data.mensagem);
            })
    }

    function tipoDaUnidade(){
        if (unidadeEnsino) {
            setUnidadeEnsino(false)
        }
        if (!unidadeEnsino) {
            setUnidadeEnsino(true)
        }
    }

    return(
        <Container className="mt-4">
            <Card title="Lista de Unidades">
                <div className={styles.divBusca}>
                    <div className="input-group "> 
                        <div>
                            <input
                            type="text"
                            className="form-control"
                            placeholder="Pesquisar pelo nome"
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                            value={nomeBuscar}
                            onChange={(buscar) => setNomeBuscar(buscar.target.value)}
                            />
                        </div>
                    </div>
                    <div className={styles.btnAdd} >
                        <button onClick={handleShow} type="button" className="btn btn-primary">Adicionar</button>
                    </div>
                </div>
                <Table striped bordered hover size="md">
                    <thead>
                        <tr>
                            <th>##</th>
                            <th>Nome da Unidade</th>
                            <th>Unidade de Ensino?</th>
                            <th>Secretaria</th>
                        </tr>
                    </thead>
                    <tbody>
                        {unidade.map((u, index) => {
                            return(
                                <tr key={u.id}>
                                    <td>{index+1}</td>
                                    <td className='col-md-8'>{u.nome}</td>
                                    <td className='col-md-1'>{u.unidadeEscolar === true ? "Sim" : "Não"}</td>
                                    <td className='col-md-1'>{u.secretaria}</td>
                                    <td style={{textAlign: 'center', padding: '3px'}}>
                                        <button className="btn btn-primary" title='Editar' onClick={() => abrirEditModal(u.id)} style={{marginRight: '5px'}}>
                                            <i className='pi pi-pencil'></i>
                                        </button>
                                 
                                        <button onClick={() => handleConfShow(setImputId(`${u.id}`))} className="btn btn-danger" title='Excluir'>
                                            <i className='pi pi-trash'></i>
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}

                    </tbody>
                </Table>
                {unidade.length > 0 &&
                    <ReactPaginate
                        previousLabel={"Voltar"}
                        nextLabel={"Avançar"}
                        breakLabel={"..."}
                        pageCount={numeroDePaginas}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                        initialPage={pesquisou}
                    />
                }
            </Card>

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Body>
                    <Container className="mt-4">
                        <Card title="Cadastro de Unidades">
                            <Form>
                                <Row>
                                    <Col>
                                        <label className="col-form-label" htmlFor="nome">Nome da Unidade:</label>
                                        <input type="text" 
                                            className="form-control" 
                                            id="nome" 
                                            name="nome"
                                            value={values.nome}
                                            onChange={onChange}
                                            placeholder="Informe o nome da unidade" 
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="col-form-label" htmlFor="secretaria">Secretaria Municipal:</label>
                                        <Form.Select className="form-control"  id="secretaria" name="secretaria" value={values.secretaria} onChange={onChange}  >
                                                <option value="">Selecione</option>
                                                <option value="SEMEC">SEMEC</option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                                <Row style={{alignItems: "center"}}>
                                        <label className="col-form-label" htmlFor="unidadeEscolar">É uma unidade de ensino?:</label>
                                        <input type="checkbox"
                                            style={{width: 70, height: 36}} 
                                            id="substituirServidor" 
                                            checked={unidadeEnsino}
                                            onChange={tipoDaUnidade}
                                        />
                                </Row>
                            </Form>
                        </Card>

                        <div className="mt-4">
                            <div className="text-center">
                                {values.id > 0 ?
                                    <button onClick={atualizar} className="btn btn-primary">Atualizar</button> 
                                    :
                                    <button onClick={cadastrar} className="btn btn-primary">Cadastrar</button> 
                                }
                            </div>
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-secondary" onClick={limparDados}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showConf} onHide={handleConfclose} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <h3>Confirmação</h3>
                </Modal.Header>
                <Modal.Body>
                    <h5>Você realmente deseja excluir o registro?</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-danger" onClick={() => deletar(imputId)}>
                        Confirmar
                    </Button>
                    <Button className="btn btn-secondary" onClick={handleConfclose}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}

export default ListaUnidades
import ApiService from "../app/apiservice";

class ServidorMotivoSub extends ApiService {

    constructor(){
        super('/sermotsub')
    }

    listarPorIdLancamento(idLancamento){
        return this.get(`/listar/${idLancamento}`)
    }

    salvar(IdsServidorEMotivo){
        return this.post('/salvar', IdsServidorEMotivo)
    }

    atualizar(dadosAtualizar){
        return this.put('/atualizar', dadosAtualizar)
    }

    buscarSM(id){
        return this.get(`/buscar/${id}`)
    }

    excluir(id){
        return this.delete(`/excluir/${id}`)
    }

}


export default ServidorMotivoSub
import ApiService from "../app/apiservice"
import ErroValidacao from "../app/ErroValidacao"

class CooperadoService extends ApiService {

    constructor(){
        super('/cooperados')
    }

    buscarPorNome(nome){
        return this.get(nome)
    }
    
    listarTodos(pagina){
        return this.get(pagina)
    }
    
    obterPorId(id){
        return this.get(`/${id}`)
    }
    
    salvar(cooperado){
        return this.post('/', cooperado)
    }
    
    listar() {
        return this.get('/')
    }
    
    deletar(id){
        return this.delete(`/${id}`)
    }
    
    atualizar(cooperado){
        return this.put(`/${cooperado.id}`, cooperado)
    }

    listarCh(){
        return this.get('/ch')
    }

    validar(cooperado){
        const erros = [];

        if(!cooperado.nome || cooperado.nome.trim() === ''){
            erros.push('O nome é obrigatório.')
        }

        if(!cooperado.cpf){
            erros.push("O CPF é obrigatório.")
        }

        if(erros && erros.length > 0){
            throw new ErroValidacao(erros);
        }
    }

    validarBack(cooperado, error){
        const erros = [];

        if(!cooperado.nome || cooperado.nome.trim() === ''){
            erros.push(error.response.data)
        }
        if(cooperado.cpf === "" || cooperado.cpf === null){
            erros.push(error.response.data)
        }

        if(erros && erros.length > 0){
            throw new ErroValidacao(erros);
        }
    }
}

export default CooperadoService;
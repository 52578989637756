import { useEffect, useState } from "react"
import { Container, Row, Col, Table, Modal, Form, Tabs, Tab, OverlayTrigger, Tooltip  } from "react-bootstrap"
import Card from "../../components/card";
import DatePicker from 'react-multi-date-picker';
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import calendarioPersonalizado from "../../components/calendario/calendariomulti";
import ReactPaginate from "react-paginate"
import { mensagemAlerta, mensagemErro, mensagemSucesso } from "../../components/avisos/toastr"
import { descriptografar } from "../../components/criptografar"
import AnoMes from "../../components/mesAno/anoMes"
import anoService from "../../service/anoService"
import LancamentoTerceirizadoPorHoraService from "../../service/lancamentoTerceirizadoPorHoraService"
import ParametrosStatusLancamentoService from '../../service/parametrosStatusLancamentoService'
import LocalStorageService from "../../service/localStorageService"
import TerceirizadoService from "../../service/terceirizadoService"
import FuncaoService from "../../service/funcaoService";
import faltasHorasSubsTerceirizadoHorasService from "../../service/fatlasHorasSubsTerceirizadoHorasService";
import LancamentoServidorService from "../../service/lancamentoServidorService";
import assinaturaQuadrosService from "../../service/assinaturaQuadrosService";


const dadosLancamento = {
    id: null,
    terceirizado: [],
    funcao: [],
    unidade: [],
    anoLancamento: '',
    mesLancamento: '',
    turno: '',
    chDiaria: 0,
    dataInicioUnidade: null,
    dataDesligamentoUnidade: null,
    statusLancamento: true,
    tipoTerceirizado: '',
    diasDaSemana: '',
    regimePagamento: '',
    empresa: ''
}

const lancamentoTerceirizado = {
    id: null,
    terceirizado: [],
    unidade: [],
    funcao: [],
    mesLancamento: '',
    anoLancamento: '',
    turno: '',
    cargaHoraria: 0,
    dataInicioUnidade: null,
    dataDesligamentoUnidade: null,
    statusLancamento: true
}

const adicionarFaltasHorasSubs = {
    id: null,
    lancamentoTerceirizadoHoras: [],
    datas: [],
    tipoOcorrencia: '',
    horas: 0,
    motivoHoraAcrescentar: '',
    terceirizadoSubstituido: [],
    servidorSubstituido: [],
    funcaoSubstituido: [],
    mesLancamento: '',
    anoLancamento: '',
    unidade: [],
    terceirizadoSubstituto: []
}

const serviceLancamentoTerceirizadosHora = new LancamentoTerceirizadoPorHoraService()
const serviceFaltasHorasSubTerceirizadosHora = new faltasHorasSubsTerceirizadoHorasService()
const serviceTerceirizados = new TerceirizadoService()
const serviceAno = new anoService()
const serviceStatusLancamento = new ParametrosStatusLancamentoService()
const serviceFuncoes = new FuncaoService()
const serviceServidoresDoMesAno = new LancamentoServidorService()
const serviceAssinaturaDigital = new assinaturaQuadrosService()

export default function ListarLancamentoTerceirizadoHoras(){

    const dataCriptografada = LocalStorageService.obterItem('1')
    const unidadeCriptografado = LocalStorageService.obterItem('3')
    let idUnidade = descriptografar(unidadeCriptografado)
    let dataAtual = new Date(descriptografar(dataCriptografada))
    
    const m = "MENSALISTA"
    const d = "DIARISTA"
    const [expandirCamposMensalista, setExpandirCamposMensalista] = useState(false)
    const [ano, setAno] = useState(dataAtual.getUTCFullYear())
    const [mes, setMes] = useState(dataAtual.getUTCMonth()+1)
    const [countLancamentosMesSeguinte, setCountLancamentosMesSeguinte] = useState()
    const [statusLancamento, setStatusLancamento] = useState()

    const [lancamentos, setLancamentos] = useState([dadosLancamento])
    const [terceirizados, setTerceirizados] = useState([])
    const [editarVinculoTerceirizado, setEditarVinculoTerceirizado] = useState(dadosLancamento)
    
    const [listaFuncoes, setListaFuncoes] = useState([])
    const [idFuncao, setIdFuncao] = useState()
    const [listarServidoresDaUnidade, setListarServidoresDaUnidade] = useState([])
    const [idServidor, setIdServidor] = useState();
    const [listarTerceirizadosDaUnidade, setListarTerceirizadosDaUnidade] = useState([])
    const [idTerceirizado, setIdTerceirizado] = useState()
    const [idEmpresa, setIdEmpresa] = useState();
    const [idRegimePagamento, setIdRegimePagamento] = useState();
    const [motivoDiariaAvulsa, setMotivoDiariaAvulsa] = useState(null)

    // Inicio Modais
    const [showVincular, setShowVincular] = useState(false)
    const handleShowVincular = () => setShowVincular(true)
    const closeShowVincular = () => setShowVincular(false)

    const [showLancamentos, setShowLancamentos] = useState(false)
    const handleShowLancamentos = () => setShowLancamentos(true)
    const closeShowLancamentos = () => setShowLancamentos(false)

    const [showEditarVinculo, setShowEditarVinculo] = useState(false)
    const handleShowEditarVinculo = () => setShowEditarVinculo(true)
    const closeShowEditarVinculo = () => setShowEditarVinculo(false)

    const [showExcluirVinculo, setShowExcluirVinculo] = useState(false)
    const handleShowExcluirVinculo = () => setShowExcluirVinculo(true)
    const closeShowExcluirVinculo = () => setShowExcluirVinculo(false)

    const [showExcluirData, setShowExcluirData] = useState(false)
    const handleShowExcluirData = () => setShowExcluirData(true)
    const closeShowExcluirData = () => setShowExcluirData(false)

    const [showEditarFaltasHsSub, setShowEditarFaltasHsSub] = useState(false)
    const handleShowEditarFaltasHsSub = () => setShowEditarFaltasHsSub(true)
    const closeShowEditarFaltasHsSub = () => setShowEditarFaltasHsSub(false)

    const [showConfirmarReplicacao, setShowConfirmarReplicacao] = useState(false)
    const handleShowConfirmarReplicacao = () => setShowConfirmarReplicacao(true)
    const closeShowConfirmarReplicacao = () => setShowConfirmarReplicacao(false)
    //Fim Modais

    const [idTerceirizadoExcluir, setIdTerceirizadoExcluir] = useState(null)
    const [nomeTerceirizadoExcluir, setNomeTerceirizadoExcluir] = useState('')
    const [tipoTerceirizadoExcluir, setTipoTerceirizadoExcluir] = useState('')
    const [dataIndividualExcluir, setDataIndividualExcluir] = useState()
    const [idDataExcluir, setIdDataExcluir] = useState(0)
    const [textoDataModalQueSeraExcluida, setTextoDataModalQueSeraExcluida] = useState(null)


    const [datas, setDatas] = useState([])
    const [horas, setHoras] = useState()
    const [tipoDasDatas, setTipoDasDatas] = useState()
    const [motivoHorasAcrescentar, setMotivoHorasAcrescentar] = useState('')

    const [listarHsFaltasSubstituicaoPorLancamento, setListarHsFaltasSubstituicaoPorLancamento] = useState([])
    const [lancamentoHsFaltasSubstituicaoEmEdicao, setLancamentoHsFaltasSubstituicaoEmEdicao] = useState(null)
    const [editarFaltaHorasSubIndividual, setEditarFaltaHorasSubIndividual] = useState(null)
    const [editarHorasIndividual, setEditarHorasIndividual] = useState(0)
    const [editarMotivoHorasAcrescentar, setEditarMotivoHorasAcrescentar] = useState('')
    const [atualizarHsFaltasSubEmEdicao, setAtualizarHsFaltasSubEmEdicao] = useState(true)

    const [salvarHorasFaltaSubTerceirizadosHora, setsalvarHorasFaltaSubTerceirizadosHora] = useState(adicionarFaltasHorasSubs)

    const [substituiuServidor, setSubstituiuServidor] = useState(false)
    const [diariaAvulsa, setDiariaAvulsa] = useState(false)

    let horasFaltasTerceirizados = 0;
    let horasAcrescentadasTerceirizados = 0;
    let horasSubstituicaoTerceirizado = 0;

    const carregarListaComServidores =  () => {
        setSubstituiuServidor(!substituiuServidor)
        if (substituiuServidor) {
            setIdTerceirizado(null)
        }
        if (!substituiuServidor) {
            setIdServidor(null)
        }
    }

    const alterarEstadoDiariaAvulsa =  () => {
        setDiariaAvulsa(!diariaAvulsa)
        if (diariaAvulsa) {
            setSubstituiuServidor(false)
            setIdTerceirizado(null)
            setIdServidor(null)
        }
    }

    //Paginação de Terceirizados
    const [paginaAtual, setPaginaAtual] = useState(0)
    const [nomeBuscar, setNomeBuscar] = useState("")
    const [numeroDePaginas, setNumeroDePaginas] = useState(0)
    const pesquisou = () => setPaginaAtual(0)

    function handlePageClick(data) {
        setPaginaAtual(data.selected)
    }

    function mascaraCpf(c){
        return c.match(/.{1,3}/g).join(".").replace(/\.(?=[^.]*$)/,"-")
    }

    function cancelarVincular(){
        setNomeBuscar('')
        closeShowVincular()
    }


    useEffect(() => {
        async function carregarLancamentos(){
            if(idUnidade){
                serviceLancamentoTerceirizadosHora.listarPorUnidadeAnoMes(idUnidade, ano, mes)
                .then((s) => {
                    setLancamentos(s.data)
                })
            }
        }
        carregarLancamentos()
        async function loadTerceirizadosBusca() {
            const response = await serviceTerceirizados.listarTodos(`/terceirizadopornome/${nomeBuscar}/page/${paginaAtual}`);
            setTerceirizados(response.data.content);
            setNumeroDePaginas(response.data.totalPages);
            const arrayPaginas = [];
            //cria um array de paginas na quantidade vinda do backend
            for (let p = 1; p <= numeroDePaginas; p++) {
              arrayPaginas.push(p);
            }
        }

        async function loadTerceirizados() {
            const response = await serviceTerceirizados.listarTodos(`/pag/${paginaAtual}`);
            setTerceirizados(response.data.content);
            setNumeroDePaginas(response.data.totalPages);

            const arrayPaginas = [];
            //cria um array de paginas na quantidade vinda do backend
            for (let p = 1; p <= numeroDePaginas; p++) {
              arrayPaginas.push(p);
            }
        }
        if (nomeBuscar === "") {
            loadTerceirizados();
          } else {
            loadTerceirizadosBusca();
          }
    },[ano, mes, nomeBuscar, showEditarVinculo, showVincular, showExcluirVinculo])

    useEffect(() => {
        async function ContarLancamentosMesSeguinte(){
            if (idUnidade) {
                let mesSeguinte = Number(mes)+1 > 12 ? Number(1).toString() : (Number(mes)+1).toString()
                let anoContagem = Number(mesSeguinte) === 1 ? (Number(ano)+1).toString() : ano
                const contagem = await serviceLancamentoTerceirizadosHora.contarLancamentosMesSeguinte(idUnidade, anoContagem, mesSeguinte)
                setCountLancamentosMesSeguinte(contagem.data)
            }
        }
        ContarLancamentosMesSeguinte()
    },[mes, ano, showConfirmarReplicacao])

    useEffect(() => {
        async function VerificarStatusLancamento(){
            if (idUnidade) {
                serviceStatusLancamento.buscarPorUnidadeAnoMes(idUnidade, ano, mes)
                .then((s) => {
                    setStatusLancamento(s.data.statusLancamentoTerceirizadoHora)
                })
            }
        }
        VerificarStatusLancamento()
    },[ano, mes])

    useEffect(() => {
        async function CarregarFuncoes(){
            serviceFuncoes.listar()
            .then((s) => {
                setListaFuncoes(s.data)
            })
        }
        CarregarFuncoes()
    },[])

    useEffect(() => {
        async function CarregarTerceirizadosDaUnidadePorMesAno(){
            serviceLancamentoTerceirizadosHora.listarPorUnidadeAnoMes(idUnidade, ano, mes)
            .then((s) => {
                setListarTerceirizadosDaUnidade(s.data)
            })
        }
        CarregarTerceirizadosDaUnidadePorMesAno()

        async function CarregarServidoresDaUnidadePorMesAno(){
            serviceServidoresDoMesAno.listarPorUnidAnoMes(idUnidade, ano, mes)
            .then((s) => {
                setListarServidoresDaUnidade(s.data)
            })
        }
        CarregarServidoresDaUnidadePorMesAno()
    },[ano, mes, showEditarVinculo, showVincular, showExcluirVinculo])

    useEffect(() => {
        if (lancamentoHsFaltasSubstituicaoEmEdicao !== null) {
            function carregarHsSubFaltasSubstituicoes(){
                serviceFaltasHorasSubTerceirizadosHora.listarHorasSubFaltasPorLancamento(lancamentoHsFaltasSubstituicaoEmEdicao)
                .then((s) => {
                    setListarHsFaltasSubstituicaoPorLancamento(s.data)
                })
            }
            carregarHsSubFaltasSubstituicoes()
        }
    }, [lancamentoHsFaltasSubstituicaoEmEdicao, atualizarHsFaltasSubEmEdicao])


    //modal de edição horas, faltas e substituições individuais
    function editarHorasFaltasSubstituicoes(idEditar){
        serviceFaltasHorasSubTerceirizadosHora.editarHoraFaltaSub(idEditar)
        .then((s) => {
            setDiariaAvulsa(false)
            setEditarFaltaHorasSubIndividual(s.data)
            setEditarHorasIndividual(s.data.horas)
            setEditarMotivoHorasAcrescentar(s.data.motivoHoraAcrescentar)
            handleShowEditarFaltasHsSub()
        })
        .catch(() => {
            mensagemErro('Edição dos dados não é possível. Recarregue a página e tente novamente.')
        })
    }

    function salvarHorasEditadaIndividual(){
        editarFaltaHorasSubIndividual.horas = Number(editarHorasIndividual)
        editarFaltaHorasSubIndividual.motivoHoraAcrescentar = editarMotivoHorasAcrescentar === '' ? null : editarMotivoHorasAcrescentar
        serviceFaltasHorasSubTerceirizadosHora.atualizarHoras(editarFaltaHorasSubIndividual)
        .then((s) => {
            mensagemSucesso(s.data)
            setEditarHorasIndividual(0)
            setEditarMotivoHorasAcrescentar('')
            atualizarListaDeDatasQueEstaoSendoEditada()
            closeShowEditarFaltasHsSub()
        })
        .catch((e) => {
            mensagemErro(e.response.data)
        })
    }

    function salvarLancamentosFaltasHorasAcrescentarSubstituicao(){

        if (datas.length <= 0 || datas.length === null) {
            mensagemAlerta('A(s) data(s) deve(m) ser(em) informada(s)')
            return false
        }
        salvarHorasFaltaSubTerceirizadosHora.datas = datas.toString().split(",")
        if (!horas) {
            mensagemAlerta('A quantidade de horas deve ser informada')
            return false
        }
        salvarHorasFaltaSubTerceirizadosHora.horas = Number(horas)
        if (tipoDasDatas === 'substituicao') {
            
            if (!idFuncao) {
                mensagemAlerta('A função do substituido deve ser informada')
                return false
            }
            salvarHorasFaltaSubTerceirizadosHora.funcaoSubstituido = {id: Number(idFuncao)}
            if (!idServidor && !idTerceirizado) {
                mensagemAlerta('O substituido deve ser informado')
                return false
            }
            salvarHorasFaltaSubTerceirizadosHora.servidorSubstituido = substituiuServidor ? {id: Number(idServidor)} : null
            salvarHorasFaltaSubTerceirizadosHora.terceirizadoSubstituido = !substituiuServidor ? {id: Number(idTerceirizado)} : null
            salvarHorasFaltaSubTerceirizadosHora.terceirizadoSubstituto = editarVinculoTerceirizado.terceirizado ? {id: Number(editarVinculoTerceirizado.terceirizado.id)} : null
            salvarHorasFaltaSubTerceirizadosHora.motivoHoraAcrescentar = null
        }
        if (tipoDasDatas === 'diaria') {
            if (!idFuncao) {
                mensagemAlerta('A função da diária realizada deve ser informada.')
                return false
            }
            if (motivoDiariaAvulsa === '' || motivoDiariaAvulsa === null) {
                mensagemAlerta('Descrição da diária deve ser informado.')
                return false
            }
            salvarHorasFaltaSubTerceirizadosHora.motivoHoraAcrescentar = motivoDiariaAvulsa
            salvarHorasFaltaSubTerceirizadosHora.funcaoSubstituido = {id: Number(idFuncao)}
            salvarHorasFaltaSubTerceirizadosHora.servidorSubstituido = null
            salvarHorasFaltaSubTerceirizadosHora.terceirizadoSubstituido = null
            salvarHorasFaltaSubTerceirizadosHora.terceirizadoSubstituto = null
        }
        if (tipoDasDatas === 'acrescentar') {
            if (motivoHorasAcrescentar === '' || motivoHorasAcrescentar === null) {
                mensagemAlerta('Descrição do motivo das horas a acrescentar deve ser informado.')
                return false
            }
            salvarHorasFaltaSubTerceirizadosHora.motivoHoraAcrescentar = motivoHorasAcrescentar
            salvarHorasFaltaSubTerceirizadosHora.funcaoSubstituido = null
            salvarHorasFaltaSubTerceirizadosHora.servidorSubstituido = null
            salvarHorasFaltaSubTerceirizadosHora.terceirizadoSubstituido = null
            salvarHorasFaltaSubTerceirizadosHora.terceirizadoSubstituto = null
        }
        if (tipoDasDatas === 'falta') {
            salvarHorasFaltaSubTerceirizadosHora.motivoHoraAcrescentar = null
            salvarHorasFaltaSubTerceirizadosHora.funcaoSubstituido = null
            salvarHorasFaltaSubTerceirizadosHora.servidorSubstituido = null
            salvarHorasFaltaSubTerceirizadosHora.terceirizadoSubstituido = null
            salvarHorasFaltaSubTerceirizadosHora.terceirizadoSubstituto = null
        }
        if (!editarVinculoTerceirizado.terceirizado.id) {
            mensagemAlerta('Lancamento sem informação. Recarregue a página e tente novamente e caso persistir o erro contate o administrador.')
            return false
        }
        salvarHorasFaltaSubTerceirizadosHora.lancamentoTerceirizadoHoras = editarVinculoTerceirizado ? {id: Number(editarVinculoTerceirizado.id)} : null
        if (!editarVinculoTerceirizado.id) {
            mensagemAlerta('Lancamento sem informação. Recarregue a página e tente novamente e caso persistir o erro contate o administrador.')
            return false
        }
        salvarHorasFaltaSubTerceirizadosHora.unidade = {id: Number(idUnidade)}
        salvarHorasFaltaSubTerceirizadosHora.tipoOcorrencia = tipoDasDatas
        salvarHorasFaltaSubTerceirizadosHora.mesLancamento = mes.toString()
        salvarHorasFaltaSubTerceirizadosHora.anoLancamento = ano.toString()
        serviceFaltasHorasSubTerceirizadosHora.salvarFaltasHrSubstituicao(salvarHorasFaltaSubTerceirizadosHora)
        .then((s) => {
            mensagemSucesso('Dados salvo com sucesso.')
            atualizarListaDeDatasQueEstaoSendoEditada()
            limparDadosAposMudarAbaOuSalvar()
        })
        .catch((e) => {
            mensagemErro(e.response.data)
            atualizarListaDeDatasQueEstaoSendoEditada()
        })
    }

    function atualizarListaDeDatasQueEstaoSendoEditada(){
        if (atualizarHsFaltasSubEmEdicao) {
            setAtualizarHsFaltasSubEmEdicao(false)
        }
        if (!atualizarHsFaltasSubEmEdicao) {
            setAtualizarHsFaltasSubEmEdicao(true)
        }
    }

    function editarLancamentos(idEditar){
        serviceAssinaturaDigital.verificarSeAssinado(Number(idUnidade), ano, mes, 'TERCEIRIZADO')
        .then((s) => {
            if (s.data > 0) {
                mensagemErro("O Quadro de Terceirizados esta ASSINADO, para prosseguir com alterações nos Lançamentos de Terceirizados deve apagar a assinatura.")
            }
            if (s.data === 0) {
                setLancamentoHsFaltasSubstituicaoEmEdicao(idEditar)
                serviceLancamentoTerceirizadosHora.localizarPorId(idEditar)
                .then((s) => {
                    if (s.data.tipoTerceirizado === null) {
                        mensagemAlerta('Primeiro edite o vinculo e informe se o Tipo de Terceirizado é MENSALISTA ou DIARISTA.')
                        return false
                    }
        
                    setEditarVinculoTerceirizado(s.data)
                    serviceFaltasHorasSubTerceirizadosHora.listarHorasSubFaltasPorLancamento(idEditar)
                    .then((s) => {
                        setListarHsFaltasSubstituicaoPorLancamento(s.data)
                        handleShowLancamentos()
                    })
                })
                .catch(() => {
                    mensagemErro('Não foi possivel carregar os dados para edição.')
                })
            }
        })
        .catch((e) => {
            mensagemErro(e.response.data)
        })
    }


    function editarVinculo(idEditar){
        setIdRegimePagamento('');
        setIdEmpresa('')
        serviceLancamentoTerceirizadosHora.localizarPorId(idEditar)
        .then((s) => {
            setEditarVinculoTerceirizado(s.data)
            camposParaMensalista(s.data.tipoTerceirizado)
            if(s.data.empresa){
                setIdEmpresa(s.data.empresa);
            }
             if(s.data.regimePagamento){
                setIdRegimePagamento(s.data.regimePagamento);
            }

            handleShowEditarVinculo()
        })
        .catch(() => {
            mensagemErro('Não foi possivel carregar os dados para edição.')
        })
    }

    function vincularTerceirizado (idTerceirizado) {
        lancamentoTerceirizado.terceirizado = {id: Number(idTerceirizado)}
        lancamentoTerceirizado.unidade = {id: Number(idUnidade)}
        lancamentoTerceirizado.mesLancamento = mes
        lancamentoTerceirizado.anoLancamento = ano
        lancamentoTerceirizado.statusLancamento = true
        lancamentoTerceirizado.funcao = null
        serviceLancamentoTerceirizadosHora.vincularTerceirizadoPorHora(lancamentoTerceirizado)
            .then(() => {
                mensagemSucesso("Terceirizado vinculado com sucesso.")
                setTimeout(() => {
                    window.location.reload()
                },1000)  
                    closeShowVincular()
            })
            .catch((e) => {
                mensagemErro(e.response.data)
            }) 
    }

    const formatarData = (dataF) => {
        if(dataF){
            let data = new Date(dataF),
            dia = data.getUTCDate().toString().padStart(2, '0'),
            mes = (data.getUTCMonth()+1).toString().padStart(2, '0'),
            ano = data.getUTCFullYear()
            return dia+"/"+mes+"/"+ano
        }
    }

    function onChangeEditarLancamentoTerceirizado(e) {
        const { name, value } = e.target;
        setEditarVinculoTerceirizado({...editarVinculoTerceirizado, [name]: value })
    }

    function camposParaMensalista(tipo){
        if (tipo === m) {
            setExpandirCamposMensalista(true)
        }
        if (tipo !== m || tipo === null) {
            setExpandirCamposMensalista(false)
        }
    }

    function salvarDadosEdicaoVinculo(){
        editarVinculoTerceirizado.chDiaria = editarVinculoTerceirizado.chDiaria === '' ? null : Number(editarVinculoTerceirizado.chDiaria)
        editarVinculoTerceirizado.tipoTerceirizado = editarVinculoTerceirizado.tipoTerceirizado === '' ? null : editarVinculoTerceirizado.tipoTerceirizado
        editarVinculoTerceirizado.turno = editarVinculoTerceirizado.turno === '' ? null : editarVinculoTerceirizado.turno
        editarVinculoTerceirizado.diasDaSemana = editarVinculoTerceirizado.diasDaSemana === '' ? null : editarVinculoTerceirizado.diasDaSemana
        editarVinculoTerceirizado.funcao = idFuncao === null ? {id: null} : {id: Number(idFuncao)}
        editarVinculoTerceirizado.empresa = idEmpresa
        editarVinculoTerceirizado.regimePagamento = idRegimePagamento
        editarVinculoTerceirizado.dataDesligamentoUnidade = editarVinculoTerceirizado.dataDesligamentoUnidade === '' ? null : editarVinculoTerceirizado.dataDesligamentoUnidade

        if (editarVinculoTerceirizado.tipoTerceirizado === null || editarVinculoTerceirizado.tipoTerceirizado === d) {
            editarVinculoTerceirizado.chDiaria = null
            editarVinculoTerceirizado.tipoTerceirizado =  null 
            editarVinculoTerceirizado.diasDaSemana = null
            editarVinculoTerceirizado.tipoTerceirizado = d
            editarVinculoTerceirizado.funcao = {id: null}
            editarVinculoTerceirizado.dataInicioUnidade = null
            editarVinculoTerceirizado.dataDesligamentoUnidade = null
        }
       
        serviceLancamentoTerceirizadosHora.atualizarDadosDoVinculo(editarVinculoTerceirizado)
        .then(() => {
            mensagemSucesso('Dados do vínculo editado com sucesso.')
            closeShowEditarVinculo()
        })
        .catch((e) => {
            mensagemErro(e.response.data)
        })
    }

    function carregarParaExcluirVinculoTerceirizado(idExcluir, nomeTerceirizado, tipoTerceirizado){
        setIdTerceirizadoExcluir(idExcluir)
        setNomeTerceirizadoExcluir(nomeTerceirizado)
        setTipoTerceirizadoExcluir(tipoTerceirizado)
        handleShowExcluirVinculo()
    }

    function excluirVinculoTerceirizado(){
        serviceLancamentoTerceirizadosHora.deletarVinculoTerceirizado(idTerceirizadoExcluir)
        .then(() => {
            mensagemSucesso('Lancamento Terceirizado, excluído com sucesso.')
            closeShowExcluirVinculo()
        })
        .catch((e) => {
            mensagemErro(e.response.data)
            closeShowExcluirVinculo()
        })
    }

    function abrirModalConfirmacaoExcluirDataSelecionada(dataExclusao, idExcluir, tipoDataExcluir){
        setDataIndividualExcluir(dataExclusao)
        setIdDataExcluir(idExcluir)
        setTextoDataModalQueSeraExcluida(tipoDataExcluir)
        handleShowExcluirData()
    }

    function excluirDataDoLancamento(){
        serviceFaltasHorasSubTerceirizadosHora.apagarData(idDataExcluir)
        .then((response) => {
            mensagemSucesso(response.data)
            atualizarListaDeDatasQueEstaoSendoEditada()
            setDataIndividualExcluir()
            setIdDataExcluir(0)
            closeShowExcluirData()
        })
        .catch((response) => {
            mensagemErro('Ocorreu um erro. Recarregue a pagina e tente novamente. '+ response.data)
        })
    }

    function limparDadosAposMudarAbaOuSalvar(){
        setDatas([])
        setHoras(0)
        setMotivoHorasAcrescentar('')
        setIdFuncao(0)
        setIdServidor('')
        setIdTerceirizado(0)
        setSubstituiuServidor(false)
        setIdEmpresa('')
        setIdRegimePagamento('')
        setDiariaAvulsa(false)
        setMotivoDiariaAvulsa(null)
    }

    function fecharLimparDadosDeLancamentosHorasFaltasSub(){
        limparDadosAposMudarAbaOuSalvar()
        setLancamentoHsFaltasSubstituicaoEmEdicao(null)
        closeShowLancamentos()
    }

    function replicarLancamentos(mesReplicar){
        if (Number(mesReplicar) === 12) {
            serviceAno.anoExiste(Number(ano)+Number(1))
            .then((s) => {
                if (s.data) {
                    serviceLancamentoTerceirizadosHora.salvarReplicacao(idUnidade, ano, mesReplicar)
                    .then((response) => {
                        mensagemSucesso(response.data)
                        closeShowConfirmarReplicacao()
                    })
                    .catch((e) => {
                        mensagemErro(e.response.data)
                        closeShowConfirmarReplicacao()
                    })
                }
                if (!s.data) {
                    mensagemErro('O ano para replicação dos dados ainda não existe. Contate o administrador para criação do ano letivo seguinte.')
                }
            })
        } else {
            serviceLancamentoTerceirizadosHora.salvarReplicacao(idUnidade, ano, mesReplicar)
            .then((response) => {
                mensagemSucesso(response.data)
                closeShowConfirmarReplicacao()
            })
            .catch((e) => {
                mensagemErro(e.response.data)
                closeShowConfirmarReplicacao()
            })
        }
    }
        
    

    return(
        <>
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs lg="4">
                        <AnoMes
                            htmlForMes="mesLancamento"
                            idMes="mesLancamento"
                            nameMes="mesLancamento"
                            valueMes={mes}
                            onChangeMes={(e) => setMes(e.target.value)}
                            htmlForAno="anoLancamento"
                            idAno="anoLancamento"
                            nameAno="anoLancamento"
                            valueAno={ano}
                            onChangeAno={(e) => setAno(e.target.value)}
                        />
                    </Col>
                    <Col lg={1} style={{ marginTop: "37px"}}>
                        {statusLancamento &&     
                            <button className="btn btn-primary" type="button" onClick={handleShowVincular}> Vincular </button>
                        }
                    </Col>

                    <Col lg={2} style={{marginTop: "37px"}}>
                        {(countLancamentosMesSeguinte < 1 && lancamentos.length > 0) && 
                            <button type='button' className='btn btn-primary' onClick={handleShowConfirmarReplicacao}>
                                Replicar
                            </button>
                        }
                    </Col>
                </Row>
            </Container>

                
            <Container className="mt-4">
                <Card title="Lista de Lançamentos de Terceirizados">
                    <Table id="tabela_lancamentos" striped bordered hover size="md">
                        <thead>
                            {lancamentos.length > 0 ?
                                <>
                                    <tr>
                                        <th>##</th>
                                        <th>Nome do Terceirizado</th>
                                        <th>Início na Unidade</th>
                                        <th>Função</th>
                                        <th>Turno</th>
                                        <th>Tipo de Terceirizado</th>
                                        <th>C.H. Diária</th>
                                    </tr>
                                </>
                                :
                                <></>
                            }
                        </thead> 
                        <tbody>
                            {lancamentos.length > 0 ? 
                                <>
                                    {lancamentos.sort((a, b) => a.terceirizado.nome > b.terceirizado.nome ? 1:-1).map((l, index) => {
                                        return(
                                            <tr key={l.id}>
                                                <td>{index + 1}</td>
                                                <td>{l.terceirizado.nome}</td>
                                                <td>{formatarData(l.dataInicioUnidade)}</td>
                                                <td>{l.funcao ? l.funcao.nome : ''}</td>
                                                <td>{l.turno}</td>
                                                <td>{l.tipoTerceirizado}</td>
                                                <td style={{textAlign: "center"}}>{l.chDiaria}</td>
                                                <td className="col-md-2" style={{textAlign: "center"}}>
                                                    {l.statusLancamento.toString() === 'false' ?
                                                        <button className="btn btn-success" disabled onClick={() => editarLancamentos(l.id)}>
                                                            <i className='pi pi-plus-circle'></i> 
                                                        </button>
                                                        :
                                                        <button className="btn btn-success" title='Fazer lançamento' onClick={() => editarLancamentos(l.id)}>
                                                            <i className='pi pi-plus-circle'></i> 
                                                        </button>
                                                    }

                                                    {l.statusLancamento.toString() === 'false' ?
                                                        <button className="btn btn-primary" style={{margin: "0 5px"}} disabled onClick={() => editarVinculo(l.id, setIdFuncao(!l.funcao ? null : l.funcao.id))}>
                                                            <i className='pi pi-pencil'></i> 
                                                        </button>
                                                        :
                                                        <button className="btn btn-primary" title='Editar' style={{margin: "0 5px"}} onClick={() => editarVinculo(l.id, setIdFuncao(!l.funcao ? null : l.funcao.id))}>
                                                            <i className='pi pi-pencil'></i> 
                                                        </button>
                                                    }

                                                    {l.statusLancamento.toString() === 'false' ?
                                                        <button className="btn btn-danger" disabled onClick={() => carregarParaExcluirVinculoTerceirizado(l.id, l.terceirizado.nome, l.tipoTerceirizado)}>
                                                            <i className='pi pi-trash'></i> 
                                                        </button>
                                                        :
                                                        <button className="btn btn-danger" title='Excluir' onClick={() => carregarParaExcluirVinculoTerceirizado(l.id, l.terceirizado.nome, l.tipoTerceirizado)}>
                                                            <i className='pi pi-trash'></i> 
                                                        </button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </>
                                : 
                                <>
                                    <tr>
                                        <td style={{fontSize: '1.5rem'}}>Nenhum registro encontrado.</td>
                                    </tr>
                                </>
                            } 
                        </tbody>
                    </Table>
                </Card>
                {/* Início modal para Vincular os Terceirizados */}
                <Modal show={showVincular} onHide={closeShowVincular} backdrop="static" keyboard={false} size="xl">
                    <Modal.Header>
                        <h3>Terceirizados</h3>
                    </Modal.Header>
                    <Modal.Body>
                    <Card title="Listagem de Terceirizados"> 
                            <br></br>
                            <div >
                                <Row>
                                    <Col md={6}>
                                        <input
                                            id="nomeBuscar"
                                            name="nomeBuscar"
                                            type="text"
                                            className="form-control"
                                            placeholder="Digite o nome para pesquisar..."
                                            value={nomeBuscar}
                                            onChange={(buscar) => setNomeBuscar(buscar.target.value)}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <br></br>
                            <div>
                                <table id="tabela_cooperados" className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th> Nome </th>
                                            <th>CPF</th>
                                            <th style={{textAlign: "center", width: "40px"}}>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {terceirizados.map((t) => {
                                            const cpf = mascaraCpf(t.cpf)
                                            return (
                                                <tr key={t.id}>
                                                    <td>{t.nome}</td>
                                                    <td>{cpf}</td>
                                                    <td style={{textAlign: "center"}}>
                                                        <button 
                                                            className="btn btn-primary" 
                                                            type="button" 
                                                            onClick={() => vincularTerceirizado(t.id)}> Vincular </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                {terceirizados.length > 0 &&
                                    <ReactPaginate
                                        previousLabel={"Voltar"}
                                        nextLabel={"Avançar"}
                                        breakLabel={"..."}
                                        pageCount={numeroDePaginas}
                                        marginPagesDisplayed={3}
                                        pageRangeDisplayed={2}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination justify-content-center"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                        initialPage={pesquisou}
                                    />
                                }
                            </div>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-danger" onClick={cancelarVincular}>
                            Cancelar
                        </button>
                    </Modal.Footer>
                </Modal> 
                {/* Final modal para Vincular os Terceirizados */}
                {/* Início modal para Editar dados do vínculo dos Terceirizados */}
                <Modal show={showEditarVinculo} onHide={closeShowEditarVinculo} backdrop="static" keyboard={false} size="xl">
                    <Modal.Body>
                        <Container>
                            <Card title="Edições de Lançamentos">
                                <Form>
                                    <Row>
                                        <Col md={5}>
                                            <label className="col-form-label" htmlFor="terceirizado">Terceirizado:</label>
                                            <input type="text" 
                                                className="form-control" 
                                                id="terceirizado" 
                                                name="terceirizado"
                                                disabled
                                                value={editarVinculoTerceirizado ? editarVinculoTerceirizado.terceirizado.nome : null} 
                                                
                                            />
                                        </Col>
                                        <Col md={5}>
                                            <label className="col-form-label" htmlFor="unidade">Unidade:</label>
                                            <input type="text" 
                                                className="form-control" 
                                                id="unidade" 
                                                name="unidade"
                                                disabled
                                                value={editarVinculoTerceirizado ? editarVinculoTerceirizado.unidade.nome : null}
                                            />
                                        </Col>
                                        <Col md={2}>
                                            <label className="col-form-label" htmlFor="mesLancamento">Mês/Ano:</label>
                                            <input type="text" 
                                                className="form-control" 
                                                id="mesLancamento" 
                                                name="mesLancamento"
                                                disabled
                                                value={editarVinculoTerceirizado ? `${editarVinculoTerceirizado.mesLancamento}/${editarVinculoTerceirizado.anoLancamento}` : null} 
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <label className="col-form-label" htmlFor="tipoTerceirizado">Tipo de Terceirizado:</label>
                                            <Form.Select 
                                                className="form-control" 
                                                id="tipoTerceirizado" 
                                                name="tipoTerceirizado"
                                                value={editarVinculoTerceirizado.tipoTerceirizado}
                                                onChange={onChangeEditarLancamentoTerceirizado}
                                                onClick={() => camposParaMensalista(editarVinculoTerceirizado.tipoTerceirizado)}
                                            >
                                                <option value="">Selecione</option>
                                                <option value="DIARISTA">Diarista</option>
                                                <option value="MENSALISTA">Mensalista</option>
                                            </Form.Select>
                                        </Col>
                                        <Col md={2}>
                                            <label className="col-form-label" htmlFor="turno">Turno:</label>
                                            <Form.Select 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Turno" 
                                                id="turno" 
                                                name="turno"
                                                value={editarVinculoTerceirizado.turno}
                                                onChange={onChangeEditarLancamentoTerceirizado}
                                            >
                                                <option value=''>Selecione</option>
                                                <option value="Matutino">Matutino</option>
                                                <option value="Vespertino">Vespertino</option>
                                                <option value="Noturno">Noturno</option>
                                                <option value="Integral">Integral</option>
                                            </Form.Select>
                                        </Col>
                                        <Col md={3}>
                                            <label className="col-form-label" htmlFor="regimePagamento">Regime de Pagamento:</label>
                                            <Form.Select 
                                                className="form-control" 
                                                id="regimePagamento" 
                                                name="regimePagamento"
                                                value={idRegimePagamento}
                                                onChange={(e) => setIdRegimePagamento(e.target.value)}
                                            >
                                                <option value="">Selecione</option>
                                                <option value="DIARIA">Diária</option>
                                                <option value="MENSAL"> Mensal</option>
                                            </Form.Select>
                                        </Col>
                                        <Col md={2}>
                                            <label className="col-form-label" htmlFor="empresa">Empresa:</label>
                                            <Form.Select 
                                                className="form-control" 
                                                id="empresa" 
                                                name="empresa"
                                                value={idEmpresa}
                                                onChange={(e) => setIdEmpresa(e.target.value)}
                                            >
                                                <option value="">Selecione</option>
                                                <option value="BEM-ESTAR">Bem estar</option>
                                            </Form.Select>
                                        </Col>
                                        {expandirCamposMensalista && 
                                            <Col md={3}>
                                                <label className="col-form-label" htmlFor="funcao">Função:</label>
                                                <Form.Select
                                                    id="funcao" 
                                                    name="funcao" 
                                                    value={idFuncao} 
                                                    onChange={(e) => setIdFuncao(e.target.value)}
                                                    >
                                                    <option value={null}>Selecione</option>
                                                    {listaFuncoes.sort((a, b) => a.nome > b.nome ? 1:-1).map((f) => (
                                                        <option value={f.id} key={f.id} > {f.nome} </option>
                                                        ))}
                                                </Form.Select>
                                            </Col>
                                        }
                                    </Row>
                                    {expandirCamposMensalista && 
                                    <Row>
                                        <Col md={2}>
                                            <label className="col-form-label" htmlFor="chDiaria">C.H. Diária:</label>
                                            <input type="number" 
                                                className="form-control" 
                                                min={1} max={12}
                                                id="chDiaria"
                                                name="chDiaria"
                                                value={editarVinculoTerceirizado.chDiaria}
                                                onChange={onChangeEditarLancamentoTerceirizado}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <label className="col-form-label" htmlFor="diasDaSemana">Dias da Semana:</label>
                                            <Form.Select 
                                                type="text" 
                                                className="form-control" 
                                                id="diasDaSemana" 
                                                name="diasDaSemana"
                                                value={editarVinculoTerceirizado.diasDaSemana}
                                                onChange={onChangeEditarLancamentoTerceirizado}
                                            >
                                                <option value=''>Selecione</option>
                                                <option value="SS">Segunda a Sexta</option>
                                                <option value="SD">Sábado e Domingo</option>
                                            </Form.Select>
                                        </Col>
                                        <Col md={3}>
                                            <label className="col-form-label" htmlFor="dataInicioUnidade">Data de Início:</label>
                                            <input type="date" 
                                                className="form-control" 
                                                id="dataInicioUnidade"
                                                name="dataInicioUnidade"
                                                value={editarVinculoTerceirizado.dataInicioUnidade}
                                                onChange={onChangeEditarLancamentoTerceirizado}
                                            />   
                                        </Col>
                                        <Col md={4}>
                                            <label className="col-form-label" htmlFor="dataDesligamentoUnidade">Data de Desligamento:</label>
                                            <input type="date" 
                                                className="form-control" 
                                                id="dataDesligamentoUnidade"
                                                name="dataDesligamentoUnidade"
                                                value={editarVinculoTerceirizado.dataDesligamentoUnidade}
                                                onChange={onChangeEditarLancamentoTerceirizado}
                                            />
                                        </Col>
                                    </Row>
                                    }
                                    <br/>
                                    <div className="text-center">
                                        <button 
                                            type="button" 
                                            onClick={salvarDadosEdicaoVinculo} 
                                            className="btn btn-primary"> Salvar 
                                        </button> 
                                    </div>
                                </Form>
                            </Card>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-danger" onClick={closeShowEditarVinculo} >
                            Fechar
                        </button>
                    </Modal.Footer>
                </Modal>
                {/* Fim modal para Editar dados do vínculo dos Terceirizados */}
                {/* Início modal para Editar dados do Substituições, Faltas e Horas Acrescentar dos Terceirizados */}
                <Modal show={showLancamentos} onHide={closeShowLancamentos} backdrop="static" keyboard={false} size="xl">
                    <Modal.Body>
                    <Card title="Lançamentos Terceirizados">
                        <Row>
                            <Col md={5}>
                                <label className="col-form-label" htmlFor="terceirizado">Terceirizado:</label>
                                <input type="text" 
                                    className="form-control" 
                                    id="terceirizado" 
                                    name="terceirizado"
                                    disabled
                                    value={editarVinculoTerceirizado ? editarVinculoTerceirizado.terceirizado.nome : null} 
                                    
                                />
                            </Col>
                            <Col md={5}>
                                <label className="col-form-label" htmlFor="unidade">Unidade:</label>
                                <input type="text" 
                                    className="form-control" 
                                    id="unidade" 
                                    name="unidade"
                                    disabled
                                    value={editarVinculoTerceirizado ? editarVinculoTerceirizado.unidade.nome : null}
                                />
                            </Col>
                            <Col md={2}>
                                <label className="col-form-label" htmlFor="mesLancamento">Mês/Ano:</label>
                                <input type="text" 
                                    className="form-control" 
                                    id="mesLancamento" 
                                    name="mesLancamento"
                                    disabled
                                    value={editarVinculoTerceirizado ? `${editarVinculoTerceirizado.mesLancamento}/${editarVinculoTerceirizado.anoLancamento}` : null} 
                                />
                            </Col>
                        </Row>
                        <br/>
                        <Tabs defaultActiveKey={editarVinculoTerceirizado.tipoTerceirizado === m ? "faltas" : "datasubstituicoes"} className="justify-content-center" >
                            {/* Início Tab dados Faltas dos Terceirizados */}
                            {(editarVinculoTerceirizado.tipoTerceirizado === m) && 
                                <Tab eventKey="faltas" onClick={() => setTipoDasDatas('falta')}  onExited={limparDadosAposMudarAbaOuSalvar} title="Lançamentos de Faltas">
                                    <Row>
                                        <label className="col-form-label mt-4" htmlFor="dataHorasAcrescentar"> Datas das Faltas: </label><br/>
                                            <DatePicker
                                                mapDays={({ date }) => {
                                                    let props = {}
                                                    let isWeekend = [0, 6].includes(date.weekDay.index)
                                                    
                                                    if (isWeekend) props.className = "highlight highlight-red"
                                                    
                                                    return props
                                                }}
                                                onChange={setDatas}
                                                value={datas}
                                                id="dataHorasAcrescentar"
                                                name="dataHorasAcrescentar"
                                                numberOfMonths={2}
                                                multiple
                                                sort
                                                format="DD/MM/YYYY"
                                                locale={calendarioPersonalizado}
                                                plugins={[<DatePanel />]}
                                                style={{
                                                    width: "100%",
                                                    height: "38px",
                                                    padding: "12px 20px",
                                                    boxSizing: "border-box",
                                                }}
                                            />
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <label className="col-form-label mt-2" htmlFor="horas">Horas Faltadas:</label>
                                            <input type="number" 
                                                className="form-control"
                                                min={1} max={12} 
                                                id="horas"
                                                name="horas"
                                                value={horas}
                                                onChange={(e) => setHoras(e.target.value)} />
                                        </Col>
                                    </Row>
                                    <br/>
                                    <div className="text-center mt-4">
                                        <button className="btn btn-primary" onClick={salvarLancamentosFaltasHorasAcrescentarSubstituicao} > Salvar </button>
                                    </div>
                                    <br/>
                                    <Card title="Listagem de Faltas">
                                        <Table id="tabela_lancamentos_faltas" striped bordered hover size="md">
                                            <thead>
                                                <tr>
                                                    <th>Data da Falta</th>
                                                    <th>Horas Falta</th>
                                                    <th>Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listarHsFaltasSubstituicaoPorLancamento.sort((a, b) => a.dataEvento > b.dataEvento ? 1:-1).map((f) => {
                                                    if (f.tipoOcorrencia === 'falta') {
                                                        return(
                                                            <tr key={f.id}>
                                                                <td>{formatarData(f.dataEvento)}</td>
                                                                <td>{f.horas}</td>
                                                                <td className='col-md-2' style={{textAlign: "center"}}>
                                                                    <button className="btn btn-primary" title='Editar' type="button" style={{margin: "0 5px"}} onClick={() => editarHorasFaltasSubstituicoes(f.id, f.horas)} >
                                                                        <i className='pi pi-pencil'></i>
                                                                    </button>

                                                                    <button onClick={() => abrirModalConfirmacaoExcluirDataSelecionada(f.dataEvento, f.id)} title='Excluir' className="btn btn-danger">
                                                                        <i className='pi pi-trash'></i>
                                                                    </button>
                                                                </td>
                                                                <td style={{display: "none"}}>{horasFaltasTerceirizados += f.horas}</td>
                                                            </tr>
                                                        )
                                                    }                                                                                                   
                                                })}
                                                <span style={{paddingTop: '30px'}}><strong>Total de horas:</strong> {horasFaltasTerceirizados}</span>
                                            </tbody>
                                        </Table>
                                    </Card>
                                </Tab>
                            }
                            {/* Fim Tab dados Faltas dos Terceirizados */}
                            {/* Início Tab dados Horas Acresenctar dos Terceirizados */}
                            {(editarVinculoTerceirizado.tipoTerceirizado === m) &&
                                <Tab eventKey="dataHorasAcrescentar" onClick={(s) => setTipoDasDatas('acrescentar')} onExited={limparDadosAposMudarAbaOuSalvar} title="Horas Acrescentar">
                                    <Row>
                                        <label className="col-form-label mt-4" htmlFor="dataHorasAcrescentar"> Datas dos Acréscimos de Horas:</label><br/>
                                            <DatePicker
                                                mapDays={({ date }) => {
                                                    let props = {}
                                                    let isWeekend = [0, 6].includes(date.weekDay.index)
                                                    
                                                    if (isWeekend) props.className = "highlight highlight-red"
                                                    
                                                    return props
                                                }}
                                                onChange={setDatas}
                                                value={datas}
                                                id="dataHorasAcrescentar"
                                                name="dataHorasAcrescentar"
                                                numberOfMonths={2}
                                                multiple
                                                sort
                                                format="DD/MM/YYYY"
                                                locale={calendarioPersonalizado}
                                                plugins={[<DatePanel />]}
                                                style={{
                                                    width: "100%",
                                                    height: "38px",
                                                    padding: "12px 20px",
                                                    boxSizing: "border-box",
                                                }}
                                            />
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <label className="col-form-label mt-2" htmlFor="horas">Horas Acrescentadas:</label>
                                            <input type="number" 
                                                className="form-control"
                                                min={1} max={12} 
                                                id="horas"
                                                name="horas"
                                                value={horas}
                                                onChange={(e) => setHoras(e.target.value)} />
                                        </Col>
                                        <Col md={10}>
                                            <label className="col-form-label mt-2" htmlFor="descricaoMotivo">Motivo do acréscimo de horas:</label>
                                            <input type="text" 
                                                className="form-control"
                                                id="descricaoMotivo"
                                                name="descricaoMotivo"
                                                value={motivoHorasAcrescentar}
                                                onChange={(e) => setMotivoHorasAcrescentar(e.target.value)} />
                                        </Col>
                                    </Row>
                                    <br/>
                                    <div className="text-center mt-4">
                                        <button className="btn btn-primary" onClick={salvarLancamentosFaltasHorasAcrescentarSubstituicao} > Salvar </button>
                                    </div>
                                    <br/>
                                    <Card title="Listagem de Horas Acrescentadas">
                                        <Table id="tabela_lancamentos_faltas" striped bordered hover size="md">
                                            <thead>
                                                <tr>
                                                    <th>Data</th>
                                                    <th>Motivo</th>
                                                    <th>Horas Acrescentar</th>
                                                    <th>Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listarHsFaltasSubstituicaoPorLancamento.sort((a, b) => a.dataEvento > b.dataEvento ? 1:-1).map((f) => {
                                                        if (f.tipoOcorrencia === 'acrescentar') {
                                                            return(
                                                                <tr key={f.id}>
                                                                    <td>{formatarData(f.dataEvento)}</td>
                                                                    <td>{f.motivoHoraAcrescentar}</td>
                                                                    <td>{f.horas}</td>
                                                                    <td className='col-md-2' style={{textAlign: "center"}}>
                                                                        <button className="btn btn-primary" title='Editar' type="button" style={{margin: "0 5px"}} onClick={() => editarHorasFaltasSubstituicoes(f.id)} >
                                                                            <i className='pi pi-pencil'></i>
                                                                        </button>

                                                                        <button onClick={() => abrirModalConfirmacaoExcluirDataSelecionada(f.dataEvento, f.id)} title='Excluir' className="btn btn-danger">
                                                                            <i className='pi pi-trash'></i>
                                                                        </button>
                                                                    </td>
                                                                    <td style={{display: "none"}}>{horasAcrescentadasTerceirizados+= f.horas}</td>
                                                                </tr>
                                                            )
                                                        }                                                                                                   
                                                    })}
                                                    <span style={{paddingTop: '30px'}}><strong>Total de horas acrescentadas:</strong> {horasAcrescentadasTerceirizados}</span>
                                            </tbody>
                                        </Table>
                                    </Card>
                                </Tab>
                    
                            }
                            {/* Fim Tab dados Horas Acresenctar dos Terceirizados */}
                            {/* Início Tab dados Substituição dos Terceirizados */}
                            {(editarVinculoTerceirizado.tipoTerceirizado === d) && 
                            <Tab eventKey="datasubstituicoes" onClick={(s) => setTipoDasDatas(diariaAvulsa ? 'diaria' : 'substituicao')} onExited={limparDadosAposMudarAbaOuSalvar} title={diariaAvulsa ? "Diária" : "Substituição"}>
                                <Row>
                                    <label className="col-form-label mt-4" htmlFor="datasSubstituicoes"> Datas das {diariaAvulsa ? "Diárias" : "Substituições"}:</label><br/>
                                        <DatePicker
                                            mapDays={({ date }) => {
                                                let props = {}
                                                let isWeekend = [0, 6].includes(date.weekDay.index)
                                                
                                                if (isWeekend) props.className = "highlight highlight-red"
                                                
                                                return props
                                            }}
                                            onChange={setDatas}
                                            value={datas}
                                            id="datasSubstituicoes"
                                            name="datasSubstituicoes"
                                            numberOfMonths={2}
                                            multiple
                                            sort
                                            format="DD/MM/YYYY"
                                            locale={calendarioPersonalizado}
                                            plugins={[<DatePanel />]}
                                            style={{
                                                width: "100%",
                                                height: "38px",
                                                padding: "12px 20px",
                                                boxSizing: "border-box",
                                            }}
                                        />
                                </Row>
                                <br/>
                                <Row>
                                    <Col md={2}>
                                        <label className="col-form-label" htmlFor="horas">Horas de {diariaAvulsa ? "Diária" : "Substituição"}:</label>
                                        <input type="number" 
                                            className="form-control"
                                            min={1} max={12} 
                                            id="horas"
                                            name="horas"
                                            value={horas}
                                            onChange={(e) => setHoras(e.target.value)} />
                                        
                                    </Col>
                                        {!diariaAvulsa &&
                                            <>    
                                                <Col>
                                                    <label style={{fontWeight: 500}} className="col-form-label" htmlFor="substituirServidor">Substituir um servidor?:</label><br/>
                                                    <input type="checkbox"
                                                        style={{width: 70, height: 36}} 
                                                        id="substituirServidor" 
                                                        checked={substituiuServidor}
                                                        onChange={carregarListaComServidores}
                                                    />
                                                </Col>
                                                {substituiuServidor ?
                                                    <Col xl={4} lg={4}>
                                                        <label className="col-form-label" htmlFor="servidorSubstituido">Servidor Substituído:</label>
                                                        <Form.Select 
                                                            id="servidorSubstituido" 
                                                            name="servidor" 
                                                            value={idServidor} 
                                                            onChange={(e) => setIdServidor(e.target.value)}
                                                        >
                                                            <option value="">Selecione</option>
                                                            {listarServidoresDaUnidade.map((s) => (
                                                                <option value={s.servidor.id} key={s.id}>
                                                                    {s.servidor.nomeCompleto}
                                                                </option>
                                                            ))}
                                                        </Form.Select> 
                                                    </Col>
                                                :
                                                    <Col xl={4} lg={4}>
                                                        <label className="col-form-label" htmlFor="terceirizadoSubstituido">Terceirizado Substituído:</label>
                                                        <Form.Select 
                                                            id="terceirizadoSubstituido" 
                                                            name="terceirizadoSubstituido" 
                                                            value={idTerceirizado} 
                                                            onChange={(e) => setIdTerceirizado(e.target.value)}
                                                        >
                                                            <option value={0}>Selecione</option>
                                                            {listarTerceirizadosDaUnidade.map((c) => {
                                                                if (c.tipoTerceirizado === "MENSALISTA" && editarVinculoTerceirizado.terceirizado.id !== c.id) {
                                                                return (
                                                                    <option value={c.terceirizado.id} key={c.id}>
                                                                        {c.terceirizado.nome}
                                                                    </option>
                                                                )
                                                                }
                                                            })}
                                                        </Form.Select> 
                                                    </Col>
                                                }
                                            </>
                                        }
                                        <Col md={3}>
                                            <label className="col-form-label" htmlFor="funcaoSubstituido">Função da {diariaAvulsa ? "Diária" : "Substituição"}:</label>
                                            <Form.Select
                                                id="funcaoSubstituido" 
                                                name="funcaoSubstituido" 
                                                value={idFuncao} 
                                                onChange={(e) => setIdFuncao(e.target.value)}
                                            >
                                                <option value={0}>Selecione</option>
                                                {listaFuncoes.sort((a, b) => a.nome > b.nome ? 1:-1).map((f) => (
                                                    <option value={f.id} key={f.id} > {f.nome} </option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                </Row> <br/>
                                <Row>
                                    <Col md={2}>
                                        <label style={{fontWeight: 500}} className="col-form-label" htmlFor="diariaAvulsa">Diária Avulsa:</label><br/>
                                        {['right'].map((placement) => (
                                            <OverlayTrigger
                                            key={placement}
                                            placement={placement}
                                            overlay={
                                                <Tooltip id={`tooltip-${placement}`}>
                                                Marcar esse campo quando o Terceirizado realizar uma diária em que não substituiu um outro Terceirizado.
                                                </Tooltip>
                                            }
                                            >
                                                <input type="checkbox"
                                                    style={{width: 70, height: 36}} 
                                                    id="diariaAvulsa" 
                                                    checked={diariaAvulsa}
                                                    onChange={alterarEstadoDiariaAvulsa}
                                                />
                                            </OverlayTrigger>
                                        ))}
                                    </Col>
                                    {diariaAvulsa &&
                                    <Col>
                                        <label className="col-form-label">Descrição da Diária Avulsa:</label>
                                        <input className="form-control" type="text" name="diariaAvulsaDescricao" value={motivoDiariaAvulsa} onChange={(e) => setMotivoDiariaAvulsa(e.target.value)}/>
                                    </Col>
                                    }
                                </Row>
                                <div className="text-center mt-4">
                                    <button className="btn btn-primary" onClick={salvarLancamentosFaltasHorasAcrescentarSubstituicao} > Salvar </button>
                                </div>
                                <br/>
                                <Card title="Listagem de Substituições/Diárias">
                                    <Table id="tabelaLancamentosSubstituicoes" striped bordered hover size="md">
                                        <thead>
                                            <tr>
                                                <th>Nome do Substituído/Diária</th>
                                                <th>Função Substituído/Diária</th>
                                                <th>Data Substituição/Diária</th>
                                                <th>Horas </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listarHsFaltasSubstituicaoPorLancamento.sort((a, b) => a.dataEvento > b.dataEvento ? 1:-1).map((f) => {
                                                if (f.tipoOcorrencia === 'substituicao' || f.tipoOcorrencia === 'diaria') {
                                                    return(
                                                        <tr key={f.id}>
                                                            <td>{f.servidorSubstituido !== null ? f.servidorSubstituido.nomeCompleto : f.terceirizadoSubstituido !== null ? f.terceirizadoSubstituido.nome : 'Diária Avulsa'} <br/>
                                                                {f !== null ? f.tipoOcorrencia === 'diaria' ? f.motivoHoraAcrescentar : null : null}</td>
                                                            <td>{f.funcaoSubstituido.nome}</td>
                                                            <td>{formatarData(f.dataEvento)}</td>
                                                            <td>{f.horas}</td>
                                                            <td className='col-md-2' style={{textAlign: "center"}}>
                                                                <button className="btn btn-primary" title='Editar' type="button" style={{margin: "0 5px"}} onClick={() => editarHorasFaltasSubstituicoes(f.id)} >
                                                                    <i className='pi pi-pencil'></i>
                                                                </button>

                                                                <button onClick={() => abrirModalConfirmacaoExcluirDataSelecionada(f.dataEvento, f.id, f.tipoOcorrencia)} title='Excluir' className="btn btn-danger">
                                                                    <i className='pi pi-trash'></i>
                                                                </button>
                                                            </td>
                                                            <td style={{display: "none"}}>{horasSubstituicaoTerceirizado += f.horas}</td>
                                                        </tr>
                                                    )
                                                }                                                                                                   
                                            })}
                                            <span style={{paddingTop: '30px'}}><strong>Total de horas de substituição:</strong> {horasSubstituicaoTerceirizado}</span>
                                        </tbody>
                                    </Table>
                                </Card>
                            </Tab>
                            }
                            {/* Fim Tab dados Substituição dos Terceirizados */}
                        </Tabs>
                    </Card>
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-danger" onClick={fecharLimparDadosDeLancamentosHorasFaltasSub}>
                            Fechar
                        </button>
                    </Modal.Footer>
                </Modal>
                {/* Fim modal para Editar dados do Substituições e Horas acrescentar dos Terceirizados */}
                {/* Início modal de confirmação para excluir um vínculo de tercirizados */}
                <Modal show={showExcluirVinculo} onHide={closeShowExcluirVinculo} backdrop="static" keyboard={false} size="lg">
                    <Modal.Header>
                        <h3>Excluir Lancamento de Terceirizados</h3>
                    </Modal.Header>
                    <Modal.Body>
                       <h5 style={{textAlign: "center"}}>Deseja realmente excluir o lançamento do terceirizado: <br/>
                       <label style={{color: '#ff0000'}}>{nomeTerceirizadoExcluir} - {tipoTerceirizadoExcluir}</label> ? </h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-danger" onClick={() => excluirVinculoTerceirizado()}>
                            Excluir
                        </button>
                        <button className="btn btn-primary" onClick={closeShowExcluirVinculo}>
                            Fechar
                        </button>
                    </Modal.Footer>
                </Modal>
                {/* Fim modal de confirmação para excluir um vínculo de tercirizados */}
                {/* Início modal de confirmação para excluir data */}
                <Modal show={showExcluirData} onHide={closeShowExcluirData} backdrop="static" keyboard={false} size="lg" style={{backgroundColor: "#b1aeae"}}>
                    <Modal.Header>
                        <h3>Excluir {textoDataModalQueSeraExcluida === 'diaria' ? 'Diária' : tipoDasDatas === 'falta' ? 'Falta' : tipoDasDatas === 'substituicao' ? 'Substituição': tipoDasDatas === 'acrescentar' ? 'Horas Acrescentar' : 'Diária Avulsa'} de Terceirizados</h3>
                    </Modal.Header>
                    <Modal.Body>
                       <h5 style={{textAlign: "center"}}>Deseja realmente excluir a data de {textoDataModalQueSeraExcluida === 'diaria' ? 'Diária ' :tipoDasDatas === 'falta' ? 'Falta ' : tipoDasDatas === 'substituicao' ? 'Substituição ': tipoDasDatas === 'acrescentar' ? 'Horas Acrescentar ' : null}
                       <label style={{color: '#ff0000'}}>{formatarData(dataIndividualExcluir)}</label> do lançamento? 
                        </h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-danger" onClick={() => excluirDataDoLancamento()}>
                            Sim
                        </button>
                        <button className="btn btn-primary" onClick={closeShowExcluirData}>
                            Não
                        </button>
                    </Modal.Footer>
                </Modal>
                {/* Fim modal de confirmação para excluir data */}
                {/* Início modal Editar Faltas, Horas e Substituições */}
                <Modal show={showEditarFaltasHsSub} onHide={closeShowEditarFaltasHsSub} backdrop="static" keyboard={false} size="lg" style={{backgroundColor: "#b1aeae"}}>
                    <Modal.Header>
                        <h3>Editar {editarFaltaHorasSubIndividual !== null ? editarFaltaHorasSubIndividual.tipoOcorrencia === 'falta' ? 'Falta' : editarFaltaHorasSubIndividual.tipoOcorrencia === 'substituicao' ? 'Substituição': editarFaltaHorasSubIndividual.tipoOcorrencia === 'acrescentar' ? 'Horas Acrescentar' : editarFaltaHorasSubIndividual.tipoOcorrencia === 'diaria' ? 'Diária Avulsa' : '' : ''} de Terceirizados</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <Col style={{textAlign: 'center'}}>
                            <label  htmlFor="terceirizado" className="col-form-label" >Terceirizado:</label>
                            <input type="text" 
                                style={{textAlign: 'center'}}
                                className="form-control" 
                                id="terceirizado" 
                                name="terceirizado"
                                disabled
                                value={editarVinculoTerceirizado ? editarVinculoTerceirizado.terceirizado.nome : null}  
                            />
                        </Col>
                        {tipoDasDatas === 'falta' &&
                        <Row className="justify-content-md-center">
                            <Col lg={3} style={{textAlign: 'center'}}>
                                <label  className="col-form-label">Data da Falta:</label>
                                <input type="date"
                                    style={{textAlign: 'center'}} 
                                    className="form-control" 
                                    id="dataFalta" 
                                    name="dataFalta"
                                    disabled
                                    value={editarFaltaHorasSubIndividual ? editarFaltaHorasSubIndividual.dataEvento : null}  
                                />     
                            </Col>
                            <Col lg={2} style={{textAlign: 'center'}}>
                            <label  className="col-form-label">Horas Faltadas:</label>
                            <input type="number" 
                                className="form-control"
                                style={{textAlign: 'center'}}
                                id="horasFaltas" 
                                name="horasFaltas"
                                min={1}
                                max={12}
                                value={editarHorasIndividual}
                                onChange={(h) => setEditarHorasIndividual(h.target.value)}
                            />   
                            </Col>
                        </Row>
                        } 
                        {tipoDasDatas === 'acrescentar' &&
                        <Row className="justify-content-md-center">
                            <Col lg={3} style={{textAlign: 'center'}}>
                                <label  className="col-form-label">Data Hora Acrescentar:</label>
                                <input type="date"
                                    style={{textAlign: 'center'}} 
                                    className="form-control" 
                                    id="dataFalta" 
                                    name="dataFalta"
                                    disabled
                                    value={editarFaltaHorasSubIndividual ? editarFaltaHorasSubIndividual.dataEvento : null}  
                                />     
                            </Col>
                            <Col lg={2} style={{textAlign: 'center'}}>
                            <label  className="col-form-label">Horas:</label>
                            <input type="number" 
                                className="form-control"
                                style={{textAlign: 'center'}}
                                id="horasAcrescentar" 
                                name="horasAcrescentar"
                                min={1}
                                max={12}
                                value={editarHorasIndividual}
                                onChange={(h) => setEditarHorasIndividual(h.target.value)}
                            />   
                            </Col>
                            <Col lg={7} style={{textAlign: 'center'}}>
                            <label  className="col-form-label">Motivo:</label>
                            <input type="text" 
                                className="form-control"
                                id="motivoHoras" 
                                name="motivoHoras"
                                value={editarMotivoHorasAcrescentar}
                                onChange={(m) => setEditarMotivoHorasAcrescentar(m.target.value)}
                            />   
                            </Col>
                        </Row>
                        }
                        {(tipoDasDatas === 'substituicao' || tipoDasDatas === 'diaria') &&
                        <>
                        <Row className="justify-content-md-center">
                            <Col lg={3} style={{textAlign: 'center'}}>
                                <label  className="col-form-label">Data Substituição:</label>
                                <input type="date"
                                    style={{textAlign: 'center'}} 
                                    className="form-control" 
                                    id="dataFalta" 
                                    name="dataFalta"
                                    disabled
                                    value={editarFaltaHorasSubIndividual ? editarFaltaHorasSubIndividual.dataEvento : null}  
                                />     
                            </Col>
                            <Col lg={5} style={{textAlign: 'center'}}>
                                <label  className="col-form-label">Substituído/Diária:</label>
                                <input type="text"
                                    style={{textAlign: 'center'}}
                                    className="form-control" 
                                    id="nomeSubstituido" 
                                    name="nomeSubstituido"
                                    disabled
                                    value={editarFaltaHorasSubIndividual ? 
                                            editarFaltaHorasSubIndividual.servidorSubstituido != null ? editarFaltaHorasSubIndividual.servidorSubstituido.nomeCompleto : 
                                            editarFaltaHorasSubIndividual.terceirizadoSubstituido != null ? editarFaltaHorasSubIndividual.terceirizadoSubstituido.nome : "Diária Avulsa" : "Diária Avulsa"}  
                                />     
                            </Col>
                            <Col lg={4} style={{textAlign: 'center'}}>
                            <label  className="col-form-label">Função:</label>
                            <input type="text"
                                style={{textAlign: 'center'}}
                                className="form-control"
                                id="funcao" 
                                name="funcao"
                                disabled
                                value={editarFaltaHorasSubIndividual !== null ? editarFaltaHorasSubIndividual.funcaoSubstituido.nome : null}
                                onChange={(m) => setEditarMotivoHorasAcrescentar(m.target.value)}
                            />   
                            </Col>
                            {(editarFaltaHorasSubIndividual !== null && editarFaltaHorasSubIndividual.tipoOcorrencia === 'diaria') &&
                            <Col lg={12} style={{textAlign: 'center'}}>
                                <label  className="col-form-label">Descrição Diária:</label>
                                <input type="text"
                                    style={{textAlign: 'center'}}
                                    className="form-control" 
                                    id="nomeSubstituido" 
                                    name="nomeSubstituido"
                                    value={editarMotivoHorasAcrescentar}
                                    onChange={(m) => setEditarMotivoHorasAcrescentar(m.target.value)}
                                />     
                            </Col>
                            }
                            <Row></Row>
                            <Col lg={2} style={{textAlign: 'center'}}>
                            <label  className="col-form-label">Horas:</label>
                            <input type="number" 
                                className="form-control"
                                style={{textAlign: 'center'}}
                                id="horasAcrescentar" 
                                name="horasAcrescentar"
                                min={1}
                                max={12}
                                value={editarHorasIndividual}
                                onChange={(h) => setEditarHorasIndividual(h.target.value)}
                            />   
                            </Col>
                        </Row>
                        </>
                        } 
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-danger" onClick={() => closeShowEditarFaltasHsSub()}>
                            Fechar
                        </button>
                        <button className="btn btn-primary" onClick={salvarHorasEditadaIndividual}>
                            Salvar
                        </button>
                    </Modal.Footer>
                </Modal>
                {/* Fim modal Editar Faltas, Horas e Substituições */}
                {/* Início modal de confirmação Replicação */}
                <Modal show={showConfirmarReplicacao} onHide={closeShowConfirmarReplicacao} backdrop="static" keyboard={false} size="lg">
                    <Modal.Header>
                        <h3>Replicar Lançamentos de Terceirizados</h3>
                    </Modal.Header>
                    <Modal.Body>
                       <h5 style={{textAlign: "center"}}>Deseja realmente replicar os Lançamentos do mês selecionado para o mês seguinte?</h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={() => replicarLancamentos(mes)}>
                            Sim
                        </button>
                        <button className="btn btn-danger" onClick={closeShowConfirmarReplicacao}>
                            Não
                        </button>
                    </Modal.Footer>
                </Modal>
                {/* Fim modal de confirmação Replicação */}
            </Container>
        </>
    )
}
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { mensagemSucesso } from "../../components/avisos/toastr";
import { Row, Col, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import PrestadorService from "../../service/prestadorService";
import gerarPdfService from "../../service/gerarPdfService";
import LancamentoPrestadorService from "../../service/lancamentoPrestadorService";
import Card from "../../components/card";
import LoadingPage from "../landing/LandingPage";

const DivPesquisar = styled.div`
  position: relative;
  padding-left: 5%;
  padding-right: 5%;
`;

const DivTitulo = styled.div`
  color: black;
  font-weight: bold;
  text-align: center;
  padding-top: 10px;
`;

function ListarPrestadores() {
  const [prestadores, setPrestadores] = useState([]);
  const [totalPrestadores, setTotalPrestadores] = useState(0);
  const [totalPaginas, setTotalPaginas] = useState([]);
  const [paginaAtual, setPaginaAtual] = useState(0);
  const [nomeBuscar, setNomeBuscar] = useState("");
  const [listarPorFormacao, setListarPorFormacao] = useState("");
  const [buscarFormacao, setBuscarFormacao] = useState("");
  const [nomeSituacao, setNomeSituacao] = useState("ATIVO");
  const [numeroDePaginas, setNumeroDePaginas] = useState(0);
  const [totalPrestadoresPorPagina, setTotalPrestadoresPorPagina] = useState(0);
  const redirecionar = useHistory();
  const [loading, setLoading] = useState(false)

  //implementar futuramente função para quando pesquisar setar a primeira pagina
  const pesquisou = () => setPaginaAtual(0);

  //Modal deletar
  const [showModal, setShowModal] = useState(false);
  //const [idDelete, setIdDelete] = useState();
  const handleClose = () => setShowModal(false);
  const handleConfclose = () => setShowModal(false);
  const handleDelete = () => setShowModal(true);

  const [showModalHistoricoPrestador, setShowModalHistoricoPrestador] = useState(false);
  const handleCloseHistoricoPrestador = () => setShowModalHistoricoPrestador(false);
  const handleShowHistoricoPrestador = () => setShowModalHistoricoPrestador(true);

  const [nomeExibicao, setNomeExibicao] = useState();
  const [lancamentosPrestador, setLancamentosPrestador] = useState([]);

  const serviceP = new PrestadorService();
  const serviceLancamentoPrestador = new LancamentoPrestadorService();
  useEffect(() => {

    async function carregarPrestadorBusca() {
      const response = await serviceP.buscarPorNome(
        `/pornome/${nomeBuscar}/page/${paginaAtual}`
      );
      setTotalPrestadores(response.data.totalElements);
      setPrestadores(response.data.content);
      setTotalPrestadoresPorPagina(response.data.size);
      setNumeroDePaginas(response.data.totalPages);
      const paginas = response.data.totalPages;
      const arrayPaginas = [];
      for (let p = 1; p <= paginas; p++) {
        arrayPaginas.push(p);
      }
      setTotalPaginas(arrayPaginas);
    }

    async function carregarPrestadores() {
      const response = await serviceP.listarTodos(`/pag/${paginaAtual}`);
      //setTotalPaginas(response.totalPages)
      setTotalPrestadores(response.data.totalElements);
      setPrestadores(response.data.content);
      setTotalPrestadoresPorPagina(response.data.size);
      setNumeroDePaginas(response.data.totalPages);
      const paginas = response.data.totalPages;
      const arrayPaginas = [];
      //cria um array de paginas na quantidade vinda do backend
      for (let p = 1; p <= paginas; p++) {
        arrayPaginas.push(p);
      }
      setTotalPaginas(arrayPaginas);
    }

    async function listarPorFormacao(){
      serviceP.listarPorFormacao(buscarFormacao)
      .then((s) => {
        setPrestadores(s.data)
      })
    }

    if (nomeBuscar === "" && buscarFormacao === "") {
      carregarPrestadores();
    } else if (nomeBuscar !== "") {
      carregarPrestadorBusca();
      setBuscarFormacao("")
    } else if (buscarFormacao !== ""){
      listarPorFormacao()
    }
  }, [paginaAtual, nomeBuscar, buscarFormacao]);

  function handlePageClick(data) {
    setPaginaAtual(data.selected);
  }

  function relatorioListarTodos() {
    setLoading(true)
    gerarPdfService("/prestador/r/listartodos/")
    .then(() => {
			setLoading(false)
		})
  }

  function mascaraTelefone(e) {
    if (e) { 
      if (e.length >= 12) {
        return false;
      }
      if (e.length > 10) {
        return e.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
      } else if (e.length > 5) {
        return e.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
      } else if (e.length > 2) {
        return e.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
      } else {
        return e.replace(/^(\d*)/, "($1");
      }    
    }
  }

  function mascaraCpf(c){
    return c.match(/.{1,3}/g).join(".").replace(/\.(?=[^.]*$)/,"-")
  }
   
function buscarHistoricoPrestador(id, nome){
  setNomeExibicao(nome);
	serviceLancamentoPrestador.buscarHistoricoPrestador(id).then((response) => {
		setLancamentosPrestador(response.data);
    
	})
}

  return (
    <div>
      {loading && <LoadingPage/> }
      <DivPesquisar>
        
          <Row>
            <Col md={4}>
              <label htmlFor="nomeBuscar" className="col-form-label">Nome:</label >
              <input
                id="nomeBuscar"
                name="nomeBuscar"
                type="text"
                className="form-control"
                placeholder="Digite o nome para pesquisar..."
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
                value={nomeBuscar}
                onChange={(buscar) => setNomeBuscar(buscar.target.value)}
              />
            </Col>

            <Col md={1} style={{paddingTop: "4%", textAlign: "center"}}><label >Ou</label></Col>

            <Col md={3}>
              <label className="col-form-label" htmlFor="formacao" >
                      Área de Formação/Atuação:
                    </label>
                    <Form.Select
                    id="formacao"
                    name="formacao"
                    value={buscarFormacao}
                    onChange={(e) => setBuscarFormacao(e.target.value)}
                    onClick={() => setNomeBuscar("")}      
                  >
                    <option value="">TODOS</option>
                    <option value="PORTUGUÊS">PORTUGUÊS</option>
                    <option value="INGLÊS">INGLÊS</option>
                    <option value="MATEMÁTICA">MATEMÁTICA</option>
                    <option value="CIÊNCIAS">CIÊNCIAS</option>
                    <option value="EDUCAÇÃO FÍSICA">EDUCAÇÃO FÍSICA</option>
                    <option value="GEOGRAFIA">GEOGRAFIA</option>
                    <option value="HISTÓRIA">HISTÓRIA</option>
                    <option value="ARTES">ARTES</option>
                    <option value="PEDAGOGIA">PEDAGOGIA</option>
                    
                  </Form.Select></Col>

            <Col md={4} style={{paddingTop: "3%"}}>
            
              <button className="btn btn-primary" type="button" style={{ marginRight: "5px" }}>
                <Link
                  style={{ textDecoration: "none", color: "#ffffff" }}
                  to={"/prestador/cad"}
                >
                  Cadastrar
                </Link>
              </button>
            
              <button
                className="btn btn-primary"
                type="button"
                target="_blank"
                onClick={() => relatorioListarTodos()}
                title="Baixar PDF com todos os Substitutos Temporários cadastrados"
              >
                Salvar PDF
              </button>
            </Col>
          </Row>
      </DivPesquisar>

      <DivTitulo>Listar Substitutos Temporários</DivTitulo>

      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Nome</th>
            <th scope="col">CPF</th>
            <th scope="col">Contato</th>
            <th scope="col">Formação ou Área de Atuação</th>
            <th scope="col">Disponibilidade</th>
            <th scope="col">Ações</th>
          </tr>
        </thead>
        <tbody>
          {prestadores.map((p) => {
            const tel = mascaraTelefone(p.telefone1)
            const cpf = mascaraCpf(p.cpf)
            return (
              <tr key={p.id}>
                <td style={{ maxWidth: "300px" }}>{p.nome}</td>
                <td>{cpf}</td>
                <td>{tel}</td>
                <td >{p.formacao}</td>
                <td style={p.disponibilidade === "DISPONÍVEL" ? {backgroundColor: "#242fd4", color: "#fff", textAlign: "center"} : 
                p.disponibilidade === "INDISPONÍVEL" ? {backgroundColor: "#fa0f0f", color: "#fff", textAlign: "center"} : null}>{p.disponibilidade} <br></br>{p.disponibilidade === 'DISPONÍVEL' ? p.turnoDisponibilidade : ''}</td>
                <td>
                  <button className="btn btn-primary" type="button">
                    <Link
                      style={{ textDecoration: "none", color: "#00008B" }}
                      to={`/prestador/edit/${p.id}`}
                    >
                      <i className='pi pi-pencil' title="Editar"></i>
                    </Link>
                  </button>{" "}
                  <button onClick={() => handleShowHistoricoPrestador(buscarHistoricoPrestador(p.id, p.nome))}
                      className="btn btn-success"
                      type="button" title="Histórico do Prestador"
                  >
                      <i className='pi pi-history'></i>
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <ReactPaginate
        previousLabel={"Voltar"}
        nextLabel={"Avançar"}
        breakLabel={"..."}
        pageCount={numeroDePaginas}
        marginPagesDisplayed={3}
        pageRangeDisplayed={2}
        onPageChange={handlePageClick}
        containerClassName={"pagination justify-content-center"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
        initialPage={pesquisou}
      />
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Usar para Outros fins</Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-primary"
            type="button"
            onClick={handleClose}
          >
            Não
          </button>
          <button
            //onClick={() => deletaUsuario(idDelete)}
            className="btn btn-outline-danger"
            type="button"
          >
            Sim
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalHistoricoPrestador} onHide={handleCloseHistoricoPrestador} backdrop="static" keyboard={false} size="lg">
			
        <Modal.Body>
            <Card title="Histórico do Prestador">
                <h3 style={{color: 'gray'}}>{nomeExibicao}</h3>
                <Table id="tabela_lancamentos_faltas" striped bordered hover size="md">
                    <thead>
                        <tr>
                            <th>Ano</th>
                            <th>Mês</th>
                            <th>Unidade</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lancamentosPrestador.map((lp) => {
                            return (
                                <tr key={lp.id}>
                                    <td>{lp.anoLancamento}</td>
                                    <td>{lp.mesLancamento}</td>
                                    <td>{lp.unidade.nome}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Card>
        </Modal.Body>
        <Modal.Footer>
            <Button className="btn btn-secondary" onClick={handleCloseHistoricoPrestador}>
                Fechar
            </Button>
        </Modal.Footer>
		  </Modal>
    </div>
  );
}

export default ListarPrestadores;

import ApiService from "../app/apiservice";


export default class assinaturaQuadrosService extends ApiService{

    constructor(){
        super('/assinatura')
    }

    assinarQuadro(dados){
        return this.post('/assinar', dados)
    }

    listarAssinaturasDaUnidade(idUnidade, ano, mes){
        return this.get(`/listarassinaturas/${idUnidade}/${ano}/${mes}`)
    }

    listarAssinaturasPorAnoMes(ano, mes){
        return this.get(`/assinaturas/mesano/${ano}/${mes}`)
    }

    carregarListaParaPainel(ano, mes){
        return this.get(`/assinaturaspainel/${ano}/${mes}`)
    }

    contarAssinaturasDaUnidade(idUnidade, ano, mes){
        return this.get(`/contarassinaturas/${idUnidade}/${ano}/${mes}`)
    }

    validarCodigoVerificador(codigo){
        return this.get(`/verificarassinatura/${codigo}`)
    }

    deletarAssinatura(idUnidade, ano, mes, tipo){
        return this.delete(`/deletarassinatura/${idUnidade}/${ano}/${mes}/${tipo}`)
    }

    verificarSeAssinado(idUnidade, ano, mes, tipo){
        return this.get(`/verificarseassinado/${idUnidade}/${ano}/${mes}/${tipo}`)
    }
   
}
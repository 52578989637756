/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import UsuarioService from "../../service/usuarioService";
import { Link } from "react-router-dom";
import { mensagemSucesso } from "../../components/avisos/toastr";
import AlterarSenha from "./AlterarSenha";
import ReactPaginate from "react-paginate";
import Desabilitar from "./Desabilitar";
import LocalStorageService from "../../service/localStorageService";
import { descriptografar } from "../../components/criptografar";

const DivPesquisar = styled.div`
  position: relative;
  width: 90%;
  padding-left: 10%;
  padding-top: 10px;
`;

const DivTitulo = styled.div`
  color: black;
  font-weight: bold;
  text-align: center;
`;
const service = new UsuarioService();
function ListarUsuarios() {
  const [usuarios, setUsuarios] = useState([]);
  const [usuariosCarregar, setUsuariosCarregar] = useState([]);
  const [totalUsuarios, setTotalUsuarios] = useState(0);
  const [totalPaginas, setTotalPaginas] = useState([]);
  const [paginaAtual, setPaginaAtual] = useState(0);
  const [nomeBuscar, setNomeBuscar] = useState("");
  const [numeroDePaginas, setNumeroDePaginas] = useState(0)

  const usuarioCriptografado = LocalStorageService.obterItem('2')
  const usuarioLogado = JSON.parse(descriptografar(usuarioCriptografado))
  const rolerUser = usuarioLogado ? usuarioLogado.role : ""
  const admin = "ADMINISTRADOR";

  //implementar futuramente função para quando pesquisar setar a primeira pagina
  const pesquisou = () => setPaginaAtual(0);

  //Modal Desabilitar
  const [showModal, setShowModal] = useState(false);
  const [idDesabilitar, setIdDesabilitar] = useState();
  const handleClose = () => setShowModal(false);
  const handleConfclose = () => setShowModal(false);
  const handleDesabilitar = () => setShowModal(true);

  //Modal alterar senha
  const [showModalAltarSenha, setShowModalAltarSenha] = useState(false);
  const [idAltararSenha, setIdAlterarSenha] = useState();
  const handleCloseAS = () => setShowModalAltarSenha(false);
  const handleAlterarSenha = () => setShowModalAltarSenha(true);

  function desabilitarUsuario(id) {
    service.buscarPorId(id).then((resposta) => {
      service.desabilitar(resposta.data).then((e) => {
        handleConfclose()
        mensagemSucesso("Usuario desabilitado com sucesso.");
      });
    });
  }

  function habilitarUsuario(id) {
    service.habilitar(id).then((e) => {
      mensagemSucesso("Usuario habilitado com sucesso.");
      setTimeout(function(){
        window.location.reload();
      }, 1000)
    });
  }

  useEffect(() => {
    //função para acessar o endpoint

    async function carregarUsuariosBusca() {
      const response = await service.buscarPorNome(
        `/usuariopornome/${nomeBuscar}/page/${paginaAtual}`
      );
      //setTotalPaginas(response.totalPages)
      setTotalUsuarios(response.data.totalElements);
      setUsuarios(response.data.content);
      setNumeroDePaginas(response.data.totalPages);
      const paginas = response.data.totalPages;
      const arrayPaginas = [];
      //cria um array de paginas na quantidade vinda do backend
      for (let p = 1; p <= paginas; p++) {
        arrayPaginas.push(p);
      }
      setTotalPaginas(arrayPaginas);
      //console.log(response.data);
      //console.log("Pagina atual: " + paginaAtual);
      //console.log("Total usuarios:" + response.data.totalElements);
      //console.log("Total paginas:" + arrayPaginas);
    }

    async function carregarUsuarios() {
      const response = await service.listarTodos(`/pag/${paginaAtual}`);
      //setTotalPaginas(response.totalPages)
      setTotalUsuarios(response.data.totalElements);
      setUsuarios(response.data.content);
      setNumeroDePaginas(response.data.totalPages);
      const paginas = response.data.totalPages;
      const arrayPaginas = [];
      //cria um array de paginas na quantidade vinda do backend
      for (let p = 1; p <= paginas; p++) {
        arrayPaginas.push(p);
      }
      setTotalPaginas(arrayPaginas);
      //console.log("Pagina atual: " + paginaAtual);
      //console.log("Total usuarios:" + response.data.totalElements);
      //console.log("Total paginas:" + arrayPaginas);
    }
    //setUsuariosCarregar([])

    //console.log(usuariosCarregar);
    if (nomeBuscar === "") {
      carregarUsuarios();
    } else {
      carregarUsuariosBusca();
    }
  }, [paginaAtual, nomeBuscar, showModal, idDesabilitar]);

  function handlePageClick(data) {
    setPaginaAtual(data.selected);
    //console.log(paginaAtual);
  }
  function mascaraTelefone(e) {
   if(e){
     if (e && e.length >= 12) {
         return 0;
     }
     if (e.length === null) {
       return 0
    }
     if (e.length > 15) {
         return e.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
     } else if (e.length > 5) {
         return e.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
     } else if (e.length > 2) {
        return e.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
     } else {
       return e.replace(/^(\d*)/, "($1");
     }
   }
}

  return (
    <div>
      <DivPesquisar>
        <div className="input-group mb-2">
          <input
            type="text"
            className="form-control"
            placeholder="Digite o nome para pesquisar..."
            aria-label="Recipient's username"
            aria-describedby="button-addon2"
            value={nomeBuscar}
            onChange={(buscar) => setNomeBuscar(buscar.target.value)}
          />
        </div>
      </DivPesquisar>

      <DivTitulo>Listar Usuários</DivTitulo>

      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Matrícula</th>
            <th scope="col">Nome Usuário</th>
            <th scope="col">Login</th>
            <th scope="col">Local Atuação</th>
            <th scope="col">Telefone</th>
            <th scope="col">Status</th>
            <th scope="col">Ações</th>
          </tr>
        </thead>
        <tbody>
          {usuarios.map((u) => {
            const tel = mascaraTelefone(u.telefone)
            return (
              <tr key={u.id}>
                <td>{u.matricula}</td>
                <td>{u.nomeCompleto}</td>
                <td>{u.login}</td>
                <td>{u.unidade.nome}</td>
                <td>{tel}</td>
                <td style={{ textAlign: "center" }}>
                  {u.status === true ? (
                              <label>ATIVO</label>
                            ) : (
                                <label>INATIVO</label>
                            )}
                </td>
                <td>
                  {u.tipoUsuario === admin && rolerUser === admin ? 
                      <button className="btn btn-primary" type="button" title="Editar">
                      <Link
                        style={{ textDecoration: "none", color: "#fff" }}
                        to={`/usuario/edit/${u.id}`}
                      >
                        <i className='pi pi-pencil'></i>
                      </Link>
                    </button> : 
                    u.tipoUsuario === admin && rolerUser !== admin ?
                    null : 
                    u.status === false || u.status === null ? 
                    (
                      <button
                        onClick={(e) => habilitarUsuario(u.id)}
                        className="btn btn-primary"
                        type="button" title="Habilitar"
                      >
                        <i className='pi pi-unlock'></i>
                      </button>
                    ) : (
                      <>
                        <button className="btn btn-primary" type="button" title="Editar">
                          <Link
                            style={{ textDecoration: "none", color: "#fff" }}
                            to={`/usuario/edit/${u.id}`}
                          >
                            <i className='pi pi-pencil'></i>
                          </Link>
                        </button> {" "}
                        <button
                          onClick={(e) =>
                            handleAlterarSenha(setIdAlterarSenha(u.id))
                          }
                          className="btn btn-primary" type="button" title="Alterar Senha"
                        >
                          <i className='pi pi-key'></i>
                        </button> {" "}

                        <button
                          onClick={(e) =>
                            handleDesabilitar(setIdDesabilitar(u.id))
                          }
                          className="btn btn-danger"
                          type="button" title="Desabilitar"
                        >
                          <i className='pi pi-power-off'></i>
                        </button>
                      </>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <ReactPaginate
        previousLabel={"Voltar"}
        nextLabel={"Avançar"}
        breakLabel={"..."}
        pageCount={numeroDePaginas}
        marginPagesDisplayed={3}
        pageRangeDisplayed={2}
        onPageChange={handlePageClick}
        containerClassName={"pagination justify-content-center"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
        initialPage={pesquisou}
      />

      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Desabilitar Usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Desabilitar id={idDesabilitar} />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            type="button"
            onClick={handleClose}
          >
            Não
          </button>
          <button
            onClick={() => desabilitarUsuario(idDesabilitar)}
            className="btn btn-danger"
            type="button"
          >
            Sim
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModalAltarSenha}
        onHide={handleCloseAS}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Alteração de senha</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlterarSenha id={idAltararSenha} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ListarUsuarios;

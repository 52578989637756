/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import styled from "styled-components";
import ServidorService from "../../service/servidorService";
import { Link } from "react-router-dom";
import { mensagemErro, mensagemSucesso } from "../../components/avisos/toastr";
import Form from "react-bootstrap/Form";
import { Row, Col } from "react-bootstrap";

import ReactPaginate from "react-paginate";
import LancamentoServidorService from "../../service/lancamentoServidorService";
import Card from "../../components/card";

const DivPesquisar = styled.div`
    position: relative;
    padding-left: 20%;
    padding-right: 10%;
`;

const DivTitulo = styled.div`
  color: black;
  font-weight: bold;
  text-align: center;
  padding-top: 10px;
`;

function ListarServidores() {
  const [servidores, setServidores] = useState([]);
  const [totalServidores, setTotalServidores] = useState(0);
  const [totalPaginas, setTotalPaginas] = useState([]);
  const [paginaAtual, setPaginaAtual] = useState(0);
  const [nomeBuscar, setNomeBuscar] = useState("");
  const [nomeSituacao, setNomeSituacao] = useState("ATIVO");
  const [numeroDePaginas, setNumeroDePaginas] = useState(0);
  const [totalServidoresPorPagina, setTotalServidoresPorPagina] = useState(0);
  const [lancamentosServidor, setLancamentosServidor] = useState([]);


  const [nomeExibicao, setNomeExibicao] = useState();

  //implementar futuramente função para quando pesquisar setar a primeira pagina
  const pesquisou = () => setPaginaAtual(0);

  //Modal Desabilitar/Habilitar servidor
  const [showModalAlterarSituacao, setShowModalAlterarSituacao] = useState(false);
  const handleCloseAlterarSituacao = () => setShowModalAlterarSituacao(false);
  const handleAlterarSituacao = () => setShowModalAlterarSituacao(true);
  const [servidorAtualizarSituacao, setServidorAtualizarSituacao] = useState([]);

const [showModalHistoricoServidor, setShowModalHistoricoServidor] = useState(false);
const handleCloseHistoricoServidor = () => setShowModalHistoricoServidor(false);
const handleShowHistoricoServidor = () => setShowModalHistoricoServidor(true);

  const service = new ServidorService();
  const serviceLancamentoServidor = new LancamentoServidorService();
  useEffect(() => {
    //IMPLEMENTAR NO BACKEND: endpoint de filtrar por ATIVO, INATIVO e TODOS

    async function carregarServidorBusca() {
      const response = await service.buscarPorNome(
        `/servidorpornome/${nomeBuscar}/page/${paginaAtual}`
      );

      //setTotalPaginas(response.totalPages)
      setTotalServidores(response.data.totalElements);
      setServidores(response.data.content);
      setTotalServidoresPorPagina(response.data.size);
      setNumeroDePaginas(response.data.totalPages);
      const paginas = response.data.totalPages;
      const arrayPaginas = [];
      //cria um array de paginas na quantidade vinda do backend
      for (let p = 1; p <= paginas; p++) {
        arrayPaginas.push(p);
      }
      setTotalPaginas(arrayPaginas);
      //console.log(response.data);
      //console.log("Pagina atual: " + paginaAtual);
      //console.log("Total servidores:" + totalServidores)
      //console.log("Total  pagina:" + totalServidoresPorPagina);
      //console.log("Total de paginas:" + numeroDePaginas);
    }

    async function carregarServidores() {
      const response = await service.listarTodos(`/pag/${paginaAtual}`);
      //setTotalPaginas(response.totalPages)
      setTotalServidores(response.data.totalElements);
      setServidores(response.data.content);
      setTotalServidoresPorPagina(response.data.size);
      setNumeroDePaginas(response.data.totalPages);
      const paginas = response.data.totalPages;
      const arrayPaginas = [];
      //cria um array de paginas na quantidade vinda do backend
      for (let p = 1; p <= paginas; p++) {
        arrayPaginas.push(p);
      }
      setTotalPaginas(arrayPaginas);

      //console.log(response.data);
      //console.log("Pagina todos atual: " + paginaAtual);
      //console.log("Total todos servidores:" + totalServidores);
      //console.log("Total todos paginas:" + arrayPaginas);

      //console.log("Total todos por pagina:" + totalServidoresPorPagina);
      //console.log("Total todos de paginas:" + numeroDePaginas);
    }

    if (nomeBuscar === "") {
      carregarServidores();
    } else {
      carregarServidorBusca();
    }
  }, [paginaAtual, nomeBuscar, showModalAlterarSituacao]);

  function handlePageClick(data) {
    setPaginaAtual(data.selected);
    //console.log(paginaAtual);
  }

  function localizarServidor(id){
    service.buscarPorId(id)
    .then((s) => {
      setServidorAtualizarSituacao(s.data)
    })
  }

  function servidorHabilitar(id){
    servidorAtualizarSituacao.situacao = "ATIVO"
    service.habilitar(id, servidorAtualizarSituacao)
    .then(() => { 
      handleCloseAlterarSituacao()
    })
    .catch((e) => {
      mensagemErro(e.response.data)
      handleCloseAlterarSituacao()
    })
  }

  function servidorDesabilitar(id){
    servidorAtualizarSituacao.situacao = "INATIVO"
    service.atualizar(id, servidorAtualizarSituacao)
    .then(() => { 
      handleCloseAlterarSituacao()
    })
    .catch((e) => {
      mensagemErro(e.response.data)
      handleCloseAlterarSituacao()
    })
  }
  
function buscarHistoricoServidor(id, nome){
  setNomeExibicao(nome);
	serviceLancamentoServidor.buscarHistoricoServidor(id).then((response) => {
		setLancamentosServidor(response.data);
    
	})
}

  return (
    <div>
      <DivPesquisar>
      <div
        style={{
          padding: "1%",
          justifyContent: "left",
        }}
      >
        <Row>
          <Col md={6}>
            <label htmlFor="nomeBuscar">Nome:</label>
            <input
              id="nomeBuscar"
              name="nomeBuscar"
              type="text"
              className="form-control"
              placeholder="Digite o nome para pesquisar..."
              aria-label="Recipient's username"
              aria-describedby="button-addon2"
              value={nomeBuscar}
              onChange={(buscar) => setNomeBuscar(buscar.target.value)}
            />
          </Col>
          <Col>
          <br />
          <button className="btn btn-primary" type="button">
                    <Link
                      style={{ textDecoration: "none", color: "#fff" }}
                      to={`/servidor/cad`}
                    >
                      Cadastar
                    </Link>
                  </button>
          </Col>
{/*           <Col md={2}>
            <label htmlFor="situacaoServidor">Situação:</label>
            <Form.Select
              id="situacaoServidor"
              name="situacaoServidor"
              onChange={(s) => setNomeSituacao(s.target.value)}
            >
              <option value="ATIVO">Ativo</option>
              <option value="INATIVO">Inativo</option>
              <option value="TODOS">Todos</option>
            </Form.Select>
          </Col> */}
        </Row>
      </div>
      </DivPesquisar>

      <DivTitulo>Listar Servidores</DivTitulo>

      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">n.º</th>
            <th scope="col">Matrícula</th>
            <th scope="col">Nome Servidor</th>
            <th scope="col">Cargo</th>
            <th scope="col">Vínculo</th>
            <th scope="col">C.H.</th>
            <th scope="col">Status</th>
            <th scope="col">Ações</th>
          </tr>
        </thead>
        <tbody>
          {servidores.map((s, index) => {
            return (
              <tr key={s.id}>
                {s.matriculaDefinitiva ?
                  <>
                    <td>{index+1}</td>
                    <td>{s.matricula}</td>
                    <td style={{ maxWidth: "300px" }}>
                      {s.nomeCompleto}
                    </td>
                    <td>{s.cargo.nome}</td>
                    <td>{s.vinculo}</td>
                    <td>{s.cargaHoraria}</td>
                    <td>{s.situacao}</td>
                  </>
                      :
                  <>
                    <td style={{borderBottom: 'solid #F08080'}}>{index+1}</td>
                    <td style={{borderBottom: 'solid #F08080'}}>{s.matricula}</td>
                    <td style={{ maxWidth: "300px", borderBottom: 'solid #F08080' }}>
                      {s.nomeCompleto} <br></br><span style={{color: "red", fontSize: "13px"}}>Servidor(a) com matrícula provisória.</span>
                    </td>
                    <td style={{borderBottom: 'solid #F08080'}}>{s.cargo.nome}</td>
                    <td style={{borderBottom: 'solid #F08080'}}>{s.vinculo}</td>
                    <td style={{borderBottom: 'solid #F08080'}}>{s.cargaHoraria}</td>
                    <td style={{borderBottom: 'solid #F08080'}}>{s.situacao}</td>
                  </>
                }

                <td>
                  {s.situacao === "ATIVO" ?
                  <>
                    <button className="btn btn-primary" type="button">
                      <Link
                        style={{ textDecoration: "none", color: "#fff" }}
                        to={`/servidor/edit/${s.id}`}
                      >
                        <i title="Editar" className='pi pi-pencil'></i>
                      </Link>
                    </button>{" "}
                    <button
                          onClick={(e) =>
                            handleAlterarSituacao(localizarServidor(s.id))
                          }
                          className="btn btn-danger"
                          type="button" title="Desabilitar"
                        >
                          <i className='pi pi-lock'></i>
                    </button>{" "}
                    <button
                      onClick={() => handleShowHistoricoServidor(buscarHistoricoServidor(s.id, s.nomeCompleto))}
                      className="btn btn-success"
                      type="button" title="Histórico do Servidor"
                    >
                      <i className='pi pi-history'></i>
                    </button>
                  </>
                  :
                    <>
                      <button
                        onClick={(e) =>
                          handleAlterarSituacao(localizarServidor(s.id))
                        }
                        className="btn btn-primary"
                        type="button" title="Habilitar"
                      >
                        <i className='pi pi-unlock'></i>
                      </button>{" "}
                      <button title='Copiar para novo cadastro' className="btn btn-primary">
                          <Link style={{ textDecoration: 'none', color: '#FFFFFF' }} to={`/servidor/edit/${s.id}`} >
                              <i className='pi pi-copy'></i>
                          </Link>
                      </button>{" "}
                      <button
                      onClick={() => handleShowHistoricoServidor(buscarHistoricoServidor(s.id, s.nomeCompleto))}
                      className="btn btn-success"
                      type="button" title="Histórico do Servidor"
                      >
                        <i className='pi pi-history'></i>
                      </button>
                    
                    </>
                
                }
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
          {servidores.length > 0 && 
      <ReactPaginate
        previousLabel={"Voltar"}
        nextLabel={"Avançar"}
        breakLabel={"..."}
        pageCount={numeroDePaginas}
        marginPagesDisplayed={3}
        pageRangeDisplayed={2}
        onPageChange={handlePageClick}
        containerClassName={"pagination justify-content-center"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
        initialPage={pesquisou}
      />}
      
      <Modal
        show={showModalAlterarSituacao}
        onHide={handleCloseAlterarSituacao}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{servidorAtualizarSituacao.situacao === "INATIVO" ? "Habilitar Servidor" : "Desabilitar Servidor"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza que deseja {servidorAtualizarSituacao.situacao === "INATIVO" ? "habilitar" : "desabilitar"} o servidor <br /> 
          <b>{servidorAtualizarSituacao.nomeCompleto}</b> ?
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            type="button"
            onClick={handleCloseAlterarSituacao}
          >
            Não
          </button>{" "}
          {servidorAtualizarSituacao.situacao === "INATIVO" ?
          <button
            onClick={() => servidorHabilitar(servidorAtualizarSituacao.id)}
            className="btn btn-danger"
            type="button"
          >
            Sim
          </button>
          :
          <button
            onClick={() => servidorDesabilitar(servidorAtualizarSituacao.id)}
            className="btn btn-danger"
            type="button"
          >
            Sim
          </button>


          }
        </Modal.Footer>
      </Modal>
	
	  <Modal show={showModalHistoricoServidor} onHide={handleCloseHistoricoServidor} backdrop="static" keyboard={false} size="lg">
			
			<Modal.Body>
				<Card title="Histórico do Servidor">
            <h3 style={{color: 'gray'}}>{nomeExibicao}</h3>
            <Table id="tabela_lancamentos_faltas" striped bordered hover size="md">
                <thead>
                    <tr>
                        <th>Ano</th>
                        <th>Mês</th>
                        <th>Unidade</th>
                        <th>Turno</th>
                    </tr>
                </thead>
                <tbody>
                    {lancamentosServidor.map((ls) => {
                        return (
                            <tr key={ls.id}>
                                <td>{ls.anoLancamento}</td>
                                <td>{ls.mesLancamento}</td>
                                <td>{ls.unidade.nome}</td>
                                <td>{ls.turno}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </Card>
			</Modal.Body>
			<Modal.Footer>
				<Button className="btn btn-secondary" onClick={handleCloseHistoricoServidor}>
					Fechar
				</Button>
			</Modal.Footer>
		</Modal>
    </div>
  );
}

export default ListarServidores;

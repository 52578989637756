import ApiService from "../app/apiservice";

class datasSubPrestadorService extends ApiService {

    constructor(){
        super('/dadossubprestador')
    }

    salvar(dados){
        return this.post('/cadastrar', dados)
    }

    atualizarDatas(id, dadosAtualizar){
        return this.put(`/atualizar/${id}`, dadosAtualizar)
    }

    listarPorIdLancamento(id){
        return this.get(`/lancamento/${id}`)
    }

    excluir(id){
        return this.delete(`/excluir/${id}`)
    }

    editarData(id){
        return this.get(`/${id}`)
    }

    listarPorServidorMotivo(id){
        return this.get(`/listarporsm/${id}`)
    }

}

export default datasSubPrestadorService
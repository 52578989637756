import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom'
import InputMask from "react-input-mask";
import Form from 'react-bootstrap/Form'
import { Row, Col, Container, OverlayTrigger, Popover } from 'react-bootstrap';
import Card from '../../components/card';
import EstagiarioService from '../../service/estagiarioService';
import { mensagemErro, mensagemSucesso } from '../../components/avisos/toastr';

function CadastrarEstagiario() {

    const history = useHistory();
    const { id } = useParams();

    const initialValue = {
        id: null,
        nome: '',
        sexo: '',
        cargaHoraria: '',
        cpf: '',
        pis: '',
        rg: '',
        email: '',
        ch: [],
        telefone: '',
        status: true,
        matricula: 0,
        dataAdmissao: '',
        instituicaoEnsino: '',
        nivelEscolaridade: '',
        matriculaDefinitiva: false
    }

    const [values, setValues] = useState(initialValue);

    function onChange(e) {
        const {name, value} = e.target;
        setValues({...values, [name]: value})
    }

    const [isCheckedMatricula, setIsCheckedMatricula] = useState(false);
    const textoMatProvisoria = 'Servidor com matrícula provisória.'

    const handleOnCheckedMatricula = () => {
    setIsCheckedMatricula(!isCheckedMatricula);
    }


    const service = new EstagiarioService();

    useEffect(() => {
        if(id){
            service.obterPorId(id)
                .then( response => {
                    if (response.data.status) {
                        setValues(response.data)
                    } else if (!response.data.status) {
                        setValues({...values, id: null, matricula: 0, 
                                            nome: response.data.nome,
                                            sexo: response.data.sexo,
                                            cpf: response.data.cpf,
                                            rg: response.data.rg,
                                            pis: response.data.pis,
                                            telefone: response.data.telefone,
                                            email: response.data.email,
                                            instituicaoEnsino: response.data.instituicaoEnsino,
                                            nivelEscolaridade: response.data.nivelEscolaridade,
                                            matriculaDefinitiva: response.data.matriculaDefinitiva})
                    }
                    setIsCheckedMatricula(response.data.matriculaDefinitiva);
                })
        }
    }, []);
    

    const cadastrar = () => {

        if(values.dataAdmissao !== null){
            if(checarData(values.dataAdmissao) === false){
                mensagemErro('Data de admissão tem o formato inválido.');
                return;
            }
        }

        try {
            service.validar(values)
        } catch (erro) {
            const mensagens = erro.mensagens;
            mensagens.forEach(msg => mensagemErro(msg));
                return false;
        } 
        values.cpf = RetiraMascara(values.cpf)
        values.pis = RetiraMascara(values.pis)
        values.telefone = RetiraMascara(values.telefone)
        values.matriculaDefinitiva = isCheckedMatricula;
        service.salvar(values)
            .then(() => {
                mensagemSucesso('Estagiário salvo com sucesso.')
                    history.push('/estagiario/ListarEstagiarios')
            })
            .catch((e) => {
                mensagemErro(e.response.data)
            })
            .catch( error => {
                try {
                    service.validarBack(values, error)
                } catch (error) {
                    const msgs = error.mensagens;
                    msgs.forEach(msg => mensagemErro(msg));
                    return false;
                }  
            })
            
    }

    const atualizar = () => {

        if(values.dataAdmissao !== null){
            if(checarData(values.dataAdmissao) === false){
                mensagemErro('Data de admissão tem o formato inválido.');
                return;
            }
        }
        
        try {
            service.validar(values)
        } catch (erro) {
            const mensagens = erro.mensagens;
            mensagens.forEach(msg => mensagemErro(msg));
                return false;
        }
        values.cpf = RetiraMascara(values.cpf)
        values.pis = RetiraMascara(values.pis)
        values.telefone = RetiraMascara(values.telefone)
        values.matriculaDefinitiva = isCheckedMatricula;
        service.atualizar(values)
            .then(() => {
                mensagemSucesso('Estagiário atualizado com sucesso.')
                    history.push('/estagiario/ListarEstagiarios')
            })
            .catch((e) => {
                mensagemErro(e.response.data)
            })
    }

    const limparDados = () => {
        setValues({
            id: null,
            nome: '',
            sexo: '',
            cargaHoraria: '',
            cpf: '',
            pis: '',
            rg: '',
            email: '',
            ch: [],
            telefone: '',
            status: true,
            matricula: 0,
            instituicaoEnsino: '',
            nivelEscolaridade: '',
        })
    }

    function checarData(data) {
        let dataAno = new Date(data);
        if(data !== ""){
            if(dataAno.getFullYear().toString().length === 4){
                return true;
            }else{
                return false;
            }
        }
    }

    const cancelar = () => {

        limparDados();
            history.push('/estagiario/ListarEstagiarios')
        }
    
        function RetiraMascara(Obj) {
            return Obj.replace(/\D/g, '');
          }
    

        return (
            <Container className="mt-4">
                <div className="form-group">
                    <Card title="Cadastrar/Editar Estagiários">
                        <Form>
                            {!isCheckedMatricula &&
                            <span style={{color: 'red'}}>{textoMatProvisoria}</span>
                            }
                            <Row>
                                <Col md={2}>
                                    <label className="col-form-label" htmlFor="matricula">Matrícula:</label>
                                    <input type="number" 
                                        className="form-control" 
                                        placeholder="Matrícula" 
                                        id="matricula" 
                                        name="matricula"
                                        /* disabled={id > 0 && values.id > 0 ? true : false} */
                                        value={values.matricula} 
                                        onChange={onChange} />
                                </Col>
                                <Col md={2}>
                                    <label style={{fontWeight: 500}} className="col-form-label" htmlFor="matriculaDefiniva">Matrícula Definitiva?</label><br/>
                                    <input type="checkbox"
                                        style={{width: 70, height: 36}} 
                                        id="matriculaDefiniva" 
                                        name="matriculaDefiniva"
                                        value={values.matriculaDefinitiva}
                                        checked={isCheckedMatricula}
                                        onChange={handleOnCheckedMatricula}
                                    /> 

                                    {['top'].map((placement) => (
                                    <OverlayTrigger trigger="click"
                                        key={placement}
                                        placement={placement}
                                        overlay={
                                        <Popover id={`popover-positioned-${placement}`}>
                                            <Popover.Header as="h3">Atenção</Popover.Header>
                                            <Popover.Body>
                                            <strong>Esse campo só deve ser marcado, se a matrícula inserida for a definitiva, fornecida pelo RH Geral.</strong>
                                            </Popover.Body>
                                        </Popover>
                                        }
                                    >

                                    <i className="pi pi-question-circle" style={{fontSize: '2rem'}}>
                                        
                                    </i>
                                    </OverlayTrigger>
                                    ))}

                                </Col>
                                <Col md={4}>
                                    <label className="col-form-label" htmlFor="nome">Nome:</label>
                                    <input type="text" 
                                        className="form-control" 
                                        placeholder="Nome" 
                                        id="nome" 
                                        name="nome" 
                                        value={values.nome} 
                                        onChange={onChange} />
                                </Col>
                                <Col md={2}>
                                    <label className="col-form-label" htmlFor="dataAdmissao">Data de Admissão:</label>
                                    <input id="dataAdmissao"
                                        name="dataAdmissao"
                                        type="date"
                                        className="form-control"
                                        value={values.dataAdmissao}
                                        onChange={onChange}/>
                                </Col>
                                <Col md={2}>
                                    <label className="col-form-label" htmlFor="cargaHoraria">Carga Horária:</label>
                                        <Form.Select
                                            d="cargaHoraria" 
                                            name="cargaHoraria"
                                            value={values.cargaHoraria} 
                                            onChange={onChange}
                                        >
                                            <option value="">Selecione</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                        </Form.Select>
                                </Col> 
                            </Row>
                            <Row>
                            <Col md={2}>
                                <label className="col-form-label" htmlFor="sexo">
                                    Sexo:
                                </label>
                                <Form.Select
                                    defaultValue="Selecione"
                                    id="sexo"
                                    name="sexo"
                                    value={values.sexo} 
                                    onChange={onChange}
                                >
                                    <option value="">Selecione</option>
                                    <option value="M">Masculino</option>
                                    <option value="F">Feminino</option>
                                </Form.Select>
                            </Col>
                                <Col md={3}>
                                    <label className="col-form-label" htmlFor="cpf">CPF:</label>
                                    <InputMask
                                        mask="999.999.999-99"
                                        type="text" 
                                        className="form-control" 
                                        placeholder="CPF" 
                                        id="cpf" 
                                        name="cpf"
                                        value={values.cpf} 
                                        onChange={onChange} />
                                </Col>
                                <Col md={4}>
                                    <label className="col-form-label" htmlFor="rg">RG/Orgão Emissor/UF:</label>
                                    <input type="text"
                                        className="form-control" 
                                        placeholder="Ex.: 0000000 SSP UF" 
                                        id="rg" 
                                        name="rg"
                                        value={values.rg} 
                                        onChange={onChange} />
                                </Col>
                                <Col md={3}>
                                    <label className="col-form-label" htmlFor="pis">PIS:</label>
                                    <InputMask
                                        mask="999.99999.99-9"
                                        type="text" 
                                        className="form-control" 
                                        placeholder="PIS" 
                                        id="pis" 
                                        name="pis"
                                        value={values.pis} 
                                        onChange={onChange} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={5}>
                                    <label className="col-form-label" htmlFor="telefone">Telefone:</label>
                                    <InputMask
                                        mask="(99) 9 9999-9999"
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Telefone para contato" 
                                        id="telefone" 
                                        name="telefone"
                                        value={values.telefone} 
                                        onChange={onChange} />
                                </Col>
                                <Col md={7}>
                                    <label className="col-form-label" htmlFor="email">Email:</label>
                                    <input type="text" 
                                        className="form-control" 
                                        placeholder="Email" 
                                        id="email" 
                                        name="email" 
                                        value={values.email} 
                                        onChange={onChange}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={9}>
                                    <label className="col-form-label" htmlFor="instituicaoEnsino">Instituição de Ensino que Estuda:</label>
                                    <input
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Instituição de Ensino que está frequentando" 
                                        id="instituicaoEnsino" 
                                        name="instituicaoEnsino"
                                        value={values.instituicaoEnsino} 
                                        onChange={onChange} />
                                </Col>
                                <Col md={3}>
                                    <label className="col-form-label" htmlFor="nivelEscolaridade">Nível Escolaridade:</label>
                                    <Form.Select
                                        className="form-control" 
                                        id="nivelEscolaridade" 
                                        name="nivelEscolaridade" 
                                        value={values.nivelEscolaridade} 
                                        onChange={onChange}>
                                            <option value="">Selecione</option>
                                            <option value="TECNICO">Técnico</option>
                                            <option value="MEDIO">Médio</option>
                                            <option value="SUPERIOR">Superior</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                                <br />
                            <Row>
                                <Col md={1}>
                                    {values.id > 0 ?
                                        <button 
                                            type="button" 
                                            onClick={atualizar} 
                                            className="btn btn-primary"> Atualizar 
                                        </button> :

                                        <button 
                                            type="button" 
                                            onClick={cadastrar} 
                                            className="btn btn-primary"> Salvar 
                                        </button>
                                    }
                                </Col>

                                <Col md={1}>
                                    <button type="button" onClick={cancelar} className="btn btn-danger"> Cancelar </button>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </div>
            </Container>
        );
    }

export default CadastrarEstagiario;
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row, Tab, Table, Tabs } from "react-bootstrap"
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import ReactPaginate from "react-paginate";
import { mensagemErro, mensagemSucesso } from "../../components/avisos/toastr";
import Card from "../../components/card";
import { descriptografar } from "../../components/criptografar";
import AnoMes from "../../components/mesAno/anoMes";
import FuncaoService from "../../service/funcaoService";
import LancamentoTerceirizadoService from "../../service/lancamentoTerceirizadoService";
import LocalStorageService from "../../service/localStorageService";
import ParametrosStatusLancamentoService from "../../service/parametrosStatusLancamentoService";
import calendarioPersonalizado from '../../components/calendario/calendariomulti';
import TerceirizadoService from "../../service/terceirizadoService";
import FaltasSubsTerceirizadosService from "../../service/faltasSubsTerceirizadosService";
import AnoService from "../../service/anoService";


function ListarLancamentosTerceirizados() {

    const lancamentoTerceirizado = {
        id: null,
        terceirizado: null,
        unidade: null,
        funcao: null,
        mesLancamento: '',
        anoLancamento: '',
        turno: '',
        cargaHoraria: 0,
        dataInicioUnidade: null,
        dataDesligamentoUnidade: null,
        statusLancamento: true
    };

    const [lancamento, setLancamento] = useState(lancamentoTerceirizado);
    const [lancamentos, setLancamentosTerceirizados] = useState([]);

    const dataFaltaSubstituicao = {
        id: null,
        lancamentoTerceirizado: null,
        terceirizadoSubstituido: null,
        funcaoSubstituido: null,
        dataEvento: null,
        tipoOcorrencia: '',
        mesLancamento: '',
        anoLancamento: '',
        listaDataSubstituicao: []
    }

    const [dataFaltaSub, setDataFaltaSub] = useState(dataFaltaSubstituicao);
    const [dataFaltaListaSub, setDataFaltaListaSub] = useState([]);
    const [listaDataFaltaSub, setListaDataFaltaSub] = useState([]);
    const [dataSub, setDataSub] = useState();

    const [terceirizados, setTerceirizados] = useState([]);
    const [funcoes, setFuncoes] = useState([]);
    const [listaTerceirizadosSub, setListaTerceirizadosSub] = useState([]);
    const [listaFuncoesSub, setListaFuncoesSub] = useState([]);

    function onChangeLancamento(e) {
        const { name, value } = e.target;
        setLancamento({...lancamento, [name]: value })
    }

    const [key, setKey] = useState('substituicao');
    const [imputId, setImputId] = useState();
    const [idTerceirizado, setIdTerceirizado] = useState();
    const [idFuncao, setIdFuncao] = useState();
    const [idFuncaoSub, setIdFuncaoSub] = useState();
    const [idLancamentoTerceirizado, setIdLancamentoTerceirizado] = useState(0);
    const [imputIdDataFalta, setImputIdDataFalta] = useState();

    const unidadeCriptografada = LocalStorageService.obterItem('3');
    let idUnidade = descriptografar(unidadeCriptografada);
    const dataCriptografada = LocalStorageService.obterItem('1');
    let dataAtual = new Date(descriptografar(dataCriptografada));

    const [mes, setMes] = useState(dataAtual.getUTCMonth() + 1);
    const [ano, setAno] = useState(dataAtual.getUTCFullYear());

    //modal vincular terceirizado
    const [showModalTerceirizado, setShowModalTerceirizado] = useState(false);
    const handleCloseModalTerceirizado = () => setShowModalTerceirizado(false);
    const handleShowModalTerceirizado = () => setShowModalTerceirizado(true);

    //MODAL PARA CONFIRMAR A REPLICAÇÃO DE LANCAMENTOS
     const [showConfReplica, setShowConfReplica] = useState(false);
     const handleConfReplicaClose = () => setShowConfReplica(false);
     const handleConfReplicaShow = () => setShowConfReplica(true);

    //Para verificar se o botão 'REPLICAR' dever ser exibido ou não.
    //const [mostrarBtnReplicar, setMostrarBtnReplicar] = useState(false);
    const [countLancamentosMesSeguinte, setCountLancamentosMesSeguinte] = useState(0);
    const [lancamentoEstaAberto, setLancamentoEstaAberto] = useState(true);

    //Modal de exclusão
    const [showConf, setShowConf] = useState(false);
    const handleConfclose = () => setShowConf(false);
    const handleConfShow = () => setShowConf(true);

    //Modal de edição
    const [showEditConf, setShowEditConf] = useState(false);
    const handleEditClose = () => setShowEditConf(false)
    const handleEditShow = () => setShowEditConf(true)

    //Modal de lançamentos
    const [showLanc, setShowLanc] = useState(false);
    const handleLancClose = () => setShowLanc(false);
    const handleLancShow = () => setShowLanc(true);

    //MODAL PARA CONFIRMAR EXCLUSÃO DAS SUBSTITUIÇÕES
    const [showConfDataSub, setShowConfDataSub] = useState(false);
    const handleConfDataSubclose = () => setShowConfDataSub(false);
    const handleConfDataSubShow = () => setShowConfDataSub(true);

    //Paginação de Terceirizados
    const [paginaAtual, setPaginaAtual] = useState(0);
    const [nomeBuscar, setNomeBuscar] = useState("");
    const [numeroDePaginas, setNumeroDePaginas] = useState(0);

    const pesquisou = () => setPaginaAtual(0);

    //Para atualizar a lista de datas e faltas no botão de excluir.
    const [isRemoved, setIsRemoved] = useState(false);
    //Para atualizar a lista de datas e faltas no botão de salvar.
    const [salvou, setSalvou] = useState(false);

    const service = new LancamentoTerceirizadoService();
    const serviceFaltaSubTerceirizado = new FaltasSubsTerceirizadosService();
    const serviceTerceirizados = new TerceirizadoService();
    const serviceFuncao = new FuncaoService();
    const serviceParametrosStatus = new ParametrosStatusLancamentoService();
    const serviceAno = new AnoService();

    useEffect(() => {
        async function loadLancamentos() {
            const response = await service.listarPorUnidAnoMes(idUnidade, ano, mes);
            setLancamentosTerceirizados(response.data);
        }
        loadLancamentos();

        async function listarFuncoes() {
            const response = await serviceFuncao.listar();
            setFuncoes(response.data);
        }
        listarFuncoes();

        async function loadTerceirizadosBusca() {
            const response = await serviceTerceirizados.listarTodos(`/terceirizadopornome/${nomeBuscar}/page/${paginaAtual}`);
            //setTotalCooperados(response.data.totalElements);
            setTerceirizados(response.data.content);
            setNumeroDePaginas(response.data.totalPages);

            const arrayPaginas = [];
            //cria um array de paginas na quantidade vinda do backend
            for (let p = 1; p <= numeroDePaginas; p++) {
              arrayPaginas.push(p);
            }
            //setTotalPaginas(arrayPaginas);
        }

        async function loadTerceirizados() {
            const response = await serviceTerceirizados.listarTodos(`/pag/${paginaAtual}`);
            //setTotalCooperados(response.data.totalElements);
            setTerceirizados(response.data.content);
            setNumeroDePaginas(response.data.totalPages);

            const arrayPaginas = [];
            //cria um array de paginas na quantidade vinda do backend
            for (let p = 1; p <= numeroDePaginas; p++) {
              arrayPaginas.push(p);
            }
            //setTotalPaginas(arrayPaginas);
        }
        if (nomeBuscar === "") {
            loadTerceirizados();
          } else {
            loadTerceirizadosBusca();
          }

    }, [mes, ano, paginaAtual, nomeBuscar, showModalTerceirizado, showConf, showEditConf]);


    useEffect(() => {

        async function carregarTerceirizadosSub(){
            const response = await serviceTerceirizados.listar();
            setListaTerceirizadosSub(response.data);
        }
        carregarTerceirizadosSub();

        async function carregarFuncoes() {
            const response = await serviceFuncao.listarAtivos();
            setListaFuncoesSub(response.data);
        }
        carregarFuncoes();

        async function listarDataSubs() {
            const response = await serviceFaltaSubTerceirizado.buscarFaltasSubssPorLancamentoTerceirizado(idLancamentoTerceirizado);
            setListaDataFaltaSub(response.data);
        }
        listarDataSubs();

    }, [showLanc, salvou, isRemoved])


     //ESCONDER O BOTÃO DE REPLICAR
    useEffect(() => {
        const flagLancamentoTerceirizadoDia = 'lancamentoTerceirizadoDia';

        //CHECAGEM DOS STATUS DO PARAMETRO DO LANCAMENTOS PARA ESCONDER OS BOTOES REPLICAR E VINCULAR.
        async function verificarParametroStatusLancamento() {
            const response = await serviceParametrosStatus.verificarParametroStatusLancamento(idUnidade, ano, mes, flagLancamentoTerceirizadoDia);
            setLancamentoEstaAberto(response.data);
        }
        verificarParametroStatusLancamento();

        if(Number(mes) !== 12){
           /*  const date = Date.now();
            const today = new Date(date);

            //Mês Atual
            let mesAtual = (today.getMonth() + 1); */

            //Mês Informado
            let mesFormatado = parseInt(mes)
            let mesSeguinte = mesFormatado + 1;

            //fazer um count dos lancamentos do mes seguinte, se tiver, ocultar o botão "Replicar"
            async function buscarLancamentosMesSeguinte(){
                const response = await service.countLancamentosMesSeguinte(idUnidade, ano, mesSeguinte);
                setCountLancamentosMesSeguinte(response.data);
            }
            buscarLancamentosMesSeguinte();

           


       /*      if(mesAtual === parseInt(mes)){
                setMostrarBtnReplicar(true);
            }else{
                setMostrarBtnReplicar(false);
            } */

        }else{
            let mesAnoSeguinte = 1;
            let anoContagem = ano + 1;

            //fazer um count dos lancamentos do mes seguinte, se tiver, ocultar o botão "Replicar"
            async function buscarLancamentosMesSeguinte(){
                const response = await service.countLancamentosMesSeguinte(idUnidade, anoContagem, mesAnoSeguinte);
                setCountLancamentosMesSeguinte(response.data);
            }
            buscarLancamentosMesSeguinte();
        }

    }, [countLancamentosMesSeguinte, mes, showConfReplica]);

    const vincularTerceirizado = (id) => {
        lancamentoTerceirizado['terceirizado'] = {id};
        lancamentoTerceirizado['unidade'] = {id: Number.parseInt(idUnidade)};
        lancamentoTerceirizado['mesLancamento'] = mes;
        lancamentoTerceirizado['anoLancamento'] = ano;
        lancamentoTerceirizado['statusLancamento'] = true;

        service.salvar(lancamentoTerceirizado)
        .then(() => {
            mensagemSucesso("Cooperado vinculado com sucesso.");
        }) 
        setTimeout(() => {
            window.location.reload()
        },1000)
    
        handleCloseModalTerceirizado();
    }

    function handlePageClick(data) {
        setPaginaAtual(data.selected);
    }

    function mascaraCpf(c){
        return c.match(/.{1,3}/g).join(".").replace(/\.(?=[^.]*$)/,"-")
    }

    //EXCLUINDO O LANÇAMENTO
    const deletar = (id) => {
        service.deletar(id).then(() => {
            mensagemSucesso('Lançamento deletado com sucesso.')
            handleConfclose()
        }).catch( error => {
            mensagemErro(error.data);
        })
    }

    //ABRINDO MODAL DE EDIÇÃO DO LANÇAMENTO
    const abrirEditModal = (id) => {
        limparDados();
        handleEditShow();

        if(id){
            service.obterPorId(id)
            .then((response) => {
                if(response.data.funcao){
                    setIdFuncao(response.data.funcao.id);
                }
                setLancamento(response.data)
            })
        }
    }

    const atualizar = () => {
        if(!idFuncao){
                mensagemErro("A função é obrigatória.")
                return;
        }else{
            lancamento['funcao'] = {id: Number.parseInt(idFuncao)};
        }
        service.atualizar(lancamento)
            .then(() => {
                mensagemSucesso('Lancamento atualizado com sucesso.')
                handleEditClose();
            })

    }

    const limparDados = () => {
        setLancamento({
            id: null,
            terceirizado: null,
            unidade: null,
            mesLancamento: '',
            anoLancamento: '',
            turno: '',
            dataInicioUnidade: null,
            dataDesligamentoUnidade: null
        })
        setIdFuncao(null);

        handleEditClose();
    }

    const replicar = () => {

        serviceAno.anoExiste(Number(ano) +1).then((response) => {
            if(response.data === true){
                service.replicarLancamentos(idUnidade, ano, mes)
                .then(() => {
                    mensagemSucesso("A replicação foi um sucesso!");
                    handleConfReplicaClose();
                })
            }else{
                mensagemErro("Ano seguinte ainda não foi criado no sistema. Solicite ao RH.")
            }
        })
    }

    //FUNÇÃO PARA FORMATAR A EXIBIDAÇÃO DA DATA
    const formatarData = (dataF) => {
        if(dataF){
            var data = new Date(dataF),
            dia  = data.getUTCDate().toString().padStart(2, '0'),
            mes  = (data.getUTCMonth()+1).toString().padStart(2, '0'),
            ano  = data.getUTCFullYear();

            return dia+"/"+mes+"/"+ano;
        }
    }

    const abrirModalLancamento = (id, funcao) => {

        if(!funcao){
            mensagemErro("Atualize as informações para fazer lançamentos");
            return;
        }
        limparFaltaSubTerceirizado();
        handleLancShow();
        setIdLancamentoTerceirizado(id);

        if(id){
            service.obterPorId(id)
            .then((response) => {
                setLancamento(response.data);
            })
        }
    }

    const closeModalLanc = () => {
        handleLancClose();
    }

    const salvarDataFaltaTerceirizado = () => {
        if(dataFaltaListaSub.length < 1){
            mensagemErro("A data da subsituição é obrigatória.");
        }else{
            dataFaltaSub.listaDataSubstituicao = dataFaltaListaSub.toString().split(",");
            dataFaltaSub.lancamentoTerceirizado = {id: Number.parseInt(lancamento.id)};
            dataFaltaSub.terceirizadoSubstituido = {id: Number.parseInt(idTerceirizado)};
            dataFaltaSub.funcaoSubstituido = {id: Number.parseInt(idFuncaoSub)};
            dataFaltaSub.anoLancamento = ano.toString();
            dataFaltaSub.mesLancamento = mes.toString();

            serviceFaltaSubTerceirizado.salvar(dataFaltaSub)
                .then((response) => {
                    if(response.data === true){
                        setSalvou(true);
                        mensagemSucesso('Lançamento salvo com sucesso.');
                        limparFaltaSubTerceirizado();
                    }else{
                        mensagemErro("Não foi possível inserir o registro.");
                    }
            }).catch((error) => {
                mensagemErro(error.response.data.mensagem);
            })
        }
    }

    const limparFaltaSubTerceirizado = () => {
        setDataFaltaSub({
            id: null,
            lancamentoTerceirizado: null,
            terceirizadoSubstituido: null,
            funcaoSubstituido: null,
            dataEvento: null,
            tipoOcorrencia: '',
            mesLancamento: '',
            anoLancamento: ''
        })
            dataFaltaListaSub.length = 0;
            listaTerceirizadosSub.length = 0;
            listaFuncoesSub.length = 0;
            setIdTerceirizado(null);
            setIdFuncaoSub(null);
            setDataSub(null);
    }

    const editarDataSub = (id) => {
        
        if(id){
            serviceFaltaSubTerceirizado.obterPorId(id).then((response) => {
                setIdFuncaoSub(response.data.funcaoSubstituido.id);
                setIdTerceirizado(response.data.terceirizadoSubstituido.id);
                setDataSub(response.data.dataEvento);

                setDataFaltaSub({
                    id: response.data.id,
                    lancamentoTerceirizado: lancamento,
                    terceirizadoSubstituido: idTerceirizado,
                    funcaoSubstituido: idFuncao,
                    dataEvento: dataSub
                })
            })
        }
    }

    const atualizarDataFalta = () => {
        if(!dataSub){
            mensagemErro("A data da subsituição é obrigatória.");
        }else if(!idTerceirizado){
            mensagemErro("O terceirizado é obrigatório.");
        }else if(!idFuncaoSub){
            mensagemErro("A função é obrigatória.");
        }else{
            dataFaltaSub.dataEvento = dataSub;
            dataFaltaSub.terceirizadoSubstituido = {id: Number.parseInt(idTerceirizado)};
            dataFaltaSub.funcaoSubstituido = {id: Number.parseInt(idFuncaoSub)};

            serviceFaltaSubTerceirizado.atualizar(dataFaltaSub)
                .then((response) => {
                    if(response.data === true){
                        setSalvou(true);
                        mensagemSucesso('Lançamento salvo com sucesso.');
                        limparFaltaSubTerceirizado();
                    }else{
                        mensagemErro("Não foi possível inserir o registro.");
                    }
            }).catch((error) => {
                mensagemErro(error.response.data.mensagem);
            })
        }
    }

    const excluirDatasFaltas = (id) => {
        serviceFaltaSubTerceirizado.deletar(id)
        .then(() => {
                setIsRemoved(true);
                mensagemSucesso('Registro deletado com sucesso.')
                handleConfDataSubclose()
            
        }).catch( error => {
            mensagemErro(error.data);
        })
        setIsRemoved(false);
    }

    return(
        <>
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs lg="4">
                        <AnoMes
                            htmlForMes="mesLancamento"
                            idMes="mesLancamento"
                            nameMes="mesLancamento"
                            valueMes={mes}
                            onChangeMes={(e) => setMes(e.target.value)}
                            htmlForAno="anoLancamento"
                            idAno="anoLancamento"
                            nameAno="anoLancamento"
                            valueAno={ano}
                            onChangeAno={(e) => setAno(e.target.value)}
                        />
                    </Col>
                    <Col lg={1} style={{ marginTop: "37px"}}>
                        {(lancamentoEstaAberto) &&
                            <button className="btn btn-primary" type="button" onClick={handleShowModalTerceirizado}>
                                Vincular
                            </button>
                        }
                    </Col>

                    <Col lg={2} style={{marginTop: "37px"}}>
                        {(countLancamentosMesSeguinte < 1 && lancamentos.length > 0) &&
                            <button type='button' className='btn btn-primary' onClick={handleConfReplicaShow}>
                                Replicar
                            </button>
                        }
                    </Col>
                </Row>

                <Modal show={showModalTerceirizado} onHide={handleCloseModalTerceirizado} backdrop="static" keyboard={false} size="lg" >
                    <Modal.Header>
                        <Modal.Title id="lancamentoModal">
                            <h2>Terceirizados</h2>
                        </Modal.Title>

                        <Col md={2} style={{ float: "left" }}>
                            <button className="btn btn-danger" onClick={handleCloseModalTerceirizado}>
                                Fechar
                            </button>
                        </Col>
                    </Modal.Header>

                    <Modal.Body>
                        <Card title="Listagem de Terceirizados"> 
                            <br></br>
                            <div >
                                <Row>
                                    <Col md={6}>
                                        <input
                                            id="nomeBuscar"
                                            name="nomeBuscar"
                                            type="text"
                                            className="form-control"
                                            placeholder="Digite o nome para pesquisar..."
                                            value={nomeBuscar}
                                            onChange={(buscar) => setNomeBuscar(buscar.target.value)}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <br></br>
                            <div>
                                <table id="tabela_cooperados" className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th> Nome </th>
                                            <th>CPF</th>
                                            <th style={{textAlign: "center", width: "40px"}}>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {terceirizados.map((t) => {
                                            const cpf = mascaraCpf(t.cpf)
                                            return (
                                                <tr key={t.id}>
                                                    <td>{t.nome}</td>
                                                    <td>{cpf}</td>
                                                    <td style={{textAlign: "center"}}>
                                                        <button 
                                                            className="btn btn-primary" 
                                                            type="button" 
                                                            onClick={() => vincularTerceirizado(t.id)}> Vincular </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                {terceirizados.length > 0 &&
                                    <ReactPaginate
                                        previousLabel={"Voltar"}
                                        nextLabel={"Avançar"}
                                        breakLabel={"..."}
                                        pageCount={numeroDePaginas}
                                        marginPagesDisplayed={3}
                                        pageRangeDisplayed={2}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination justify-content-center"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                        initialPage={pesquisou}
                                    />
                                }
                            </div>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        Pesquise o cooperado e vincule a sua unidade para informar os lançamentos.
                    </Modal.Footer>
                </Modal>
            </Container>

            <Container className="mt-4">
                <Card title="Lista de Lançamentos de Terceirizados">
                    <Table id="tabela_lancamentos" striped bordered hover size="md">
                        <thead>
                            {lancamentos.length > 0 ?
                                <tr>
                                    <th>##</th>
                                    <th>Nome</th>
                                    <th>Início na Unidade</th>
                                    <th>Função</th>
                                    <th>Turno</th>
                                    <th>Carha Horária</th>
                                </tr>
                                :
                                <>
                                </>
                            }
                        </thead>
                        <tbody>
                            {lancamentos.length > 0 ?
                                <>
                                    {lancamentos.map((l, index) => {
                                        return(
                                            <tr key={l.id}>
                                                <td>{index + 1}</td>
                                                <td>{l.terceirizado.nome}</td>
                                                <td>{formatarData(l.dataInicioUnidade)}</td>
                                                <td>{l.funcao ? l.funcao.nome : ''}</td>
                                                <td>{l.turno}</td>
                                                <td>{l.cargaHoraria}</td>
                                                <td className="col-md-2" style={{textAlign: "center"}}>
                                                    {l.statusLancamento.toString() === 'false' ?
                                                        <button className="btn btn-success" disabled onClick={() => abrirModalLancamento(l.id)}>
                                                            <i className='pi pi-plus-circle'></i>
                                                        </button>
                                                        :
                                                        <button className="btn btn-success" title='Fazer lançamento' onClick={() => abrirModalLancamento(l.id, l.funcao)}>
                                                            <i className='pi pi-plus-circle'></i>
                                                        </button>
                                                    }
                                                    {l.statusLancamento.toString() === 'false' ?
                                                        <button className="btn btn-primary" style={{margin: "0 5px"}} disabled onClick={() => abrirEditModal(l.id)}>
                                                            <i className='pi pi-pencil'></i>
                                                        </button>
                                                        :
                                                        <button className="btn btn-primary" title='Editar' style={{margin: "0 5px"}} onClick={() => abrirEditModal(l.id)}>
                                                            <i className='pi pi-pencil'></i>
                                                        </button>
                                                    }
                                    
                                                    {l.statusLancamento.toString() === 'false' ?
                                                        <button className="btn btn-danger" disabled onClick={() => handleConfShow(setImputId(`${l.id}`))} >
                                                            <i className='pi pi-trash'></i>
                                                        </button>
                                                        :
                                                        <button className="btn btn-danger" title='Excluir' onClick={() => handleConfShow(setImputId(`${l.id}`))} >
                                                            <i className='pi pi-trash'></i>
                                                        </button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })} 
                                </>
                                 :
                                <>
                                    <tr>
                                        <td style={{fontSize: '1.5rem'}}>Nenhum registro encontrado.</td>
                                    </tr>
                                </>
                            }
                        </tbody>
                    </Table>
                </Card>
                 <Modal show={showConfReplica} onHide={handleConfReplicaClose} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <h3>Confirmação</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Essa ação criará uma cópia de todos os lancamentos desse mês no mês posterior. Deseja prosseguir?</h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={replicar}>
                            Confirmar
                        </Button>
                        <Button className="btn btn-secondary" onClick={handleConfReplicaClose}>
                            Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showConf} onHide={handleConfclose} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <h3>Confirmação</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Você realmente deseja excluir o registro?</h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={() => deletar(imputId)}>
                            Confirmar
                        </Button>
                        <Button className="btn btn-secondary" onClick={handleConfclose}>
                            Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showEditConf} onHide={handleEditClose} backdrop="static" keyboard={false} size="xl">
                    <Modal.Body>
                        <Container>
                            <Card title="Edições de Lançamentos">
                                <Form>
                                    <Row>
                                        <Col md={5}>
                                            <label className="col-form-label" htmlFor="terceirizado">Terceirizado:</label>
                                            <input type="text" 
                                                className="form-control" 
                                                id="terceirizado" 
                                                name="terceirizado"
                                                disabled
                                                value={!lancamento.terceirizado ? null : lancamento.terceirizado.nome}
                                                onChange={onChangeLancamento}
                                            />
                                        </Col>
                                        <Col md={5}>
                                            <label className="col-form-label" htmlFor="unidade">Unidade:</label>
                                            <input type="text" 
                                                className="form-control" 
                                                id="unidade" 
                                                name="unidade"
                                                disabled
                                                value={!lancamento.unidade ? null : lancamento.unidade.nome}
                                                onChange={onChangeLancamento}
                                            />
                                        </Col>
                                        <Col md={2}>
                                            <label className="col-form-label" htmlFor="mesLancamento">Mês/Ano:</label>
                                            <input type="text" 
                                                className="form-control" 
                                                id="mesLancamento" 
                                                name="mesLancamento"
                                                disabled
                                                value={`${lancamento.mesLancamento}/${lancamento.anoLancamento}`}
                                                onChange={onChangeLancamento} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={3}>
                                            <label className="col-form-label" htmlFor="turno">Turno:</label>
                                            <Form.Select 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Turno" 
                                                id="turno" 
                                                name="turno"
                                                value={lancamento.turno}
                                                onChange={onChangeLancamento} 
                                            >
                                                <option value="">Selecione</option>
                                                <option value="Matutino">Matutino</option>
                                                <option value="Vespertino">Vespertino</option>
                                                <option value="Noturno">Noturno</option>
                                                <option value="Integral">Integral</option>
                                            </Form.Select>
                                        </Col>
                                        
                                            <Col md={6}>
                                                <label className="col-form-label" htmlFor="funcao">Função:</label>
                                                <Form.Select
                                                    id="funcao" 
                                                    name="funcao" 
                                                    value={idFuncao} 
                                                    onChange={(e) => setIdFuncao(e.target.value)}
                                                >
                                                    <option value="">Selecione</option>
                                                    {funcoes.map((f) => (
                                                        <option value={f.id} key={f.id} > {f.nome} </option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <label className="col-form-label" htmlFor="cargaHoraria">C.H. Diária:</label>
                                            <input type="number" 
                                                className="form-control" 
                                                min={1} max={12}
                                                id="cargaHoraria"
                                                name="cargaHoraria"
                                                value={lancamento.cargaHoraria}
                                                onChange={onChangeLancamento}>
                                            </input>
                                        </Col>
                                        <Col md={3}>
                                            <label className="col-form-label" htmlFor="dataInicioUnidade">Data de Início:</label>
                                            <input type="date" 
                                                className="form-control" 
                                                id="dataInicioUnidade"
                                                name="dataInicioUnidade"
                                                value={lancamento.dataInicioUnidade}
                                                onChange={onChangeLancamento}
                                            />   
                                        </Col>
                                        <Col md={4}>
                                            <label className="col-form-label" htmlFor="dataDesligamentoUnidade">Data de Desligamento:</label>
                                            <input type="date" 
                                                className="form-control" 
                                                id="dataDesligamentoUnidade"
                                                name="dataDesligamentoUnidade"
                                                value={lancamento.dataDesligamentoUnidade}
                                                onChange={onChangeLancamento}
                                            />
                                        </Col>
                                    </Row>
                                    <br/>
                                    <div className="text-center">
                                        <button 
                                            type="button" 
                                            onClick={atualizar} 
                                            className="btn btn-primary"> Atualizar 
                                        </button> 
                                    </div>
                                </Form>
                            </Card>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={limparDados}>
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showLanc} onHide={handleLancClose} backdrop="static" keyboard={false} size="xl">
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col md={5}>
                                    <label className="col-form-label" htmlFor="terceirizado">Terceirizado:</label>
                                    <input type="text" 
                                        className="form-control" 
                                        id="terceirizado" 
                                        name="terceirizado"
                                        disabled
                                        value={!lancamento.terceirizado ? null : lancamento.terceirizado.nome}
                                     />
                                </Col>
                                <Col md={5}>
                                    <label className="col-form-label" htmlFor="unidade">Unidade:</label>
                                    <input type="text" 
                                        className="form-control" 
                                        id="unidade" 
                                        name="unidade"
                                        disabled
                                        value={!lancamento.unidade ? null : lancamento.unidade.nome}
                                     />
                                </Col>
                                <Col md={2}>
                                    <label className="col-form-label" htmlFor="mesLancamento">Mês/Ano:</label>
                                    <input type="text" 
                                        className="form-control" 
                                        id="mesLancamento" 
                                        name="mesLancamento"
                                        disabled
                                        value={`${lancamento.mesLancamento}/${lancamento.anoLancamento}`}
                                     />
                                </Col>
                            </Row>

                            <hr></hr>

                            <Card title={dataFaltaSub.id > 0 ? "Edição de Lançamentos" : "Cadastro de Lançamentos"}>
                                <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="justify-content-center">
                                    <Tab eventKey="substituicao" title="Substituições">
                                        <Form>
                                            <Row>
                                                <label className="col-form-label" htmlFor="dataFaltaListaSub">Data da Substituição:</label>
                                                {dataFaltaSub.id < 1 ?
                                                    <DatePicker
                                                        mapDays={({ date }) => {
                                                            let props = {}
                                                            let isWeekend = [0, 6].includes(date.weekDay.index)
                                                            
                                                            if (isWeekend) props.className = "highlight highlight-red"
                                                            
                                                            return props
                                                        }}
                                                        className="mb-3 form-control"
                                                        onChange={setDataFaltaListaSub}
                                                        value={dataFaltaSub.listaDataSubstituicao}
                                                        id="dataFaltaListaSub"
                                                        name="dataFaltaListaSub"
                                                        numberOfMonths={2}
                                                        multiple
                                                        sort
                                                        format="DD/MM/YYYY"
                                                        locale={calendarioPersonalizado}
                                                        plugins={[<DatePanel />]}
                                                        style={{
                                                            width: "100%",
                                                            height: "38px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                        }}
                                                    />
                                                    :
                                                    <Col xl={4} lg={6}>
                                                        <label className="col-form-label" htmlFor="dataEvento">Data da Substituição:</label>
                                                        <input 
                                                            type="date" 
                                                            className="form-control" 
                                                            id="dataEvento" 
                                                            name="dataEvento"
                                                            value={dataSub}
                                                            onChange={(e) => setDataSub(e.target.value)} />
                                                    </Col>
                                                }
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <label className="col-form-label" htmlFor="terceirizado-substituido">Terceirizado Substituído:</label>
                                                    <Form.Select 
                                                        id="terceirizado-substituido" 
                                                        name="terceirizadoSubstituido" 
                                                        value={idTerceirizado} 
                                                        onChange={(e) => setIdTerceirizado(e.target.value)}
                                                    >
                                                        <option value="">Selecione</option>
                                                        {listaTerceirizadosSub.map((t) => (
                                                            <option value={t.id} key={t.id}>
                                                                {t.nome}
                                                            </option>
                                                        ))}
                                                    </Form.Select> 
                                                </Col>
                                                <Col>
                                                    <label className="col-form-label" htmlFor="funcaoSubstituido">Função dp Substituído:</label>
                                                    <Form.Select 
                                                        id="funcaoSubstituido" 
                                                        name="funcaoSubstituido" 
                                                        value={idFuncaoSub} 
                                                        onChange={(e) => setIdFuncaoSub(e.target.value)}
                                                    >
                                                        <option value="">Selecione</option>
                                                        {listaFuncoesSub.map((f) => (
                                                            <option value={f.id} key={f.id}>
                                                                {f.nome}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Col>
                                            </Row>
                                            <div className="text-center mt-4">
                                                {dataFaltaSub.id > 0 ?
                                                    <button 
                                                        type="button" 
                                                        onClick={atualizarDataFalta} 
                                                        className="btn btn-primary"> Atualizar 
                                                    </button> 
                                                    :
                                                    <button 
                                                        type="button" 
                                                        onClick={salvarDataFaltaTerceirizado} 
                                                        className="btn btn-primary"> Salvar 
                                                    </button> 
                                                }
                                            </div>
                                        </Form> <br></br>

                                        <Card title="Listagem de Substituições">
                                            <Table id="tabela_lancamentos_faltas" striped bordered hover size="md">
                                                <thead>
                                                    <tr>
                                                        <th>Nome do Terceirizado</th>
                                                        <th>Função do Terceirizado</th>
                                                        <th>Data da Substituição</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listaDataFaltaSub.map((df) => {
                                                        return(
                                                            <tr key={df.id}>
                                                                <td>{df.terceirizadoSubstituido.nome}</td>
                                                                <td>{df.funcaoSubstituido.nome}</td>
                                                                <td>{formatarData(df.dataEvento)}</td>
                                                                <td className='col-md-2' style={{textAlign: "center"}}>
                                                                    <button className="btn btn-primary" title='Editar' type="button" style={{margin: "0 5px"}} onClick={() => editarDataSub(df.id)}>
                                                                        <i className='pi pi-pencil'></i>
                                                                    </button>   

                                                                    <button onClick={() => handleConfDataSubShow(setImputIdDataFalta(`${df.id}`))} title='Excluir' className="btn btn-danger">
                                                                        <i className='pi pi-trash'></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                        </Card>
                                    </Tab>
                                </Tabs>
                            </Card>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={closeModalLanc}>
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showConfDataSub} onHide={handleConfDataSubclose} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <h3>Confirmação</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Você realmente deseja excluir o registro?</h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={() => excluirDatasFaltas(imputIdDataFalta)}>
                            Confirmar
                        </Button>
                        <Button className="btn btn-secondary" onClick={handleConfDataSubclose}>
                            Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </>
        )
}

export default ListarLancamentosTerceirizados
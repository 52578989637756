import Button from 'react-bootstrap/Button'
import React, { useEffect, useState } from "react";
import { Container, Modal, Table } from "react-bootstrap";
import { mensagemErro, mensagemSucesso } from "../../components/avisos/toastr";
import { Link } from   'react-router-dom';
import Card from "../../components/card";
import EstagiarioService from "../../service/estagiarioService";
import ReactPaginate from "react-paginate";

import styles from '../unidade/listarUnidade.module.css'
import LancamentoEstagiarioService from '../../service/lancamentoEstagiarioService';
//import LancamentoEstagiarioService from "../../service/lancamentoEstagiarioService";

function ListarEstagiarios() {

    const [showConf, setShowConf] = useState(false);
    const handleConfclose = () => setShowConf(false);
    const handleConfShow = () => setShowConf(true);

    const [estagiario, setEstagiarios] = useState([]);
    const [estagiarioMudarStatus, setEstagiarioMudarStatus] = useState([]);

    const service = new EstagiarioService();
    const serviceLancamentoEstagiario = new LancamentoEstagiarioService();

    //Parte relacionada a paginação e busca por nome 
    //const [totalEstagiarios, setTotalEstagiarios] = useState(0);
    //const [totalPaginas, setTotalPaginas] = useState([]);
    const [paginaAtual, setPaginaAtual] = useState(0);
    const [nomeBuscar, setNomeBuscar] = useState("");
    const [numeroDePaginas, setNumeroDePaginas] = useState(0);

    const [showModalHistoricoEstagiario, setShowModalHistoricoEstagiario] = useState(false);
    const handleCloseHistoricoEstagiario = () => setShowModalHistoricoEstagiario(false);
    const handleShowHistoricoEstagiario = () => setShowModalHistoricoEstagiario(true);

    const [nomeExibicao, setNomeExibicao] = useState();
    const [lancamentosEstagiario, setLancamentosEstagiario] = useState([]);

    //implementar futuramente função para quando pesquisar setar a primeira pagina
    const pesquisou = () => setPaginaAtual(0);

    useEffect(() => {

        async function loadEstagiariosPaginacao(){
            const response = await service.buscarPorNome(`/estagiariopornome/${nomeBuscar}/page/${paginaAtual}`);
            //setTotalEstagiarios(response.data.totalElements);
            setEstagiarios(response.data.content);
            setNumeroDePaginas(response.data.totalPages);

            const arrayPaginas = [];
            //cria um array de paginas na quantidade vinda do backend
            for (let p = 1; p <= numeroDePaginas; p++) {
                arrayPaginas.push(p);
            }
            //setTotalPaginas(arrayPaginas);

        }

        async function carregarEstagiarios() {
            const response = await service.listarTodos(`/pag/${paginaAtual}`);
            //setTotalEstagiarios(response.data.totalElements);
            setEstagiarios(response.data.content);
            setNumeroDePaginas(response.data.totalPages);
        
            const arrayPaginas = [];
            //cria um array de paginas na quantidade vinda do backend
            for (let p = 1; p <= numeroDePaginas; p++) {
                arrayPaginas.push(p);
            }
            //setTotalPaginas(arrayPaginas);
        
        }

        if (nomeBuscar === "") {
            carregarEstagiarios();
        } else {
            loadEstagiariosPaginacao();
        }

    }, [paginaAtual, nomeBuscar, showConf, estagiarioMudarStatus]);

    function handlePageClick(data) {
        setPaginaAtual(data.selected);
    }

    const desabilitar = () => {
        estagiarioMudarStatus.status = false
        service.atualizar(estagiarioMudarStatus)
            .then(() => {
                mensagemSucesso('Estagiário desabilitado com sucesso.')
                handleConfclose()
            }).catch( error => {
                mensagemErro(error.response.data.mensagem);
            })
    }

    const habilitar = () => {
        estagiarioMudarStatus.status = true
        service.habilitarEstagiario(estagiarioMudarStatus)
            .then(() => {
                mensagemSucesso('Estagiário habilitado com sucesso.')
                handleConfclose()
            }).catch( (error) => {
                mensagemErro(error.response.data);
            })
    }

    function mudarStatus(idAlterar){
        service.obterPorId(idAlterar)
        .then((s) => {
            setEstagiarioMudarStatus(s.data)
        })
        .catch((e) => {
            mensagemErro(e.response)
        })
        handleConfShow()
    }

    function mascaraTelefone(e) {
        if (e.length >= 12) {
            return false;
        }
        if (e.length > 10) {
            return e.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
        } else if (e.length > 5) {
            return e.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
        } else if (e.length > 2) {
           return e.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
        } else {
          return e.replace(/^(\d*)/, "($1");
        }
  }

  function mascaraCpf(c){
    return c.match(/.{1,3}/g).join(".").replace(/\.(?=[^.]*$)/,"-")
  }

function buscarHistoricoEstagiario(id, nome){
  setNomeExibicao(nome);
	serviceLancamentoEstagiario.buscarHistoricoEstagiario(id).then((response) => {
		setLancamentosEstagiario(response.data);
    
	})
}

    /* const verificarLancamentos = (id) => {
        serviceLancamentoEstagiario.buscarLancamentoPorEstagiario(id)
            .then(response => {
                console.log('Boolean vindo do back', response.data)
                
                if(response.data === true){
                    mensagemErro('Estagiário já possui um lançamento nesse mês.');
                    return false;
                }else{
                    console.log('Ok, vamos lançar.')
                }
            })
    } */

    return(
        <Container className="mt-4">
            <Card title="Listagem de Estágiários">
                <div className={styles.divBusca}>
                    <div className="input-group "> 
                        <div>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Pesquisar pelo nome"
                                aria-label="Recipient's username"
                                aria-describedby="button-addon2"
                                value={nomeBuscar}
                                onChange={(buscar) => setNomeBuscar(buscar.target.value)}
                            />
                        </div>
                    </div>
                    <div className={styles.btnAdd} >
                        <button type="button" className="btn btn-primary">
                            <Link style={{ textDecoration: "none", color: "#ffffff" }} to="/estagiario/Estagiario">
                                Adicionar
                            </Link>{" "}
                        </button>
                    </div>
                </div>
                <Table bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Matrícula</th>
                            <th>Nome</th>
                            <th>Email</th>
                            <th>CPF</th>
                            <th>Telefone</th>
                            <th>PIS</th>
                            <th>C/H</th>
                        </tr>
                    </thead>
                    <tbody>
                        {estagiario.map((est, index) => {
                            let cpf = mascaraCpf(est.cpf)
                            let telefone = mascaraTelefone(est.telefone)
                            return(
                                <tr key={est.id} >
                                    {est.matriculaDefinitiva ?
                                        <>
                                            <td>{index+1}</td>
                                            <td>{est.matricula}</td>
                                            <td style={{maxWidth: '250px'}}>{est.nome}</td>
                                            <td style={{maxWidth: '200px'}}>{est.email}</td>
                                            <td>{cpf}</td>
                                            <td>{telefone}</td>
                                            <td>{est.pis}</td>
                                            <td>{est.cargaHoraria}</td>
                                        </>
                                            :
                                        <>
                                            <td style={{borderBottom: 'solid #F08080'}}>{index+1}</td>
                                            <td style={{borderBottom: 'solid #F08080'}}>{est.matricula}</td>
                                            <td style={{maxWidth: '250px', borderBottom: 'solid #F08080'}}>
                                                {est.nome} <br></br><span style={{color: "red", fontSize: '13px'}}>Estagiário(a) com matrícula provisória.</span>
                                            </td>
                                            <td style={{maxWidth: '200px', borderBottom: 'solid #F08080'}}>{est.email}</td>
                                            <td style={{borderBottom: 'solid #F08080'}}>{cpf}</td>
                                            <td style={{borderBottom: 'solid #F08080'}}>{telefone}</td>
                                            <td style={{borderBottom: 'solid #F08080'}}>{est.pis}</td>
                                            <td style={{borderBottom: 'solid #F08080'}}>{est.cargaHoraria}</td>
                                        </>
                                    }
                                    {est.status ?
                                        <td style={{textAlign: 'center', padding: '3px'}}> 
                                            <button className="btn btn-primary" title='Editar' style={{marginRight: '5px'}} >
                                                <Link style={{ textDecoration: 'none', color: '#FFFFFF' }} to={`/estagiario/Estagiario/${est.id}`} > 
                                                    <i className='pi pi-pencil'></i>
                                                </Link>
                                            </button>
                                        
                                            <button onClick={() => mudarStatus(est.id)} title='Desabilitar' className="btn btn-danger" style={{marginRight: '5px'}}>
                                                <i className='pi pi-lock'></i>
                                            </button>
                                            <button onClick={() => handleShowHistoricoEstagiario(buscarHistoricoEstagiario(est.id, est.nome))}
                                                className="btn btn-success"
                                                type="button" title="Histórico do Servidor"
                                            >
                                                <i className='pi pi-history'></i>
                                            </button>
                                        </td>
                                        :
                                        <td style={{textAlign: 'center', padding: '3px'}}> 
                                            <button onClick={() => mudarStatus(est.id)} title='Habilitar' className="btn btn-primary" style={{marginRight: '5px'}}>
                                                <i className='pi pi-unlock'></i>
                                            </button>
                                            <button title='Copiar para novo cadastro' className="btn btn-primary" >
                                                <Link style={{ textDecoration: 'none', color: '#FFFFFF' }} to={`/estagiario/Estagiario/${est.id}`} >
                                                    <i className='pi pi-copy'></i>
                                                </Link>
                                            </button> {" "}
                                            <button onClick={() => handleShowHistoricoEstagiario(buscarHistoricoEstagiario(est.id, est.nome))}
                                                className="btn btn-success"
                                                type="button" title="Histórico do Servidor"
                                            >
                                                <i className='pi pi-history'></i>
                                            </button>
                                        </td>
                                        }
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>

                {estagiario.length > 0 && 
                    <ReactPaginate
                        previousLabel={"Voltar"}
                        nextLabel={"Avançar"}
                        breakLabel={"..."}
                        pageCount={numeroDePaginas}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                        initialPage={pesquisou}
                    />
                }
            </Card>

            <Modal show={showConf} onHide={handleConfclose} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <h3>Confirmação</h3>
                </Modal.Header>
                <Modal.Body>
                    <h5>Você realmente deseja {estagiarioMudarStatus.status ? "desabilitar" : "habilitar"} o(a) estagiário(a) {estagiarioMudarStatus.nome}?</h5>
                </Modal.Body>
                <Modal.Footer>
                {estagiarioMudarStatus.status ?
                    <Button className="btn btn-danger" onClick={() => desabilitar()}>
                        Desabilitar
                    </Button>
                :
                    <Button className="btn btn-primary" onClick={() => habilitar()}>
                        Habilitar
                    </Button>
                }
                <Button className="btn btn-secondary" onClick={handleConfclose}>
                    Cancelar
                </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showModalHistoricoEstagiario} onHide={handleCloseHistoricoEstagiario} backdrop="static" keyboard={false} size="lg">
			
                <Modal.Body>
                    <Card title="Histórico do Estagiário">
                        <h3 style={{color: 'gray'}}>{nomeExibicao}</h3>
                        <Table id="tabela_lancamentos_faltas" striped bordered hover size="md">
                            <thead>
                                <tr>
                                    <th>Ano</th>
                                    <th>Mês</th>
                                    <th>Unidade</th>
                                    <th>Turno</th>
                                </tr>
                            </thead>
                            <tbody>
                                {lancamentosEstagiario.map((le) => {
                                    return (
                                        <tr key={le.id}>
                                            <td>{le.anoLancamento}</td>
                                            <td>{le.mesLancamento}</td>
                                            <td>{le.unidade.nome}</td>
                                            <td>{le.turnoEstagio}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-secondary" onClick={handleCloseHistoricoEstagiario}>
                        Fechar
                    </Button>
                </Modal.Footer>
		    </Modal>
        </Container>
    )
}

export default ListarEstagiarios
import React, { useState } from 'react';
import LocalStorageService from "../../service/localStorageService";
import { AuthConsumer } from "../../provedorAutenticacao";
import NavbarItem from "./navbarItem";
import UnidadeService from '../../service/unidadeService';
import { descriptografar } from '../criptografar';
const serviceU = new UnidadeService()
function BarraMenus(props) {

    const [nomeUnidade, setNomeUnidade] = useState('')
    const [unidadeDeEnsino, setUnidadeDeEnsino] = useState(null)
    const usuarioCriptografado = LocalStorageService.obterItem('2')
    const usuarioLogado =  usuarioCriptografado ? JSON.parse(descriptografar(usuarioCriptografado)) : null
    const unidadeCriptografado = LocalStorageService.obterItem('3')
    const unidadeEdicao = unidadeCriptografado ? descriptografar(unidadeCriptografado) : null
    const usuarioSemUnidade = "USUÁRIO SEM VÍNCULO"
        if(unidadeEdicao !== null){
            serviceU.obterPorId(unidadeEdicao)
                .then((e) => {
                    setNomeUnidade(e.data.nome)
                    setUnidadeDeEnsino(e.data.unidadeEscolar)
                })
                .catch(() => {
                    setNomeUnidade(usuarioSemUnidade)
                })
        }
    const rolerUser = usuarioLogado ? usuarioLogado.role : ""
    //const d = "DIRETOR"
    const r = "RH"
    const a = "ADMINISTRADOR"

    const userAuth = props.isUsuarioAutenticado;
 
    return (
        <>
            
            {(userAuth && unidadeEdicao !== null) && 
                <div>
                    <nav className="navbar navbar-dark navbar-expand-lg bg-primary">
                        <div className="container">
                            <a className="navbar-brand" href="#/home" style={{margin: '0', padding: '0'}}>
                                <img src="/img/Prefeitura_.png" alt="RH Semec" width="55px"/>
                            </a>
                            <button className="navbar-toggler" type="button" 
                                    data-bs-toggle="collapse" 
                                    data-bs-target="#navbarSupportedContent" 
                                    aria-controls="navbarSupportedContent" 
                                    aria-expanded="false" 
                                    aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
            
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    {props.isUsuarioAutenticado &&
                                        <NavbarItem render={props.isUsuarioAutenticado} className="nav-link" href="#/inicio" label="Home" /> 
                                    }
            
                                    {(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a)) &&
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownUsuarios" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Usuários
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownUsuarios">
                                                <NavbarItem render={props.isUsuarioAutenticado} className="dropdown-item" href="#/usuario/cad" label="Cadastrar" />
                                                <NavbarItem render={props.isUsuarioAutenticado} className="dropdown-item" href="#/usuario/listar" label="Listar" />
                                            </ul>
                                        </li>
                                    }
            
                                    {(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a)) &&
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownServidores" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Servidores
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownServidores">
                                                <NavbarItem render={props.isUsuarioAutenticado} className="dropdown-item" href="#/servidor/cad" label="Cadastrar" />
                                                <NavbarItem render={props.isUsuarioAutenticado} className="dropdown-item" href="#/servidor/listar" label="Listar" />
                                            </ul>
                                        </li>
                                    }

                                    {props.isUsuarioAutenticado &&
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownLancamentos" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Lançamentos
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownLancamentos">
                                                <NavbarItem render={props.isUsuarioAutenticado} className="dropdown-item" href="#/lancamentos/ListarLancamentosServidores" label="Lançamentos de Servidores" /> 
                                                <NavbarItem render={props.isUsuarioAutenticado} className="dropdown-item" href="#/lancamentos/ListarLancamentosEstagiario" label="Lançamentos de Estagiários" />
                                                <NavbarItem render={props.isUsuarioAutenticado} className="dropdown-item" href="#/lancamentos/ListarLancamentosCooperado" label="Lançamentos de Cooperados" />
                                                <NavbarItem render={(props.isUsuarioAutenticado && rolerUser===a)} className="dropdown-item" href="#/lancamentos/ListarLancamentosTerceirizados" label="Lançamentos de Terceirizados - Dias" />
                                                <NavbarItem render={props.isUsuarioAutenticado} className="dropdown-item" href="#/lancamentos/terceirizadosporhora" label="Lançamentos de Terceirizados" />
                                                <NavbarItem render={props.isUsuarioAutenticado} className="dropdown-item" href="#/prestador/lanc" label="Lançamentos de Sub. Temporários" />
                                                <NavbarItem render={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} className="dropdown-item" href="#/lancamentos/faltas" label="Lançamentos de Faltas" />
                                                <li><hr className="dropdown-divider"/></li>
                                                <NavbarItem render={(props.isUsuarioAutenticado && unidadeDeEnsino)} className="dropdown-item" href="#/params/numalunos" label="Nº Alunos" />
                                                <NavbarItem render={props.isUsuarioAutenticado} className="dropdown-item" href="#/assinatura/quadros" label="Assinatura de Quadros" />
                                                <NavbarItem render={props.isUsuarioAutenticado} className="dropdown-item" href="#verificacao" label="Verificação de Assinatura" />
                                                <NavbarItem render={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} className="dropdown-item" href="#/assinatura/painel" label="Painel de Assinaturas" />
                                            </ul>
                                        </li>
                                    }
            
                                    {props.isUsuarioAutenticado &&
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownRelatorios" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Relatórios
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <NavbarItem render={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} className="dropdown-item" href="#/rel/recibos" label="Recibos" />
                                                <NavbarItem render={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} className="dropdown-item" href="#/rel/quadroprsmes" label="Lista Geral" />
                                                <li><hr className="dropdown-divider"/></li>
                                                <NavbarItem render={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} className="dropdown-item" href="#/rel/anexos" label="Anexos Mensais" />
                                                <NavbarItem render={(props.isUsuarioAutenticado && (rolerUser===a))} className="dropdown-item" href="#/rel/logstabelas" label="Logs do Sistema" />
                                                <NavbarItem render={props.isUsuarioAutenticado} className="dropdown-item" href="#/rel/quadros-mensais" label="Quadros Mensais" />
                                            </ul>
                                        </li>
                                    }
            
                                    {(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a)) &&
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownParametros" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Parâmetros
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownParametros">
                                                <NavbarItem render={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} className="dropdown-item" href="#/params/tab" label="INSS/IRRF/Valor Hora Aula" />
                                                <NavbarItem render={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} className="dropdown-item" href="#/parametrosLancamentos/ParametrosLancamentos" label="Parâmetros de Lançamentos" />
                                                <NavbarItem render={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} className="dropdown-item" href="#/ano" label="Cadastro Ano Letivo" />
                                                <NavbarItem render={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} className="dropdown-item" href="#/params/fgs" label="FG" />
                                                
                                                <NavbarItem render={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} className="dropdown-item" href="#/params/periodonumalunos" label="Abertura/Fechamento Nº Alunos" />
                                                <NavbarItem render={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} className="dropdown-item" href="#/params/calendario" label="Calendário Letivo" />
                                            </ul>
                                        </li>
                                    } 
            
                                    {props.isUsuarioAutenticado &&
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownSubstitutos" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Substitutos Temporários
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownSubstitutos">
                                                <NavbarItem render={props.isUsuarioAutenticado} className="dropdown-item" href="#/prestador/listar" label="Listar" />
                                                <NavbarItem render={props.isUsuarioAutenticado} className="dropdown-item" href="#/prestador/cad" label="Cadastrar" />
                                                
                                            </ul>
                                        </li>
                                    }
            
                                    {props.isUsuarioAutenticado &&
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownCadastros" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Cadastros
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownCadastros">
                                                <NavbarItem render={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} className="dropdown-item" href="#/cargo/ListarCargos" label="Cargos" />
                                                <NavbarItem render={props.isUsuarioAutenticado} className="dropdown-item" href="#/cooperado/ListarCooperados" label="Cooperados" />
                                                <NavbarItem render={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} className="dropdown-item" href="#/estagiario/ListarEstagiarios" label="Estagiários" />
                                                <NavbarItem render={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} className="dropdown-item" href="#/funcao/ListarFuncoes" label="Funções" />
                                                <NavbarItem render={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} className="dropdown-item" href="#/unidade/listarUnidades" label="Unidades" />
                                                <NavbarItem render={(props.isUsuarioAutenticado && (rolerUser===r || rolerUser===a))} className="dropdown-item" href="#/motivos/listarmotivos" label="Motivos" />
                                                <NavbarItem render={props.isUsuarioAutenticado} className="dropdown-item" href="#/terceirizado/ListarTerceirizados" label="Terceirizados" />
                                            </ul>
                                        </li>
                                    }
                                    
                                    {props.isUsuarioAutenticado &&
                                        <NavbarItem render={props.isUsuarioAutenticado} className="nav-link" href="#/usuario/alterpass" label="Alterar Senha" />
                                    }
                                </ul>

                                {props.isUsuarioAutenticado &&
                                    <form className="d-flex" role="search">
                                        <span style={{marginRight: '15px', marginTop: '5px'}}>
                                        Olá, <strong>{usuarioLogado.nome.split(' ')[0]}</strong>
                                        </span>
                                        <a href="#/login" className="btn btn-secondary" role="button" onClick={props.deslogar}>
                                            <i className="pi pi-sign-out"></i> Sair
                                        </a>
                                    </form>
                                }
                                
                            </div>
                        </div>
                    </nav>
                            <div style={{backgroundColor: "#2c9ad9", textAlign: "center"}}>Unidade: <b>{nomeUnidade}</b> 
                                <a href="#/ini" style={{height: '19px', width: '20px', fontSize: "10px", marginBottom: "5px", paddingTop: '1px', paddingLeft: '1px', marginLeft: "10px"}} className="btn btn-secondary" role="button" title='Trocar de Unidade'>
                                    <i className="pi pi-directions"></i>
                                </a>
                            </div>
                </div>
            }
            
        </>
    );
}

export default () => (
    <AuthConsumer>
        {(context) => (
           <BarraMenus isUsuarioAutenticado={context.isAutenticado} deslogar={context.encerrarSessao}/>
        )}
    </AuthConsumer>
)
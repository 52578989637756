import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import AnoMes from "../../../components/mesAno/anoMes";
import gerarPdfService from "../../../service/gerarPdfService"
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-teal/theme.css";
import "primeicons/primeicons.css";
import "react-datepicker/dist/react-datepicker.css";
import LoadingPage from "../../landing/LandingPage";
import LocalStorageService from "../../../service/localStorageService";
import { descriptografar } from "../../../components/criptografar";

function Recibos() {

	const dataCriptografada = LocalStorageService.obterItem('1')
	let dataAtual = new Date(descriptografar(dataCriptografada));
	const [loading, setLoading] = useState(false)
	const [mes, setMes] = useState(dataAtual.getMonth() + 1);
	const [ano, setAno] = useState(dataAtual.getFullYear());

	useEffect(() => { }, []);

	function gerarRecibos() {
		setLoading(true)
		gerarPdfService(`/prestador/r/recibos/${ano}/${mes}`)
		.then(() => {
			setLoading(false)
		})
	}

	return (
		<div>
			{loading && <LoadingPage/> }
			<div className="container mt-4">
				<div className="form-group">
					<div className="card md-3">
						<h4 className="card-header">Geração de Recibos dos Substitutos Temporários</h4>
						<div className="card-body">
							<Row className="justify-content-md-center" style={{ textAlign: "center" }}>
								<Col xs lg="3" >
									<br />
									<AnoMes
										htmlForMes="mesLancamento"
										idMes="mesLancamento"
										nameMes="mesLancamento"
										valueMes={mes}
										onChangeMes={(e) => setMes(e.target.value)}
										htmlForAno="anoLancamento"
										idAno="anoLancamento"
										nameAno="anoLancamento"
										valueAno={ano}
										onChangeAno={(e) => setAno(e.target.value)}
									/>
								</Col>

								<Col xs lg="1">
									<br />
									<br />
									<div style={{ paddingTop: "15px" }}></div>
									<button
										className="btn btn-primary"
										type="button"
										onClick={() => gerarRecibos()}
									>
										Gerar
									</button>
								</Col>
							</Row>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Recibos;
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import gerarPdfService from "../../service/gerarPdfService";
import logsAtualizacaoesValoresService from "../../service/logsAtualizacaoesValoresService";
import LoadingPage from "../landing/LandingPage"

const serviceLogs = new logsAtualizacaoesValoresService();

function LogsAtualizacoes() {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false)
  let redirecionar = useHistory();

  function voltar() {
    redirecionar.push("/params/tab");
  }

  function gerarPdfAtualizacoes(){
    setLoading(true)
      gerarPdfService('/logatualizacoesvalores/logs')
      .then(() => {
        setLoading(false)
      })
  }


  useEffect(() => {
    function carregarLogs() {
      serviceLogs.listarLogs().then((e) => {
        setLogs(e.data);
      });
    }
    carregarLogs();
  }, []);


  return (
    <>
      <>
      {loading && <LoadingPage/> }
        <div className="container mt-6">
          <div className="form-group">
            <div className="card md-3">
              <h4 className="card-header">
                Logs de Atualizações dos valores para cálculos
              </h4>

              <Container>
                <br />

                <div style={{ textAlign: "right" }}>
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={(e) => voltar()}
                >
                  Voltar
                </button>
                {" "}
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={(e) => gerarPdfAtualizacoes()}
                >
                  Gerar PDF Atualizações
                </button>
                </div>

                <table className="table table-hover">
                  <thead style={{ textAlign: "center" }}>
                    <tr>
                      <th scope="col">Atualização</th>
                      <th scope="col">Usuário</th>
                      <th scope="col">Hora Aula</th>
                      <th scope="col">INSS e IRRF</th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: "center" }}>
                      {logs.map((l) => {
                          return (    
                    <tr key={l.id}>
                      <td><input  style={{ boxShadow: "0 0 0 0", border: "0 none", outline: "0", textAlign: "center", backgroundColor: '#fff' }}
                       type="date" disabled="true" value={l.dataAtualizacao}/></td>
                      <td>{l.usuario}</td>

                      <td><input  style={{ boxShadow: "0 0 0 0", border: "0 none", outline: "0", textAlign: "center", backgroundColor: '#fff' }}
                       type="number" disabled="true" value={l.horaAula}/></td>
                       <td>Gerar PDF para visualizar valores</td>
                    </tr>
                    )})}
                  </tbody>
                </table>
              </Container>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default LogsAtualizacoes;

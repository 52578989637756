import ApiService from '../app/apiservice'


class MotivosSubstituicaoService extends ApiService {

    constructor(){
        super('/motivos')
    }

    
    salvar(dados){
        return this.post('/cadastrar', dados)
    }


    atualizar(dados){
        return this.put('/atualizar', dados)
    }

    listar(){
        return this.get('/')
    }

    listarAtivos(){
        return this.get('/listarativos')
    }

    buscarId(id){
        return this.get(`/buscar/${id}`)
    }

}

export default MotivosSubstituicaoService;
import './LoadingPage.css'
import styled from 'styled-components'

const FundoModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(0, 0, 0, 0.7);
    z-index: 99;
`;

export default function LoadingPage(){

   
        return(
            <>
                    <FundoModal>
                        <div className="container text-center">
                            <div class="row">
                                    <div style={{color: "#4e0afa", marginLeft: "46%", marginRight: "50%", marginTop: "20%"}} className="la-ball-atom la-3x">
                                        <div></div>
                                        <div>
                                            <img src="/img/Prefeitura_.png" alt="RH Semec" style={{width: "100px", alignItems: "center", paddingTop: "25%"}}/>
                                        </div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                        <div><br/></div>
                                <h5>Carregando... Aguarde!</h5>
                            </div>
                        </div>   
                    </FundoModal> 
            </>
        )
}


import Button from 'react-bootstrap/Button'
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import Card from "../../components/card";
import LancamentoEstagiarioService from "../../service/lancamentoEstagiarioService";
import { mensagemErro, mensagemSucesso } from "../../components/avisos/toastr";
import ReactPaginate from "react-paginate";
import LocalStorageService from "../../service/localStorageService";
import AnoMes from "../../components/mesAno/anoMes";
import EstagiarioService from "../../service/estagiarioService";
import DataFaltaSubstituicaoService from "../../service/dataFaltaSubstituicaoService";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import calendarioPersonalizado from "../../components/calendario/calendariomulti";
import ParametrosStatusLancamentoService from '../../service/parametrosStatusLancamentoService';
import { descriptografar } from '../../components/criptografar';
import AnoService from "../../service/anoService";

function ListarLancamentosEstagiario() {

    const lancamentoEstagiario = {
        id: null,
        estagiario: null,
        unidade: null,
        mesLancamento: '',
        anoLancamento: '',
        dataInicioUnidade: null,
        dataDesligamentoUnidade: null,
        rescisao: false,
        alterarCargaHoraria: false,
        novaCargaHoraria: null,
        inicioNovaCargaHoraria: null,
        turmaEstagio: '',
        turnoEstagio: '',
        nomeAlunoEspecial: '',
        observacao: '',
        statusLancamento: true
    }

    const [lancamento, setLancamento] = useState(lancamentoEstagiario);

    function onChange(e) {
        const {name, value} = e.target;
        setLancamento({...lancamento, [name]: value})
    }

    const dataFaltaSubstituicao = {
        id: null,
        lancamentoEstagiario: null,
        lancamentoServidor: null,
        servidor: null,
        dataFalta: null,
        horasFaltas: null,
        tipoFalta: null,
        motivosSubstituicao: null,
        aulasSubstituicao: null,
        datasAulasSubstituicao: null,
        listaDataFaltas: []
    }

    const [dataFaltaSub, setDataFaltaSub] = useState(dataFaltaSubstituicao);
    const [listaDataFaltaSub, setListaDataFaltaSub] = useState([]);
    const [idLancamentoEstagiario, setIdLancamentoEstagiario] = useState(0);

    //Para verificar se o botão 'REPLICAR' dever ser exibido ou não.
    //const [mostrarBtnReplicar, setMostrarBtnReplicar] = useState(false);
    const [countLancamentosMesSeguinte, setCountLancamentosMesSeguinte] = useState(0);
    const [lancamentoEstaAberto, setLancamentoEstaAberto] = useState(true);

    const [alterouCargaHoraria, setAlterouCargaHoraria] = useState(false)
    const exibirDadosAlterouCargaHoraria = () => {
        setAlterouCargaHoraria(!alterouCargaHoraria)
    }

    const [rescindiu, setRescindiu] = useState(false)
    const rescisaoEstagiario = () => {
        setRescindiu(!rescindiu)
    }

    const [dataFaltaLista, setDataFaltaLista] = useState([]);

    function onChangeDataFaltaSub(e){
        const {name, value} = e.target;
        setDataFaltaSub({...dataFaltaSub, [name]: value})
    }

    //MODAL PARA CONFIRMAR A REPLICAÇÃO DE LANCAMENTOS
    const [showConfReplica, setShowConfReplica] = useState(false);
    const handleConfReplicaClose = () => setShowConfReplica(false);
    const handleConfReplicaShow = () => setShowConfReplica(true);

    //MODAL PARA VINCULAR ESTAGIÁRIOS
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    //MODAL PARA CONFIRMAR EXCLUSÃO DE LANÇAMENTOS
    const [showConf, setShowConf] = useState(false);
    const handleConfclose = () => setShowConf(false);
    const handleConfShow = () => setShowConf(true);

    //MODAL PARA LANÇAR FALTAS DO ESTAGIÁRIOS
    const [showLanc, setShowLanc] = useState(false);
    const handleLancClose = () => setShowLanc(false);
    const handleLancShow = () => setShowLanc(true);

    //MODAL PARA CONFIRMAR EXCLUSÃO DAS FALTAS
    const [showConfDataFalta, setShowConfDataFalta] = useState(false);
    const handleConfDataFaltaclose = () => setShowConfDataFalta(false);
    const handleConfDataFaltaShow = () => setShowConfDataFalta(true);

    const [imputId, setImputId] = useState();
    const [imputIdDataFalta, setImputIdDataFalta] = useState();
    const [estagiarios, setEstagiarios] = useState([]);
    const [lancamentos, setLancamentosEstagiarios] = useState([]);

    const dataCriptografada = LocalStorageService.obterItem('1')
    const unidadeCriptografado = LocalStorageService.obterItem('3')
    let idUnidade = descriptografar(unidadeCriptografado)
    let dataAtual = new Date(descriptografar(dataCriptografada));

    const [mes, setMes] = useState(dataAtual.getUTCMonth() + 1);
    const [ano, setAno] = useState(dataAtual.getUTCFullYear());

    //paginação de Estagiários
    const [paginaAtual, setPaginaAtual] = useState(0);
    const [nomeBuscar, setNomeBuscar] = useState("");
    const [numeroDePaginas, setNumeroDePaginas] = useState(0);

    //Para atualizar a lista de datas e faltas no botão de excluir.
    const [isRemoved, setIsRemoved] = useState(false);
    //Para atualizar a lista de datas e faltas no botão de salvar.
    const [salvou, setSalvou] = useState(false);

    //Modal de edição
    const [showEditConf, setShowEditConf] = useState(false);

    const handleEditClose = () => setShowEditConf(false)
    const handleEditShow = () => setShowEditConf(true)
    
    //implementar futuramente função para quando pesquisar setar a primeira pagina
    const pesquisou = () => setPaginaAtual(0);
    
    const service = new LancamentoEstagiarioService();
    const serviceEstagiario = new EstagiarioService();
    const serviceDataFaltaSubstituicao = new DataFaltaSubstituicaoService();
    const serviceParametrosStatus = new ParametrosStatusLancamentoService();
    const serviceAno = new AnoService();

    useEffect(() => {
        
        async function loadLancamentosEstagiarios(){
            const response = await service.listarPorUnidAnoMes(idUnidade, ano, mes);
            setLancamentosEstagiarios(response.data);
        }

        async function loadEstagiariosBusca() {
            const response = await serviceEstagiario.buscarPorNomeAtivo(nomeBuscar, paginaAtual);
            setEstagiarios(response.data.content);
            setNumeroDePaginas(response.data.totalPages);
        
            const arrayPaginas = [];
            //cria um array de paginas na quantidade vinda do backend
            for (let p = 1; p <= numeroDePaginas; p++) {
                arrayPaginas.push(p);
            }
        }

        async function loadEstagiarios() {
            const response = await serviceEstagiario.listarTodosAtivos(paginaAtual);
            setEstagiarios(response.data.content);
            setNumeroDePaginas(response.data.totalPages);

            const arrayPaginas = [];
            //cria um array de paginas na quantidade vinda do backend
            for (let p = 1; p <= numeroDePaginas; p++) {
              arrayPaginas.push(p);
            }
        }
        loadLancamentosEstagiarios();

        if (nomeBuscar === "") {
            loadEstagiarios();
        } else {
            loadEstagiariosBusca();
        }

    }, [mes, ano, paginaAtual, nomeBuscar, showConf, show, showEditConf]);

    useEffect(() => {
        async function listarFaltasHorasSub() {
            const response = await serviceDataFaltaSubstituicao.buscarFaltasEHorasPorLancamentoEstagiario(idLancamentoEstagiario);
                setListaDataFaltaSub(response.data);
        }

        listarFaltasHorasSub();
    }, [showLanc, salvou, isRemoved]);

    //ESCONDER O BOTÃO DE REPLICAR
    useEffect(() => {
            const flagLancamentoEstagiario = 'lancamentoEstagiario';

            //CHECAGEM DOS STATUS DO PARAMETRO DO LANCAMENTOS PARA ESCONDER OS BOTOES REPLICAR E VINCULAR.
            async function verificarParametroStatusLancamento() {
                const response = await serviceParametrosStatus.verificarParametroStatusLancamento(idUnidade, ano, mes, flagLancamentoEstagiario);
                setLancamentoEstaAberto(response.data);
            }
            verificarParametroStatusLancamento();

        if(Number(mes) !== 12){
           /*  const date = Date.now();
            const today = new Date(date);

            //Mês Atual
            let mesAtual = (today.getMonth() + 1); */

            //Mês Informado
            let mesFormatado = parseInt(mes)
            let mesSeguinte = mesFormatado + 1;

            //fazer um count dos lancamentos do mes seguinte, se tiver, ocultar o botão "Replicar"
            async function buscarLancamentosMesSeguinte(){
                const response = await service.countLancamentosMesSeguinte(idUnidade, ano, mesSeguinte);
                setCountLancamentosMesSeguinte(response.data);
            }
            buscarLancamentosMesSeguinte();

           

           /*  if(mesAtual === parseInt(mes)){
                setMostrarBtnReplicar(true);
            }else{
                setMostrarBtnReplicar(false);
            } */
        }else{
            let mesAnoSeguinte = 1;
            let anoContagem = ano + 1;

            //fazer um count dos lancamentos do mes seguinte, se tiver, ocultar o botão "Replicar"
            async function buscarLancamentosMesSeguinte(){
                const response = await service.countLancamentosMesSeguinte(idUnidade, anoContagem, mesAnoSeguinte);
                setCountLancamentosMesSeguinte(response.data);
            }
            buscarLancamentosMesSeguinte();
        }


    }, [countLancamentosMesSeguinte, mes, ano, showConfReplica]);

    const vincularEstagiario = (id) => {
        service.buscarLancamentoEstagiarioPorMes(idUnidade, ano, mes, id)
            .then((response) => {
                const result = response.data;

                if(result){
                    mensagemErro("Estagiário já possui vinculo ao mês atual.");
                }else{
                    lancamentoEstagiario['estagiario'] = {id};
                    lancamentoEstagiario['unidade'] = {id: Number.parseInt(idUnidade)};
                    lancamentoEstagiario['mesLancamento'] = mes;
                    lancamentoEstagiario['anoLancamento'] = ano;
                    lancamentoEstagiario['statusLancamento'] = true;
        
                    service.salvar(lancamentoEstagiario)
                    .then((s) => {
                        mensagemSucesso("Estagiário vinculado com sucesso.");
                        handleClose();
                         setTimeout(() => {
                            window.location.reload()
                        },1000)
                    })
                    .catch((e) => {
                        mensagemErro(e.response.data)
                    })
                }
            })
    }

    function handlePageClick(data) {
        setPaginaAtual(data.selected);
    }

    const deletar = (id) => {

        serviceDataFaltaSubstituicao.buscarFaltasEHorasPorLancamentoEstagiario(id)
        .then((response) => {
            const result = response.data;

            if(result.length > 0){
                mensagemErro("Já existem faltas lançadas.");
            }else{
                service.deletar(id)
                .then(() => {
                    mensagemSucesso('Lançamento deletado com sucesso.')
                    handleConfclose()
                }).catch( e => {
                    mensagemErro(e.response.data);
                })
            }
        })
    }

    const abrirEditModal = (id) => {
        limparDados();
        handleEditShow();
        
        if(id){
            service.obterPorId(id)
            .then((response) => {
                setAlterouCargaHoraria(response.data.alterarCargaHoraria)
                setRescindiu(response.data.rescisao)
                setLancamento(response.data)
            })
        }
    }

    const limparDados = () => {

        setLancamento({
            id: null,
            estagiario: null,
            unidade: null,
            mesLancamento: '',
            anoLancamento: '',
            dataInicioUnidade: null,
            dataDesligamentoUnidade: null,
            rescisao: false,
            alterarCargaHoraria: false,
            novaCargaHoraria: null,
            inicioNovaCargaHoraria: null,
            turmaEstagio: '',
            turnoEstagio: '',
            nomeAlunoEspecial: '',
            observacao: ''
        })

        handleEditClose();
    }

    const closeModalLanc = () => {
        handleLancClose();
    }

    const atualizar = () => {
         if(lancamento.dataInicioUnidade !== null){
            if(checarData(lancamento.dataInicioUnidade) === false){
                mensagemErro('Data de início tem o formato inválido.');
                return;
            }
        }

         if(lancamento.dataDesligamentoUnidade !== null){
            if(checarData(lancamento.dataDesligamentoUnidade) === false){
                mensagemErro('Data de desligamento tem o formato inválido.');
                return;
            }
        } 

        //Validações tranferidas para o back 17-02-2023
/*
        if(lancamento.dataInicioUnidade === null){
            mensagemErro('Data de início deve ser informada.')
            return
        } 


         if(!lancamento.turnoEstagio){
            mensagemErro("O turno do estágio deve ser informado.");
            return;
        }
 
        if(!lancamento.turmaEstagio){
            mensagemErro("O local de atuação deve ser informado.");
            return;
        } 

        if(!lancamento.nomeAlunoEspecial){
            mensagemErro("O Nome do Aluno/Setor deve ser informado.");
            return;
        }
        if (alterouCargaHoraria) {
            if(lancamento.novaCargaHoraria === null) {
                mensagemErro("Nova Carga Horária deve ser informada.")
                return false
            }
            if (lancamento.novaCargaHoraria === lancamento.estagiario.cargaHoraria) {
                mensagemErro("Nova Carga Horária deve ser diferente da atual.")
                return false
            }
            if(lancamento.inicioNovaCargaHoraria === null) {
                mensagemErro("Data de início da nova Carga Horária deve ser informada.")
                return false
            }
        } */
        lancamento.novaCargaHoraria = lancamento.novaCargaHoraria !== null ? Number.parseInt(lancamento.novaCargaHoraria) : null
        lancamento.alterarCargaHoraria = alterouCargaHoraria
        lancamento.rescisao = rescindiu
        if (!alterouCargaHoraria) {
            lancamento.novaCargaHoraria = null
            lancamento.inicioNovaCargaHoraria = null
        }
        service.atualizar(lancamento)
            .then(() => {
                mensagemSucesso('Lancamento atualizado com sucesso.')
                handleEditClose();
                setAlterouCargaHoraria(false)
                setRescindiu(false)
            })
            .catch((e) => {
                mensagemErro(e.response.data)
            })
    }

    const atualizarDataFaltaEstagiario = () => {

        if(!dataFaltaSub.dataFalta){
            mensagemErro('Data da falta é obrigatória.');
            return;

        }else if(!dataFaltaSub.horasFaltas || dataFaltaSub.horasFaltas < 1 || dataFaltaSub.horasFaltas > 6){
            mensagemErro(`${!dataFaltaSub.horasFaltas ? 'Informe a quantidade de horas.' : 'A quantidade de horas deve está entre 1 e 6.'}`);
            return;

        }else if(!dataFaltaSub.tipoFalta){
            mensagemErro('Tipo de falta é obrigatória.');
            return;

        }else{
            dataFaltaSub['lancamentoEstagiario'] = lancamento;
            serviceDataFaltaSubstituicao.atualizar(dataFaltaSub).then((response) => {
                if(response.data === true){
                    setSalvou(true);
                    mensagemSucesso('Lançamento salvo com sucesso.');
                    limparDataFaltaEstagiario();
                }else{
                    mensagemErro("Não foi possível inserir o registro.")
                }
            }).catch((error) => {
                mensagemErro(error.response.data.mensagem);
            })
        }

        setSalvou(false);
    }

    const salvarDataFaltaEstagiario = () => {
        
        if(dataFaltaLista.length < 1){
            mensagemErro('Data da falta é obrigatória.');
            return;

        }else if(!dataFaltaSub.horasFaltas || dataFaltaSub.horasFaltas < 1 || dataFaltaSub.horasFaltas > 6){
            mensagemErro(`${!dataFaltaSub.horasFaltas ? 'Informe a quantidade de horas.' : 'A quantidade de horas deve está entre 1 e 6.'}`);
            return;

        }else if(!dataFaltaSub.tipoFalta){
            mensagemErro('Tipo de falta é obrigatória.');
            return;

        }else{
            dataFaltaSub.id = null;
            dataFaltaSub.dataFalta = null;
            dataFaltaSub.listaDataFaltas = dataFaltaLista.toString().split(",");
            dataFaltaSub['lancamentoEstagiario'] = lancamento;
            serviceDataFaltaSubstituicao.salvar(dataFaltaSub).then((response) => {

                if(response.data === true){
                    setSalvou(true);
                    mensagemSucesso('Lançamento salvo com sucesso.');
                    limparDataFaltaEstagiario();
                }else{
                    mensagemErro("Não foi possível inserir o registro.")
                }
            }).catch((error) => {
                mensagemErro(error.response.data.mensagem);
            })
        }
        setSalvou(false);
    }

    //EDIÇÃO DE HORAS E FALTAS
    const editarDataFalta = (id) => {

        if(id){
            serviceDataFaltaSubstituicao.obterPorId(id)
            .then((response) => {
                setDataFaltaSub(response.data)
            })
        }
    }

    const excluirDatasFaltas = (id) => {
        serviceDataFaltaSubstituicao.deletar(id)
        .then(() => {
                setIsRemoved(true);
                mensagemSucesso('Registro deletado com sucesso.')
                handleConfDataFaltaclose();
            
        }).catch( error => {
            mensagemErro(error.data);
        })
        setIsRemoved(false);

    }

    const limparDataFaltaEstagiario = () => {
        setDataFaltaSub({
            id: 0,
            dataFalta: '',
            horasFaltas: '',
            tipoFalta: ''
        })

        dataFaltaLista.length = 0;
    }

    const formatarData = (dataF) => {
        if(dataF){
            var data = new Date(dataF),
            dia  = data.getUTCDate().toString().padStart(2, '0'),
            mes  = (data.getUTCMonth()+1).toString().padStart(2, '0'),
            ano  = data.getUTCFullYear();
            return dia+"/"+mes+"/"+ano;
        }
    }

    const formatarMes =(mes) => {
        return ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"][mes -1];
    }

    const replicar = () => {
        
        if(Number(mes) !== 12){
            service.replicarLancamentos(idUnidade, ano, mes)
                .then(() => {
                    mensagemSucesso("A replicação foi um sucesso!");
                    handleConfReplicaClose();
                })
        }else{
            serviceAno.anoExiste(Number(ano) +1).then((response) => {
                if(response.data === true){
                    service.replicarLancamentos(idUnidade, ano, mes)
                    .then(() => {
                        mensagemSucesso("A replicação foi um sucesso!");
                        handleConfReplicaClose();
                    })
                }else{
                    mensagemErro("Ano seguinte ainda não foi criado no sistema. Solicite ao RH.");
                }
            })
        }
    }

    function checarData(data) {
        let dataAno = new Date(data);
        if(data !== ""){
            if(dataAno.getFullYear().toString().length === 4){
                return true;
            }else{
                return false;
            }
        }
    }

    return(
        <>
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs lg="4">
                        <AnoMes
                            htmlForMes="mesLancamento"
                            idMes="mesLancamento"
                            nameMes="mesLancamento"
                            valueMes={mes}
                            onChangeMes={(e) => setMes(e.target.value)}
                            htmlForAno="anoLancamento"
                            idAno="anoLancamento"
                            nameAno="anoLancamento"
                            valueAno={ano}
                            onChangeAno={(e) => setAno(e.target.value)}
                        />
                    </Col>

                    <Col sm={1} style={{ marginTop: "37px"}}>
                        {(lancamentoEstaAberto) &&
                            <button className="btn btn-primary" type="button" onClick={handleShow}>
                                Vincular
                            </button>
                        }
                    </Col>

                    <Col lg={2} style={{marginTop: "37px"}}>
                        {(countLancamentosMesSeguinte < 1) && (lancamentos.length > 0) &&
                            <button type='button' className='btn btn-primary' onClick={handleConfReplicaShow}>
                                Replicar
                            </button>
                        }
                    </Col>
                </Row>

                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="xl" >
                    <Modal.Header>
                        <Modal.Title id="lancamentoModal">
                            <h2>Estagiários</h2>
                        </Modal.Title>

                        <Col md={2} style={{ float: "left" }}>
                            <button className="btn btn-danger" onClick={handleClose}>
                                Fechar
                            </button>
                        </Col>
                    </Modal.Header>

                    <Modal.Body>
                        <Card title="Listagem de Estagiários"> 
                            <br></br>
                            <div >
                                <Row>
                                    <Col md={6}>
                                        <input
                                            id="nomeBuscar"
                                            name="nomeBuscar"
                                            type="text"
                                            className="form-control"
                                            placeholder="Digite o nome para pesquisar..."
                                            value={nomeBuscar}
                                            onChange={(buscar) => setNomeBuscar(buscar.target.value)}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <br></br>
                            <div>
                                <table id="tabela_estagiarios" className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>##</th>
                                            <th> Matrícula </th>
                                            <th> Nome </th>
                                            <th>Carga Horária</th>
                                            <th style={{textAlign: "center", width: "40px"}}>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {estagiarios.map((est, index) => {
                                            return (
                                                <tr key={est.id}>
                                                    <td>{index+1}</td>
                                                    <td>{est.matricula}</td>
                                                    <td>{est.nome}</td>
                                                    <td>{est.cargaHoraria}</td>
                                                    <td style={{textAlign: "center"}}>
                                                        <button 
                                                            className="btn btn-primary" 
                                                            type="button" 
                                                            onClick={(e) => vincularEstagiario(est.id)}> Vincular </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                {estagiarios.length > 0 &&
                                    <ReactPaginate
                                        previousLabel={"Voltar"}
                                        nextLabel={"Avançar"}
                                        breakLabel={"..."}
                                        pageCount={numeroDePaginas}
                                        marginPagesDisplayed={3}
                                        pageRangeDisplayed={2}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination justify-content-center"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                        initialPage={pesquisou}
                                    />
                                }
                            </div>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        Pesquise o estagiário e vincule a sua unidade para informar os lançamentos.
                    </Modal.Footer>
                </Modal>
            </Container>

            <Container className="mt-4">
                <Card title="Lista de Lançamentos de Estagiários">
                    <Table striped bordered hover size="md">
                        <thead>
                            {lancamentos.length > 0 ?
                                <tr>
                                    <th>##</th>
                                    <th>Nome do Estagiário</th>
                                    <th>Início na Unidade</th>
                                    <th>Mês do Lançamento</th>
                                    <th>Turno</th>
                                    <th>Turma</th>
                                </tr>
                                :
                                <>
                                </>
                            }
                        </thead>
                        <tbody>
                            {lancamentos.length > 0 ?
                                <>
                                    {lancamentos.map((l, index) => {
                                        return(
                                            <tr key={l.id}>
                                                <td>{index+1}</td>
                                                <td>{l.estagiario.nome}</td>
                                                <td>{formatarData(l.dataInicioUnidade)}</td>
                                                <td>{formatarMes(l.mesLancamento)}</td>
                                                <td>{l.turnoEstagio}</td>
                                                <td>{l.turmaEstagio}</td>
                                                <td className="col-md-2" style={{textAlign: "center"}}>
                                                    {l.statusLancamento.toString() === 'false' ?
                                                        <button className="btn btn-primary" style={{margin: "0 5px"}} disabled onClick={() => abrirEditModal(l.id)}>
                                                            <i className='pi pi-pencil'></i>
                                                        </button>
                                                        :
                                                        <button className="btn btn-primary" title='Editar' style={{margin: "0 5px"}} onClick={() => abrirEditModal(l.id)}>
                                                            <i className='pi pi-pencil'></i>
                                                        </button>
                                                    }
                                            
                                                    {l.statusLancamento.toString() === 'false' ?
                                                        <button className="btn btn-danger" disabled onClick={() => handleConfShow(setImputId(`${l.id}`))} >
                                                            <i className='pi pi-trash'></i>
                                                        </button>
                                                        :
                                                        <button className="btn btn-danger" title='Excluir' onClick={() => handleConfShow(setImputId(`${l.id}`))} >
                                                            <i className='pi pi-trash'></i>
                                                        </button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </>
                                : 
                                <>
                                    <tr>
                                        <td style={{fontSize: '1.5rem'}}>Nenhum registro encontrado.</td>
                                    </tr>
                                </>
                            }
                        </tbody>
                    </Table>
                </Card>

                <Modal show={showLanc} onHide={handleLancClose} backdrop="static" keyboard={false} size="xl">
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col md={5}>
                                    <label className="col-form-label" htmlFor="estagiario">Estagiário:</label>
                                    <input type="text" 
                                        className="form-control" 
                                        id="estagiario" 
                                        name="estagiario"
                                        disabled
                                        value={!lancamento.estagiario ? null : lancamento.estagiario.nome}
                                        onChange={onChangeDataFaltaSub} />
                                </Col>
                                <Col md={5}>
                                    <label className="col-form-label" htmlFor="unidade">Unidade:</label>
                                    <input type="text" 
                                        className="form-control" 
                                        id="unidade" 
                                        name="unidade"
                                        disabled
                                        value={!lancamento.unidade ? null : lancamento.unidade.nome}
                                        onChange={onChangeDataFaltaSub} />
                                </Col>
                                <Col md={2}>
                                    <label className="col-form-label" htmlFor="mesLancamento">Mês/Ano:</label>
                                    <input type="text" 
                                        className="form-control" 
                                        id="mesLancamento" 
                                        name="mesLancamento"
                                        disabled
                                        value={`${lancamento.mesLancamento}/${lancamento.anoLancamento}`}
                                        onChange={onChangeDataFaltaSub} />
                                </Col>
                            </Row>
                            <br></br>
                            <hr></hr>
                            <Card title={dataFaltaSub.id > 0 ? "Edição de Lançamentos" : "Cadastro de Lançamentos"}>
                                <Form>
                                    <Row>
                                        <label className="col-form-label" htmlFor="dataFaltaLista">Datas das Faltas:</label>
                                        {dataFaltaSub.id > 0 ?
                                            <Col xl={4} lg={6}>
                                                <label className="col-form-label" htmlFor="dataFalta">Data da Falta:</label>
                                                <input type="date" 
                                                    className="form-control" 
                                                    id="dataFalta"
                                                    name="dataFalta"
                                                    value={dataFaltaSub.dataFalta}
                                                    onChange={onChangeDataFaltaSub}></input>    
                                            </Col>
                                            :
                                            <DatePicker
                                                mapDays={({ date }) => {
                                                    let props = {}
                                                    let isWeekend = [0, 6].includes(date.weekDay.index)
                                                    
                                                    if (isWeekend) props.className = "highlight highlight-red"
                                                    
                                                    return props
                                                }}
                                                className="mb-3"
                                                onChange={setDataFaltaLista}
                                                value={dataFaltaSub.listaDataFaltas}
                                                id="dataFaltaLista"
                                                name="dataFaltaLista"
                                                numberOfMonths={2}
                                                multiple
                                                sort
                                                format="DD/MM/YYYY"
                                                locale={calendarioPersonalizado}
                                                plugins={[<DatePanel />]}
                                                style={{
                                                    width: "100%",
                                                    height: "38px",
                                                    padding: "12px 20px",
                                                    boxSizing: "border-box",
                                                }}
                                            />                                           
                                        }
                                    </Row>
                                    <Row>
                                        <Col xl={4} lg={6}>
                                            <label className="col-form-label" htmlFor="horasFaltas">Horas Faltadas:</label>
                                            <input type="number" 
                                                className="form-control" 
                                                min={1} max={6}
                                                id="horasFaltas"
                                                name="horasFaltas"
                                                value={dataFaltaSub.horasFaltas}
                                                onChange={onChangeDataFaltaSub}></input>
                                        </Col>
                                        <Col xl={4}>
                                            <label className="col-form-label" htmlFor="tipoFalta">Tipo de Falta:</label>
                                            <Form.Select 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Turno do Estágio" 
                                                id="tipoFalta" 
                                                name="tipoFalta"
                                                value={dataFaltaSub.tipoFalta}
                                                onChange={onChangeDataFaltaSub}>

                                                    <option value="">Selecione</option>
                                                    <option value="Justificada">Justificada</option>
                                                    <option value="Injustificada">Injustificada</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>

                                    <div className="text-center mt-4">
                                        {dataFaltaSub.id > 0 ? 
                                            <button 
                                                type="button" 
                                                onClick={atualizarDataFaltaEstagiario} 
                                                className="btn btn-primary"> 
                                                Atualizar
                                            </button> 
                                            :
                                            <button 
                                                type="button" 
                                                onClick={salvarDataFaltaEstagiario} 
                                                className="btn btn-primary"> 
                                                Salvar
                                            </button> 
                                        }
                                    </div>
                                </Form>
                            </Card>

                            <br/> <hr></hr>

                            <Card title="Listagem de Faltas">
                                <Table id="tabela_lancamentos_faltas" striped bordered hover size="md">
                                    <thead>                                       
                                        <tr>
                                            <th>##</th>
                                            <th>Data da Falta</th>
                                            <th>Horas Faltadas</th>
                                            <th>Tipo de Falta</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listaDataFaltaSub.map((df, index) => {
                                            return (
                                                <tr key={df.id}>
                                                    <td>{index+1}</td>
                                                    <td>{formatarData(df.dataFalta)}</td>
                                                    <td>{df.horasFaltas}</td>
                                                    <td>{df.tipoFalta}</td>
                                                    <td className='col-md-2' style={{textAlign: "center"}}>
                                                        <button className="btn btn-primary" title='Editar' type="button" style={{margin: "0 5px"}} onClick={() => editarDataFalta(df.id)}>
                                                            <i className='pi pi-pencil'></i>
                                                        </button>

                                                        <button onClick={() => handleConfDataFaltaShow(setImputIdDataFalta(`${df.id}`))} title='Excluir' className="btn btn-danger">
                                                            <i className='pi pi-trash'></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </Card>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={closeModalLanc}>
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showEditConf} onHide={handleEditClose} backdrop="static" keyboard={false} size="xl">
                    <Modal.Body>
                        <Container>
                            <Card title="Edições de Lançamentos">
                                <Form>
                                    <Row>
                                        <Col md={9}>
                                            <label className="col-form-label" htmlFor="estagiario">Estagiário:</label>
                                            <input type="text" 
                                                className="form-control" 
                                                id="estagiario" 
                                                name="estagiario"
                                                disabled
                                                value={!lancamento.estagiario ? null : lancamento.estagiario.nome}
                                                onChange={onChange} />
                                        </Col>
                                        <Col md={1}>
                                            <label className="col-form-label" htmlFor="estagiario">C.H.:</label>
                                            <input type="text" 
                                                className="form-control" 
                                                id="estagiario" 
                                                name="estagiario"
                                                disabled
                                                value={!lancamento.estagiario ? null : lancamento.estagiario.cargaHoraria}
                                                onChange={onChange} />
                                        </Col>
                                        <Col md={2}>
                                            <label className="col-form-label" htmlFor="nivelEscolaridade">Nível de Escolaridade:</label>
                                            <input type="text" 
                                                className="form-control" 
                                                id="nivelEscolaridade" 
                                                name="nivelEscolaridade"
                                                disabled
                                                value={!lancamento.estagiario ? null : lancamento.estagiario.nivelEscolaridade}
                                                onChange={onChange} />
                                        </Col>
                                    </Row>
                                    <Row>
                                    <Col md={10}>
                                            <label className="col-form-label" htmlFor="unidade">Unidade:</label>
                                            <input type="text" 
                                                className="form-control" 
                                                id="unidade" 
                                                name="unidade"
                                                disabled
                                                value={!lancamento.unidade ? null : lancamento.unidade.nome}
                                                onChange={onChange} />
                                    </Col>
                                    <Col md={2}>
                                            <label className="col-form-label" htmlFor="mesLancamento">Mês/Ano:</label>
                                            <input type="text" 
                                                className="form-control" 
                                                id="mesLancamento" 
                                                name="mesLancamento"
                                                disabled
                                                value={`${lancamento.mesLancamento}/${lancamento.anoLancamento}`}
                                                onChange={onChange} />
                                    </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={4} lg={6}>
                                            <label className="col-form-label" htmlFor="dataInicioUnidade">Data de Início na Unidade:</label>
                                            <input type="date" 
                                                className="form-control" 
                                                id="dataInicioUnidade"
                                                name="dataInicioUnidade"
                                                value={lancamento.dataInicioUnidade}
                                                onChange={onChange}></input>    
                                        </Col>
                                        <Col xl={3} lg={6}>
                                            <label className="col-form-label" htmlFor="dataDesligamentoUnidade">Desligamento da Unidade:</label>
                                            <input type="date" 
                                                className="form-control" 
                                                id="dataDesligamentoUnidade"
                                                name="dataDesligamentoUnidade"
                                                value={lancamento.dataDesligamentoUnidade}
                                                onChange={onChange}></input>
                                        </Col>
                                        <Col md={1}>
                                            <label className="col-form-label" htmlFor="rescisao">Rescisão?:</label><br/>
                                            <input type="checkbox"
                                                style={{width: 70, height: 36}} 
                                                id="rescisao" 
                                                name="rescisao"
                                                value={lancamento.rescisao}
                                                checked={rescindiu}
                                                onChange={rescisaoEstagiario}
                                            />
                                        </Col>
                                        <Col xl={4}>
                                            <label className="col-form-label" htmlFor="turnoEstagio">Turno do Estágio:</label>
                                            <Form.Select 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Turno do Estágio" 
                                                id="turnoEstagio" 
                                                name="turnoEstagio"
                                                value={lancamento.turnoEstagio}
                                                onChange={onChange}>
                                                    <option value="">Selecione</option>
                                                    <option value="Matutino">Matutino</option>
                                                    <option value="Vespertino">Vespertino</option>
                                                    <option value="Vespertino">Noturno</option>
                                                    <option value="Integral">Integral</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <label className="col-form-label" htmlFor="turmaEstagio">Turma do Estágio/Local:</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Turma do estágio ou local de trabalho" 
                                                id="turmaEstagio" 
                                                name="turmaEstagio"
                                                value={lancamento.turmaEstagio}
                                                onChange={onChange} />
                                        </Col>
                                        <Col md={6}>
                                            <label className="col-form-label" htmlFor="nomeAlunoEspecial">Nome do Aluno/Setor:</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Nome do aluno ou setor " 
                                                id="nomeAlunoEspecial" 
                                                name="nomeAlunoEspecial"
                                                value={lancamento.nomeAlunoEspecial} 
                                                onChange={onChange}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <label style={{fontWeight: 500}} className="col-form-label" htmlFor="alterouCH">Alterou C.H.?:</label><br/>
                                            <input type="checkbox"
                                                style={{width: 70, height: 36}} 
                                                id="alterouCH" 
                                                name="alterouCH"
                                                value={lancamento.alterarCargaHoraria}
                                                checked={alterouCargaHoraria}
                                                onChange={exibirDadosAlterouCargaHoraria}
                                            />
                                        </Col>
                                        {alterouCargaHoraria &&
                                            <>
                                                <Col md={2}>
                                                    
                                                    <label className='col-form-label' for="novaCargaHoraria"> Nova Carga Horária:</label>
                                                    <Form.Select className='form-control' 
                                                        id="novaCargaHoraria"
                                                        name="novaCargaHoraria"
                                                        value={lancamento.novaCargaHoraria} 
                                                        onChange={onChange}>
                                                            <option value="">Selecione</option>
                                                            <option value="20">20</option>
                                                            <option value="30">30</option>
                                                    </Form.Select>
                                                </Col>
                                                <Col md={2}>
                                                    <label for="inicioNovaCargaHoraria" className="col-form-label">Início Nova C.H.:</label>
                                                    <input type="date" id="inicioNovaCargaHoraria" name="inicioNovaCargaHoraria" className="form-control"
                                                        value={lancamento.inicioNovaCargaHoraria} onChange={onChange} />
                                                </Col>
                                            </>
                                        } 
                                    </Row>
                                    <Row>
                                        <Col>
                                            <label className="col-form-label" htmlFor="observacoes">Observações:</label>
                                            <Form.Control 
                                                as="textarea" 
                                                rows={3} 
                                                id="observacao"
                                                name="observacao"
                                                value={lancamento.observacao}
                                                onChange={onChange}/>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <div className="text-center mt-2">
                                        <button 
                                            type="button" 
                                            onClick={atualizar} 
                                            className="btn btn-primary"> Atualizar 
                                        </button> 
                                    </div>
                                </Form>
                            </Card>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={limparDados}>
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showConfReplica} onHide={handleConfReplicaClose} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <h3>Confirmação</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Essa ação criará uma cópia de todos os lancamentos desse mês no mês posterior. Deseja prosseguir?</h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={replicar}>
                            Confirmar
                        </Button>
                        <Button className="btn btn-secondary" onClick={handleConfReplicaClose}>
                            Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showConf} onHide={handleConfclose} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <h3>Confirmação</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Você realmente deseja excluir o registro?</h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={() => deletar(imputId)}>
                            Confirmar
                        </Button>
                        <Button className="btn btn-secondary" onClick={handleConfclose}>
                            Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showConfDataFalta} onHide={handleConfDataFaltaclose} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <h3>Confirmação</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Você realmente deseja excluir o registro?</h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={() => excluirDatasFaltas(imputIdDataFalta)}>
                            Confirmar
                        </Button>
                        <Button className="btn btn-secondary" onClick={handleConfDataFaltaclose}>
                            Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </>
    )
}

export default ListarLancamentosEstagiario
import ApiService from "../app/apiservice";


class logsAtualizacaoesValoresService extends ApiService {
   
    constructor(){
        super('/logatualizacoesvalores')
    }

    salvar(atualizacao){
        return this.post('/salvar', atualizacao)
    }

    listarLogs(){
        return this.get('/listaratualizacoes')
    }

}

export default logsAtualizacaoesValoresService;
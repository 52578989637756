import ApiService from "../app/apiservice"

class ParametrosLancamentosService extends ApiService {
    constructor() {
        super("/parametroslancamento")
    }

    obterPorId(id){
        return this.get(`/${id}`)
    }
    
    salvar(parametrosLancamento){
        return this.post('/', parametrosLancamento)
    }
    
    atualizar(parametrosLancamento){
        return this.put(`/${parametrosLancamento.id}`, parametrosLancamento)
    }

    listarTodos() {
        return this.get('/');
    } 

    listarPorAno(ano){
        return this.get(`/buscarparametroporano/ano/${ano}`)
    }
}

export default ParametrosLancamentosService

const calendarioPersonalizado = {
    name: "calendarioPersonalizado",
    months: [
      ["Janeiro", "jan"],
      ["Fevereiro", "fev"],
      ["Março", "mar"],
      ["Abril", "abr"],
      ["Maio", "mai"],
      ["Junho", "jun"],
      ["Julho", "jul"],
      ["Agosto", "ago"],
      ["Setembro", "set"],
      ["Outubro", "out"],
      ["Novembro", "nov"],
      ["Dezembro", "dec"],
    ],
    weekDays: [
      ["Sábado", "sab"],
      ["Domingo", "dom"],
      ["Segunda", "seg"],
      ["Terça", "ter"],
      ["Quarta", "qua"],
      ["Quinta", "qui"],
      ["Sexta", "sex"],
    ],
    digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    meridiems: [
      ["AM", "am"],
      ["PM", "pm"],
    ],
  };
  
  export default calendarioPersonalizado;
  
import Button from 'react-bootstrap/Button'
import { useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row, Tab, Table, Tabs, OverlayTrigger, Tooltip } from "react-bootstrap";
import Card from "../../components/card";
import { mensagemErro, mensagemSucesso } from "../../components/avisos/toastr";
import LancamentoCooperadoService from "../../service/lancamentoCooperadoService";
import AnoMes from "../../components/mesAno/anoMes";
import ReactPaginate from "react-paginate";
import CooperadoService from "../../service/cooperadoService";
import LocalStorageService from "../../service/localStorageService";
import FaltasHorasCooperadoService from "../../service/faltasHorasCooperadoService";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import calendarioPersonalizado from "../../components/calendario/calendariomulti";
import FuncaoService from "../../service/funcaoService";
import LancamentoServidorService from  '../../service/lancamentoServidorService';
import ParametrosStatusLancamentoService from '../../service/parametrosStatusLancamentoService';
import { descriptografar } from '../../components/criptografar';
import AnoService from "../../service/anoService";
import assinaturaQuadrosService from '../../service/assinaturaQuadrosService';

function ListarLancamentosCooperados() {

    //PARA O CADASTRO DE LANÇAMENTOS DOS COOPERADOS ==>
    const lancamentoCooperado = {
        id: null,
        cooperado: null,
        unidade: null,
        funcao: null,
        mesLancamento: '',
        anoLancamento: '',
        turno: '',
        chDiaria: 0,
        dataInicioUnidade: null,
        dataDesligamentoUnidade: null,
        statusLancamento: true,
        tipoCooperado: '',
        diasDaSemana: '', 
        empresa: ''
    };

    const [lancamento, setLancamento] = useState(lancamentoCooperado);

    function onChangeLancamento(e) {
        const { name, value } = e.target;
        setLancamento({...lancamento, [name]: value })
    }
    // <== PARA O CADASTRO DE LANÇAMENTOS DOS COOPERADOS

    //PARA O CADASTRO DE LANÇAMENTOS DE FALTAS E HORAS ==>
    const [key, setKey] = useState('faltas');
    const [idLancamentoCooperado, setIdLancamentoCooperado] = useState(0);

    const faltaOuHorasAcrescentarCooperado = {
        id: null,
        lancamentoCooperado: null,
        cooperado: '',
        servidor: '',
        funcaoSubstituido: '',
        tipoLancamento: '',
        dataFalta: null,
        horasFalta: 0,
        dataHorasAcrescentar: null,
        horasAcrescentar: 0,
        dataSubstituicao: null,
        horasSubstituicao: 0,
        motivoAcrescimoHoras: '',
        listaDataFaltas: [],
        listaDataSubstituicao: [],
        listaDataHoras: []
    }

    const [isChecked, setIsChecked] = useState(false);
    
    const handleOnChecked =  () => {
        setIsChecked(!isChecked);
    }

    //Para verificar se o botão 'REPLICAR' dever ser exibido ou não.
    //const [mostrarBtnReplicar, setMostrarBtnReplicar] = useState(false);
    const [countLancamentosMesSeguinte, setCountLancamentosMesSeguinte] = useState(0);
    const [lancamentoEstaAberto, setLancamentoEstaAberto] = useState(true);

    const [faltasHorasCooperado, setFaltasHorasCooperado] = useState(faltaOuHorasAcrescentarCooperado);
    const [listaFaltaHorasCooperado, setListaFaltaHorasCooperado] = useState([]);

    const [listaCooperadosSub, setListaCooperadosSub] = useState([]);
    const [idCooperado, setIdCooperado] = useState();

    const [listaServidoresSub, setListaServidoresSub] = useState([]);
    const [idServidor, setIdServidor] = useState();

    const [listaFuncoesSubstituido, setListaFuncoesSubstituido] = useState([]);
    const [idFuncaoSubstituido, setIdFuncaoSubstituido] = useState();

    const [dataFaltaLista, setDataFaltaLista] = useState([]);
    const [dataFaltaListaSub, setDataFaltaListaSub] = useState([]);
    const [dataHoraLista, setDataHoraLista] = useState([]);

    const [idFuncao, setIdFuncao] = useState();
    const [idEmpresa, setIdEmpresa] = useState();

    const [exibirAbasMensalista, setExibirAbasMensalista] = useState(false);
    const [idTipoCooperado, setIdTipoCooperado] = useState();
    const [motivoDiariaAvulsa, setMotivoDiariaAvulsa] = useState(null)
    const [diariaAvulsa, setDiariaAvulsa] = useState(false)
    const [ocultarCheckDiariasAvulsa, setOcultarCheckDiariasAvulsa] = useState(false)

    let horasAcrescentadas = 0;
    let horasFaltas = 0;
    let horasSubstituicao = 0;

    const alterarEstadoDiariaAvulsa =  () => {
        setDiariaAvulsa(!diariaAvulsa)
        if (diariaAvulsa) {
            setIdCooperado(null)
            setIdServidor(null)
        }
    }

    const ocultarExibirCheckDiariasAvulsar =  () => {
        setOcultarCheckDiariasAvulsa(!ocultarCheckDiariasAvulsa)
    }

    function onChangeHorasFaltas(e) {
        const { name, value } = e.target;
        setFaltasHorasCooperado({...faltasHorasCooperado, [name]: value })
    }
    // <== PARA O CADASTRO DE LANÇAMENTOS DE FALTAS E HORAS

    /* //Para o cadastro de cooperado no modal ==>
    const initialValue = {
        nome: '',
        cpf: null
    }

    const [values, setValues] = useState(initialValue);
    
    function onChange(e) {
        const { name, value } = e.target;
        setValues({...values, [name]: value })
    }
    // <== Para o cadastro de cooperado no modal */

    const unidadeCriptografado = LocalStorageService.obterItem('3')
    let idUnidade = descriptografar(unidadeCriptografado)
    const dataCriptografada = LocalStorageService.obterItem('1')
    let dataAtual = new Date(descriptografar(dataCriptografada));

    const [mes, setMes] = useState(dataAtual.getUTCMonth() + 1);
    const [ano, setAno] = useState(dataAtual.getUTCFullYear());

    //modal vincular cooperado
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [funcoes, setFuncoes] = useState([]);
    const [cooperados, setCooperados] = useState([]);

    //Modal de exclusão
    const [showConf, setShowConf] = useState(false);
    const handleConfclose = () => setShowConf(false);
    const handleConfShow = () => setShowConf(true);

    //Modal de exclusão de faltas e horas 
    const [showConfFaltas, setShowConfFaltas] = useState(false);
    const handleConfFaltasclose = () => setShowConfFaltas(false);
    const handleConfFaltasShow = () => setShowConfFaltas(true);

    //Modal de lançamentos de faltas, horas e substituições
    const [showLanc, setShowLanc] = useState(false);
    const handleLancClose = () => setShowLanc(false);
    const handleLancShow = () => setShowLanc(true);

    //Modal de edição
    const [showEditConf, setShowEditConf] = useState(false);
    const handleEditClose = () => setShowEditConf(false)
    const handleEditShow = () => setShowEditConf(true)

     //MODAL PARA CONFIRMAR A REPLICAÇÃO DE LANCAMENTOS
     const [showConfReplica, setShowConfReplica] = useState(false);
     const handleConfReplicaClose = () => setShowConfReplica(false);
     const handleConfReplicaShow = () => setShowConfReplica(true);

    const [imputId, setImputId] = useState();
    const [imputIdFaltas, setImputIdFaltas] = useState();
    const [lancamentos, setLancamentosCooperado] = useState([]);
    
    //paginação de Cooperados
    const [paginaAtual, setPaginaAtual] = useState(0);
    const [nomeBuscar, setNomeBuscar] = useState("");
    const [numeroDePaginas, setNumeroDePaginas] = useState(0);

    //Para atualizar a lista de datas e faltas no botão de excluir.
    const [isRemoved, setIsRemoved] = useState(false);
    //Para atualizar a lista de datas e faltas no botão de salvar.
    const [salvou, setSalvou] = useState(false);

    const pesquisou = () => setPaginaAtual(0);
    
    const service = new LancamentoCooperadoService();
    const serviceCooperado = new CooperadoService();
    const serviceFaltasHorasCooperado = new FaltasHorasCooperadoService();
    const serviceFuncao = new FuncaoService();
    const serviceLancamentoServidor = new LancamentoServidorService();
    const serviceParametrosStatus = new ParametrosStatusLancamentoService();
    const serviceAno = new AnoService();
    const serviceAssinaturaDigital = new assinaturaQuadrosService()

    useEffect(() => {
        async function loadLancamentos() {
            const response = await service.listarPorUnidAnoMes(idUnidade, ano, mes);
            setLancamentosCooperado(response.data);
        }
        loadLancamentos();

        async function loadCooperadosBusca() {
            const response = await serviceCooperado.listarTodos(`/cooperadopornome/${nomeBuscar}/page/${paginaAtual}`);
            setCooperados(response.data.content);
            setNumeroDePaginas(response.data.totalPages);

            const arrayPaginas = [];
            //cria um array de paginas na quantidade vinda do backend
            for (let p = 1; p <= numeroDePaginas; p++) {
              arrayPaginas.push(p);
            }
        }

        async function loadCooperados() {
            const response = await serviceCooperado.listarTodos(`/pag/${paginaAtual}`);
            setCooperados(response.data.content);
            setNumeroDePaginas(response.data.totalPages);

            const arrayPaginas = [];
            //cria um array de paginas na quantidade vinda do backend
            for (let p = 1; p <= numeroDePaginas; p++) {
              arrayPaginas.push(p);
            }
        }

        if (nomeBuscar === "") {
            loadCooperados();
          } else {
            loadCooperadosBusca();
          }

    }, [mes, ano, paginaAtual, nomeBuscar, showConf, show, showEditConf]);

    useEffect(() => {
        async function listarFaltasHoras() {
            const response = await serviceFaltasHorasCooperado.buscarFaltasEHorasPorLancamentoCooperado(idLancamentoCooperado);
                setListaFaltaHorasCooperado(response.data);
        }
        listarFaltasHoras();

        async function listarFuncoes() {
            const response = await serviceFuncao.listar();
            setFuncoes(response.data);
            setListaFuncoesSubstituido(response.data);
        }
        listarFuncoes();

        async function listarCooperadosSubstituicao(){
            const response = await service.buscarCooperadoLancamentoMes(idUnidade, ano, mes);
            setListaCooperadosSub(response.data);
        }
        listarCooperadosSubstituicao();

        async function listarServidoresSubstituicao(){
            const response = await serviceLancamentoServidor.buscarServidorLancamentoMes(idUnidade, ano, mes);
            setListaServidoresSub(response.data);
        }
        listarServidoresSubstituicao();

    }, [showLanc ,showEditConf, salvou, isRemoved]);

    //ESCONDER O BOTÃO DE REPLICAR
    useEffect(() => {
        const flagLancamentoCooperado = 'lancamentoCooperado';

        //CHECAGEM DOS STATUS DO PARAMETRO DO LANCAMENTOS PARA ESCONDER OS BOTOES REPLICAR E VINCULAR.
        async function verificarParametroStatusLancamento() {
            const response = await serviceParametrosStatus.verificarParametroStatusLancamento(idUnidade, ano, mes, flagLancamentoCooperado);
            setLancamentoEstaAberto(response.data);
        }
        verificarParametroStatusLancamento();

        if(Number(mes) !== 12){
            
            //Mês Informado
            let mesFormatado = parseInt(mes)
            let mesSeguinte = mesFormatado + 1;

            //fazer um count dos lancamentos do mes seguinte, se tiver, ocultar o botão "Replicar"
            async function buscarLancamentosMesSeguinte(){
                const response = await service.countLancamentosMesSeguinte(idUnidade, ano, mesSeguinte);
                setCountLancamentosMesSeguinte(response.data);
            }
            buscarLancamentosMesSeguinte();
        }else{
            let mesAnoSeguinte = 1;
            let anoContagem = ano + 1;

            //fazer um count dos lancamentos do mes seguinte, se tiver, ocultar o botão "Replicar"
            async function buscarLancamentosMesSeguinte(){
                const response = await service.countLancamentosMesSeguinte(idUnidade, anoContagem, mesAnoSeguinte);
                setCountLancamentosMesSeguinte(response.data);
            }
            buscarLancamentosMesSeguinte();
        }

    }, [countLancamentosMesSeguinte, mes, showConfReplica]);

    //FUNÇÃO PARA CRIAR O LANÇAMENTO E VINCULAR O COOPERADO AO MESMO 
    const vincularCooperado = (id) => {
        lancamentoCooperado['cooperado'] = {id};
        lancamentoCooperado['unidade'] = {id: Number.parseInt(idUnidade)};
        lancamentoCooperado['mesLancamento'] = mes;
        lancamentoCooperado['anoLancamento'] = ano;
        lancamentoCooperado['statusLancamento'] = true;

        service.salvar(lancamentoCooperado)
        .then(() => {
            mensagemSucesso("Cooperado vinculado com sucesso.");
            setTimeout(() => {
                window.location.reload()
            },1000)
            handleClose();
        })
        .catch((e) => {
            mensagemErro(e.response.data)
        })
    }

    function handlePageClick(data) {
        setPaginaAtual(data.selected);
    }

    //ABRINDO MODAL DE EDIÇÃO DO LANÇAMENTO
    const abrirEditModal = (id) => {
        limparDados();
        handleEditShow();

        if(id){
            service.obterPorId(id)
            .then((response) => {
                if(response.data.funcao){
                    setIdFuncao(response.data.funcao.id);
                }

                if(response.data.tipoCooperado){
                    setIdTipoCooperado(response.data.tipoCooperado)
                    let tipo = response.data.tipoCooperado;
                    if(tipo.match(/MENSALISTA/)){
                        setExibirAbasMensalista(true)
                    }else{
                        setExibirAbasMensalista(false)
                    }
                }
                if(response.data.empresa){
                    setIdEmpresa(response.data.empresa);
                }
                setLancamento(response.data)
            })
        }
    }

    const abrirModalLancamento = (id, tipoCooperado) => {
        limparFaltasHoras();
        setIdLancamentoCooperado(id);
        serviceAssinaturaDigital.verificarSeAssinado(Number(idUnidade), ano, mes, "COOPERADO")
        .then((s) => {
            if (s.data > 0) {
                mensagemErro("O Quadro de Cooperado esta ASSINADO, para prosseguir com alterações nos Lançamentos de Cooperado deve apagar a assinatura.")
            }
            if (s.data === 0) {
                
                handleLancShow();
                if(!tipoCooperado){ 
                    mensagemErro('Atualize as informações antes de fazer os lançamentos');
                    return;
                }
                if(id){
                    service.obterPorId(id)
                    .then((response) => {
    
                        if(response.data.tipoCooperado){
                            setIdTipoCooperado(response.data.tipoCooperado)
                            let tipo = response.data.tipoCooperado;
                            if(tipo.match(/MENSALISTA/)){
                                setExibirAbasMensalista(true)
                                setKey('faltas');
                            }else{
                                setExibirAbasMensalista(false)
                                setKey('substituicao');
                            }
                        }
                        setLancamento(response.data);
                    })
                }
            }
        })
    }

    const closeModalLanc = () => {
        setOcultarCheckDiariasAvulsa()
        handleLancClose();
    }

    //EXCLUINDO O LANÇAMENTO
    const deletar = (id) => {
        serviceFaltasHorasCooperado.buscarFaltasEHorasPorLancamentoCooperado(id)
            .then((response) => {
                const result = response.data;

                if(result.length > 0){
                    mensagemErro("Não é possível excluir o registro. Já existem horas ou faltas lançadas.");
                }else{
                    service.deletar(id)
                        .then(() => {
                            mensagemSucesso('Lançamento deletado com sucesso.')
                            handleConfclose()
                        }).catch( error => {
                            mensagemErro(error.response.data);
                        })
                }
            })
    }

    //FUNÇÃO PARA FORMATAR A EXIBIDAÇÃO DA DATA
    const formatarData = (dataF) => {
        if(dataF){
            var data = new Date(dataF),
            dia  = data.getUTCDate().toString().padStart(2, '0'),
            mes  = (data.getUTCMonth()+1).toString().padStart(2, '0'),
            ano  = data.getUTCFullYear();

            return dia+"/"+mes+"/"+ano;
        }
    }

    //FUNÇÃO PARA LIMPAR OS DADOS DO FORMULÁRIO
    const limparDados = () => {

        setLancamento({
            id: null,
            cooperado: null,
            unidade: null,
            mesLancamento: '',
            anoLancamento: '',
            turno: '',
            dataInicioUnidade: null,
            dataDesligamentoUnidade: null,
            tipoCooperado: '',
            diasDaSemana: '',
            empresa: ''
        })

        setIdFuncao(null)
        setIdTipoCooperado('')
        setIdEmpresa('')

        setListaFaltaHorasCooperado([]);
        handleEditClose();
    }

    function mascaraCpf(c){
        return c.match(/.{1,3}/g).join(".").replace(/\.(?=[^.]*$)/,"-")
      }

    //SALVAR OS LANÇAMENTOS DE HORAS E FALTAS
    const salvarFaltaEHoras = () => {
        faltasHorasCooperado['tipoLancamento'] = key
        faltasHorasCooperado.motivoAcrescimoHoras = motivoDiariaAvulsa
        if(key === 'faltas'){
            if(dataFaltaLista.length < 1){
                mensagemErro("Data da falta é obrigatória!!.");
            }else if(!faltasHorasCooperado.horasFalta || faltasHorasCooperado.horasFalta < 1 || faltasHorasCooperado.horasFalta > 12){
                mensagemErro(`${!faltasHorasCooperado.horasFalta ? 'Informe a quantidade de horas.' : 'A quantidade de horas deve está entre 1 e 12.'}`);
            }else{
                faltasHorasCooperado.id = null;
                faltasHorasCooperado.listaDataFaltas = dataFaltaLista.toString().split(",");
                faltasHorasCooperado.dataFalta = null;
                faltasHorasCooperado.dataSubstituicao = null;
                faltasHorasCooperado.dataHorasAcrescentar = null;
                faltasHorasCooperado.dataSubstituicao = null;
                faltasHorasCooperado.cooperado = null;
                faltasHorasCooperado.servidor = null;
                faltasHorasCooperado.funcaoSubstituido = null;
                faltasHorasCooperado.lancamentoCooperado = lancamento;
                serviceFaltasHorasCooperado.salvar(faltasHorasCooperado)
                .then((response) => {
                    if(response.data === true){
                        setSalvou(true);
                        mensagemSucesso('Lançamento salvo com sucesso.');
                        limparFaltasHoras();
                    }else{
                        mensagemErro("Não foi possível inserir o registro.");
                    }
                }).catch((error) => {
                    mensagemErro(error.response.data.mensagem);
                })
            }
            setSalvou(false);

        }else if(key === 'horas'){
            faltasHorasCooperado.motivoAcrescimoHoras = motivoDiariaAvulsa
            if(dataHoraLista.length < 1){
                mensagemErro("Data é obrigatória.");
                return;

            }else if(!faltasHorasCooperado.horasAcrescentar || faltasHorasCooperado.horasAcrescentar < 1 || faltasHorasCooperado.horasAcrescentar > 12){
                mensagemErro(`${!faltasHorasCooperado.horasAcrescentar ? 'Informe a quantidade de horas.' : 'A quantidade de horas deve está entre 1 e 12.'}`);
                return;

            }else if(!faltasHorasCooperado.motivoAcrescimoHoras || faltasHorasCooperado.motivoAcrescimoHoras.trim() === ''){
                mensagemErro("O motivo obrigatório.");
                return;

            }else{
                faltasHorasCooperado.id = null;
                faltasHorasCooperado.listaDataHoras = dataHoraLista.toString().split(",");
                faltasHorasCooperado.dataFalta = null;
                faltasHorasCooperado.dataSubstituicao = null;
                faltasHorasCooperado.dataHorasAcrescentar = null;
                faltasHorasCooperado.cooperado = null;
                faltasHorasCooperado.servidor = null;
                faltasHorasCooperado.funcaoSubstituido = null;
                faltasHorasCooperado.lancamentoCooperado = lancamento;
                serviceFaltasHorasCooperado.salvar(faltasHorasCooperado)
                .then((response) => {
                    if(response.data === true){
                        setSalvou(true);
                        mensagemSucesso('Lançamento salvo com sucesso.');
                        limparFaltasHoras();
                    }else{
                        mensagemErro("Não foi possível inserir o registro.")
                    }
                }).catch((error) => {
                    mensagemErro(error.response.data.mensagem);
                })
            }
            setSalvou(false);

        }else{
            faltasHorasCooperado.motivoAcrescimoHoras = motivoDiariaAvulsa
            if(dataFaltaListaSub.length < 1){
                mensagemErro("Data é obrigatória.");
                return;
            }
            if (diariaAvulsa) {
                if(faltasHorasCooperado.motivoAcrescimoHoras === null || faltasHorasCooperado.motivoAcrescimoHoras.trim() === ''){
                    mensagemErro("Descrição da Diária Avulsa é obrigatório.");
                    return;
                }
            }
            if (!diariaAvulsa) {
                if(!isChecked ? !idCooperado : !idServidor){
                    mensagemErro(`${!isChecked ? 'Cooperado é obrigatório.' : 'Servidor é obrigatório.'}`);
                    return;
                }
            } 
            if(!idFuncaoSubstituido){
                mensagemErro(`Função do ${diariaAvulsa ? 'diária' : 'substituído'} é obrigatória.`);
                return;
            }else if(!faltasHorasCooperado.horasSubstituicao || faltasHorasCooperado.horasSubstituicao < 1 || faltasHorasCooperado.horasSubstituicao > 12){
                mensagemErro(`${!faltasHorasCooperado.horasSubstituicao ? 'Informe a quantidade de horas.' : 'A quantidade de horas deve está entre 1 e 12.'}`);
                return;
            }else{
                faltasHorasCooperado.id = null;
                faltasHorasCooperado.listaDataSubstituicao = dataFaltaListaSub.toString().split(",");
                faltasHorasCooperado.dataFalta = null;
                faltasHorasCooperado.dataSubstituicao = null;
                faltasHorasCooperado.dataHorasAcrescentar = null;
                if (!diariaAvulsa) {
                    if(isChecked){
                        faltasHorasCooperado.servidor = {id: Number.parseInt(idServidor)};
                        faltasHorasCooperado.cooperado = null;
                        
                    }else{
                        faltasHorasCooperado.cooperado = {id: Number.parseInt(idCooperado)};
                        faltasHorasCooperado.servidor = null;
                    }
                } else {
                    faltasHorasCooperado.servidor = null;
                    faltasHorasCooperado.cooperado = null;
                    faltasHorasCooperado.tipoLancamento = 'diaria'
                    faltasHorasCooperado.motivoAcrescimoHoras = motivoDiariaAvulsa
                }
                faltasHorasCooperado.funcaoSubstituido = {id: Number.parseInt(idFuncaoSubstituido)};
                faltasHorasCooperado.lancamentoCooperado = lancamento;
                serviceFaltasHorasCooperado.salvar(faltasHorasCooperado)
                .then((response) => {
                    if(response.data === true){
                        setSalvou(true);
                        mensagemSucesso('Lançamento salvo com sucesso.');
                        limparFaltasHoras();
                    }else{
                        mensagemErro("Não foi possível inserir o registro.")
                    }
                }).catch((error) => {
                    mensagemErro(error.response.data.mensagem);
                })
                setSalvou(false);
            }
        }
    }

    const atualizarFaltaHoras = () => {
        faltasHorasCooperado['tipoLancamento'] = key
        faltasHorasCooperado.motivoAcrescimoHoras = motivoDiariaAvulsa
        if(key === 'faltas'){
            if(faltasHorasCooperado.dataFalta !== null){
                if(checarData(faltasHorasCooperado.dataFalta) === false){
                    mensagemErro('Data falta tem o formato inválido.');
                    return;
                }
            }
            if(!faltasHorasCooperado.dataFalta){
                mensagemErro("Data da falta é obrigatória!!.");
            }else if(!faltasHorasCooperado.horasFalta || faltasHorasCooperado.horasFalta < 1 || faltasHorasCooperado.horasFalta > 12){
                mensagemErro(`${!faltasHorasCooperado.horasFalta ? 'Informe a quantidade de horas.' : 'A quantidade de horas deve está entre 1 e 12.'}`);
            }else{
                faltasHorasCooperado.lancamentoCooperado = lancamento;
                serviceFaltasHorasCooperado.atualizar(faltasHorasCooperado)
                .then((response) => {
                    if(response.data === true){
                        setSalvou(true);
                        mensagemSucesso('Lançamento salvo com sucesso.');
                        limparFaltasHoras();
                    }else{
                        mensagemErro("Não foi possível inserir o registro.");
                    }
                }).catch((error) => {
                    mensagemErro(error.response.data.mensagem);
                })
            }
            setSalvou(false);

        }else if(key === 'horas'){
            if(faltasHorasCooperado.dataHorasAcrescentar !== null){
                if(checarData(faltasHorasCooperado.dataHorasAcrescentar) === false){
                    mensagemErro('Data de acréscimo tem o formato inválido.');
                    return;
                }
            }
            if(!faltasHorasCooperado.dataHorasAcrescentar){
                mensagemErro("Data é obrigatória.");
                return;
            }else if(!faltasHorasCooperado.horasAcrescentar || faltasHorasCooperado.horasAcrescentar < 1 || faltasHorasCooperado.horasAcrescentar > 12){
                mensagemErro(`${!faltasHorasCooperado.horasAcrescentar ? 'Informe a quantidade de horas.' : 'A quantidade de horas deve está entre 1 e 12.'}`);
                return;
            }else if(!faltasHorasCooperado.motivoAcrescimoHoras || faltasHorasCooperado.motivoAcrescimoHoras.trim() === ''){
                mensagemErro("O motivo obrigatório.");
                return;
            }else{
                faltasHorasCooperado.lancamentoCooperado = lancamento;
                serviceFaltasHorasCooperado.atualizar(faltasHorasCooperado)
                .then((response) => {
                    if(response.data === true){
                        setSalvou(true);
                        mensagemSucesso('Lançamento salvo com sucesso.');
                        limparFaltasHoras();
                        setMotivoDiariaAvulsa('')
                    }else{
                        mensagemErro("Não foi possível inserir o registro.")
                    }
                }).catch((error) => {
                    mensagemErro(error.response.data.mensagem);
                })
            }
            setSalvou(false);

        }else{
            faltasHorasCooperado.motivoAcrescimoHoras = motivoDiariaAvulsa
            if(faltasHorasCooperado.dataSubstituicao !== null){
                if(checarData(faltasHorasCooperado.dataSubstituicao) === false){
                    mensagemErro('Data de substituição tem o formato inválido.');
                    return;
                }
            }
            if(!faltasHorasCooperado.dataSubstituicao){
                mensagemErro("Data é obrigatória.");
                return;
            }
            if (!diariaAvulsa) {
                if(!isChecked ? !idCooperado : !idServidor){
                    mensagemErro(`${!isChecked ? 'Cooperado é obrigatório.' : 'Servidor é obrigatório.'}`);
                    return;
                }
            } 
            if (diariaAvulsa) {
                if(!faltasHorasCooperado.motivoAcrescimoHoras || faltasHorasCooperado.motivoAcrescimoHoras.trim() === ''){
                    mensagemErro("O motivo obrigatório.");
                    return;
                } 
            }
            if(!faltasHorasCooperado.funcaoSubstituido){
                mensagemErro('Função do substituído é obrigatória.');
                return;
            }else if(!faltasHorasCooperado.horasSubstituicao || faltasHorasCooperado.horasSubstituicao < 1 || faltasHorasCooperado.horasSubstituicao > 12){
                mensagemErro(`${!faltasHorasCooperado.horasSubstituicao ? 'Informe a quantidade de horas.' : 'A quantidade de horas deve está entre 1 e 12.'}`);
                return;
            }else{
                faltasHorasCooperado.lancamentoCooperado = lancamento;
                if (!diariaAvulsa) {
                    if(isChecked){
                        faltasHorasCooperado.servidor = {id: Number.parseInt(idServidor)};
                        faltasHorasCooperado.cooperado = null;
                        
                    }else{
                        faltasHorasCooperado.cooperado = {id: Number.parseInt(idCooperado)};
                        faltasHorasCooperado.servidor = null;
                    }
                } else {
                    faltasHorasCooperado.servidor = null;
                    faltasHorasCooperado.cooperado = null;
                    faltasHorasCooperado.tipoLancamento = 'diaria'
                }
                faltasHorasCooperado.funcaoSubstituido = {id: Number.parseInt(idFuncaoSubstituido)};
                serviceFaltasHorasCooperado.atualizar(faltasHorasCooperado)
                .then((response) => {
                    if(response.data === true){
                        setSalvou(true);
                        mensagemSucesso('Lançamento salvo com sucesso.');
                        limparFaltasHoras();
                        ocultarExibirCheckDiariasAvulsar()
                    }else{
                        mensagemErro("Não foi possível inserir o registro.")
                    }
                }).catch((error) => {
                    mensagemErro(error.response.data.mensagem);
                })
            }
            setSalvou(false);
        }
    }

    const limparFaltasHoras = () => {
        setFaltasHorasCooperado ({
            id: '',
            lancamentoCooperado: '',
            faltaOuHorasAcrescentar: '',
            dataFalta: '',
            horasFalta: '',
            cooperado: '',
            servidor: '',
            funcaoSubstituido: '',
            dataHorasAcrescentar: '',
            horasAcrescentar: '',
            dataSubstituicao: '',
            horasSubstituicao: '',
            motivoAcrescimoHoras: ''
        })

        dataFaltaLista.length = 0;
        dataHoraLista.length = 0;
        dataFaltaListaSub.length = 0;

        setIdCooperado('');
        setIdServidor('');
        setIdFuncaoSubstituido('');
        setIsChecked(false);
        setDiariaAvulsa(false)
        setMotivoDiariaAvulsa('')
        
    }

    //EDIÇÃO DE HORAS E FALTAS
    const editarHorasFaltas = (id) => {
        
        ocultarExibirCheckDiariasAvulsar()
        if(key === 'faltas'){
            setFaltasHorasCooperado({
                dataHorasAcrescentar: '',
                horasAcrescentar: 0,
                dataSubstituicao: '',
                horasSubstituicao: 0
            })
        }else if(key === 'horas'){
            setFaltasHorasCooperado({
                dataFalta: '',
                horasFalta: 0,
                dataSubstituicao: '',
                horasSubstituicao: 0,
                motivoAcrescimoHoras: ''
            })
        }else{
            setFaltasHorasCooperado({
                dataHorasAcrescentar: '',
                horasAcrescentar: 0,
                dataFalta: '',
                horasFalta: 0
            })
        }

        if(id){
            serviceFaltasHorasCooperado.obterPorId(id)
            .then((response) => {
                if(key === 'substituicao' && response.data.tipoLancamento !== 'diaria'){
                    if(response.data.cooperado){
                        setIdCooperado(response.data.cooperado.id);
                        setIsChecked(false);
                    }else{
                        setIdServidor(response.data.servidor.id);
                        setIsChecked(true);
                    }
                    setOcultarCheckDiariasAvulsa(true)
                    setDiariaAvulsa(false)
                    //setIdFuncaoSubstituido(response.data.funcaoSubstituido.id)
                    setIdFuncaoSubstituido(response.data.funcaoSubstituido.id)
                }
                if(key === 'substituicao' && response.data.tipoLancamento === 'diaria'){
                    setOcultarCheckDiariasAvulsa(true)
                    setDiariaAvulsa(true)
                    setMotivoDiariaAvulsa(response.data.motivoAcrescimoHoras !== null ? response.data.motivoAcrescimoHoras : null)
                    setIdFuncaoSubstituido(response.data.funcaoSubstituido.id)
                }
                setFaltasHorasCooperado(response.data)
                setMotivoDiariaAvulsa(response.data.motivoAcrescimoHoras !== null ? response.data.motivoAcrescimoHoras : null)
            })
        }
    }

    const excluirHorasFaltas = (id) => {
        serviceFaltasHorasCooperado.deletar(id)
            .then(() => {
                setIsRemoved(true);
                mensagemSucesso('Registro deletado com sucesso.')
                handleConfFaltasclose()
            }).catch( error => {
                mensagemErro(error.data);
            })
            setIsRemoved(false);
    }

    const atualizar = () => {
        lancamento['tipoCooperado'] = idTipoCooperado;
        lancamento['empresa'] = idEmpresa;
        if(lancamento.dataInicioUnidade !== null){
            if(checarData(lancamento.dataInicioUnidade) === false){
                mensagemErro('Data de início tem o formato inválido.');
                return;
            }
        }

        if(lancamento.dataDesligamentoUnidade !== null){
            if(checarData(lancamento.dataDesligamentoUnidade) === false){
                mensagemErro('Data de desligamento tem o formato inválido.');
                return;
            }
        }

        if(!lancamento.tipoCooperado){
            mensagemErro("O tipo de cooperado é obrigatório.")
            return false;
        }

        if(!lancamento.turno){
             mensagemErro("O turno é obrigatório.")
            return false;
        }

        if(idTipoCooperado === 'MENSALISTA'){
            if(!idFuncao){
                mensagemErro("A função é obrigatória.")
                return false;
            }else{
                lancamento['funcao'] = {id: Number.parseInt(idFuncao)};
            }

             if(!lancamento.chDiaria || lancamento.chDiaria < 1 || lancamento.chDiaria > 12){
                mensagemErro(`${!lancamento.chDiaria ? 'Informe a quantidade de horas.' : 'A quantidade de horas deve está entre 1 e 12.'}`);
                return false;
            }else{
                lancamento.chDiaria = Number.parseInt(lancamento.chDiaria)
            }

            if(lancamento.diasDaSemana === '' || lancamento.diasDaSemana === null){
                mensagemErro("O campo dias da semana é obrigatório.")
                return false;
            }

            if(lancamento.dataDesligamentoUnidade === '' || lancamento.dataDesligamentoUnidade === null){
                lancamento.dataDesligamentoUnidade = null
            }

            if(lancamento.dataInicioUnidade === '' || lancamento.dataInicioUnidade === null){
                mensagemErro("O campo Data de Início é obrigatório.")
                return false;
            }
        }
        
        service.atualizar(lancamento)
            .then(() => {
                mensagemSucesso('Lancamento atualizado com sucesso.')
            })
            .catch((e) => {
                mensagemErro(e.response.data)
            })
    }

    const replicar = () => {

        if(Number(mes) !== 12){
            service.replicarLancamentos(idUnidade, ano, mes)
                .then(() => {
                    mensagemSucesso("A replicação foi um sucesso!");
                    handleConfReplicaClose();
                })
        }else{
            serviceAno.anoExiste(Number(ano) +1).then((response) => {
                if(response.data === true){
                    service.replicarLancamentos(idUnidade, ano, mes)
                    .then(() => {
                        mensagemSucesso("A replicação foi um sucesso!");
                        handleConfReplicaClose();
                    })
                }else{
                    mensagemErro("Ano seguinte ainda não foi criado no sistema. Solicite ao RH.")
                }
            })
        }
    }

    const handleChangeTipoCooperado = (e) => {
        setIdTipoCooperado(e.target.value)

        let tipo = e.target.selectedOptions[0].value;

        if(tipo.match(/MENSALISTA/)){
            setExibirAbasMensalista(true);
        }else{
            setExibirAbasMensalista(false);
        }
    }

    function checarData(data) {
        let dataAno = new Date(data);
        if(data !== ""){
            if(dataAno.getFullYear().toString().length === 4){
                return true;
            }else{
                return false;
            }
        }
    }

    return(
        <>
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs lg="4">
                        <AnoMes
                            htmlForMes="mesLancamento"
                            idMes="mesLancamento"
                            nameMes="mesLancamento"
                            valueMes={mes}
                            onChangeMes={(e) => setMes(e.target.value)}
                            htmlForAno="anoLancamento"
                            idAno="anoLancamento"
                            nameAno="anoLancamento"
                            valueAno={ano}
                            onChangeAno={(e) => setAno(e.target.value)}
                        />
                    </Col>
                    <Col lg={1} style={{ marginTop: "37px"}}>
                        {(lancamentoEstaAberto) &&
                            <button className="btn btn-primary" type="button" onClick={handleShow}>
                                Vincular
                            </button>
                        } 
                    </Col>

                    <Col lg={2} style={{marginTop: "37px"}}>
                       {(countLancamentosMesSeguinte < 1) && (lancamentos.length > 0) &&
                            <button type='button' className='btn btn-primary' onClick={handleConfReplicaShow}>
                                Replicar
                            </button>
                        } 
                    </Col>
                </Row>

                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg" >
                    <Modal.Header>
                        <Modal.Title id="lancamentoModal">
                            <h2>Cooperados</h2>
                        </Modal.Title>

                        <Col md={2} style={{ float: "left" }}>
                            <button className="btn btn-danger" onClick={handleClose}>
                                Fechar
                            </button>
                        </Col>
                    </Modal.Header>

                    <Modal.Body>
                        <Card title="Listagem de Cooperados"> 
                            <br></br>
                            <div >
                                <Row>
                                    <Col md={6}>
                                        <input
                                            id="nomeBuscar"
                                            name="nomeBuscar"
                                            type="text"
                                            className="form-control"
                                            placeholder="Digite o nome para pesquisar..."
                                            value={nomeBuscar}
                                            onChange={(buscar) => setNomeBuscar(buscar.target.value)}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <br></br>
                            <div>
                                <table id="tabela_cooperados" className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th> Nome </th>
                                            <th>CPF</th>
                                            <th style={{textAlign: "center", width: "40px"}}>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cooperados.map((c) => {
                                            const cpf = mascaraCpf(c.cpf)
                                            return (
                                                <tr key={c.id}>
                                                    <td>{c.nome}</td>
                                                    <td>{cpf}</td>
                                                    <td style={{textAlign: "center"}}>
                                                        <button 
                                                            className="btn btn-primary" 
                                                            type="button" 
                                                            onClick={() => vincularCooperado(c.id)}> Vincular </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                {cooperados.length > 0 &&
                                    <ReactPaginate
                                        previousLabel={"Voltar"}
                                        nextLabel={"Avançar"}
                                        breakLabel={"..."}
                                        pageCount={numeroDePaginas}
                                        marginPagesDisplayed={3}
                                        pageRangeDisplayed={2}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination justify-content-center"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                        initialPage={pesquisou}
                                    />
                                }
                            </div>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        Pesquise o cooperado e vincule a sua unidade para informar os lançamentos.
                    </Modal.Footer>
                </Modal>
            </Container>

            <Container className="mt-4">
                <Card title="Lista de Lançamentos de Cooperados">
                    <Table id="tabela_lancamentos" striped bordered hover size="md">
                        <thead>
                            {lancamentos.length > 0 ?
                                <>
                                    <tr>
                                        <th>##</th>
                                        <th>Nome do Cooperado</th>
                                        <th>Início na Unidade</th>
                                        <th>Função</th>
                                        <th>Turno</th>
                                        <th>Tipo de Cooperado</th>
                                        <th>C.H. Diária</th>
                                    </tr>
                                </>
                                :
                                <>
                                </>
                            }
                        </thead>
                        <tbody>
                            {lancamentos.length > 0 ? 
                                <>
                                    {lancamentos.map((l, index) => {
                                        return(
                                            <tr key={l.id}>
                                                <td>{index + 1}</td>
                                                <td>{l.cooperado.nome}</td>
                                                <td>{formatarData(l.dataInicioUnidade)}</td>
                                                <td>{l.funcao ? l.funcao.nome : ''}</td>
                                                <td>{l.turno}</td>
                                                <td>{l.tipoCooperado}</td>
                                                <td style={{textAlign: "center"}}>{l.chDiaria}</td>
                                                <td className="col-md-2" style={{textAlign: "center"}}>
                                                    {l.statusLancamento.toString() === 'false' ?
                                                        <button className="btn btn-success" disabled onClick={() => abrirModalLancamento(l.id)}>
                                                            <i className='pi pi-plus-circle'></i>
                                                        </button>
                                                        :
                                                        <button className="btn btn-success" title='Fazer lançamento' onClick={() => abrirModalLancamento(l.id, l.tipoCooperado)}>
                                                            <i className='pi pi-plus-circle'></i>
                                                        </button>
                                                    }
                                                    {l.statusLancamento.toString() === 'false' ?
                                                        <button className="btn btn-primary" style={{margin: "0 5px"}} disabled onClick={() => abrirEditModal(l.id)}>
                                                            <i className='pi pi-pencil'></i>
                                                        </button>
                                                        :
                                                        <button className="btn btn-primary" title='Editar' style={{margin: "0 5px"}} onClick={() => abrirEditModal(l.id)}>
                                                            <i className='pi pi-pencil'></i>
                                                        </button>
                                                    }
                                    
                                                    {l.statusLancamento.toString() === 'false' ?
                                                        <button className="btn btn-danger" disabled onClick={() => handleConfShow(setImputId(`${l.id}`))} >
                                                            <i className='pi pi-trash'></i>
                                                        </button>
                                                        :
                                                        <button className="btn btn-danger" title='Excluir' onClick={() => handleConfShow(setImputId(`${l.id}`))} >
                                                            <i className='pi pi-trash'></i>
                                                        </button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </>
                                : 
                                <>
                                    <tr>
                                        <td style={{fontSize: '1.5rem'}}>Nenhum registro encontrado.</td>
                                    </tr>
                                </>
                            }
                        </tbody>
                    </Table>
                </Card>

                <Modal show={showConfReplica} onHide={handleConfReplicaClose} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <h3>Confirmação</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Essa ação criará uma cópia de todos os lancamentos desse mês no mês posterior. Deseja prosseguir?</h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={replicar}>
                            Confirmar
                        </Button>
                        <Button className="btn btn-secondary" onClick={handleConfReplicaClose}>
                            Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showConf} onHide={handleConfclose} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <h3>Confirmação</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Você realmente deseja excluir o registro?</h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={() => deletar(imputId)}>
                            Confirmar
                        </Button>
                        <Button className="btn btn-secondary" onClick={handleConfclose}>
                            Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showEditConf} onHide={handleEditClose} backdrop="static" keyboard={false} size="xl">
                    <Modal.Body>
                        <Container>
                            <Card title="Edições de Lançamentos">
                                <Form>
                                    <Row>
                                        <Col md={5}>
                                            <label className="col-form-label" htmlFor="cooperado">Cooperado:</label>
                                            <input type="text" 
                                                className="form-control" 
                                                id="cooperado" 
                                                name="cooperado"
                                                disabled
                                                value={!lancamento.cooperado ? null : lancamento.cooperado.nome}
                                                onChange={onChangeLancamento}
                                            />
                                        </Col>
                                        <Col md={5}>
                                            <label className="col-form-label" htmlFor="unidade">Unidade:</label>
                                            <input type="text" 
                                                className="form-control" 
                                                id="unidade" 
                                                name="unidade"
                                                disabled
                                                value={!lancamento.unidade ? null : lancamento.unidade.nome}
                                                onChange={onChangeLancamento}
                                            />
                                        </Col>
                                        <Col md={2}>
                                            <label className="col-form-label" htmlFor="mesLancamento">Mês/Ano:</label>
                                            <input type="text" 
                                                className="form-control" 
                                                id="mesLancamento" 
                                                name="mesLancamento"
                                                disabled
                                                value={`${lancamento.mesLancamento}/${lancamento.anoLancamento}`}
                                                onChange={onChangeLancamento} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <label className="col-form-label" htmlFor="tipoCooperado">Tipo de Cooperado:</label>
                                            <Form.Select 
                                                className="form-control" 
                                                id="tipoCooperado" 
                                                name="tipoCooperado"
                                                value={idTipoCooperado}
                                                onChange={handleChangeTipoCooperado} 
                                            >
                                                <option value="">Selecione</option>
                                                <option value="DIARISTA">Diarista</option>
                                                <option value="MENSALISTA">Mensalista</option>
                                            </Form.Select>
                                        </Col>
                                        <Col md={2}>
                                            <label className="col-form-label" htmlFor="turno">Turno:</label>
                                            <Form.Select 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Turno" 
                                                id="turno" 
                                                name="turno"
                                                value={lancamento.turno}
                                                onChange={onChangeLancamento} 
                                            >
                                                <option value="">Selecione</option>
                                                <option value="Matutino">Matutino</option>
                                                <option value="Vespertino">Vespertino</option>
                                                <option value="Noturno">Noturno</option>
                                                <option value="Integral">Integral</option>
                                            </Form.Select>
                                        </Col>
                                        <Col md={4}>
                                            <label className="col-form-label" htmlFor="empresa">Empresa:</label>
                                            <Form.Select 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Empresa" 
                                                id="empresa" 
                                                name="empresa"
                                                value={idEmpresa}
                                                onChange={(e) => setIdEmpresa(e.target.value)} 
                                            >
                                                <option value="">Selecione</option>
                                                <option value="COOPERVALE">Coopervale</option>
                                                <option value="COOPERVISO">Cooperviso</option>
                                                <option value="COOPERSERVS">Cooperservs</option>
                                            </Form.Select>
                                        </Col>
                                        {exibirAbasMensalista &&
                                            <Col md={4}>
                                                <label className="col-form-label" htmlFor="funcao">Função:</label>
                                                <Form.Select
                                                    id="funcao" 
                                                    name="funcao" 
                                                    value={idFuncao} 
                                                    onChange={(e) => setIdFuncao(e.target.value)}
                                                >
                                                    <option value="">Selecione</option>
                                                    {funcoes.sort((a, b) => a.nome > b.nome ? 1:-1).map((f) => (
                                                        <option value={f.id} key={f.id} > {f.nome} </option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                        }
                                    </Row>
                                    {exibirAbasMensalista &&
                                    <Row>
                                        <Col md={2}>
                                            <label className="col-form-label" htmlFor="chDiaria">C.H. Diária:</label>
                                            <input type="number" 
                                                className="form-control" 
                                                min={1} max={12}
                                                id="chDiaria"
                                                name="chDiaria"
                                                value={lancamento.chDiaria}
                                                onChange={onChangeLancamento}>
                                            </input>
                                        </Col>
                                        <Col md={3}>
                                            <label className="col-form-label" htmlFor="diasDaSemana">Dias da Semana:</label>
                                            <Form.Select 
                                                type="text" 
                                                className="form-control" 
                                                id="diasDaSemana" 
                                                name="diasDaSemana"
                                                value={lancamento.diasDaSemana}
                                                onChange={onChangeLancamento} 
                                            >
                                                <option value="">Selecione</option>
                                                <option value="SS">Segunda a Sexta</option>
                                                <option value="SD">Sábado e Domingo</option>
                                            </Form.Select>
                                        </Col>
                                        <Col md={3}>
                                            <label className="col-form-label" htmlFor="dataInicioUnidade">Data de Início:</label>
                                            <input type="date" 
                                                className="form-control" 
                                                id="dataInicioUnidade"
                                                name="dataInicioUnidade"
                                                value={lancamento.dataInicioUnidade}
                                                onChange={onChangeLancamento}
                                            />   
                                        </Col>
                                        <Col md={4}>
                                            <label className="col-form-label" htmlFor="dataDesligamentoUnidade">Data de Desligamento:</label>
                                            <input type="date" 
                                                className="form-control" 
                                                id="dataDesligamentoUnidade"
                                                name="dataDesligamentoUnidade"
                                                value={lancamento.dataDesligamentoUnidade}
                                                onChange={onChangeLancamento}
                                            />
                                        </Col>
                                    </Row>
                                    }
                                    <br/>
                                    <div className="text-center">
                                        <button 
                                            type="button" 
                                            onClick={atualizar} 
                                            className="btn btn-primary"> Atualizar 
                                        </button> 
                                    </div>
                                </Form>
                            </Card>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={limparDados}>
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showLanc} onHide={handleLancClose} backdrop="static" keyboard={false} size="xl">
                    <Modal.Body>
                        <Card title="Lançamentos">
                            <Form className='mb-4'>
                                <Row>
                                    <Col md={5}>
                                        <label className="col-form-label" htmlFor="cooperado">Cooperado:</label>
                                        <input type="text" 
                                            className="form-control" 
                                            id="cooperado" 
                                            name="cooperado"
                                            disabled
                                            value={!lancamento.cooperado ? null : lancamento.cooperado.nome}
                                            onChange={onChangeLancamento}
                                        />
                                    </Col>
                                    <Col md={5}>
                                        <label className="col-form-label" htmlFor="unidade">Unidade:</label>
                                        <input type="text" 
                                            className="form-control" 
                                            id="unidade" 
                                            name="unidade"
                                            disabled
                                            value={!lancamento.unidade ? null : lancamento.unidade.nome}
                                            onChange={onChangeLancamento}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <label className="col-form-label" htmlFor="mesLancamento">Mês/Ano:</label>
                                        <input type="text" 
                                            className="form-control" 
                                            id="mesLancamento" 
                                            name="mesLancamento"
                                            disabled
                                            value={`${lancamento.mesLancamento}/${lancamento.anoLancamento}`}
                                            onChange={onChangeLancamento} />
                                    </Col>
                                </Row>
                            </Form> 
                            <hr></hr>
                            <Tabs  
                                id="controlled-tab-example"
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                                className="justify-content-center"
                            >
                                {exibirAbasMensalista &&
                                    <Tab eventKey="faltas" title="Lançamento de Faltas" >
                                        <Row>
                                            {faltasHorasCooperado.id < 1 &&
                                                <label className="col-form-label mt-4" htmlFor="dataFaltaLista"> Datas das faltas:</label>
                                            }

                                            {faltasHorasCooperado.id < 1 ?
                                                <DatePicker
                                                    mapDays={({ date }) => {
                                                        let props = {}
                                                        let isWeekend = [0, 6].includes(date.weekDay.index)
                                                        
                                                        if (isWeekend) props.className = "highlight highlight-red"
                                                        
                                                        return props
                                                    }}
                                                    className="mb-3"
                                                    onChange={setDataFaltaLista}
                                                    value={faltasHorasCooperado.listaDataFaltas}
                                                    id="dataFaltaLista"
                                                    name="dataFaltaLista"
                                                    numberOfMonths={2}
                                                    multiple
                                                    sort
                                                    format="DD/MM/YYYY"
                                                    locale={calendarioPersonalizado}
                                                    plugins={[<DatePanel />]}
                                                    style={{
                                                        width: "100%",
                                                        height: "38px",
                                                        padding: "12px 20px",
                                                        boxSizing: "border-box",
                                                    }}
                                                />
                                                :
                                                <Col>
                                                    <label className="col-form-label mt-4" htmlFor="dataFalta">Data da Falta:</label>
                                                    {faltasHorasCooperado.id < 1 ? 
                                                        <input type="date" 
                                                            className="form-control" 
                                                            id="dataFalta"
                                                            name="dataFalta"
                                                            value={faltasHorasCooperado.dataFalta}
                                                            onChange={onChangeHorasFaltas}>
                                                        </input>
                                                    :
                                                        <input type="date" 
                                                            className="form-control" 
                                                            id="dataFalta"
                                                            name="dataFalta"
                                                            disabled
                                                            value={faltasHorasCooperado.dataFalta}
                                                            onChange={onChangeHorasFaltas}>
                                                        </input>
                                                    }
                                                </Col>
                                            }
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <label className="col-form-label mt-4" htmlFor="horasFalta">Horas Faltadas:</label>
                                                <input type="number" 
                                                    className="form-control" 
                                                    min={1} max={12}
                                                    id="horasFalta"
                                                    name="horasFalta"
                                                    value={faltasHorasCooperado.horasFalta}
                                                    onChange={onChangeHorasFaltas}>
                                                </input>
                                            </Col>
                                        </Row>
                                        
                                        <div className="text-center mt-4">
                                            {faltasHorasCooperado.id > 0 ?
                                                <button 
                                                    type="button" 
                                                    onClick={atualizarFaltaHoras} 
                                                    className="btn btn-primary"> Atualizar 
                                                </button> 
                                                :
                                                <button 
                                                    type="button" 
                                                    onClick={salvarFaltaEHoras} 
                                                    className="btn btn-primary"> Salvar 
                                                </button>
                                            }
                                        </div>

                                        <hr></hr> <br/>

                                        <Card title="Listagem de Faltas">
                                            <Table id="tabela_lancamentos_faltas" striped bordered hover size="md">
                                                <thead>
                                                    <tr>
                                                        <th>Data da Falta</th>
                                                        <th>Horas Faltadas</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listaFaltaHorasCooperado.map((fh) => {
                                                        if(fh.tipoLancamento === 'faltas')
                                                            return (
                                                                <tr key={fh.id}>
                                                                    <td>{formatarData(fh.dataFalta)}</td>
                                                                    <td>{fh.horasFalta}</td>
                                                                    <td className='col-md-2' style={{textAlign: "center"}}>
                                                                        <button className="btn btn-primary" title='Editar' type="button" style={{margin: "0 5px"}} onClick={() => editarHorasFaltas(fh.id)}>
                                                                            <i className='pi pi-pencil'></i>
                                                                        </button>

                                                                        <button onClick={() => handleConfFaltasShow(setImputIdFaltas(`${fh.id}`))} title='Excluir' className="btn btn-danger">
                                                                            <i className='pi pi-trash'></i>
                                                                        </button>
                                                                    </td>
                                                                    <td style={{display: "none"}}>{horasFaltas += fh.horasFalta}</td>
                                                                </tr>
                                                            );
                                                    })}
                                                    <span><strong>Total de horas de faltas:</strong> {horasFaltas}</span>
                                                </tbody>
                                            </Table>
                                        </Card>
                                    </Tab>
                                }

                                {exibirAbasMensalista &&
                                    <Tab eventKey="horas" title="Horas Acrescentar" >
                                        <Row>
                                            {faltasHorasCooperado.id < 1 &&
                                                <label className="col-form-label mt-4" htmlFor="dataHoraLista"> Datas dos Acréscimos de Horas:</label>
                                            }

                                            {faltasHorasCooperado.id < 1 ?
                                                <DatePicker
                                                    mapDays={({ date }) => {
                                                        let props = {}
                                                        let isWeekend = [0, 6].includes(date.weekDay.index)
                                                        
                                                        if (isWeekend) props.className = "highlight highlight-red"
                                                        
                                                        return props
                                                    }}
                                                    className="mb-3"
                                                    onChange={setDataHoraLista}
                                                    value={faltasHorasCooperado.listaDataHoras}
                                                    id="dataHoraLista"
                                                    name="dataHoraLista"
                                                    numberOfMonths={2}
                                                    multiple
                                                    sort
                                                    format="DD/MM/YYYY"
                                                    locale={calendarioPersonalizado}
                                                    plugins={[<DatePanel />]}
                                                    style={{
                                                        width: "100%",
                                                        height: "38px",
                                                        padding: "12px 20px",
                                                        boxSizing: "border-box",
                                                    }}
                                                />
                                                :
                                                <Col>
                                                    <label className="col-form-label mt-4" htmlFor="dataHorasAcrescentar">Data do Acréscimo:</label>
                                                    {faltasHorasCooperado.id < 1 ?
                                                        <input type="date" 
                                                            className="form-control" 
                                                            id="dataHorasAcrescentar"
                                                            name="dataHorasAcrescentar"
                                                            value={faltasHorasCooperado.dataHorasAcrescentar}
                                                            onChange={onChangeHorasFaltas}>
                                                        </input>
                                                    :
                                                        <input type="date" 
                                                            className="form-control" 
                                                            id="dataHorasAcrescentar"
                                                            name="dataHorasAcrescentar"
                                                            disabled
                                                            value={faltasHorasCooperado.dataHorasAcrescentar}
                                                            onChange={onChangeHorasFaltas}>
                                                        </input>
                                                    }
                                                </Col>
                                            }
                                        </Row>
                                        <Row>
                                            <Col md={2}>
                                                <label className="col-form-label mt-4" htmlFor="horasAcrescentar">Horas Acrescentadas:</label>
                                                <input type="number" 
                                                    className="form-control"
                                                    min={1} max={12} 
                                                    id="horasAcrescentar"
                                                    name="horasAcrescentar"
                                                    value={faltasHorasCooperado.horasAcrescentar}
                                                    onChange={onChangeHorasFaltas}>
                                                </input>
                                            </Col>
                                            <Col md={10}>
                                                <label className="col-form-label mt-4" htmlFor="motivoAcrescimoHoras">Motivo do Acréscimo:</label>
                                                <input type="text" 
                                                    className="form-control" 
                                                    id="motivoAcrescimoHoras" 
                                                    name="motivoAcrescimoHoras"
                                                    value={motivoDiariaAvulsa}
                                                    onChange={(e) => setMotivoDiariaAvulsa(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                        
                                        <div className="text-center mt-4">
                                            {faltasHorasCooperado.id > 0 ?
                                                <button 
                                                    type="button" 
                                                    onClick={atualizarFaltaHoras} 
                                                    className="btn btn-primary"> Atualizar 
                                                </button> 
                                                :
                                                <button 
                                                    type="button" 
                                                    onClick={salvarFaltaEHoras} 
                                                    className="btn btn-primary"> Salvar 
                                                </button>
                                            }
                                        </div>
                                        <hr></hr> <br/>
                                        <Card title="Listagem de Horas Acrescentadas">
                                            <Table id="tabela_lancamentos_horas_acrescentadas" striped bordered hover size="md">
                                                <thead>
                                                    <tr>
                                                        <th>Data do Acréscimo</th>
                                                        <th>Horas Acrescentadas</th>
                                                        <th>Motivo do Acréscimo</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listaFaltaHorasCooperado.map((fh) => {
                                                        if(fh.tipoLancamento === 'horas')
                                                            return (
                                                                <tr key={fh.id}>
                                                                    <td>{formatarData(fh.dataHorasAcrescentar)}</td>
                                                                    <td>{fh.horasAcrescentar}</td>
                                                                    <td>{fh.motivoAcrescimoHoras}</td>
                                                                    <td className='col-md-2' style={{textAlign: "center"}}>
                                                                        <button className="btn btn-primary" title='Editar' type="button" style={{margin: "0 5px"}} onClick={() => editarHorasFaltas(fh.id)}>
                                                                            <i className='pi pi-pencil'></i>
                                                                        </button>

                                                                        <button onClick={() => handleConfFaltasShow(setImputIdFaltas(`${fh.id}`))} title='Excluir' className="btn btn-danger">
                                                                            <i className='pi pi-trash'></i>   
                                                                        </button>   
                                                                    </td>   
                                                                    <td style={{display: "none"}}>{horasAcrescentadas += fh.horasAcrescentar}</td>
                                                                </tr>
                                                            );
                                                    })}
                                                    <span style={{paddingTop: '30px'}}><strong>Total de horas acrescentadas:</strong> {horasAcrescentadas}</span>
                                                </tbody>
                                            </Table>
                                        </Card>
                                    </Tab>
                                }
                                {!exibirAbasMensalista &&
                                    <Tab eventKey="substituicao" title={diariaAvulsa ? 'Díarias' : 'Substituições'}>
                                        <Row>
                                            {faltasHorasCooperado.id < 1 &&
                                                <label className="col-form-label mt-4" htmlFor="dataFaltaListaSub">Datas das {diariaAvulsa ? 'Díarias' : 'Substituições'}:</label>
                                            }
                                            {faltasHorasCooperado.id < 1  ?
                                                <DatePicker
                                                    mapDays={({ date }) => {
                                                        let props = {}
                                                        let isWeekend = [0, 6].includes(date.weekDay.index)
                                                        
                                                        if (isWeekend) props.className = "highlight highlight-red"
                                                        
                                                        return props
                                                    }}
                                                    className="mb-3"
                                                    onChange={setDataFaltaListaSub}
                                                    value={faltasHorasCooperado.listaDataSubstituicao}
                                                    id="dataFaltaListaSub"
                                                    name="dataFaltaListaSub"
                                                    numberOfMonths={2}
                                                    multiple
                                                    sort
                                                    format="DD/MM/YYYY"
                                                    locale={calendarioPersonalizado}
                                                    plugins={[<DatePanel />]}
                                                    style={{
                                                        width: "100%",
                                                        height: "38px",
                                                        padding: "12px 20px",
                                                        boxSizing: "border-box",
                                                    }}
                                                />
                                            :
                                                <Col>
                                                    <label className="col-form-label mt-4" htmlFor="dataSubstituicao">Data da Substituição:</label>
                                                    <input type="date" 
                                                        className="form-control" 
                                                        id="dataSubstituicao"
                                                        name="dataSubstituicao"
                                                        disabled
                                                        value={faltasHorasCooperado.dataSubstituicao}
                                                        /* onChange={onChangeHorasFaltas} */
                                                    />
                                                </Col>
                                            }
                                        </Row>
                                        <Row>
                                            {!diariaAvulsa &&
                                                <>
                                                    <Col>
                                                        <label style={{fontWeight: 500}} className="col-form-label" htmlFor="substituirServidor">Substituir um servidor?:</label><br/>

                                                        {faltasHorasCooperado.id < 1  ?
                                                            <input type="checkbox"
                                                                style={{width: 70, height: 36}} 
                                                                id="substituirServidor" 
                                                                checked={isChecked}
                                                                onChange={handleOnChecked}
                                                            />
                                                        :
                                                            <input type="checkbox"
                                                                style={{width: 70, height: 36}} 
                                                                id="substituirServidor" 
                                                                disabled
                                                                checked={isChecked}
                                                                onChange={handleOnChecked}
                                                            />
                                                        }
                                                    </Col>
                                                    {isChecked ?
                                                        <Col xl={4} lg={4}>
                                                            <label className="col-form-label" htmlFor="servidorSubstituido">Servidor Substituído:</label>
                                                            {faltasHorasCooperado.id < 1  ?
                                                                <Form.Select 
                                                                    id="servidorSubstituido" 
                                                                    name="servidor" 
                                                                    value={idServidor} 
                                                                    onChange={(e) => setIdServidor(e.target.value)}
                                                                >
                                                                    <option value="">Selecione</option>
                                                                    {listaServidoresSub.map((s) => (
                                                                        <option value={s.id} key={s.id}>
                                                                            {s.nomeCompleto}
                                                                        </option>
                                                                    ))}
                                                                </Form.Select> 
                                                            :
                                                                <Form.Select 
                                                                    id="servidorSubstituido" 
                                                                    name="servidor" 
                                                                    disabled
                                                                    value={idServidor} 
                                                                    onChange={(e) => setIdServidor(e.target.value)}
                                                                >
                                                                    <option value="">Selecione</option>
                                                                    {listaServidoresSub.map((s) => (
                                                                        <option value={s.id} key={s.id}>
                                                                            {s.nomeCompleto}
                                                                        </option>
                                                                    ))}
                                                                </Form.Select> 
                                                            }
                                                        </Col>
                                                        :
                                                        <Col xl={4} lg={4}>
                                                            <label className="col-form-label" htmlFor="cooperadoSubstituido">Cooperado Substituído:</label>
                                                            {faltasHorasCooperado.id < 1  ?
                                                                <Form.Select 
                                                                    id="cooperadoSubstituido" 
                                                                    name="cooperado" 
                                                                    value={idCooperado} 
                                                                    onChange={(e) => setIdCooperado(e.target.value)}
                                                                >
                                                                    <option value="">Selecione</option>
                                                                    {listaCooperadosSub.map((c) => (
                                                                        <option value={c.id} key={c.id}>
                                                                            {c.nome}
                                                                        </option>
                                                                    ))}
                                                                </Form.Select> 
                                                            :
                                                                <Form.Select 
                                                                    id="cooperadoSubstituido" 
                                                                    name="cooperado" 
                                                                    disabled
                                                                    value={idCooperado} 
                                                                    onChange={(e) => setIdCooperado(e.target.value)}
                                                                >
                                                                    <option value="">Selecione</option>
                                                                    {listaCooperadosSub.map((c) => (
                                                                        <option value={c.id} key={c.id}>
                                                                            {c.nome}
                                                                        </option>
                                                                    ))}
                                                                </Form.Select> 
                                                            }
                                                        </Col>
                                                    }
                                                </>
                                            }
                                            <Col md={4}>
                                                <label className="col-form-label" htmlFor="funcaoSubstituido">Função {diariaAvulsa ? 'da Diária' : 'do Substituído'}:</label>
                                                {faltasHorasCooperado.id < 1  ?
                                                    <Form.Select
                                                        id="funcaoSubstituido" 
                                                        name="funcaoSubstituido" 
                                                        value={idFuncaoSubstituido} 
                                                        onChange={(e) => setIdFuncaoSubstituido(e.target.value)}
                                                    >
                                                        <option value="">Selecione</option>
                                                        {listaFuncoesSubstituido.sort((a, b) => a.nome > b.nome ? 1:-1).map((f) => (
                                                            <option value={f.id} key={f.id} > {f.nome} </option>
                                                        ))}
                                                    </Form.Select>
                                                :
                                                    <Form.Select
                                                        id="funcaoSubstituido" 
                                                        name="funcaoSubstituido" 
                                                        value={idFuncaoSubstituido} 
                                                        disabled
                                                        onChange={(e) => setIdFuncaoSubstituido(e.target.value)}
                                                    >
                                                        <option value="">Selecione</option>
                                                        {listaFuncoesSubstituido.map((f) => (
                                                            <option value={f.id} key={f.id} > {f.nome} </option>
                                                        ))}
                                                    </Form.Select>
                                                }
                                            </Col>
                                            
                                            <Col xl={4} lg={4}>
                                                <label className="col-form-label" htmlFor="horasSubstituicao">Horas de {diariaAvulsa ? 'Díaria' : 'Substituição'}:</label>
                                                <input type="number" 
                                                    className="form-control" 
                                                    min={1} max={12}
                                                    id="horasSubstituicao"
                                                    name="horasSubstituicao"
                                                    value={faltasHorasCooperado.horasSubstituicao}
                                                    onChange={onChangeHorasFaltas}>
                                                </input>
                                            </Col>
                                            {!ocultarCheckDiariasAvulsa &&
                                            <Col md={2}>
                                                <label style={{fontWeight: 500}} className="col-form-label" htmlFor="diariaAvulsa">Diária Avulsa:</label><br/>
                                                {['right'].map((placement) => (
                                                    <OverlayTrigger
                                                    key={placement}
                                                    placement={placement}
                                                    overlay={
                                                        <Tooltip id={`tooltip-${placement}`}>
                                                        Marcar esse campo quando o Cooperado realizar uma diária em que não substituiu um outro Cooperado.
                                                        </Tooltip>
                                                    }
                                                    >
                                                        <input type="checkbox"
                                                            style={{width: 70, height: 36}} 
                                                            id="diariaAvulsa" 
                                                            checked={diariaAvulsa}
                                                            onChange={alterarEstadoDiariaAvulsa}
                                                        />
                                                    </OverlayTrigger>
                                                ))}
                                            </Col>
                                            }
                                        </Row>
                                                {diariaAvulsa &&
                                                <Col>
                                                    <label className="col-form-label">Descrição da Diária Avulsa:</label>
                                                    <input className="form-control" type="text" name="diariaAvulsaDescricao" value={motivoDiariaAvulsa} onChange={(e) => setMotivoDiariaAvulsa(e.target.value)}/>
                                                </Col>
                                                }
                                        <Row>


                                        </Row>
                                        
                                        <div className="text-center mt-4">
                                            {faltasHorasCooperado.id > 0 ?
                                                <button 
                                                    type="button" 
                                                    onClick={atualizarFaltaHoras} 
                                                    className="btn btn-primary"> Atualizar 
                                                </button> 
                                                :
                                                <button 
                                                    type="button" 
                                                    onClick={salvarFaltaEHoras} 
                                                    className="btn btn-primary"> Salvar 
                                                </button>
                                            }
                                        </div>

                                            <hr></hr> <br/>

                                        <Card title="Listagem de Substituições">
                                            <Table id="tabela_lancamentos_substituicoes" striped bordered hover size="md">
                                                <thead>
                                                    <tr>
                                                        <th>Nome do Substituído/Diária</th>
                                                        <th>Função do Substituído/Diária</th>
                                                        <th>Data da Substituição/Diária</th>
                                                        <th>Horas de Substituição/Diária</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listaFaltaHorasCooperado.map((fh) => {
                                                        if(fh.tipoLancamento === 'substituicao' || fh.tipoLancamento === 'diaria')
                                                            return (
                                                                <tr key={fh.id}>
                                                                    <td>{
                                                                        fh.servidor ? fh.servidor.nomeCompleto : fh.cooperado ? fh.cooperado.nome : 'Diária Avulsa'
                                                                    }<br/>{fh.tipoLancamento === 'diaria' ? fh.motivoAcrescimoHoras : null}</td>
                                                                    <td>{fh.funcaoSubstituido ? fh.funcaoSubstituido.nome : ''}</td>
                                                                    <td>{formatarData(fh.dataSubstituicao)}</td>
                                                                    <td>{fh.horasSubstituicao}</td>
                                                                    <td className='col-md-2' style={{textAlign: "center"}}>
                                                                        <button className="btn btn-primary" title='Editar' type="button" style={{margin: "0 5px"}} onClick={() => editarHorasFaltas(fh.id)}>
                                                                            <i className='pi pi-pencil'></i>
                                                                        </button>

                                                                        <button onClick={() => handleConfFaltasShow(setImputIdFaltas(`${fh.id}`))} title='Excluir' className="btn btn-danger">
                                                                            <i className='pi pi-trash'></i>
                                                                        </button>
                                                                    </td>
                                                                    <td style={{display: "none"}}>{horasSubstituicao += fh.horasSubstituicao}</td>
                                                                </tr>
                                                            );
                                                    })}
                                                    <span style={{paddingTop: '30px'}}><strong>Total de horas de substituição:</strong> {horasSubstituicao}</span>
                                                </tbody>
                                            </Table>
                                        </Card>
                                    </Tab>
                                }
                            </Tabs>

                            <Modal show={showConfFaltas} onHide={handleConfFaltasclose} backdrop="static" keyboard={false}>
                                <Modal.Header>
                                    <h3>Confirmação</h3>
                                </Modal.Header>
                                <Modal.Body>
                                    <h5>Você realmente deseja excluir o registro?</h5>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button className="btn btn-danger" onClick={() => excluirHorasFaltas(imputIdFaltas)}>
                                        Confirmar
                                    </Button>
                                    <Button className="btn btn-secondary" onClick={handleConfFaltasclose}>
                                        Cancelar
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={closeModalLanc}>
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </>
    )
}

export default ListarLancamentosCooperados
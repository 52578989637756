import ApiService from "../app/apiservice";

class dadosSubPrestador extends ApiService {
    
    constructor() {
        super('/dadossubprestador')
    }
    
    cadastrarDatas(idLancamento, idServMoti, totalAulas, datas, idPrestador, anoLancamento, mesLancamento, unidade){
        return this.post(`/cadastrar/${idLancamento}/${idServMoti}/${totalAulas}/${datas}/${idPrestador}/${anoLancamento}/${mesLancamento}/${unidade}`)
    }

    somaAulas(id){
        return this.get(`/somaaulas/${id}`)
    }

    somaAulasPorMotivoServidor(idMs){
        return this.get(`/somaaulaspormotivoservidor/${idMs}`)
    }

    excluirTodosIdServidorMotivo(idSerMot){
        return this.delete(`/excluirtodosporid/${idSerMot}`)
    }

    somaParaAtualizarRecibo(ano, mes, idPrestador, idServMotSub, idLanc){
        return this.get(`/atualizarsomarecibo/${ano}/${mes}/${idPrestador}/${idServMotSub}/${idLanc}`)
    }
}

export default dadosSubPrestador;
import ApiService from "../app/apiservice";

class calculosRecibosService extends ApiService {
   

    constructor(){
        super('/calculorecibos')
    }

    atualizar(idPrestador, ano, mes){
        return this.put(`/atualizar/${idPrestador}/${ano}/${mes}`)
    }

    salvar(dados){
        return this.post('/salvar', dados)
    }



}

export default calculosRecibosService

import ApiService from '../app/apiservice'


class ServidorService extends ApiService {

    constructor(){
        super('/servidor')
    }

    buscarPorMatricula(matricula){
        return this.get(`/matricula/${matricula}`)
    }

    salvar(servidor){
        return this.post('/salvar', servidor)
    }

    listarTodos(pagina) {
        return this.get(pagina)
    }

    deletar(id){
        return this.delete(`/deletar/${id}`)
    }

    buscarPorNome(nome){
        return this.get(nome)
    }

    atualizar(id, dados){
        return this.put(`/atualizar/${id}`, dados)
    }

    habilitar(id, dados){
        return this.put(`/habilitar/${id}`, dados)
    }

    buscarPorId(id){
        return this.get(`/${id}`)
    }

    buscarTodos(){
        return this.get("/");
    }

    buscarTodosAtivos(){
        return this.get("/ativos");
    }

}

export default ServidorService;
import ApiService from "../app/apiservice";

class LancamentoEstagiarioService extends ApiService {

    constructor() {
        super('/lancamentoestagiario')
    }

    buscarPorNome(nome){
        return this.get(nome)
    }
    
    listarTodos(pagina){
        return this.get(pagina)
    }
    
    buscarLancamentoPorEstagiario(id){
        return this.get(`/buscarporestagiario/${id}`)
    }

    obterPorId(id){
        return this.get(`/${id}`)
    }

    salvar(lancamentoEstagiario){
        return this.post('/', lancamentoEstagiario)
    }

    deletar(id){
        return this.delete(`/${id}`)
    }

    listar(){
        return this.get('/');
    }

    atualizar(lancamentoEstagiario){
        return this.put(`/${lancamentoEstagiario.id}`, lancamentoEstagiario)
    }

    listarPorUnidAnoMes(idUnid, ano, mes){
        return this.get(`/unidade/${idUnid}/ano/${ano}/mes/${mes}`)
    }

    buscarLancamentoEstagiarioPorMes(idUnidade, ano, mes, id){
        return this.get(`/lancamentoestagiariospormes/unidade/${idUnidade}/ano/${ano}/mes/${mes}/estagiario/${id}`)
    }

    replicarLancamentos(idUnidade, ano, mes){
        return this.get(`/replicarlancamentos/unidade/${idUnidade}/ano/${ano}/mes/${mes}`)
    }

    countLancamentosMesSeguinte(idUnidade, ano, mesSeguinte){
        return this.get(`/countlancamentosmesseguinte/unidade/${idUnidade}/ano/${ano}/mes/${mesSeguinte}`)
    }

    countLancamentosMesAtual(idUnidade, ano, mesSeguinte){
        return this.get(`/countlancamentosmesatual/unidade/${idUnidade}/ano/${ano}/mes/${mesSeguinte}`)
    }

    buscarHistoricoEstagiario(id){
        return this.get(`/historico/${id}`)
    }
}

export default LancamentoEstagiarioService;
import { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form"
import Modal from "react-bootstrap/Modal";
import anoService from './../../service/anoService'
import UnidadeService from "../../service/unidadeService";
import LocalStorageService from "../../service/localStorageService";
import CalendarioLetivoService from "../../service/calendariLetivoService";
import { mensagemAlerta, mensagemErro, mensagemSucesso } from "../../components/avisos/toastr";
import DiasLetivosService from "../../service/diasLetivosService";
import LoadingPage from "../landing/LandingPage";
import { descriptografar } from "../../components/criptografar";


const calendarioDados = {
    unidade: [],
    anoLancamento: '',
    mesLancamento: '',
    diaNaoLetivo: '',
    diaLetivo: ''
}

const anoLetivo = new anoService()
const serviceUnidade = new UnidadeService()
const serviceCalendarioLetivo = new CalendarioLetivoService()
const serviceDiasLetivos = new DiasLetivosService()

export default function CalendarioDiasLetivos() {

    const dataCriptografada = LocalStorageService.obterItem('1')
    const unidadeCriptografado = LocalStorageService.obterItem('3')
    const unidadeAtual = descriptografar(unidadeCriptografado)
    const dataAtual = new Date(descriptografar(dataCriptografada))
    const [calendarioLetivo, setCalendarioLetivo] = useState(calendarioDados)
    const [dadosCalendarioPorAnoSecretaria, setDadosCalendarioPorAnoSecretaria] = useState([])
    const [diasLetivosAnual, setDiasLetivosAnual] = useState([])

    const [ano, setAno] = useState(dataAtual.getFullYear())
    const [anoListar, setAnoListar] = useState([])
    const [tipoData, setTipoData] = useState('')
    const [unidadeLogado, setUnidadeLogado] = useState('')
    const [secretaria, setSecretaria] = useState('')
    const [mes, setMes] = useState(0)
    const [dataDeletar, setDataDeletar] = useState('')
    const [idData, setIdData] = useState(null)
    const [loading, setLoading] = useState(false)

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const closeShow = () => setShow(false)

    const [showSecretaria, setShowSecretaria] = useState(false)
    const handleShowSecretaria = () => setShowSecretaria(true)
    const closeShowSecretaria = () => setShowSecretaria(false)

    const [showDeletar, setShowDeletar] = useState(false)
    const handleShowDeletar = () => setShowDeletar(true)
    const closeShowDeletar = () => setShowDeletar(false)

    useEffect(() => {
        async function CarregarDados(){
            anoLetivo.listar()     
            .then((a) => {
                setAnoListar(a.data)  
            })
            serviceCalendarioLetivo.listarPorAnoSecretaria(ano, unidadeAtual)
            .then((c) => {
                setDadosCalendarioPorAnoSecretaria(c.data)
            })
            serviceDiasLetivos.listarPorAnoSecretaria(ano.toString(), unidadeAtual)
            .then((d) => {
                setDiasLetivosAnual(d.data)
            })

        }
        CarregarDados()
    }, [show, showSecretaria, ano, showDeletar, loading])

    useEffect(() => {
        async function NomeUnidade(){
            serviceUnidade.obterPorId(Number(unidadeAtual))
            .then((u) => {
                setUnidadeLogado(u.data.nome)
            })
        }
        NomeUnidade()
    }, [show])

   
    useEffect(() => {
        async function FormatarMes(){
            if (calendarioLetivo.diaLetivo) {
                let mes = calendarioLetivo.diaLetivo.split('-')
                let semzero = mes[1].replace(/^(0+)(\d)/g,"$2")
                setMes(semzero)
            }
            if (calendarioLetivo.diaNaoLetivo) {
                let mes = calendarioLetivo.diaNaoLetivo.split('-')
                let semzero = mes[1].replace(/^(0+)(\d)/g,"$2")
                setMes(semzero)
            }
        }
        FormatarMes()
    },[calendarioLetivo])

    useEffect(() => {
        if (tipoData === '1') {
            calendarioLetivo.diaNaoLetivo = ''
        }
        if (tipoData === '2') {
            calendarioLetivo.diaLetivo = ''
        }
    },[tipoData])

    const formatarData = (dataF) => {
        if(dataF){
            let data = new Date(dataF),
            dia  = data.getUTCDate().toString().padStart(2, '0'),
            mes  = (data.getUTCMonth()+1).toString().padStart(2, '0'),
            ano  = data.getUTCFullYear();
            return dia+"/"+mes+"/"+ano;
        }
    }
    
    function onChange(e) {
        const {name, value} = e.target;
        setCalendarioLetivo({...calendarioLetivo, [name]: value})
    }

    function verificarSecretaria(){
        let unidadeCase
        if (unidadeLogado) {
            unidadeCase = unidadeLogado.toLowerCase()
            if (unidadeCase.match(/secretaria/)) {
                setSecretaria(unidadeLogado)
                handleShow()
            } else (
                handleShowSecretaria()
            )

        }
    }

    function verificarSecretariaParaCriarCalendario(){
        let unidadeCase
        if (unidadeLogado) {
            unidadeCase = unidadeLogado.toLowerCase()
            if (unidadeCase.match(/secretaria/)) {
                setSecretaria(unidadeLogado)
                setLoading(true)
                    serviceDiasLetivos.criarDiasLetivos(ano, unidadeAtual)
                        .then(() => {
                            setLoading(false)
                            setTimeout(() => {
                                window.location.reload()
                            }, 1000)
                        })
                        .catch(() => {
                            setLoading(false)
                        })
            } else (
                handleShowSecretaria()
            )

        }
    }

    function criarDiaCalendario(){
        if (ano.length === 0) {
            setAno(anoListar[0].ano)
        }
        verificarSecretaria()
        
    }

    function cancelarLimparDados(){
        closeShow()
        setTipoData('')
        calendarioLetivo.anoLancamento = ''
        calendarioLetivo.mesLancamento = ''
        calendarioLetivo.diaLetivo = ''
        calendarioLetivo.diaNaoLetivo = ''
    }

    function salvarDiaCalendario() {
        if (calendarioLetivo.diaLetivo !== '') {
            if (new Date(calendarioLetivo.diaLetivo) > dataAtual) {
                salvarAposValidacao()
                return true
            }
        } 
        if (calendarioLetivo.diaNaoLetivo !== '') {
            if (new Date(calendarioLetivo.diaNaoLetivo) > dataAtual) {
                salvarAposValidacao()
                return true
            }
        }  
        mensagemAlerta("A data não pode ser menor ou igual que a data atual.")
    }

    function salvarAposValidacao(){
        let dataSalvar = calendarioLetivo.diaLetivo !== '' ? calendarioLetivo.diaLetivo : calendarioLetivo.diaNaoLetivo
        calendarioLetivo.unidade = {id: unidadeAtual}
        calendarioLetivo.mesLancamento = mes
        calendarioLetivo.anoLancamento = ano
       serviceCalendarioLetivo.salvar(calendarioLetivo)
        .then((s) => {
            serviceDiasLetivos.atualizarDiasLetivos(ano, mes, unidadeAtual, tipoData, dataSalvar)
            mensagemSucesso(s.data)
            closeShow()
            cancelarLimparDados()
        })
        .catch((e) => {
            mensagemErro(e.response.data)
        })
    }

    function confirmarDeletarData(){
        handleShowDeletar()
    }

    function apagarData(){
        if (idData) {
            serviceCalendarioLetivo.deletarData(idData)
            .then((s) => {
                serviceDiasLetivos.atualizarApagandoDiasLetivos(ano, mes, unidadeAtual, tipoData, dataDeletar)
                mensagemSucesso(s.data)
                closeShowDeletar()
                setTimeout(() => {
                    window.location.reload()
                }, 1000)
            })
        } else {
            mensagemErro('Não foi possivel apagar a data. (ID nulo)')
            closeShowDeletar()
        }
    }

    function criarDiasLetivosAnual(){
        verificarSecretariaParaCriarCalendario()
        
    }

    return (

        <>
            <div className="container mt-6">
                <div className="form-group">
                    <div className="card md-3">
                        <h4 className="card-header">Calendário de Dias Letivos/Não Letivos</h4>
                        <Container>
                        {loading && <LoadingPage/>}
                            <label>* Todos os dias de segunda-feira a sexta-feira serão considerados letivos, mesmo que forem feriados. Para desconsidera adicione como data do tipo Não Letivo.</label>
                            <br /> <br />
                            
                            <Row style={{ textAlign: "center" }} className="justify-content-md-center">
                                <Col xs lg="2">
                                    <label className="col-form-label"> Mês: </label>
                                        <Form.Select  value={ano} onChange={(a) => setAno(a.target.value)}>
                                            {anoListar.map((l) => {
                                                return (
                                                    <option style={{ textAlign: "center" }} key={l.id} value={l.ano} > {l.ano} </option>
                                                )})}
                                        </Form.Select>
                                </Col>
                                {diasLetivosAnual.length === 0 &&
                                <Col md={2}>  
                                    <button type="button" className="btn btn-primary" style={{ marginTop: "20%" }} onClick={criarDiasLetivosAnual}>Criar Ano</button>
                                </Col>}
                                {diasLetivosAnual.length > 0 &&
                                <Col md={2}>  
                                    <button type="button" className="btn btn-primary" style={{ marginTop: "20%" }} onClick={criarDiaCalendario}>Adicionar Data</button>
                                </Col>}
                            </Row>
                            <br />
                            <Row style={{ textAlign: "center" }} className="justify-content-md-center" >
                                <Col xs lg="10">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col" style={{ textAlign: "center" }}> Mês </th>
                                                <th scope="col" style={{ textAlign: "center" }}> Ano </th>
                                                <th scope="col" style={{ textAlign: "center" }}> Dias Letivos </th>
                                                <th scope="col" style={{ textAlign: "center" }}> Dias Não Letivos </th>
                                                <th scope="col" style={{ textAlign: "center" }}> Secretaria </th>
                                            </tr>
                                        </thead>
                                                {diasLetivosAnual.sort((a, b) => Number.parseInt(a.mesLancamento) > Number.parseInt(b.mesLancamento) ? 1:-1).map((d) => {   
                                                    return (
                                                        <tr key={d.id}>
                                                            <td>{d.mesLancamento}</td>
                                                            <td>{d.anoLancamento}</td>
                                                            <td>{d.diasLetivos}</td>
                                                            <td>{d.diasNaoLetivos}</td>
                                                            <td>{d.unidade.nome}</td>
                                                        </tr>
                                                    )
                                                })}
                                        <tbody>
                                        
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                            <br/><hr/><br/>
                            <Row style={{ textAlign: "center" }} className="justify-content-md-center" >
                                <Col xs lg="10">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col" style={{ textAlign: "center" }}> Mês </th>
                                                <th scope="col" style={{ textAlign: "center" }}> Dia </th>
                                                <th scope="col" style={{ textAlign: "center" }}> Tipo </th>
                                                <th scope="col" style={{ textAlign: "center" }}> Secretaria </th>
                                            </tr>
                                        </thead>
                                                {dadosCalendarioPorAnoSecretaria.sort((a, b) => Number.parseInt(a.mesLancamento) > Number.parseInt(b.mesLancamento) ? 1:-1).map((d) => {
                                                        let data = d ? d.diaLetivo ? d.diaLetivo : d.diaNaoLetivo : null 
                                                    return (
                                                        <tr key={d.id}>
                                                            <td>{d.mesLancamento}</td>
                                                            <td>{formatarData(data)}</td>
                                                            <td>{d ? d.diaLetivo ? "LETIVO" : "NÃO LETIVO" : null }</td>
                                                            <td>{d.unidade.nome}</td>
                                                            {new Date(data) >= dataAtual &&
                                                                <td>
                                                                    <button className="btn btn-danger" type="button" onClick={() => confirmarDeletarData(setIdData(d.id), setDataDeletar(data), setTipoData(d ? d.diaLetivo ? "1" : "2" : "0" ), setMes(d.mesLancamento))} >
                                                                        <i className="pi pi-trash"></i>
                                                                    </button>
                                                                </td>
                                                            }
                                                        </tr>
                                                    )
                                                })}
                                        <tbody>
                                        
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>

            <Modal
            show={show}
            onHide={closeShow}
            backdrop="static"
            keyboard={false}
            size="lg"
            centered
            >
                <Modal.Header>
                <Modal.Title>Adicionar Data ao Calendário do Ano Letivo {ano}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="justify-content-md-center">
                        <Col md={12} style={{ textAlign: "center" }}>
                            <label className="col-form-label">Secretaria:</label>
                            <input type="text" className="form-control" value={secretaria} disabled style={{ textAlign: "center" }}/>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">

                        <Col md={5} style={{ textAlign: "center" }}>
                            <label className="col-form-label">Tipo da Data:</label>
                                <Form.Select  value={tipoData} onChange={(a) => setTipoData(a.target.value)}> 
                                    <option style={{ textAlign: "center" }} > Selecione </option>
                                    <option style={{ textAlign: "center" }} value="1" > Letivo </option>
                                    <option style={{ textAlign: "center" }} value="2" > Não Letivo </option>          
                                </Form.Select>
                        </Col>
                    </Row >
                    <Row className="justify-content-md-center">
                        {tipoData === "1" 
                            ?
                            <Col md={5} style={{ textAlign: "center" }}>
                                <label className="col-form-label">Dia Letivo:</label>
                                <input type="date"  className="form-control" id="diaLetivo" name="diaLetivo" min={`${ano}-01-01`} max={`${ano}-12-31`}
                                    value={calendarioLetivo.diaLetivo} onChange={onChange}/>
                            </Col>
                            : tipoData === "2" ?
                            <Col md={5} style={{ textAlign: "center" }}>
                                <label className="col-form-label">Dia Não Letivo:</label>
                                <input type="date" className="form-control" id="diaNaoLetivo" name="diaNaoLetivo" min={`${ano}-01-01`} max={`${ano}-12-31`}
                                    value={calendarioLetivo.diaNaoLetivo} onChange={onChange}/>
                            </Col>
                            : null
                        }
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" type="button" onClick={salvarDiaCalendario} >
                        Adicionar
                    </button>
                    <button className="btn btn-danger" type="button" onClick={cancelarLimparDados} >
                        Fechar
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal
            show={showSecretaria}
            onHide={closeShowSecretaria}
            backdrop="static"
            keyboard={false}
            size="lg"
            centered
            >
                <Modal.Header closeButton>
                <Modal.Title>Adicionar Data ou Criar Calendário do Ano Letivo {ano}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="justify-content-md-center">
                        <Col md={12} style={{ textAlign: "center" }}>
                            <label className="col-form-label"> <h5> <label style={{ color: "#f70808" }}> A unidade em que esta logado não tem permissão para essa ação. </label><br/>
                                Para adicionar uma data ou criar ano letivo o usuário deve estar vinculado e logado diretamente em uma Secretaria Municipal</h5> </label>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal
            show={showDeletar}
            onHide={closeShowDeletar}
            backdrop="static"
            keyboard={false}
            size="lg"
            centered
            >
                <Modal.Header>
                <Modal.Title>Excluir Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="justify-content-md-center">
                        <Col md={12} style={{ textAlign: "center" }}>
                            <label className="col-form-label"> <h5> Tem certeza que quer excluir a data <label style={{ color: "#f70808" }}> {formatarData(dataDeletar)} </label> ?
                                </h5>  </label>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" type="button" onClick={apagarData} >
                        Sim
                    </button>
                    <button className="btn btn-danger" type="button" onClick={closeShowDeletar} >
                        Não
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
import ApiService from "../app/apiservice";

class FuncaoService extends ApiService {

    constructor(){
        super('/funcao')
    }

    buscarPorNome(nome){
        return this.get(nome)
    }
    
    listarTodos(pagina){
        return this.get(pagina)
    }
    
    obterPorId(id){
        return this.get(`/${id}`)
    }
    
    salvar(funcao){
        return this.post('/', funcao)
    }
    
    listar() {
        return this.get('/')
    }
    
    deletar(id){
        return this.delete(`/${id}`)
    }
    
    atualizar(funcao){
        return this.put(`/${funcao.id}`, funcao)
    }

    listarAtivos(){
        return this.get("/ativas")
    }
}

export default FuncaoService
import ApiService from '../app/apiservice'


class PrestadorService extends ApiService {

    constructor(){
        super('/prestador')
    }

    
    salvar(prestador){
        return this.post('/salvar', prestador)
    }

    listarTodos(pagina) {
        return this.get(pagina)
    }

    deletar(id){
        return this.delete(`/deletar/${id}`)
    }

    buscarPorNome(nome){
        return this.get(nome)
    }

    listarPorFormacao(formacao){
        return this.get(`/porformacao/${formacao}`)
    }

    atualizar(id, dados){
        return this.put(`/atualizar/${id}`, dados)
    }

    buscarPorId(id){
        return this.get(`/${id}`)
    }

    //Relatórios

    relatorioListarTodos(){
        return this.get(`/r/listartodos/`)
    }

    gerarRecibos(ano, mes, recibo){
        return this.get(`/r/recibos/${ano}/${mes}/${recibo}`)
    }

    gerarQuadroGeralMes(ano, mes){
        return this.get(`/r/quadro/${ano}/${mes}`)
    }
}

export default PrestadorService;
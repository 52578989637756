import ApiService from "../app/apiservice";


class anoService extends ApiService {

    constructor(){
        super("/ano")
    }

    salvar(ano) {
        return this.post("/salvar", ano)
    }

    listar(){
        return this.get("/listar")
    }

    anoExiste(ano){
        return this.get(`/existe/${ano}`)
    }
}

export default anoService;
import ApiService from "../app/apiservice";
import ErroValidacao from "../app/ErroValidacao";

class LancamentoServidorService extends ApiService {

    constructor() {
        super('/lancamentoservidor')
    }

    gerarCoordenadores(ano, mes){
        return this.get(`/anexomensal/coord/${ano}/${mes}`, {responseType: 'blob'})
    }

    downloadPdfQuadroGeralGestao(ano, mes){
        return this.get(`/relatorios/quadrogeralgestao/${ano}/${mes}`, {responseType: 'arraybuffer'})
    }

    listarTodos(pagina) {
        return this.get(pagina)
    }

    listar(){
        return this.get('/');
    }

    buscarLancamentoPorServidor(id, ano, mes){
        return this.get(`/buscarporservidor/${id}/ano/${ano}/mes/${mes}`)
    }

    buscarLancamentoServidorPorMes(idUnidade, ano, mes, id){
        return this.get(`/lancamentoservidorespormes/unidade/${idUnidade}/ano/${ano}/mes/${mes}/servidor/${id}`)
    }

    obterPorId(id){
        return this.get(`/${id}`)
    }

    salvar(lancamentoServidor){
        return this.post('/', lancamentoServidor)
    }

    deletar(id){
        return this.delete(`/${id}`)
    }

    listarLancDaUnid(idUnidade){
        return this.get(`/listaporunidade/${idUnidade}`);
    }

    listarPorUnidAnoMes(id, ano, mes){
        return this.get(`/unidade/${id}/ano/${ano}/mes/${mes}`)
    }

    buscarServidorLancamentoMes(id, ano, mes){
        return this.get(`/servidoresmes/unidade/${id}/ano/${ano}/mes/${mes}`)
    }

    atualizar(lancamentoServidor){
        return this.put(`/${lancamentoServidor.id}`, lancamentoServidor)
    }

    replicarLancamentos(idUnidade, ano, mes){
        return this.get(`/replicarlancamentos/unidade/${idUnidade}/ano/${ano}/mes/${mes}`)
    }

    countLancamentosMesSeguinte(idUnidade, ano, mesSeguinte){
        return this.get(`/countlancamentosmesseguinte/unidade/${idUnidade}/ano/${ano}/mes/${mesSeguinte}`)
    }

    countLancamentosMesAtual(idUnidade, ano, mesSeguinte){
        return this.get(`/countlancamentosmesseguinte/unidade/${idUnidade}/ano/${ano}/mes/${mesSeguinte}`)
    }

    listarTurnos(){
        return this.get('/turnos')
    }

    listarSituacoes(){
        return this.get('/situacoes')
    }

    validar(lancamentoServidor){
        const erros = [];

        //console.log('On Service',lancamentoServidor)

        if(lancamentoServidor.id > 0 ? !lancamentoServidor.servidor.id : !lancamentoServidor.servidor){
            erros.push("Informe um servidor");
        }

        if(!lancamentoServidor.turno){
            erros.push("Informe um turno");
        }

        if(!lancamentoServidor.situacao){
            erros.push("Informe uma situação");
        }

        if(erros && erros.length > 0){
            throw new ErroValidacao(erros);
        }
        
    }

    buscarHistoricoServidor(id){
        return this.get(`/historico/${id}`)
    }
}

export default LancamentoServidorService;
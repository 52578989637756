/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import UsuarioService from "../../service/usuarioService";
import UnidadeService from "../../service/unidadeService";
import {
  mensagemAlerta,
  mensagemSucesso,
} from "../../components/avisos/toastr";
import styled from "styled-components";

//console.log(id)

const atualizarUsuarioUp = {
  nomeCompleto: "",
  matricula: 0,
  unidade: [],
  email: "",
  telefone: "",
  tipoUsuario: "",
  login: "",
  senha: "",
  senhaRepetida: "",
};

const NomeDestacado = styled.label`
  font-weight: bold;
  color: blue;
`;
const service = new UsuarioService();
const unidadeListar = new UnidadeService();

const AlterarSenha = ({ id }) => {
  const [usuarioUp, setUsuarioUp] = useState(id ? atualizarUsuarioUp : null);
  const [unidades, setUnidades] = useState([]);
  const redirecionar = useHistory();

  useEffect(() => {
    if (id) {
      //Executa um atraso e exibe uma menssagem antes do carregamento
      setTimeout(() => {
        service.buscarPorId(id).then((resposta) => {
          setUsuarioUp(resposta.data);
        });        
        async function carregarUnidades() {
          const response = await unidadeListar.listar();
          setUnidades(response.data);
        }
        carregarUnidades();
      }, 300);
    }
  }, []);


  function validarSenhas(s1, s2) {
    if (s1 !== s2) {
      mensagemAlerta("Senhas não são iguais. Favor redigitar.");
      return false;
    } else {
      return true;
    }
  }
  //ações do submit do form
  function onSubmit(ev) {
    ev.preventDefault();

    let verificar = validarSenhas(usuarioUp.senha, usuarioUp.senhaRepetida);

    if (verificar) {
      service
        .atualizarSenha(usuarioUp)
        .then(() => {
          mensagemSucesso("Atualizado com sucesso")

          redirecionar.push("/usuario/listar");
        })
      window.location.reload()
    }
  }

  function onChange(e) {
    const { name, value } = e.target;
    setUsuarioUp({ ...usuarioUp, [name]: value });
  }



  return (
    <div>
      {id ? (
        <div className="container mt-4">
          <div className="form-group">
            <div className="card md-3">
              <div className="card-body">
                <form onSubmit={onSubmit}>
                  <Row>
                    <Col md={2}>
                      <label className="col-form-label">
                        Matrícula:
                        <br /> {usuarioUp.matricula}
                      </label>
                    </Col>
                    <Col md={9}>
                      <label className="col-form-label">
                        Nome Completo: <br />{" "}
                        <NomeDestacado>{usuarioUp.nomeCompleto}</NomeDestacado>
                      </label>
                    </Col>

                    
                  </Row>
                  <Row>
                    <Col md={5}>
                      <label className="col-form-label">
                        Unidade: <br />
                        {usuarioUp.unidade.nome}
                      </label>
                    </Col>

                    <Col md={4}>
                      <label className="col-form-label">
                        E-mail:
                        <br />
                        {usuarioUp.email}
                      </label>
                    </Col>
                    <Col md={3}>
                      <label className="col-form-label">
                        Telefone: <br /> {usuarioUp.telefone}
                      </label>
                    </Col>
                  </Row>{" "}
                  <Row>
                    <Col md={4}>
                      <label className="col-form-label">
                        Tipo de Usuário:
                        <br />
                        {usuarioUp.tipoUsuario}
                      </label>
                    </Col>

                    <Col md={4}>
                      <label className="col-form-label">
                        Login:
                        <br />
                        {usuarioUp.login}
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <label className="col-form-label" htmlFor="senha">
                        Altere a senha:
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Digite a nova senha"
                        id="senha"
                        name="senha"
                        autoComplete="false"
                        value={usuarioUp.senha}
                        onFocus={(e) => e.target.setSelectionRange(0,100)}
                        onChange={onChange}
                      />
                    </Col>
                    <Col md={4}>
                      <label className="col-form-label" htmlFor="senhaRepetida">
                        Repetir alterarção da senha:
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Repita a nova senha"
                        id="senhaRepetida"
                        name="senhaRepetida"
                        autoComplete="false"
                        value={usuarioUp.senhaRepetida}
                        onChange={onChange}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={2}>
                      <button type="submit" className="btn btn-primary">
                        Atualizar
                      </button>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h1>Carregando...</h1>
      )}
    </div>
  );
};

export default AlterarSenha;

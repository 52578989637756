import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import assinaturaQuadrosService from "../../service/assinaturaQuadrosService";
import Card from "../../components/card";
import AnoMes from "../../components/mesAno/anoMes";

const serviceAssinaturaQuadro = new assinaturaQuadrosService()

export default function AssinaturaPainel() {

    const [carregarAssinaturas, setCarregarAssinaturas] = useState([])
    const [carregarUnidades, setCarregarUnidades] = useState([])
    
    const dataAtual = new Date()
    const [mes, setMes] = useState(dataAtual.getMonth()+1)
    const [ano, setAno] = useState(dataAtual.getFullYear())


    const formatarData = (dataF) => {
        if(dataF){
            let data = new Date(dataF),
            dia  = data.getUTCDate().toString().padStart(2, '0'),
            mes  = (data.getUTCMonth()+1).toString().padStart(2, '0'),
            ano  = data.getUTCFullYear();
            return dia+"/"+mes+"/"+ano;
        }
    }

    useEffect(() => {
        async function CarregarAssinaturas(){
            serviceAssinaturaQuadro.listarAssinaturasPorAnoMes(ano, mes)
                .then((assinaturas) => {
                    setCarregarAssinaturas(assinaturas.data)
                })
        }
        CarregarAssinaturas()

        async function CarregarUnidadesPainel(){
            serviceAssinaturaQuadro.carregarListaParaPainel(ano, mes)
                .then((s) => {
                    setCarregarUnidades(s.data) 
                })
        }
        CarregarUnidadesPainel()
        
    },[mes, ano])

    return (
        <>
            <Container>
                <Card title="Painel de Visualização de Assinaturas dos Quadros">
                        <h5 style={{textAlign: "center"}}>
                            Selecione mês e ano para listar assinaturas
                        </h5>
                        <Row className="justify-content-md-center">
                            <Col xs lg="4" style={{ textAlign: "center", paddingLeft: "50px" }}>
                                <AnoMes
                                    valueMes={mes}
                                    onChangeMes={(e) => setMes(e.target.value)}
                                    valueAno={ano}
                                    onChangeAno={(e) => setAno(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <br/>
                        <Row style={{textAlign: "center"}} className="justify-content-md-center">
                            <Col>
                                <div className="form-group">
                                    <div className="card md-4">
                                        <h5 className="card-header">Assinatura Quadro de Servidores</h5>
                                            <div className="card-body">
                                                <table className="table table-hover mt-7" style={{margin: "auto"}}>
                                                    <thead>
                                                        <tr style={{ textAlign: "center" }}>
                                                            <th> Unidade </th>
                                                            <th> Quadro Assinado </th>
                                                            <th> Data da Assinatura </th>
                                                            <th> Assinado Por </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody> 
                                                        {carregarAssinaturas.sort((a, b) => a.unidade > b.unidade ? 1:-1).map((assinaturas) => {
                                                            return(
                                                                    <tr key={assinaturas.id}>
                                                                        <td style={{maxWidth: '250px'}}> {assinaturas.unidade} </td>
                                                                        <td style={{maxWidth: '250px'}}> {assinaturas.tipoQuadroAssinado} </td>
                                                                        <td style={{maxWidth: '250px'}}> {formatarData(assinaturas.dataAssinatura)} </td>
                                                                        <td style={{maxWidth: '250px'}}> {assinaturas.assinadoPor} </td>
                                                                    </tr> 
                                                            )  
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                            </Col>
                        </Row>
                        <hr/>
                        <Row style={{textAlign: "center"}} className="justify-content-md-center">
                            <Col>
                                <div className="form-group">
                                    <div className="card md-4">
                                        <h5 className="card-header">Painel de Status das assinaturas de quadros por unidade</h5>
                                            <div className="card-body">
                                                <table className="table table-hover mt-7" style={{margin: "auto"}}>
                                                    <thead>
                                                        <tr style={{ textAlign: "center" }}>
                                                            <th> Unidade </th>
                                                            <th> Quadro Servidores </th>
                                                            <th> Quadro Estagiários </th>
                                                            <th> Quadro Cooperados </th>
                                                            <th> Quadro Temporários </th>
                                                            <th> Quadro Terceirizados </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody> 
                                                        {carregarUnidades.sort((a, b) => a.unidade > b.unidade ? 1:-1).map((unidades) => {
                                                            return(
                                                                    <tr key={unidades.id}>
                                                                        <td style={{maxWidth: '250px', textAlign: "left"}}> {unidades.unidade} </td>
                                                                        <td style={unidades.estaAssinadoServidor ? {backgroundColor: "#6121f8", color: "#fff"} : {backgroundColor: "#fd0808", color: "#e0f807", maxWidth: '190px'}}> 
                                                                            {unidades.estaAssinadoServidor ? "Assinado" : "Não Assinado ou Não há Lançamentos"} 
                                                                        </td>
                                                                        <td style={unidades.estaAssinadoEstagiario ? {backgroundColor: "#6121f8", color: "#fff"} : {backgroundColor: "#fd0808", color: "#e0f807", maxWidth: '190px'}}> 
                                                                            {unidades.estaAssinadoEstagiario ? "Assinado" : "Não Assinado ou Não há Lançamentos"} 
                                                                        </td>
                                                                        <td style={unidades.estaAssinadoCooperado ? {backgroundColor: "#6121f8", color: "#fff"} : {backgroundColor: "#fd0808", color: "#e0f807", maxWidth: '190px'}}> 
                                                                            {unidades.estaAssinadoCooperado ? "Assinado" : "Não Assinado ou Não há Lançamentos"} 
                                                                        </td>  
                                                                        <td style={unidades.estaAssinadoTemporario ? {backgroundColor: "#6121f8", color: "#fff"} : {backgroundColor: "#fd0808", color: "#e0f807", maxWidth: '190px'}}> 
                                                                            {unidades.estaAssinadoTemporario ? "Assinado" : "Não Assinado ou Não há Lançamentos"} 
                                                                        </td>
                                                                        <td style={unidades.estaAssinadoTerceirizado ? {backgroundColor: "#6121f8", color: "#fff"} : {backgroundColor: "#fd0808", color: "#e0f807", maxWidth: '190px'}}> 
                                                                            {unidades.estaAssinadoTerceirizado ? "Assinado" : "Não Assinado ou Não há Lançamentos"} 
                                                                        </td>     
                                                                    </tr>  
                                                            )  
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                            </Col>
                        </Row>
                </Card>    
            </Container>        
        </>
    )

}

import { useParams } from "react-router-dom";
import EdicaoLancamentosPrestador from "./EdicaoLancamentosPrestador";

const IdPrestadorEditar = () => {
  const { id } = useParams();
  
  return (
    <>
      <EdicaoLancamentosPrestador id={id ? Number.parseInt(id, 10) : null} />
    </>
  );
};

export default IdPrestadorEditar;

import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Card from "../../components/card";
import { Link } from "react-router-dom";
import LocalStorageService from "../../service/localStorageService";
import { descriptografar } from "../../components/criptografar";


export default function VerificacaoCodigo() {

    const usuarioCriptografado = LocalStorageService.obterItem('2')
    const usuario = usuarioCriptografado ? JSON.parse(descriptografar(usuarioCriptografado)) : null
    const [usuarioLogado, setUsuarioLogado] = useState(false)
    const [codigo, setCodigo] = useState(0)
    useEffect(() => {
        if (usuario) {
            setUsuarioLogado(true)
        }
    },[])

    return(
        <>
            <Container>
            {usuarioLogado ? null : 
                <div style={{paddingBottom: '10px', textAlign: 'center'}} className="container">
                                <a className="navbar-brand" href="#/login">
                                    <img src="/img/Prefeitura_.png" alt="RH Semec" width="80px"/>
                                </a>
                </div>
            }
                <Card title="Verificação/Validação de assinatura digital">
                        
                    <br/>
                        <Row style={{textAlign: "center"}} className="justify-content-md-center">
                            <Col md={5}>
                                <h5 style={{textAlign: "center"}}> Digite o código verificador: </h5>
                                <div style={{textAlign: 'center', marginLeft: '25%'}}>
                                    <input min={0} max={9999999999} type="number" className="form-control" value={codigo} onChange={(c) => setCodigo(c.target.value)} style={{maxWidth: '200px', alignItems: 'center'}}/>
                                </div>                 
                            </Col>
                        </Row>
                            <br/>
                        <Row style={{textAlign: "center"}} className="justify-content-md-center">
                            <Col>
                                <button type="button" className="btn btn-primary">
                                    <Link style={{ textDecoration: "none", color: "#fff" }} to={`/dadosverificacao/${codigo ? codigo : codigo === null ? 0 : 0}`}>
                                        Verificar                         
                                    </Link>   
                                </button>
                            </Col>
                        </Row> 
                </Card>
            </Container>
        </>
    )
}
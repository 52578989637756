/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import UsuarioService from "../../service/usuarioService";
import {mensagemAlerta, mensagemErro, mensagemSucesso} from "../../components/avisos/toastr";
import styled from "styled-components";
import LocalStorageService from "../../service/localStorageService";
import { descriptografar } from "../../components/criptografar";

//console.log(id)

const atualizarUsuarioUp = {
  nomeCompleto: "",
  matricula: 0,
  unidade: [],
  email: "",
  telefone: "",
  tipoUsuario: "",
  login: "",
  senha: "",
  senhaRepetida: "",
};

const NomeDestacado = styled.label`
  font-weight: bold;
  color: blue;
`;

const service = new UsuarioService();

export default function AlterarSenhaPropria() {

  const usuarioCriptografado = LocalStorageService.obterItem('2')
  const usuarioLogado = JSON.parse(descriptografar(usuarioCriptografado))

  const [usuarioUp, setUsuarioUp] = useState(atualizarUsuarioUp);

  const redirecionar = useHistory();

  useEffect(() => {
    async function BuscarUsuario() {
      service.buscarPorId(usuarioLogado.id).then((e) => {
        setUsuarioUp(e.data);
      });
    }
    BuscarUsuario();
  }, []);

  function validarSenhas(s1, s2) {
    if (s1 !== s2) {
      mensagemAlerta("Senhas não são iguais. Favor redigitar.");
      return false;
    } else {
      return true;
    }
  }
  //ações do submit do form
  function onSubmit(ev) {
    ev.preventDefault();

    let verificar = validarSenhas(usuarioUp.senha, usuarioUp.senhaRepetida);

    if (verificar) {
      service
        .atualizarSenha(usuarioUp)
        .then(() => {
          mensagemSucesso("Senha atualizada com sucesso.");
          redirecionar.push("/home");
        })
        .catch((e) => {
          mensagemErro(e.data);
        });
    }
  }

  function onChange(e) {
    const { name, value } = e.target;
    setUsuarioUp({ ...usuarioUp, [name]: value });
  }

  return (
    <div>
      <div className="container mt-4">
        <div className="form-group">
          <div className="card md-3">
            <div className="card-body">
              <form onSubmit={onSubmit}>
                <h4 style={{textAlign: "center"}}>Alterar Senha</h4>
                <hr />
                <Row>
                  <Col md={2}>
                    <label className="col-form-label">
                      Matrícula:
                      <br /> {usuarioUp.matricula}
                    </label>
                  </Col>
                  <Col md={9}>
                    <label className="col-form-label">
                      Nome Completo: <br />{" "}
                      <NomeDestacado>{usuarioUp.nomeCompleto}</NomeDestacado>
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col md={5}>
                    <label className="col-form-label">
                      Unidade: <br />
                      {usuarioUp.unidade.nome}
                    </label>
                  </Col>

                  <Col md={3}>
                    <label className="col-form-label">
                      E-mail:
                      <br />
                      {usuarioUp.email}
                    </label>
                  </Col>
                  <Col md={3}>
                    <label className="col-form-label">
                      Telefone: <br /> {usuarioUp.telefone}
                    </label>
                  </Col>
                </Row>{" "}
                <Row>
                  <Col md={4}>
                    <label className="col-form-label">
                      Tipo de Usuário:
                      <br />
                      {usuarioUp.tipoUsuario}
                    </label>
                  </Col>

                  <Col md={4}>
                    <label className="col-form-label">
                      Login:
                      <br />
                      {usuarioUp.login}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label className="col-form-label" htmlFor="senha">
                      Altere a senha:
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Digite a nova senha"
                      id="senha"
                      name="senha"
                      autoComplete="false"
                      value={usuarioUp.senha}
                      onFocus={(e) => e.target.setSelectionRange(0, 100)}
                      onChange={onChange}
                    />
                  </Col>
                  <Col md={4}>
                    <label className="col-form-label" htmlFor="senhaRepetida">
                      Repetir alterarção da senha:
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Repita a nova senha"
                      id="senhaRepetida"
                      name="senhaRepetida"
                      autoComplete="false"
                      value={usuarioUp.senhaRepetida}
                      onChange={onChange}
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={2}>
                    <button type="submit" className="btn btn-primary">
                      Alterar
                    </button>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

var CryptoJS = require('crypto-js')

export const cp = 'bmEgbWluaGEgbWFxdWluYSBmdW5jaW9uYQ'

export function criptografar (texto) {
    var encriptar = CryptoJS.AES.encrypt(texto, cp).toString()
    return encriptar
}

export function descriptografar (texto) {
    var bytes = CryptoJS.AES.decrypt(texto, cp)
    var descript = bytes.toString(CryptoJS.enc.Utf8)
    return descript
}
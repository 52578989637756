import ApiService from "../app/apiservice";
//import ErroValidacao from "../app/ErroValidacao";

class LancamentoCooperadoService extends ApiService {

    constructor() {
        super('/lancamentocooperado')
    }

    listarTodos(pagina) {
        return this.get(pagina)
    }

    buscarLancamentoPorCooperado(id){
        return this.get(`/buscarporcooperado/${id}`)
    }

    obterPorId(id){
        return this.get(`/${id}`)
    }

    salvar(lancamentoCooperado){
        return this.post('/', lancamentoCooperado)
    }

    deletar(id){
        return this.delete(`/${id}`)
    }

    listar(){
        return this.get('/');
    }

    atualizar(lancamentoCooperado){
        return this.put(`/${lancamentoCooperado.id}`, lancamentoCooperado)
    }

    listarPorUnidAnoMes(idUnid, ano, mes){
        return this.get(`/unidade/${idUnid}/ano/${ano}/mes/${mes}`)
    }

    buscarCooperadoLancamentoMes(id, ano, mes){
        return this.get(`/cooperadosmes/unidade/${id}/ano/${ano}/mes/${mes}`)
    }

    buscarLancamentoCooperadoPorMes(idUnidade, ano, mes, id){
        return this.get(`/lancamentocooperadospormes/unidade/${idUnidade}/ano/${ano}/mes/${mes}/cooperado/${id}`)
    }

    countLancamentos(idUnidade, ano, mes){
        return this.get(`/countlancamentos/unidade/${idUnidade}/ano/${ano}/mes/${mes}`)
    }

    replicarLancamentos(idUnidade, ano, mes){
        return this.get(`/replicarlancamentos/unidade/${idUnidade}/ano/${ano}/mes/${mes}`)
    }

    countLancamentosMesSeguinte(idUnidade, ano, mesSeguinte){
        return this.get(`/countlancamentosmesseguinte/unidade/${idUnidade}/ano/${ano}/mes/${mesSeguinte}`)
    }

    countLancamentosMesAtual(idUnidade, ano, mesSeguinte){
        return this.get(`/countlancamentosmesatual/unidade/${idUnidade}/ano/${ano}/mes/${mesSeguinte}`)
    }

    buscarHistoricoCooperado(id){
        return this.get(`/historico/${id}`)
    }

   /*  validar(lancamentoCooperado){
        const erros = [];

        console.log('On Service',lancamentoCooperado)

        if(lancamentoServidor.id > 0 ? !lancamentoServidor.servidor.id : !lancamentoServidor.servidor){
            erros.push("Informe um servidor");
        }

        if(!lancamentoServidor.turno){
            erros.push("Informe um turno");
        }

        if(!lancamentoServidor.situacao){
            erros.push("Informe uma situação");
        }

        if(erros && erros.length > 0){
            throw new ErroValidacao(erros);
        }
        
    } */
}

export default LancamentoCooperadoService;
import { useEffect, useState } from "react";
import { Row, Col, Container, Table } from "react-bootstrap";
import { mensagemErro, mensagemSucesso } from "../../components/avisos/toastr";
import FgsPagamentosService from "../../service/fgsPagamentosService";

const serviceFG = new FgsPagamentosService();

const dadosFgsAtualizar = {
  id: 1,
  diretorAlunoMin1: 0,
  diretorAlunoMax1: 0,
  diretorPorc1: 0,
  diretorAlunoMin2: 0,
  diretorAlunoMax2: 0,
  diretorPorc2: 0,
  diretorAlunoMin3: 0,
  diretorAlunoMax3: 0,
  diretorPorc3: 0,
  diretorAlunoMin4: 0,
  diretorAlunoMax4: 0,
  diretorPorc4: 0,
  diretorAlunoMin5: 0,
  diretorAlunoMax5: 0,
  diretorPorc5: 0,
  diretorAlunoMin6: 0,
  diretorPorc6: 0,
  coordOriAlunoMin1: 0,
  coordOriAlunoMax1: 0,
  coordOriPorc1: 0,
  coordOriAlunoMin2: 0,
  coordOriAlunoMax2: 0,
  coordOriPorc2: 0,
  coordOriAlunoMin3: 0,
  coordOriAlunoMax3: 0,
  coordOriPorc3: 0,
  coordOriAlunoMin4: 0,
  coordOriPorc4: 0,
  secretarioAlunoMin1: 0,
  secretarioAlunoMax1: 0,
  secretarioValor1: 0,
  secretarioAlunoMin2: 0,
  secretarioAlunoMax2: 0,
  secretarioValor2: 0,
  secretarioAlunoMin3: 0,
  secretarioAlunoMax3: 0,
  secretarioValor3: 0,
  secretarioAlunoMin4: 0,
  secretarioAlunoMax4: 0,
  secretarioValor4: 0,
  secretarioAlunoMin5: 0,
  secretarioValor5: 0,
};

function ParametrosFgs() {
  const [habilitarDesabilitar, setHabilitarDesabilitar] = useState(true);

  const [dadosFgs, setDadosFgs] = useState(dadosFgsAtualizar);

  useEffect(() => {
    async function CarregarDados() {
        const buscarValores = await serviceFG.carregarValores()
        setDadosFgs(buscarValores.data)
        //console.log(buscarValores.data)

    }
    CarregarDados();
  }, [habilitarDesabilitar]);

  function ativarDesativar() {
    if (habilitarDesabilitar) {
      setHabilitarDesabilitar(false);
    } else if (!habilitarDesabilitar) {
      setHabilitarDesabilitar(true);
    }
  }

  function salvarAlteracoes() {
        dadosFgsAtualizar.id = 1
        dadosFgsAtualizar.diretorAlunoMin1 = dadosFgs.diretorAlunoMin1
        dadosFgsAtualizar.diretorAlunoMax1 = dadosFgs.diretorAlunoMax1
        dadosFgsAtualizar.diretorPorc1 = dadosFgs.diretorPorc1
        dadosFgsAtualizar.diretorAlunoMin2 = dadosFgs.diretorAlunoMin2
        dadosFgsAtualizar.diretorAlunoMax2 = dadosFgs.diretorAlunoMax2
        dadosFgsAtualizar.diretorPorc2 = dadosFgs.diretorPorc2
        dadosFgsAtualizar.diretorAlunoMin3 = dadosFgs.diretorAlunoMin3
        dadosFgsAtualizar.diretorAlunoMax3 = dadosFgs.diretorAlunoMax3
        dadosFgsAtualizar.diretorPorc3 = dadosFgs.diretorPorc3
        dadosFgsAtualizar.diretorAlunoMin4 = dadosFgs.diretorAlunoMin4
        dadosFgsAtualizar.diretorAlunoMax4 = dadosFgs.diretorAlunoMax4
        dadosFgsAtualizar.diretorPorc4 = dadosFgs.diretorPorc4
        dadosFgsAtualizar.diretorAlunoMin5 = dadosFgs.diretorAlunoMin5
        dadosFgsAtualizar.diretorAlunoMax5 = dadosFgs.diretorAlunoMax5
        dadosFgsAtualizar.diretorPorc5 = dadosFgs.diretorPorc5
        dadosFgsAtualizar.diretorAlunoMin6 = dadosFgs.diretorAlunoMin6
        dadosFgsAtualizar.diretorPorc6 = dadosFgs.diretorPorc6
        dadosFgsAtualizar.coordOriAlunoMin1 = dadosFgs.coordOriAlunoMin1
        dadosFgsAtualizar.coordOriAlunoMax1 = dadosFgs.coordOriAlunoMax1
        dadosFgsAtualizar.coordOriPorc1 = dadosFgs.coordOriPorc1
        dadosFgsAtualizar.coordOriAlunoMin2 = dadosFgs.coordOriAlunoMin2
        dadosFgsAtualizar.coordOriAlunoMax2 = dadosFgs.coordOriAlunoMax2
        dadosFgsAtualizar.coordOriPorc2 = dadosFgs.coordOriPorc2
        dadosFgsAtualizar.coordOriAlunoMin3 = dadosFgs.coordOriAlunoMin3
        dadosFgsAtualizar.coordOriAlunoMax3 = dadosFgs.coordOriAlunoMax3
        dadosFgsAtualizar.coordOriPorc3 = dadosFgs.coordOriPorc3
        dadosFgsAtualizar.coordOriAlunoMin4 = dadosFgs.coordOriAlunoMin4
        dadosFgsAtualizar.coordOriPorc4 = dadosFgs.coordOriPorc4
        dadosFgsAtualizar.secretarioAlunoMin1 = dadosFgs.secretarioAlunoMin1
        dadosFgsAtualizar.secretarioAlunoMax1 = dadosFgs.secretarioAlunoMax1
        dadosFgsAtualizar.secretarioValor1 = dadosFgs.secretarioValor1
        dadosFgsAtualizar.secretarioAlunoMin2 = dadosFgs.secretarioAlunoMin2
        dadosFgsAtualizar.secretarioAlunoMax2 = dadosFgs.secretarioAlunoMax2
        dadosFgsAtualizar.secretarioValor2 = dadosFgs.secretarioValor2
        dadosFgsAtualizar.secretarioAlunoMin3 = dadosFgs.secretarioAlunoMin3
        dadosFgsAtualizar.secretarioAlunoMax3 = dadosFgs.secretarioAlunoMax3
        dadosFgsAtualizar.secretarioValor3 = dadosFgs.secretarioValor3
        dadosFgsAtualizar.secretarioAlunoMin4 = dadosFgs.secretarioAlunoMin4
        dadosFgsAtualizar.secretarioAlunoMax4 = dadosFgs.secretarioAlunoMax4
        dadosFgsAtualizar.secretarioValor4 = dadosFgs.secretarioValor4
        dadosFgsAtualizar.secretarioAlunoMin5 = dadosFgs.secretarioAlunoMin5
        dadosFgsAtualizar.secretarioValor5 = dadosFgs.secretarioValor5
      //console.log(dadosFgsAtualizar)
      serviceFG.atualizarFgs(dadosFgsAtualizar)
      .then((s) => {
          mensagemSucesso("Alteração salva com sucesso.")
          setTimeout(() => {
            window.location.reload()
          }, 1000)
      })
      .catch((e) => {
          mensagemErro(e.response.data)
      })
    ativarDesativar();
  }

  function cancelarAlteracoes() {
    ativarDesativar();
  }

  function onChange(e) {
    const { name, value } = e.target;
    setDadosFgs({ ...dadosFgs, [name]: Number(value) });
  }

  return (
    <>
      <div className="container mt-6">
        <div className="form-group">
          <div className="card md-3">
            <h4 className="card-header">Tabelas de Dados para FG</h4>

            <Container>
              <br />
              <div style={{ textAlign: "center" }}>
                {habilitarDesabilitar ? (
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={(e) => ativarDesativar()}
                  >
                    Atualizar Valores
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn-danger"
                      type="button"
                      onClick={(e) => salvarAlteracoes()}
                    >
                      Salvar Alterações
                    </button>

                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={(e) => cancelarAlteracoes()}
                    >
                      Cancelar
                    </button>
                  </>
                )}
              </div>

              <br />

              <Row className="justify-content-md">
                <Col>
                  <br />
                  <h5 style={{ textAlign: "center" }}>Tabela de FG Diretor</h5>

                  <Table
                    className="justify-content-center"
                    striped
                    bordered
                    hover
                  >
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          style={{ textAlign: "center", width: "10px" }}
                        >
                          Ordem
                        </th>
                        <th
                          scope="col"
                          style={{ textAlign: "center", width: "200px" }}
                        >
                          Nº Aluno Mínimo
                        </th>
                        <th
                          scope="col"
                          style={{ textAlign: "center", width: "200px" }}
                        >
                          Nº Aluno Máximo
                        </th>
                        <th
                          scope="col"
                          style={{ textAlign: "center", width: "200px" }}
                        >
                          Porcentagem (%)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ textAlign: "center" }}>
                          <label>I</label>
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="diretorAlunoMin1"
                            name="diretorAlunoMin1"
                            value={dadosFgs.diretorAlunoMin1}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="diretorAlunoMax1"
                            name="diretorAlunoMax1"
                            value={dadosFgs.diretorAlunoMax1}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="diretorPorc1"
                            name="diretorPorc1"
                            value={dadosFgs.diretorPorc1}
                            onChange={onChange}
                            min={0}
                            max={100}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "center", width: "10px" }}>
                          <label>II</label>
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="diretorAlunoMin2"
                            name="diretorAlunoMin2"
                            value={dadosFgs.diretorAlunoMin2}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="diretorAlunoMax2"
                            name="diretorAlunoMax2"
                            value={dadosFgs.diretorAlunoMax2}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="diretorPorc2"
                            name="diretorPorc2"
                            value={dadosFgs.diretorPorc2}
                            onChange={onChange}
                            min={0}
                            max={100}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "center", width: "10px" }}>
                          <label>III</label>
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="diretorAlunoMin3"
                            name="diretorAlunoMin3"
                            value={dadosFgs.diretorAlunoMin3}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="diretorAlunoMax3"
                            name="diretorAlunoMax3"
                            value={dadosFgs.diretorAlunoMax3}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="diretorPorc3"
                            name="diretorPorc3"
                            value={dadosFgs.diretorPorc3}
                            onChange={onChange}
                            min={0}
                            max={100}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "center", width: "10px" }}>
                          <label>IV</label>
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="diretorAlunoMin4"
                            name="diretorAlunoMin4"
                            value={dadosFgs.diretorAlunoMin4}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="diretorAlunoMax4"
                            name="diretorAlunoMax4"
                            value={dadosFgs.diretorAlunoMax4}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="diretorPorc4"
                            name="diretorPorc4"
                            value={dadosFgs.diretorPorc4}
                            onChange={onChange}
                            min={0}
                            max={100}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "center", width: "10px" }}>
                          <label>V</label>
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="diretorAlunoMin5"
                            name="diretorAlunoMin5"
                            value={dadosFgs.diretorAlunoMin5}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="diretorAlunoMax5"
                            name="diretorAlunoMax5"
                            value={dadosFgs.diretorAlunoMax5}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="diretorPorc5"
                            name="diretorPorc5"
                            value={dadosFgs.diretorPorc5}
                            onChange={onChange}
                            min={0}
                            max={100}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "center", width: "10px" }}>
                          <label>VI</label>
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="diretorAlunoMin6"
                            name="diretorAlunoMin6"
                            value={dadosFgs.diretorAlunoMin6}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td></td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="diretorPorc6"
                            name="diretorPorc6"
                            value={dadosFgs.diretorPorc6}
                            onChange={onChange}
                            min={0}
                            max={100}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <br />
              <hr />
              <Row className="justify-content-md">
                <Col>
                  <br />
                  <h5 style={{ textAlign: "center" }}>
                    Tabela de FG Coordenadores e Orientadores Pedagógicos
                  </h5>

                  <Table
                    className="justify-content-center"
                    striped
                    bordered
                    hover
                  >
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          style={{ textAlign: "center", width: "10px" }}
                        >
                          Ordem
                        </th>
                        <th
                          scope="col"
                          style={{ textAlign: "center", width: "200px" }}
                        >
                          Nº Aluno Mínimo
                        </th>
                        <th
                          scope="col"
                          style={{ textAlign: "center", width: "200px" }}
                        >
                          Nº Aluno Máximo
                        </th>
                        <th
                          scope="col"
                          style={{ textAlign: "center", width: "200px" }}
                        >
                          Porcentagem (%)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ textAlign: "center" }}>
                          <label>I</label>
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="coordOriAlunoMin1"
                            name="coordOriAlunoMin1"
                            value={dadosFgs.coordOriAlunoMin1}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="coordOriAlunoMax1"
                            name="coordOriAlunoMax1"
                            value={dadosFgs.coordOriAlunoMax1}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="coordOriPorc1"
                            name="coordOriPorc1"
                            value={dadosFgs.coordOriPorc1}
                            onChange={onChange}
                            min={0}
                            max={100}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "center", width: "10px" }}>
                          <label>II</label>
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="coordOriAlunoMin2"
                            name="coordOriAlunoMin2"
                            value={dadosFgs.coordOriAlunoMin2}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="coordOriAlunoMax2"
                            name="coordOriAlunoMax2"
                            value={dadosFgs.coordOriAlunoMax2}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="coordOriPorc2"
                            name="coordOriPorc2"
                            value={dadosFgs.coordOriPorc2}
                            onChange={onChange}
                            min={0}
                            max={100}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "center", width: "10px" }}>
                          <label>III</label>
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="coordOriAlunoMin3"
                            name="coordOriAlunoMin3"
                            value={dadosFgs.coordOriAlunoMin3}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="coordOriAlunoMax3"
                            name="coordOriAlunoMax3"
                            value={dadosFgs.coordOriAlunoMax3}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="coordOriPorc3"
                            name="coordOriPorc3"
                            value={dadosFgs.coordOriPorc3}
                            onChange={onChange}
                            min={0}
                            max={100}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "center", width: "10px" }}>
                          <label>IV</label>
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="coordOriAlunoMin4"
                            name="coordOriAlunoMin4"
                            value={dadosFgs.coordOriAlunoMin4}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td></td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="coordOriPorc4"
                            name="coordOriPorc4"
                            value={dadosFgs.coordOriPorc4}
                            onChange={onChange}
                            min={0}
                            max={100}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <br />
              <hr />
              <Row className="justify-content-md">
                <Col>
                  <br />
                  <h5 style={{ textAlign: "center" }}>
                    Tabela de FG Secretários Escolares
                  </h5>

                  <Table
                    className="justify-content-center"
                    striped
                    bordered
                    hover
                  >
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          style={{ textAlign: "center", width: "10px" }}
                        >
                          Ordem
                        </th>
                        <th
                          scope="col"
                          style={{ textAlign: "center", width: "200px" }}
                        >
                          Nº Aluno Mínimo
                        </th>
                        <th
                          scope="col"
                          style={{ textAlign: "center", width: "200px" }}
                        >
                          Nº Aluno Máximo
                        </th>
                        <th
                          scope="col"
                          style={{ textAlign: "center", width: "200px" }}
                        >
                          Valor (R$)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ textAlign: "center" }}>
                          <label>FGSEC-1</label>
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="secretarioAlunoMin1"
                            name="secretarioAlunoMin1"
                            value={dadosFgs.secretarioAlunoMin1}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="secretarioAlunoMax1"
                            name="secretarioAlunoMax1"
                            value={dadosFgs.secretarioAlunoMax1}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="secretarioValor1"
                            name="secretarioValor1"
                            value={dadosFgs.secretarioValor1}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "center", width: "10px" }}>
                          <label>FGSEC-2</label>
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="secretarioAlunoMin2"
                            name="secretarioAlunoMin2"
                            value={dadosFgs.secretarioAlunoMin2}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="secretarioAlunoMax2"
                            name="secretarioAlunoMax2"
                            value={dadosFgs.secretarioAlunoMax2}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="secretarioValor2"
                            name="secretarioValor2"
                            value={dadosFgs.secretarioValor2}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "center", width: "10px" }}>
                          <label>FGSEC-3</label>
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="secretarioAlunoMin3"
                            name="secretarioAlunoMin3"
                            value={dadosFgs.secretarioAlunoMin3}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="secretarioAlunoMax3"
                            name="secretarioAlunoMax3"
                            value={dadosFgs.secretarioAlunoMax3}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="secretarioValor3"
                            name="secretarioValor3"
                            value={dadosFgs.secretarioValor3}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "center", width: "10px" }}>
                          <label>FGSEC-4</label>
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="secretarioAlunoMin4"
                            name="secretarioAlunoMin4"
                            value={dadosFgs.secretarioAlunoMin4}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="secretarioAlunoMax4"
                            name="secretarioAlunoMax4"
                            value={dadosFgs.secretarioAlunoMax4}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="secretarioValor4"
                            name="secretarioValor4"
                            value={dadosFgs.secretarioValor4}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "center", width: "10px" }}>
                          <label>FGSEC-5</label>
                        </td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="secretarioAlunoMin5"
                            name="secretarioAlunoMin5"
                            value={dadosFgs.secretarioAlunoMin5}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                        <td></td>
                        <td>
                          <input
                            style={{ textAlign: "center" }}
                            id="secretarioValor5"
                            name="secretarioValor5"
                            value={dadosFgs.secretarioValor5}
                            onChange={onChange}
                            min={0}
                            type="number"
                            disabled={habilitarDesabilitar}
                            className="form-control"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}

export default ParametrosFgs;

import ApiService from "../app/apiservice";

class FaltasSubsTerceirizadosService extends ApiService {

    constructor(){
        super('/faltassubsterceirizados')
    }

    obterPorId(id){
        return this.get(`/${id}`)
    }
    
    salvar(faltaSubTerceirizado){
        return this.post('/', faltaSubTerceirizado)
    }
    
    listar() {
        return this.get('/')
    }
    
    deletar(id){
        return this.delete(`/${id}`)
    }
    
    atualizar(faltaSubTerceirizado){
        return this.put(`/${faltaSubTerceirizado.id}`, faltaSubTerceirizado)
    }

    buscarFaltasSubssPorLancamentoTerceirizado(id){
        return this.get(`/buscarporlancterceirizado/${id}`)
    }
}

export default FaltasSubsTerceirizadosService
import { useEffect, useState } from 'react'
import LancamentoServidorService from '../../service/lancamentoServidorService';
import { mensagemErro, mensagemSucesso } from '../../components/avisos/toastr';
import { Col, Container, Form, Modal, Row, Tab, Table, Tabs } from 'react-bootstrap';
import Card from '../../components/card';
import Button from 'react-bootstrap/Button'
import LocalStorageService from '../../service/localStorageService';
import ServidorService from '../../service/servidorService';
import AnoMes from '../../components/mesAno/anoMes';
import ReactPaginate from "react-paginate";
import DataFaltaSubstituicaoService from '../../service/dataFaltaSubstituicaoService';
import MotivosSubstituicaoService from '../../service/motivosSubstituicaoService';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import calendarioPersonalizado from '../../components/calendario/calendariomulti';
import DatePicker from 'react-multi-date-picker';
import FuncaoService from '../../service/funcaoService';
import { descriptografar } from '../../components/criptografar';
import ParametrosStatusLancamentoService from '../../service/parametrosStatusLancamentoService';
import AnoService from "../../service/anoService";
import assinaturaQuadrosService from '../../service/assinaturaQuadrosService';

function ListarLancamentosServidores() {

    const lancamentoServidor = {
        id: null,
        servidor: null,
        unidade: null,
        funcao: null,
        dataInicioUnidade: "",
        dataDesligamentoUnidade: "",
        inicioAulasExcedentes: null,
        terminoAulasExcedentes: "",
        anoLancamento: "",
        mesLancamento: "",
        cpfServidor: "",
        turno: "",
        turmasAtuacao: "",
        porcentagemCoordenadorSemec: "",
        turmasAulasExcedentes: "",
        aulasExcedentes: null,
        observacao: "",
        observacaoAulasExcedentes: "",
        statusLancamento: true,
        adicionalDificilAcesso: false,
        emReadaptacao: false,
        novaFuncaoReadaptada: "",
        rescindiu: false,
        verbaIdenizatoria: null
    };

    const [lancamento, setLancamento] = useState(lancamentoServidor);

    function onChange(e) {
        const {name, value} = e.target;
        setLancamento({...lancamento, [name]: value})
    }

    const dataFaltaSubstituicao = {
        id: null,
        lancamentoEstagiario: null,
        lancamentoServidor: null,
        servidor: null,
        cooperado: null,
        horasFaltas: null,
        tipoFalta: null,
        anoLancamento: "",
        mesLancamento: "",
        cpfServidor: "",
        motivosSubstituicao: null,
        aulasSubstituicao: null,
        dataAulasSubstituicao: null,
        aulasLivres: false,
        listaDataFaltas: [],
        listaDataSubstituicao: []
    }

    //PARA O CADASTRO DE LANÇAMENTOS DE FALTAS E SUBSTITUIÇÕES
    const [key, setKey] = useState('substituicao');
    const [isChecked, setIsChecked] = useState(false);
    const [isCheckedAdapt, setIsCheckedAdapt] = useState(false);
    const [isCheckedRescisao, setIsCheckedRescisao] = useState(false);
    const [exibirCheckAdapt, setExibirCheckAdapt] = useState(false);
    const [checkedAulasLivres, setCheckedAulasLivres] = useState(false)
    //const [mostrarBtnReplicar, setMostrarBtnReplicar] = useState(false);
    const [lancamentoEstaAberto, setLancamentoEstaAberto] = useState(true);

    const handleOnChecked =  () => {
        setIsChecked(!isChecked);
    }

    const handleOnCheckedAdapt = () => {
        setIsCheckedAdapt(!isCheckedAdapt);
    }

    const handleOnCheckedRescisao = () => {
        setIsCheckedRescisao(!isCheckedRescisao);
    }

    const handleOnCheckedAulasLivres = () => {
        setCheckedAulasLivres(!checkedAulasLivres)
    }

    const [dataFaltaListaSub, setDataFaltaListaSub] = useState([]);
    const [dataFaltaSub, setDataFaltaSub] = useState(dataFaltaSubstituicao);
    const [listaDataFaltaSub, setListaDataFaltaSub] = useState([]);
    const [idLancamentoServidor, setIdLancamentoServidor] = useState(0);
    const [listaMotivosSub, setListaMotivosSub] = useState([]);

    const [idServidor, setIdServidor] = useState();
    const [idFuncao, setIdFuncao] = useState();
    const [idMotivo, setIdMotivo] = useState();
    const [dataSub, setDataSub] = useState();
    const [horaAulaSub, setHoraAulaSub] = useState();
    const [idTurno, setIdTurno] = useState();
    const [idTipoFalta, setIdTipoFalta] = useState();

    const [exibirCamposProfessor, setExibirCamposProfessor] = useState(false);
    const [exibirCamposCoordenadorSemec, setExibirCamposCoordenadorSemec] = useState(false);

    const [listaServidoresSub, setListaServidoresSub] = useState([]);

    const [funcoes, setFuncao] = useState([]);

    const a = "ADMINISTRADOR"
    const r = "RH"
    const usuarioCriptografado = LocalStorageService.obterItem('2')
    const usuarioLogado = JSON.parse(descriptografar(usuarioCriptografado))
    let tipoUsuario = usuarioLogado.role;

    const unidadeCriptografado = LocalStorageService.obterItem('3')
    let idUnidade = descriptografar(unidadeCriptografado)
    const dataCriptografada = LocalStorageService.obterItem('1')
    let dataAtual = new Date(descriptografar(dataCriptografada));

    const [mes, setMes] = useState(dataAtual.getUTCMonth() + 1);
    const [ano, setAno] = useState(dataAtual.getUTCFullYear());

    //Para atualizar a lista de datas e faltas no botão de excluir.
    const [isRemoved, setIsRemoved] = useState(false);
    //Para atualizar a lista de datas e faltas no botão de salvar.
    const [salvou, setSalvou] = useState(false);
    //Para verificar se o botão 'REPLICAR' dever ser exibido ou não.
    const [countLancamentosMesSeguinte, setCountLancamentosMesSeguinte] = useState(0);

    //modal vincular servidor
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [servidores, setServidores] = useState([]);

    //Modal de edição
    const [showEditConf, setShowEditConf] = useState(false);
    const handleEditClose = () => setShowEditConf(false)
    const handleEditShow = () => setShowEditConf(true)

    //MODAL PARA LANÇAR FALTAS DO ESTAGIÁRIOS
    const [showLanc, setShowLanc] = useState(false);
    const handleLancClose = () => setShowLanc(false);
    const handleLancShow = () => setShowLanc(true);

    //MODAL PARA CONFIRMAR EXCLUSÃO DAS FALTAS
    const [showConfDataFalta, setShowConfDataFalta] = useState(false);
    const handleConfDataFaltaclose = () => setShowConfDataFalta(false);
    const handleConfDataFaltaShow = () => setShowConfDataFalta(true);

    //MODAL PARA CONFIRMAR A REPLICAÇÃO DE LANCAMENTOS
    const [showConfReplica, setShowConfReplica] = useState(false);
    const handleConfReplicaClose = () => setShowConfReplica(false);
    const handleConfReplicaShow = () => setShowConfReplica(true);

     //paginação de servidor
     const [paginaAtual, setPaginaAtual] = useState(0);
     const [nomeBuscar, setNomeBuscar] = useState("");
     const [numeroDePaginas, setNumeroDePaginas] = useState(0);

    const [showConf, setShowConf] = useState(false);
    const handleConfclose = () => setShowConf(false);
    const handleConfShow = () => setShowConf(true);

    const [lancamentos, setLancamentosServidor] = useState([]);
    const [imputId, setImputId] = useState();
    const [imputIdDataFalta, setImputIdDataFalta] = useState();

    const service = new LancamentoServidorService();
    const serviceServidor = new ServidorService();
    const serviceDataFaltaSubstituicao = new DataFaltaSubstituicaoService();
    const serviceMotivoSubstituicao = new MotivosSubstituicaoService();
    const serviceFuncao = new FuncaoService();
    const serviceParametrosStatus = new ParametrosStatusLancamentoService();
    const serviceAno = new AnoService();
    const serviceAssinaturaDigital = new assinaturaQuadrosService()

    useEffect(() => {

        async function loadLancamentosServidores() {
            const response = await service.listarPorUnidAnoMes(idUnidade, ano, mes);
            setLancamentosServidor(response.data);
        }
        loadLancamentosServidores();

        async function loadServidoresBusca() {
            const response = await serviceServidor.listarTodos(`/servidorativospornome/${nomeBuscar}/page/${paginaAtual}`);
            setServidores(response.data.content);
            setNumeroDePaginas(response.data.totalPages)

            const arrayPaginas = [];
            //cria um array de paginas na quantidade vinda do backend
            for (let p = 1; p <= numeroDePaginas; p++) {
                arrayPaginas.push(p);
            }
        }

        async function loadServidores() {
            const response = await serviceServidor.listarTodos(`/pag/ativos/${paginaAtual}`);
            setServidores(response.data.content);
            setNumeroDePaginas(response.data.totalPages)

            const arrayPaginas = [];
            //cria um array de paginas na quantidade vinda do backend
            for (let p = 1; p <= numeroDePaginas; p++) {
                arrayPaginas.push(p);
            }
        }

        if (nomeBuscar === "") {
            loadServidores();
        } else {
            loadServidoresBusca();
        }

    }, [mes, ano, paginaAtual, nomeBuscar, showConf, show, showEditConf]);

    useEffect(() => {

        async function carregarFuncoes() {
            const response = await serviceFuncao.listarAtivos();
            setFuncao(response.data);
        }
        carregarFuncoes();

        async function listarServidoresSubstituicao() {
            const response = await service.buscarServidorLancamentoMes(idUnidade, ano, mes);
            setListaServidoresSub(response.data);
        }
        listarServidoresSubstituicao();

        async function listarMotivosSubstituicao() {
            const response = await serviceMotivoSubstituicao.listarAtivos();
            setListaMotivosSub(response.data);
        }
        listarMotivosSubstituicao();

        async function listarFaltasHorasSub() {
            const response = await serviceDataFaltaSubstituicao.buscarFaltasEHorasPorLancamentoServidor(idLancamentoServidor);
            setListaDataFaltaSub(response.data);
        }
        listarFaltasHorasSub();

    }, [showLanc, salvou, isRemoved]);

    //ESCONDER O BOTÃO DE REPLICAR
    useEffect(() => {
            const flagLancamentoServidor = 'lancamentoServidor';

            //CHECAGEM DOS STATUS DO PARAMETRO DO LANCAMENTOS PARA ESCONDER OS BOTOES REPLICAR E VINCULAR.
            async function verificarParametroStatusLancamento() {
                const response = await serviceParametrosStatus.verificarParametroStatusLancamento(idUnidade, ano, mes, flagLancamentoServidor);
                setLancamentoEstaAberto(response.data);
            }
            verificarParametroStatusLancamento();

        if(Number(mes) !== 12){
            /* const date = Date.now();
            const today = new Date(date);

            //Mês Atual
            let mesAtual = (today.getMonth() + 1); */

            //Mês Informado
            let mesFormatado = parseInt(mes)
            let mesSeguinte = mesFormatado + 1;

            //fazer um count dos lancamentos do mes seguinte, se tiver, ocultar o botão "Replicar"
            async function buscarLancamentosMesSeguinte(){
                const response = await service.countLancamentosMesSeguinte(idUnidade, ano, mesSeguinte);
                setCountLancamentosMesSeguinte(response.data);
            }
            buscarLancamentosMesSeguinte();

            


           /*  if(mesAtual === parseInt(mes)){
                setMostrarBtnReplicar(true);
            }else{
                setMostrarBtnReplicar(false);
            } */

        }else{
            let mesAnoSeguinte = 1;
            let anoContagem = ano + 1;

            //fazer um count dos lancamentos do mes seguinte, se tiver, ocultar o botão "Replicar"
            async function buscarLancamentosMesSeguinte(){
                const response = await service.countLancamentosMesSeguinte(idUnidade, anoContagem, mesAnoSeguinte);
                setCountLancamentosMesSeguinte(response.data);
            }
            buscarLancamentosMesSeguinte();
        }
        

    }, [countLancamentosMesSeguinte, mes, showConfReplica]);

    const vincularServidor = (id, cpf) => {
        service.buscarLancamentoServidorPorMes(idUnidade, ano, mes, id)
            .then((response) => {
                const result = response.data;
                if(result){
                    mensagemErro("Servidor já possui vinculo ao mês atual.");
                }else{
                    lancamentoServidor['servidor'] = {id};
                    lancamentoServidor['unidade'] = {id: Number.parseInt(idUnidade)};
                    lancamentoServidor['mesLancamento'] = mes;
                    lancamentoServidor['anoLancamento'] = ano;
                    lancamentoServidor['statusLancamento'] = true;
                    lancamentoServidor['cpfServidor'] = cpf;
                    service.salvar(lancamentoServidor)
                    .then(() => {
                        mensagemSucesso("Servidor vinculado com sucesso.")
                        setTimeout(() => {
                            window.location.reload()
                        },1000)
                        handleClose();
                    })
                    .catch((e) => {
                        mensagemErro(e.response.data)
                    })
                }
            })
    }

    const pesquisou = () => setPaginaAtual(0);

    function handlePageClick(data) {
        setPaginaAtual(data.selected);
    }

    const closeModalLanc = () => {
        handleLancClose();
    }

    const deletar = (id) => {

        serviceDataFaltaSubstituicao.buscarFaltasEHorasPorLancamentoServidor(id)
        .then((response) => {
            const result = response.data;

            if(result.length > 0){
                mensagemErro("Não é possível excluir o registro. Já existem substituições lançadas.");
            }else{
                service.deletar(id)
                .then(() => {
                    mensagemSucesso('Vínculo excluído com sucesso.')
                    handleConfclose()
                }).catch( e => {
                    mensagemErro(e.response.data);
                })
            }
        })
    }

    const atualizar = () => {

        if(lancamento.dataInicioUnidade !== null){
            if(checarData(lancamento.dataInicioUnidade) === false){
                mensagemErro('Data de início tem o formato inválido.');
                return;
            }
        }

        if(lancamento.dataDesligamentoUnidade !== null){
            if(checarData(lancamento.dataDesligamentoUnidade) === false){
                mensagemErro('Data de desligamento tem o formato inválido.');
                return;
            }
        }


        if(!idFuncao){
            mensagemErro('Informe a função do servidor na unidade.');
            return;
        }else{
            lancamento.funcao = {id: Number.parseInt(idFuncao)};
        }

        if(!idTurno){
            mensagemErro('Informe o turno do servidor na unidade.');
            return;
        }else{
            lancamento.turno = {id: Number.parseInt(idTurno)};
        }

        if(!lancamento.dataInicioUnidade){
            mensagemErro('Informe a data de início do servidor na unidade.');
            return;
        }

        if(isCheckedAdapt){
            if(lancamento.novaFuncaoReadaptada.trim() === ""){
                mensagemErro('Informe a nova função em readaptação.');
                return;
            }
        }

        if(lancamento.aulasExcedentes === null){
            lancamento.aulasExcedentes = 0;
        }

        if(lancamento.aulasExcedentes > 0){
            if(!lancamento.inicioAulasExcedentes){
                mensagemErro('Informe a data de início das aulas excedentes.');
                return;
            }
        }

        lancamento.emReadaptacao = isCheckedAdapt;
        lancamento.adicionalDificilAcesso = isChecked;
        lancamento.rescindiu = isCheckedRescisao;
        lancamento.turno = idTurno;
        service.atualizar(lancamento)
            .then(() => {
                mensagemSucesso('Lancamento atualizado com sucesso.')
                setIdFuncao('');
                handleEditClose();

            }).catch((e) => {
                mensagemErro(e.response.data.mensagem)
            })
    }

    const abrirEditModal = (id) => {
        limparDados();
        handleEditShow();
        if(id){
            service.obterPorId(id)
            .then((response) => {
                if(response.data.funcao){
                    setIdFuncao(response.data.funcao.id);
                    setIsChecked(response.data.adicionalDificilAcesso);

                    let nomeFuncao = response.data.funcao.nome;
                    if(nomeFuncao.match(/PROFESSOR\(A\)/)){
                        setExibirCamposProfessor(true)
                    }
                    if(nomeFuncao.match(/COORDENADOR SEMEC/)){
                        setExibirCamposCoordenadorSemec(true)
                    }
                }

                if(response.data.servidor){
                    let tipoVinculo = response.data.servidor.vinculo
                    setIsCheckedAdapt(response.data.emReadaptacao);
                    if(tipoVinculo.match(/EFETIVO/)){
                        setExibirCheckAdapt(true)
                    }
                }

                setIdTipoFalta(response.data.tipoFalta)
                setIdTurno(response.data.turno);
                setLancamento(response.data);
                setIsCheckedRescisao(response.data.rescindiu);
            })
        }
    }
        
    

    const abrirModalLancamento = (l) => {
       
        limparDataFaltaServidor();
        setIdLancamentoServidor(l.id);
        serviceAssinaturaDigital.verificarSeAssinado(Number(idUnidade), ano, mes, "SERVIDOR")
        .then((s) => {
            if (s.data > 0) {
                mensagemErro("O Quadro de Servidor está ASSINADO, para prosseguir com alterações nos Lançamentos de Servidores deve apagar a assinatura.")
            }
            if (s.data === 0) {
                if(!l.funcao){
                    mensagemErro("Informe a função do servidor na unidade.");
                    return;
                }
                if(!l.turno){
                    mensagemErro("Informe o turno de trabalho do servidor.");
                    return;
                }
                if(!l.dataInicioUnidade){
                    mensagemErro("Informe a data de início do servidor na unidade.");
                    return;
                }
                handleLancShow();

                if(l.id){
                    service.obterPorId(l.id)
                    .then((response) => {
                        setLancamento(response.data);
                    })
                }
            }
        })
    }

    const limparDados = () => {
        handleEditClose();

        setLancamento({
            id: null,
            servidor: null,
            unidade: null,
            funcao: null,
            dataInicioUnidade: "",
            dataDesligamentoUnidade: "",
            inicioAulasExcedentes: "",
            terminoAulasExcedentes: "",
            anoLancamento: "",
            mesLancamento: "",
            cpfServidor: "",
            turno: "",
            turmasAtuacao: "",
            turmasAulasExcedentes: "",
            porcentagemCoordenadorSemec: "",
            aulasExcedentes: null,
            observacao: "",
            observacaoAulasExcedentes: "",
            statusLancamento: true,
            adicionalDificilAcesso: false,
            emReadapatacao: false,
            novaFuncaoReadaptada: "",
            rescindiu: false,
            verbaIdenizatoria: null
        })

        setExibirCamposProfessor(false)
        setExibirCamposCoordenadorSemec(false)
        setIdFuncao('');
        setExibirCheckAdapt(false)
        setIsChecked(false)
        setIsCheckedAdapt(false)
        setIsCheckedRescisao(false)
    }

    const editarDataFalta = (id) => {       
        if(id){
            serviceDataFaltaSubstituicao.obterPorId(id)
            .then((response) => {
                if(key === 'faltas'){
                    setDataFaltaSub({
                        id: response.data.id,
                        dataFalta : response.data.dataFalta,
                        horasFaltas: response.data.horasFaltas
                    })

                    setIdTipoFalta(response.data.tipoFalta)

                }else{
                    setCheckedAulasLivres(response.data.aulasLivres)
                    setIdServidor(response.data.aulasLivres === false ? response.data.servidor.id : null);
                    setIdMotivo(response.data.motivosSubstituicao.id)
                    setDataSub(response.data.dataAulasSubstituicao)
                    setHoraAulaSub(response.data.aulasSubstituicao)
                    setDataFaltaSub({
                        id: response.data.id,
                        lancamentoServidor: lancamento,
                        servidor: idServidor,
                        motivosSubstituicao: idMotivo,
                        dataAulasSubstituicao: dataSub,
                        aulasSubstituicao: horaAulaSub
                    }) 
                }
            })
        }
    }

    const atualizarDataFalta = () => {
        if(key === 'faltas'){
            if(!dataFaltaSub.dataFalta){
                mensagemErro('Informe a data da falta.');
            }else if(!dataFaltaSub.horasFaltas || dataFaltaSub.horasFaltas < 1 || dataFaltaSub.horasFaltas > 8){
                mensagemErro(`${!dataFaltaSub.horasFaltas ? 'Informe a quantidade de horas.' : 'A quantidade de horas deve está entre 1 e 8.'}`);
            }else if(!idTipoFalta){
                mensagemErro("Informe o tipo de falta.");
            }else{
                dataFaltaSub['lancamentoServidor'] = lancamento;
                dataFaltaSub['tipoFalta'] = idTipoFalta;
                serviceDataFaltaSubstituicao.atualizar(dataFaltaSub)
                .then((response) => {
                    if(response.data === true){
                        setSalvou(true);
                        mensagemSucesso('Lançamento salvo com sucesso.');
                        limparDataFaltaServidor();
                    }else{
                        mensagemErro("Não foi possível inserir o registro.")
                    }
                }).catch((error) => {
                    mensagemErro(error.response.data.mensagem);
                })
            }
            setSalvou(false);

        }else{
            if(dataSub !== null){
                if(checarData(dataSub) === false){
                    mensagemErro('Data de substituição tem o formato inválido.');
                    return;
                }
            }
            if(!dataSub){
                mensagemErro("Informe a data da substituição.");
            }else if(!idServidor && !checkedAulasLivres){
                mensagemErro("Informe o servidor a ser substituído.");
            } else if(!horaAulaSub || horaAulaSub < 1 || horaAulaSub > 8){
                mensagemErro(`${!horaAulaSub ? 'Informe a quantidade de horas.' : 'A quantidade de horas deve está entre 1 e 8.'}`);
            }else if(!idMotivo){
                mensagemErro("Informe o motivo da substituição.");
            }else{
                dataFaltaSub['lancamentoServidor'] = lancamento;
                dataFaltaSub['aulasLivres'] = checkedAulasLivres
                dataFaltaSub['servidor'] = checkedAulasLivres === false ? {id: Number.parseInt(idServidor)} : null
                dataFaltaSub['motivosSubstituicao'] = {id: Number.parseInt(idMotivo)};
                dataFaltaSub['dataAulasSubstituicao'] = dataSub;
                dataFaltaSub['aulasSubstituicao'] = horaAulaSub;
    
                serviceDataFaltaSubstituicao.atualizar(dataFaltaSub)
                .then((response) => {
                    if(response.data === true){
                        setSalvou(true);
                        mensagemSucesso('Lançamento salvo com sucesso.');
                        limparDataFaltaServidor();
                    }else{
                        mensagemErro("Nõa foi possível inserir o registro.")
                    }
                }).catch((error) => {
                    mensagemErro(error.response.data.mensagem);
                })
            }
            setSalvou(false);
        }
    }

    const excluirDatasFaltas = (id) => {
        serviceDataFaltaSubstituicao.deletar(id)
        .then(() => {
                setIsRemoved(true);
                mensagemSucesso('Registro deletado com sucesso.')
                handleConfDataFaltaclose();
            
        }).catch( error => {
            mensagemErro(error.data);
        })
        setIsRemoved(false);
    }

    const formatarData = (dataF) => {
        if(dataF){
            var data = new Date(dataF),
            dia  = data.getUTCDate().toString().padStart(2, '0'),
            mes  = (data.getUTCMonth()+1).toString().padStart(2, '0'),
            ano  = data.getUTCFullYear();
            return dia+"/"+mes+"/"+ano;
        }
    }

    const salvarDataFaltaServidor = () => {

        if(dataFaltaListaSub.length < 1){
            mensagemErro("A data da subsituição é obrigatória.");
        }else if(!horaAulaSub || horaAulaSub < 1 || horaAulaSub > 8){
            mensagemErro(`${!horaAulaSub ? 'Informe a quantidade de horas.' : 'A quantidade de horas deve está entre 1 e 8.'}`);

        }else if(!idServidor && !checkedAulasLivres){
            mensagemErro("Informe o servidor a ser substituído.");
            
        }else if(!idMotivo){
            mensagemErro("O motivo é obrigatório.");
        }else {
            dataFaltaSub.id = null;
            dataFaltaSub.listaDataSubstituicao = dataFaltaListaSub.toString().split(",");
            dataFaltaSub.dataFalta = null;
            dataFaltaSub.dataAulasSubstituicao = null;
            dataFaltaSub.lancamentoServidor = {id: Number.parseInt(lancamento.id)};

            dataFaltaSub.aulasLivres = checkedAulasLivres
            dataFaltaSub.servidor = !checkedAulasLivres ? {id: Number.parseInt(idServidor)} : null

            dataFaltaSub.motivosSubstituicao = {id: Number.parseInt(idMotivo)};
            dataFaltaSub.aulasSubstituicao = horaAulaSub;
            serviceDataFaltaSubstituicao.salvar(dataFaltaSub)
            .then((response) => {
                if(response.data === true){
                    setSalvou(true);
                    mensagemSucesso('Lançamento salvo com sucesso.');
                    limparDataFaltaServidor();
                }else{
                    mensagemErro("Não foi possível inserir o registro.")
                }
            }).catch((error) => {
                mensagemErro(error.response.data.mensagem);
            })
        }
        setSalvou(false);
    }
    
    const limparDataFaltaServidor = () => {
        if(key === 'faltas'){
            setDataFaltaSub({
                id: 0,
                dataFalta: '',
                horasFaltas: '',
                servidorFalta: ''
            })
            setIdTipoFalta('')

        }else{
            setDataFaltaSub({
                id: 0,
                dataAulasSubstituicao: '',
                servidor: '',
                motivosSubstituicao: '',
                aulasSubstituicao: ''
            })
            dataFaltaListaSub.length = 0;
        
            setIdMotivo('');
            setIdServidor('');
            setDataSub('');
            setHoraAulaSub('');
            setCheckedAulasLivres(false)
        }
    }

    const exibirValorSelect = (str) => {
        if(str === 'MATUTINO'){
            return str = 'Matutino'
        }else if(str === 'VESPERTINO'){
            return str = 'Vespertino'
        }else if(str === 'NOTURNO'){
            return str = 'Noturno'
        }else if(str === 'INTEGRAL') {
            return str = 'Integral'
        }else{
            return str = ''
        }
    }

    const replicar = () => {
        if(Number(mes) !== 12){
            service.replicarLancamentos(idUnidade, ano, mes)
                .then(() => {
                    mensagemSucesso("A replicação foi um sucesso!");
                    handleConfReplicaClose();
                })
        }else{
            serviceAno.anoExiste(Number(ano) +1).then((response) => {
                if(response.data === true){
                    service.replicarLancamentos(idUnidade, ano, mes)
                    .then(() => {
                        mensagemSucesso("A replicação foi um sucesso!");
                        handleConfReplicaClose();
                    })
                }else{
                    mensagemErro("Ano seguinte ainda não foi criado no sistema. Solicite ao RH.")
                }
            })
        }
    }

    const handleChangeFuncao = e => {
        setIdFuncao(e.target.value)

        let nomeFuncao = e.target.selectedOptions[0].label;

        if(nomeFuncao.match(/PROFESSOR\(A\)/)){
            setExibirCamposProfessor(true);
        }else{
            setExibirCamposProfessor(false);
        }

        if(nomeFuncao.match(/COORDENADOR SEMEC/)){
            setExibirCamposCoordenadorSemec(true);
        }else{
            setExibirCamposCoordenadorSemec(false);
        }
    }

    function checarData(data) {
        let dataAno = new Date(data);
        if(data !== ""){
            if(dataAno.getFullYear().toString().length === 4){
                return true;
            }else{
                return false;
            }
        }
    }

    return(
        <>
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs lg="4">
                        <AnoMes
                            htmlForMes="mesLancamento"
                            idMes="mesLancamento"
                            nameMes="mesLancamento"
                            valueMes={mes}
                            onChangeMes={(e) => setMes(e.target.value)}
                            htmlForAno="anoLancamento"
                            idAno="anoLancamento"
                            nameAno="anoLancamento"
                            valueAno={ano}
                            onChangeAno={(e) => setAno(e.target.value)}
                        />
                    </Col>
                    <Col lg={1} style={{marginTop: '37px'}}>
                        {(lancamentoEstaAberto) &&
                            <button className="btn btn-primary" type="button" onClick={handleShow}>
                                Vincular
                            </button>
                        }
                    </Col>

                    <Col lg={2} style={{marginTop: '37px'}}>
                        {(countLancamentosMesSeguinte < 1 && lancamentos.length > 0) &&
                            <button type='button' className='btn btn-primary' onClick={handleConfReplicaShow}>
                                Replicar
                            </button>
                        }
                    </Col>
                </Row>

                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="xl" >
                    <Modal.Header>
                        <Modal.Title id="lancamentoModal">
                            <h2>Servidores</h2>
                        </Modal.Title>

                        <Col md={2} style={{ float: "left" }}>
                            <button className="btn btn-danger" onClick={handleClose}>
                                Fechar
                            </button>
                        </Col>
                    </Modal.Header>

                    <Modal.Body>
                        <br></br>
                        <Card title="Listagem de Servidores"> 
                        <br></br>
                        <div >
                            <Row>
                                <Col md={6}>
                                    <input
                                        id="nomeBuscar"
                                        name="nomeBuscar"
                                        type="text"
                                        className="form-control"
                                        placeholder="Digite o nome para pesquisar..."
                                        value={nomeBuscar}
                                        onChange={(buscar) => setNomeBuscar(buscar.target.value)}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <br></br>
                        <div>
                            <table id="tabela_servidores" className="table table-hover">
                                <thead>
                                    <tr>
                                        <th> Nome </th>
                                        <th> CPF </th>
                                        <th>Matrícula</th>
                                        <th>Carga Horária</th>
                                        <th style={{textAlign: "center", width: "40px"}}>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {servidores.map((s) => {
                                        return (
                                            <tr key={s.id}>
                                                <td>{s.nomeCompleto}</td>
                                                <td>{s.cpf}</td>
                                                <td>{s.matricula}</td>
                                                <td>{s.cargaHoraria}</td>
                                                <td style={{textAlign: "center"}}>
                                                    <button 
                                                        className="btn btn-primary" 
                                                        type="button" 
                                                        onClick={() => vincularServidor(s.id, s.cpf)}> Vincular 
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            {servidores.length > 0 &&
                                <ReactPaginate
                                    previousLabel={"Voltar"}
                                    nextLabel={"Avançar"}
                                    breakLabel={"..."}
                                    pageCount={numeroDePaginas}
                                    marginPagesDisplayed={3}
                                    pageRangeDisplayed={2}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination justify-content-center"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                    initialPage={pesquisou}
                                />
                            }
                        </div>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        Pesquise o servidor e vincule a sua unidade para informar os lançamentos.
                    </Modal.Footer>
                </Modal>
            </Container>
            <Container className="mt-4">
                <Card title="Lista de Lançamentos de Servidores">
                    <Table striped bordered hover size="md">
                        <thead>
                            {lancamentos.length > 0 ?
                                <tr>
                                    <th>##</th>
                                    <th>Matrícula</th>
                                    <th>Nome do Servidor</th>
                                    <th>Nome da Unidade</th>
                                    <th>Turno</th>
                                    <th>Data Início</th>
                                </tr>
                                :
                                <>
                                </>
                            }
                        </thead>
                        <tbody>
                            {lancamentos.length > 0 ?
                                <>
                                    {lancamentos.map((l, index) => {
                                        return(
                                            <tr key={l.id}>
                                                <td>{index+1}</td>
                                                <td>{l.servidor.matricula}</td>
                                                <td>{l.servidor.nomeCompleto}</td>
                                                <td>{l.unidade.nome}</td>
                                                <td>{exibirValorSelect(l.turno)}</td>
                                                <td>{formatarData(l.dataInicioUnidade)}</td>

                                                <td className="col-md-3" style={{textAlign: "center"}}>
                                                    {l.statusLancamento.toString() === 'false' ?
                                                        <button className="btn btn-success" disabled onClick={() => abrirModalLancamento(l)}>
                                                            <i className='pi pi-plus-circle'></i>
                                                        </button>
                                                        :
                                                        <button className="btn btn-success" title='Fazer lançamento' onClick={() => abrirModalLancamento(l)}>
                                                            <i className='pi pi-plus-circle'></i>
                                                        </button>
                                                    }
                                        
                                                    {l.statusLancamento.toString() === 'false' ?
                                                        <button className="btn btn-primary" style={{margin: "0 5px"}} disabled onClick={() => abrirEditModal(l.id)}>
                                                            <i className='pi pi-pencil'></i>
                                                        </button>
                                                        :
                                                        <button className="btn btn-primary" title='Editar' style={{margin: "0 5px"}}  onClick={() => abrirEditModal(l.id)}>
                                                            <i className='pi pi-pencil'></i>
                                                        </button>
                                                    }
                                            
                                                    {l.statusLancamento.toString() === 'false' ?
                                                        <button onClick={() => handleConfShow(setImputId(`${l.id}`))} className="btn btn-danger" disabled>
                                                            <i className='pi pi-trash'></i>
                                                        </button>
                                                        :
                                                        <button onClick={() => handleConfShow(setImputId(`${l.id}`))} className="btn btn-danger" title='Excluir'>
                                                            <i className='pi pi-trash'></i>
                                                        </button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </>
                                :
                                <>
                                    <tr>
                                        <td style={{fontSize: '1.5rem'}}>Nenhum registro encontrado.</td>
                                    </tr>
                                </>
                            }
                        </tbody>
                    </Table>
                </Card>

                <Modal show={showConfReplica} onHide={handleConfReplicaClose} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <h3>Confirmação</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Essa ação criará uma cópia de todos os lancamentos desse mês no mês posterior. Deseja prosseguir?</h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={replicar}>
                            Confirmar
                        </Button>
                        <Button className="btn btn-secondary" onClick={handleConfReplicaClose}>
                            Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showConf} onHide={handleConfclose} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <h3>Confirmação</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Você realmente deseja excluir o registro?</h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={() => deletar(imputId)}>
                            Confirmar
                        </Button>
                        <Button className="btn btn-secondary" onClick={handleConfclose}>
                            Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showEditConf} onHide={handleEditClose} backdrop="static" keyboard={false} size="xl">
                    <Modal.Body>
                        <Container>
                            <Card title="Edições de Lançamentos">
                                <Row>
                                    <Col lg={5} md={12}>
                                        <label style={{fontWeight: 500}} className="col-form-label" htmlFor="servidor">Servidor:</label>
                                        <input type="text" 
                                            className="form-control" 
                                            id="servidor" 
                                            name="servidor"
                                            disabled
                                            value={!lancamento.servidor ? null : lancamento.servidor.nomeCompleto}
                                        />
                                    </Col>
                                    <Col lg={4} md={12}>
                                        <label style={{fontWeight: 500}} className="col-form-label" htmlFor="cargo">Cargo:</label>
                                        <input type="text" 
                                            className="form-control" 
                                            id="cargo" 
                                            name="cargo"
                                            disabled
                                            value={!lancamento.servidor ? null : lancamento.servidor.cargo.nome}
                                        />
                                    </Col>
                                    <Col lg={3} md={12}>
                                        <label className="col-form-label" htmlFor="funcao">Função:</label>
                                        <Form.Select
                                            id="funcao"
                                            name="funcao"
                                            value={idFuncao}
                                            onChange={handleChangeFuncao}
                                        >   
                                            <option value="">Selecione</option>
                                            {funcoes.sort((a, b) => a.nome > b.nome ? 1:-1).map((f) => (
                                                <option value={f.id} key={f.id}>
                                                {f.nome}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6} md={12}>
                                        <label style={{fontWeight: 500}} className="col-form-label" htmlFor="unidade">Unidade:</label>
                                        <input type="text" 
                                            className="form-control" 
                                            id="unidade" 
                                            name="unidade"
                                            disabled
                                            value={!lancamento.unidade ? null : lancamento.unidade.nome}
                                            />
                                    </Col>
                                    <Col lg={2} md={12}>
                                        <label style={{fontWeight: 500}} className="col-form-label" htmlFor="mesLancamento">Mês/Ano:</label>
                                        <input type="text" 
                                            className="form-control" 
                                            id="mesLancamento" 
                                            name="mesLancamento"
                                            disabled
                                            value={`${lancamento.mesLancamento}/${lancamento.anoLancamento}`}
                                         />
                                    </Col>
                                    <Col xl={2} md={12}>
                                        <label style={{fontWeight: 500}} className="col-form-label" htmlFor="turno">Turno:</label>
                                        <Form.Select
                                            id="turno"
                                            name="turno"
                                            value={idTurno}
                                            onChange={(e) => setIdTurno(e.target.value)}
                                        >   
                                            <option value="">Selecione</option>
                                            <option value="MATUTINO">Matutino</option>
                                            <option value="VESPERTINO">Vespertino</option>
                                            <option value="NOTURNO">Noturno</option>
                                            <option value="INTEGRAL">Integral</option>
                                            
                                        </Form.Select>
                                    </Col>
                                    <Col>
                                        <label style={{fontWeight: 500}} className="col-form-label" htmlFor="adicionalDificilAcesso">Difícil Acesso:</label><br/>
                                        <input type="checkbox"
                                            style={{width: 70, height: 36}} 
                                            id="adicionalDificilAcesso" 
                                            name="adicionalDificilAcesso"
                                            value={lancamento.adicionalDificilAcesso}
                                            checked={isChecked}
                                            onChange={handleOnChecked}
                                        />
                                    </Col>
                                </Row>   

                                <Row className="mt-2">
                                    <Col xl={2} md={12}>
                                        <label style={{fontWeight: 500}} className="col-form-label" htmlFor="dataInicioUnidade">Data de Início:</label>
                                        <input type="date" 
                                            className="form-control" 
                                            id="dataInicioUnidade"
                                            name="dataInicioUnidade"
                                            value={lancamento.dataInicioUnidade}
                                            onChange={onChange}></input>    
                                    </Col>
                                    <Col xl={2} md={12}>
                                        <label style={{fontWeight: 500}} className="col-form-label" htmlFor="dataDesligamentoUnidade">Data de Deslig:</label>
                                        <input type="date" 
                                            className="form-control" 
                                            id="dataDesligamentoUnidade"
                                            name="dataDesligamentoUnidade"
                                            value={lancamento.dataDesligamentoUnidade}
                                            onChange={onChange}></input>
                                    </Col>
                                    <Col xl={2}>
                                        <label style={{fontWeight: 500}} className="col-form-label" htmlFor="rescindiu">Houve Rescisão:</label><br/>
                                        <input type="checkbox"
                                            style={{width: 70, height: 36}} 
                                            id="rescindiu" 
                                            name="rescindiu"
                                            value={lancamento.rescindiu}
                                            checked={isCheckedRescisao}
                                            onChange={handleOnCheckedRescisao}
                                        />
                                    </Col>
                                    
                                    {exibirCheckAdapt &&
                                        <Col>
                                            <label style={{fontWeight: 500}} className="col-form-label" htmlFor="emReadaptacao">Em Readaptação:</label><br/>
                                            <input type="checkbox"
                                                style={{width: 70, height: 36}} 
                                                id="emReadaptacao" 
                                                name="emReadaptacao"
                                                value={lancamento.emReadaptacao}
                                                checked={isCheckedAdapt}
                                                onChange={handleOnCheckedAdapt}
                                            />
                                        </Col> 
                                    }
                                    {isCheckedAdapt &&
                                        <Col lg={4} md={12}>
                                            <label style={{fontWeight: 500}} className="col-form-label" htmlFor="novaFuncaoReadaptada">Nova Função em Readaptação:</label>
                                            <input type="text" 
                                                className="form-control" 
                                                id="novaFuncaoReadaptada" 
                                                name="novaFuncaoReadaptada"
                                                value={lancamento.novaFuncaoReadaptada}
                                                onChange={onChange}
                                            />
                                        </Col>
                                    
                                    }
                                    
                                </Row>
                                {exibirCamposCoordenadorSemec &&
                                    <Row> 
                                        <Col md={4}>
                                            <label style={{fontWeight: 500}} className="col-form-label" htmlFor="porcentagemCoordenadorSemec">Porcentagem Coordenador SEMEC</label>
                                            <input type="number" 
                                                className="form-control" 
                                                id="porcentagemCoordenadorSemec" 
                                                name="porcentagemCoordenadorSemec"
                                                value={lancamento.porcentagemCoordenadorSemec}
                                                onChange={onChange}
                                                min={0}
                                                max={100}
                                                style={{width: 100}}
                                            />
                                        </Col>
                                    </Row>
                                }

                                {(tipoUsuario === a || tipoUsuario === r) &&
                                    <Row> 
                                        <Col md={2}>
                                            <label style={{fontWeight: 500}} className="col-form-label" htmlFor="verbaIdenizatoria">Verba Idenizatória</label>
                                            <input type="number" 
                                                className="form-control" 
                                                id="verbaIdenizatoria" 
                                                name="verbaIdenizatoria"
                                                value={lancamento.verbaIdenizatoria}
                                                onChange={onChange}                                               
                                            />
                                        </Col>
                                    </Row>
                                }  
                                {exibirCamposProfessor &&
                                    <Row className="mt-2">
                                        <Col xl={4} md={12}>
                                            <label style={{fontWeight: 500}} className="col-form-label" htmlFor="inicioAulasExcedentes">Início das Aulas Excedentes:</label>
                                            <input type="date" 
                                                className="form-control" 
                                                id="inicioAulasExcedentes"
                                                name="inicioAulasExcedentes"
                                                value={lancamento.inicioAulasExcedentes}
                                                onChange={onChange}></input>
                                        </Col>
                                        <Col xl={4} md={12}>
                                            <label style={{fontWeight: 500}} className="col-form-label" htmlFor="terminoAulasExcedentes">Término das Aulas Excedentes:</label>
                                            <input type="date" 
                                                className="form-control" 
                                                id="terminoAulasExcedentes"
                                                name="terminoAulasExcedentes"
                                                value={lancamento.terminoAulasExcedentes}
                                                onChange={onChange}></input>
                                        </Col>
                                        <Col xl={4} md={12}>
                                            <label style={{fontWeight: 500}} className="col-form-label" htmlFor="aulasExcedentes">Aulas Excedentes:</label>
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                id="aulasExcedentes" 
                                                name="aulasExcedentes"
                                                min={0}
                                                max={20}
                                                value={lancamento.aulasExcedentes}
                                                onChange={onChange} />
                                        </Col>
                                    </Row>
                                }
 
                                {exibirCamposProfessor &&
                                    <>
                                        <Row className="mt-2">
                                            <Col xl={6} md={12}>
                                                <label style={{fontWeight: 500}} className="col-form-label" htmlFor="turmasAtuacao">Turmas de Atuação:</label>
                                                <Form.Control 
                                                    as="textarea" 
                                                    rows={3} 
                                                    id="turmasAtuacao"
                                                    name="turmasAtuacao"
                                                    value={lancamento.turmasAtuacao}
                                                    onChange={onChange}/>
                                            </Col>
                                            <Col xl={6} md={12}>
                                                <label style={{fontWeight: 500}} className="col-form-label" htmlFor="turmasAulasExcedentes">Turmas Aulas Excedentes:</label>
                                                <Form.Control 
                                                    as="textarea" 
                                                    rows={3} 
                                                    id="turmasAulasExcedentes"
                                                    name="turmasAulasExcedentes"
                                                    value={lancamento.turmasAulasExcedentes}
                                                    onChange={onChange}/>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={12}>
                                                <label style={{fontWeight: 500}} className="col-form-label" htmlFor="observacaoAulasExcedentes">Observações Aulas Excedentes:</label>
                                                <Form.Control 
                                                    as="textarea" 
                                                    rows={3} 
                                                    id="observacaoAulasExcedentes"
                                                    name="observacaoAulasExcedentes"
                                                    value={lancamento.observacaoAulasExcedentes}
                                                    onChange={onChange}/>
                                            </Col>
                                        </Row>
                                    </>
                                }

                                <Row className="mt-2">
                                    <Col md={12}>
                                        <label style={{fontWeight: 500}} className="col-form-label" htmlFor="observacoes">Observações:</label>
                                        <Form.Control 
                                            as="textarea" 
                                            rows={3} 
                                            id="observacao"
                                            name="observacao"
                                            value={lancamento.observacao}
                                            onChange={onChange}/>
                                    </Col>
                                </Row>
                                
                                <br/>
                                <div className="text-center mt-2">
                                    <button 
                                        type="button" 
                                        onClick={atualizar} 
                                        className="btn btn-primary"> Atualizar 
                                    </button> 
                                </div>
                            </Card>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={limparDados}>
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showLanc} onHide={handleLancClose} backdrop="static" keyboard={false} size="xl">
                    <Modal.Body>
                        {lancamento.servidor !== null ?
                         lancamento.servidor.cargaHoraria === '40' ?
                            <h2>Servidor possui carga horária de 40h. Não é possível lançar substituições.</h2>
                        :
                        <Container>
                            <Row>
                                <Col md={5}>
                                    <label className="col-form-label" htmlFor="servidor">Servidor:</label>
                                    <input type="text" 
                                        className="form-control" 
                                        id="servidor" 
                                        name="servidor"
                                        disabled
                                        value={!lancamento.servidor ? null : lancamento.servidor.nomeCompleto}
                                     />
                                </Col>
                                <Col md={5}>
                                    <label className="col-form-label" htmlFor="unidade">Unidade:</label>
                                    <input type="text" 
                                        className="form-control" 
                                        id="unidade" 
                                        name="unidade"
                                        disabled
                                        value={!lancamento.unidade ? null : lancamento.unidade.nome}
                                     />
                                </Col>
                                <Col md={2}>
                                    <label className="col-form-label" htmlFor="mesLancamento">Mês/Ano:</label>
                                    <input type="text" 
                                        className="form-control" 
                                        id="mesLancamento" 
                                        name="mesLancamento"
                                        disabled
                                        value={`${lancamento.mesLancamento}/${lancamento.anoLancamento}`}
                                     />
                                </Col>
                            </Row>
                      
                            <hr></hr>
                            <Card title={dataFaltaSub.id > 0 ? "Edição de Lançamentos" : "Cadastro de Lançamentos"}>
                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={key}
                                    onSelect={(k) => setKey(k)}
                                    className="justify-content-center"
                                >
                                    <Tab eventKey="substituicao" title="Substituições" >
                                        <Form>
                                            <Row>
                                                {dataFaltaSub.id < 1 ? <label className="col-form-label" htmlFor="dataFaltaListaSub">Data da Substituição:</label> : "" }
                                                {dataFaltaSub.id < 1 ?
                                                    <DatePicker
                                                        mapDays={({ date }) => {
                                                            let props = {}
                                                            let isWeekend = [0, 6].includes(date.weekDay.index)
                                                            
                                                            if (isWeekend) props.className = "highlight highlight-red"
                                                            
                                                            return props
                                                        }}
                                                        className="mb-3 form-control"
                                                        onChange={setDataFaltaListaSub}
                                                        value={dataFaltaSub.listaDataSubstituicao}
                                                        id="dataFaltaListaSub"
                                                        name="dataFaltaListaSub"
                                                        numberOfMonths={2}
                                                        multiple
                                                        sort
                                                        format="DD/MM/YYYY"
                                                        locale={calendarioPersonalizado}
                                                        plugins={[<DatePanel />]}
                                                        style={{
                                                            width: "100%",
                                                            height: "38px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                        }}
                                                    />
                                                    :
                                                    <Col xl={4} lg={6}>
                                                        <label className="col-form-label" htmlFor="dataAulasSubstituicao">Data da Substituição:</label>
                                                        <input 
                                                            type="date" 
                                                            className="form-control" 
                                                            id="dataAulasSubstituicao" 
                                                            name="dataAulasSubstituicao"
                                                            value={dataSub}
                                                            onChange={(e) => setDataSub(e.target.value)} />
                                                    </Col>
                                                }

                                            </Row>
                                            <Row>
                                                <Col md={2}>
                                                    <label className="col-form-label" htmlFor="aulasSubstituicao">Aulas Substituídas:</label>
                                                    <input 
                                                        type="number" 
                                                        className="form-control"
                                                        min={1} max={8} 
                                                        id="aulasSubstituicao" 
                                                        name="aulasSubstituicao"
                                                        value={horaAulaSub}
                                                        onChange={(e) => setHoraAulaSub(e.target.value)} />
                                                </Col>
                                                <Col xl={2}>
                                                    <label style={{fontWeight: 500}} className="col-form-label" htmlFor="rescindiu">Aulas Livres?</label><br/>
                                                    <input type="checkbox"
                                                        style={{width: 70, height: 36}} 
                                                        id="aulaslivres" 
                                                        name="aulaslivres"
                                                        value={checkedAulasLivres}
                                                        checked={checkedAulasLivres}
                                                        onChange={handleOnCheckedAulasLivres}
                                                    />
                                                </Col>
                                                {!checkedAulasLivres &&
                                                <Col md={4}>
                                                    <label className="col-form-label" htmlFor="servidor-substituido">Servidor Substituído:</label>
                                                    <Form.Select 
                                                        id="servidor-substituido" 
                                                        name="servidor" 
                                                        value={idServidor}  
                                                        onChange={(e) => setIdServidor(e.target.value)}
                                                    >
                                                        <option value="">Selecione</option>
                                                        {listaServidoresSub.map((s) => (
                                                            <option value={s.id} key={s.id}>
                                                                {s.nomeCompleto}
                                                            </option>
                                                        ))}
                                                    </Form.Select> 
                                                </Col>
                                                }
                                                <Col md={4}>
                                                    <label className="col-form-label" htmlFor="motivosSubstituicao">Motivo da Substituição:</label>
                                                    <Form.Select 
                                                        id="motivosSubstituicao" 
                                                        name="motivosSubstituicao" 
                                                        value={idMotivo} 
                                                        onChange={(e) => setIdMotivo(e.target.value)}
                                                    >
                                                        <option value="">Selecione</option>
                                                        {listaMotivosSub.map((m) => (
                                                            <option value={m.id} key={m.id}>
                                                                {m.descricao}
                                                            </option>
                                                        ))}
                                                    </Form.Select> 
                                                </Col>
                                            </Row>

                                            <div className="text-center mt-4">
                                                {dataFaltaSub.id > 0 ?
                                                    <button 
                                                        type="button" 
                                                        onClick={atualizarDataFalta} 
                                                        className="btn btn-primary"> Atualizar 
                                                    </button> 
                                                    :
                                                    <button 
                                                        type="button" 
                                                        onClick={salvarDataFaltaServidor} 
                                                        className="btn btn-primary"> Salvar 
                                                    </button> 
                                                }
                                            </div>
                                        </Form>
                                        <br></br>
                                        <Card title="Listagem de Substituições">
                                            <Table id="tabela_lancamentos_faltas" striped bordered hover size="md">
                                                <thead>
                                                    <tr>
                                                        <th>Nome do Substituído</th>
                                                        <th>Motivo</th>
                                                        <th>Data da Substituição</th>
                                                        <th>Horas</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listaDataFaltaSub.map((df) => {
                                                        if(key === 'substituicao')
                                                            if(df.servidor || df.cooperado || df.aulasLivres)
                                                                return (
                                                                    <tr key={df.id}>
                                                                        {df.servidor != null &&
                                                                            <td>{df.servidor.nomeCompleto}</td>
                                                                        }
                                                                        {df.aulasLivres === true &&
                                                                            <td>Aulas Livres</td>
                                                                        }
                                                                        {df.cooperado != null &&
                                                                            <td>{df.cooperado.nome}</td>
                                                                        }
                                                                        {df.motivosSubstituicao != null &&
                                                                            <td>{df.motivosSubstituicao.descricao}</td>
                                                                        }
                                                                        <td>{formatarData(df.dataAulasSubstituicao)}</td>
                                                                        <td>{df.aulasSubstituicao}</td>
                                                                        
                                                                        <td className='col-md-2' style={{textAlign: "center"}}>
                                                                            <button className="btn btn-primary" title='Editar' type="button" style={{margin: "0 5px"}} onClick={() => editarDataFalta(df.id)}>
                                                                                <i className='pi pi-pencil'></i>
                                                                            </button>   

                                                                            <button onClick={() => handleConfDataFaltaShow(setImputIdDataFalta(`${df.id}`))} title='Excluir' className="btn btn-danger">
                                                                                <i className='pi pi-trash'></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </Card>
                                    </Tab>
                                </Tabs>
                            </Card>
                            <br/>
                        </Container>
                        : null}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={closeModalLanc}>
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showConfDataFalta} onHide={handleConfDataFaltaclose} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <h3>Confirmação</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Você realmente deseja excluir o registro?</h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={() => excluirDatasFaltas(imputIdDataFalta)}>
                            Confirmar
                        </Button>
                        <Button className="btn btn-secondary" onClick={handleConfDataFaltaclose}>
                            Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </>
    )
}

export default ListarLancamentosServidores
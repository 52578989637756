import ApiService from "../app/apiservice";


class inssIrrfVhService extends ApiService {
   

    constructor(){
        super('/parampag')
    }

    salvar(dados){
        return this.post('/salvar', dados)
    }

    atualizar(dados){
        return this.put('/atualizar', dados)
    }

    carregarValores(id){
        return this.get(`/carregarvalores/${id}`)
    }

    carregarPrimeiraVez(){
        return this.get('/listarprimeiravez')
    }

    gerarPdf(){
        return this.get("/rel/tabelageral")
    }

}

export default inssIrrfVhService;
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { mensagemErro, mensagemSucesso } from "../../components/avisos/toastr";
import anoService from "../../service/anoService";
import LocalStorageService from "../../service/localStorageService";
import numAlunosService from "../../service/numAlunosService";
import Modal from "react-bootstrap/Modal";
import { descriptografar } from "../../components/criptografar";



const numeroAlunos = {
  unidade: [],
  mesLancamento: "",
  anoLancamento: "",
  numAlunos: 0,
  dataInformacao: "",
  statusInformacao: false,
};

const serviceAno = new anoService();
const serviceNA = new numAlunosService();

function NumeroDeAlunos() {
  const unidadeCriptografado = LocalStorageService.obterItem('3')
  const unidade = descriptografar(unidadeCriptografado)
  let dataAtual = new Date();
  //const [mes, setMes] = useState(dataAtual.getMonth() + 1);
  //const [ano, setAno] = useState(dataAtual.getFullYear());

  //const [numAluno, setNumAluno] = useState(0);
  //const [dadosSalvar, setDadosSalvar] = useState(numeroAlunos);

  //Atualizar lista apos modificação ou atualização
  const [modificou, setModificou] = useState(false);


  const [anoListar, setAnoListar] = useState([]);
  const [anoCarregar, setAnoCarregar] = useState([dataAtual.getFullYear()]);

  const [historico, setHistorico] = useState([]);


  //Modal de alterações
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [numAlunoAtualizar, setNumAlunoAtualizar] = useState(0)
  const [mesAtualizar, setMesAtualizar] = useState(0);
  const [anoAtualizar, setAnoAtualizar] = useState(0);


   useEffect(() => {

    

    async function CarregarAnos() {
      const anos = await serviceAno.listar();
      setAnoListar(anos.data);
    }

    async function ListarDaUnidade() {
      const listar = await serviceNA.listarHistoricoDaUnidade(
        Number(unidade),
        anoCarregar
      );
      setHistorico(listar.data);
      //console.log(listar.data);
    }
    ListarDaUnidade();
    CarregarAnos();
 

    //console.log(ano);
    //console.log(mes);
    //console.log(anoListar);
    //console.log(anoCarregar);
    //console.log(usuarioLogado.unidade.id);
  }, [anoCarregar, modificou, numAlunoAtualizar]);

  


  function atualizarFora(mes, ano, numAlunos){  // adicionar outro modal para atualização dentro do periodo
    setNumAlunoAtualizar(numAlunos)
    setAnoAtualizar(ano)
    setMesAtualizar(mes)
    //console.log(numAlunoAtualizar)
    //  console.log(anoAtualizar)
    //  console.log(mesAtualizar)
    handleShow()
  }

  

  function salvarAtualizacao(){
    //  console.log(numAlunoAtualizar)
    //  console.log(anoAtualizar)
    //  console.log(mesAtualizar)
    //  console.log(Number(usuarioLogado.unidade.id))
      serviceNA.atualizarNumAluno(Number(unidade),anoAtualizar, mesAtualizar, numAlunoAtualizar)
      .then((e) => {
          mensagemSucesso(e.data)
          setNumAlunoAtualizar(0)
          handleClose()


      })
      .catch((r) => {
          mensagemErro(r.data)
      })
  }


  return (
    <>
      <div className="container mt-6">
        <div className="form-group">
          <div className="card md-3">
            <h4 className="card-header">Número de Alunos</h4>
            <Container>
              <br />
              <h5 style={{ textAlign: "center" }}>Número de alunos Mensal</h5>
              
                <div style={{ textAlign: "center" }}>
                  Atualização do número de alunos ficará disponível de 1 ao
                  dia 10 de cada mês.{" "}
                </div>

              <br />
              <Row
                style={{ textAlign: "center" }}
                className="justify-content-md-center"
              >
                <Col xs lg="2">
                  <Form.Select
                    value={anoCarregar}
                    onChange={(a) => setAnoCarregar(a.target.value)}
                  >
                    {anoListar.map((l) => (
                      <option
                        style={{ textAlign: "center" }}
                        key={l.id}
                        value={l.ano}
                      >
                        {l.ano}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
              <br />
              <Row
                style={{ textAlign: "center" }}
                className="justify-content-md-center"
              >
                <Col xs lg="4">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Mês/Ano
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Nº de Alunos
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {historico.sort((a, b) => Number.parseInt(a.mesLancamento) > Number.parseInt(b.mesLancamento) ? 1:-1).map((d) => (
                        <tr key={d.id}>
                          <td
                            style={{ maxWidth: "300px", textAlign: "center" }}
                          >
                            {d.mesLancamento}/{d.anoLancamento}
                          </td>

                          <td style={{ textAlign: "center" }}>{d.numAlunos}</td>
                          {d.statusInformacao && (
                            <td style={{ textAlign: "center" }}>
                              <button
                                className="btn btn-primary"
                                type="button"
                                onClick={(e) => atualizarFora(d.mesLancamento, d.anoLancamento, d.numAlunos)}
                              >
                                Atualizar
                              </button>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>

                                

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Atualização de nº de alunos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <Row>
                      
                      <label style={{ textAlign: "center", color: "red" }} className="col-form-label">ATENÇÃO: Ao salvar a atualização, não será possível a edição.</label>
                         
                  </Row>
            <br />
            <Row
                
                className="justify-content-md-center"
              >
                  <Col xs lg="5" style={{ textAlign: "end" }} >
                      <label className="col-form-label">Digite a quantidade atual de alunos:</label>
                  </Col>
                <Col xs lg="2">
                <input type="number"
                    min={0}
                    max={2000}
                    value={numAlunoAtualizar}
                    onChange={(e) => setNumAlunoAtualizar(e.target.value)}
                    className="form-control"
                />

                </Col>
                <Col Col xs lg="3">
                    
                    <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => salvarAtualizacao()}
                  >
                    Salvar Alteração
                  </button>
       
                </Col>
              </Row>
          


        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            type="button"
            onClick={handleClose}
          >
            Cancelar
          </button>
          
        </Modal.Footer>
      </Modal>

      
    </>
  );
}

export default NumeroDeAlunos;

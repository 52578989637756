import ApiService from "../app/apiservice";


class ParametrosStatusLancamentoService extends ApiService {
    constructor() {
        super("/parametrosstatuslancamento")
    }

    listarTodos() {
        return this.get('/');
    }

    obterPorId(id){
        return this.get(`/${id}`)
    }

    atualizar(parametroStatus, flagStatus){
        return this.put(`/${parametroStatus.id}/flagstatus/${flagStatus}`, parametroStatus)
    }

    abrirTodosStatus(tipoLancamento, ano, mes){
        return this.put(`/abrirtodos/tipo/${tipoLancamento}/ano/${ano}/mes/${mes}`)
    }

    fecharTodosStatus(tipoLancamento, ano, mes){
        return this.put(`/fechartodos/tipo/${tipoLancamento}/ano/${ano}/mes/${mes}`)
    }

    listarTodosPorMes(mes){
        return this.get(`/listartodospormes/mes/${mes}`)
    }

    listarTodosPorAnoMes(ano, mes){
        return this.get(`/listartodosporanomes/ano/${ano}/mes/${mes}`)
    }

    inserirNovaUnidade(ano, mes, idUnidade){
        return this.post(`/inserirnovaunidade/ano/${ano}/mes/${mes}/unidade/${idUnidade}`)
    }

    buscarPorUnidadeAnoMes(idUnidade, ano, mes) {
        return this.get(`/buscarporunidadeanomes/unidade/${idUnidade}/ano/${ano}/mes/${mes}`);
    }

    verificarParametroStatusLancamento(idUnidade, ano, mes, tipoLancamento) {
        return this.get(`/verificarstatuslancamentos/unidade/${idUnidade}/ano/${ano}/mes/${mes}/tipo/${tipoLancamento}`);
    }

}

export default ParametrosStatusLancamentoService
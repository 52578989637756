/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import InputMask from "react-input-mask";
import Form from "react-bootstrap/Form";
import { Row, Col } from "react-bootstrap";
import UsuarioService from "../../service/usuarioService";
import UnidadeService from "../../service/unidadeService";
import { mensagemErro, mensagemSucesso } from "../../components/avisos/toastr";
import {MultiSelect} from 'primereact/multiselect'
import posLoginService from "../../service/posLoginService";
import LocalStorageService from "../../service/localStorageService";
import Modal from "react-bootstrap/Modal";
import { descriptografar } from "../../components/criptografar";

//console.log(id)

const atualizarUsuarioUp = {
  nomeCompleto: '',
  matricula: 0,
  unidade: [],
  email: '',
  telefone: '',
  tipoUsuario: '',
  login: ''
};

const dadosUsuarioUnidade = {
  usuario: [],
  matricula: 0,
  unidade: []
}

const service = new UsuarioService();
const servicePosLogin = new posLoginService()
const unidadeListar = new UnidadeService();

const DadosEdicao = ({ id }) => {
  
  const [usuarioUp, setUsuarioUp] = useState(id ? atualizarUsuarioUp : null);
  const [unidades, setUnidades] = useState([]);
  const [unidadeSelecionada, setUnidadeSelecionada] = useState([]);
  const [unidadesVinculadas, setUnidadesVinculadas] = useState([])
  const [usuarioUnidade, setUsuarioUnidade] = useState(dadosUsuarioUnidade)
  
  //Modal desvincular unidade
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [idUnidadeDesvincular, setIdUnidadeDesvincular] = useState(0)
  const [nomeUnidadeDesvincular, setNomeUnidadeDesvincular] = useState(0)
  
  //Modal alterar unidade
  const [showAlterarUnidade, setShowAlterarUnidade] = useState(false);
  const handleCloseAlterarUnidade = () => setShowAlterarUnidade(false);
  const handleShowAlterarUnidade = () => setShowAlterarUnidade(true);
  const [idUnidadeAtualizar, setIdUnidadeAtualizar] = useState(null)
  const [idPosLoginAtualizar, setIdPosLoginAtualizar] = useState(null)
  
  const usuarioCriptografado = LocalStorageService.obterItem('2')
  const usuarioLogado = JSON.parse(descriptografar(usuarioCriptografado))
  const rolerUser = usuarioLogado ? usuarioLogado.role : ""
  const a = "ADMINISTRADOR"
  const r = "RH"
  const d = "DIRETOR"
  

  useEffect(() => {
          service.buscarPorId(id)
          .then((resposta) => {
            setUsuarioUp(resposta.data)
            servicePosLogin.listarUnidades(Number(resposta.data.matricula))
            .then((s) => {
              setUnidadesVinculadas(s.data)
            })
          })
          async function carregarUnidades() {
            const response = await unidadeListar.listar();
            setUnidades(response.data);
          } 
          carregarUnidades();
}, [usuarioUnidade, show, showAlterarUnidade]);

 function onChange(e) {
    const { name, value } = e.target;
    setUsuarioUp({ ...usuarioUp, [name]: value });
    //console.log(usuarioUp)
  }

  function onSubmit() {
    usuarioUp.telefone = RetiraMascara(usuarioUp.telefone)
    service.atualizar(usuarioUp)
    .then((s) =>{
      salvarUsuarioUnidades()
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    })
  }

  function salvarUsuarioUnidades(){
    let matricula = Number(usuarioUp.matricula)
    if(unidadeSelecionada.length >0){
      const usuario = usuarioLogado.id
      for (let i = 0; i < unidadeSelecionada.length; i++) {
        usuarioUnidade.usuario = {id: usuario}
        usuarioUnidade.unidade = {id: unidadeSelecionada[i].id}
        usuarioUnidade.matricula = matricula
        servicePosLogin.salvar(usuarioUnidade)
        .then((s) => {
          mensagemSucesso('Atualizado com sucesso')
        })
        .catch((e) => {
          mensagemErro(e.response.data + " Unidade: " + unidadeSelecionada[i].nome)
        })
      }
    }
  }

  function atualizarUsuarioUnidades(){
        usuarioUnidade.id = idPosLoginAtualizar
        usuarioUnidade.usuario = {id: usuarioLogado.id}
        usuarioUnidade.unidade = {id: Number(idUnidadeAtualizar)}
        usuarioUnidade.matricula = Number(usuarioUp.matricula)
        servicePosLogin.atualizar(usuarioUnidade)
        .then((s) => {
          mensagemSucesso('Atualizado com sucesso')
          handleCloseAlterarUnidade()
        })
        .catch((e) => {
          mensagemErro(e.response.data)
        })
        //console.log(usuarioUnidade); 
  }

  function RetiraMascara(Obj) {
    return Obj.replace(/\D/g, '');
  }

  function desvincularUnidade(){
    servicePosLogin.deletarUnidade(idUnidadeDesvincular)
    .then((s) => {
      mensagemSucesso(s.data)
      handleClose()
    })
    .catch((e) => {
      mensagemErro(e.response.data)
      handleClose()
    })
  }

  return (
    <div>
        <div className="container mt-4">
          <div className="form-group">
            <div className="card md-3">
              <h4 className="card-header">
                Editar Usuários
              </h4>
              <div className="card-body">
                {(rolerUser === r && usuarioUp.tipoUsuario === r) || (rolerUser === a) || (rolerUser === r && usuarioUp.tipoUsuario === d) ?
                
                  <>
                  <Row>
                    <Col md={2}>
                      <label className="col-form-label" htmlFor="matricula">
                        Matrícula:
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        placeholder="Matrícula"
                        id="matricula"
                        name="matricula"
                        disabled="true"
                        value={usuarioUp.matricula}
                      />
                    </Col>
                    <Col md={9}>
                      <label className="col-form-label" htmlFor="nomeCompleto">
                        Nome Completo:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Nome Completo"
                        id="nomeCompleto"
                        name="nomeCompleto"
                        value={usuarioUp.nomeCompleto}
                        onChange={onChange}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md={11}>
                      <label className="col-form-label" htmlFor="unidade">
                        Adicionar Locais Atuação:
                      </label>
                      <div>
                        <MultiSelect value={unidadeSelecionada} options={unidades} optionLabel="nome" showSelectAll={false} style={{maxWidth: "1150px"}}
                        onChange={(u) => setUnidadeSelecionada(u.value)} display={"chip"} />
                    </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4}>
                      <label className="col-form-label" htmlFor="email">
                        E-mail:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Digite um email"
                        id="email"
                        name="email"
                        autoComplete="false"
                        value={usuarioUp.email}
                        onChange={onChange}
                      />
                    </Col>
                    <Col md={3}>
                      <label className="col-form-label" htmlFor="telefone">
                        Telefone:
                      </label>
                      <InputMask
                        mask="(99) 9 9999-9999"
                        type="text"
                        className="form-control"
                        placeholder="(00) 0 0000-0000"
                        id="telefone"
                        name="telefone"
                        value={usuarioUp.telefone}
                        onChange={onChange}
                      />
                    </Col>
                    <Col md={4}>
                      <label className="col-form-label" htmlFor="tipoUsuario">
                        Tipo de Usuário:
                      </label>
                      <Form.Select
                        value={usuarioUp.tipoUsuario}
                        id="tipoUsuario"
                        name="tipoUsuario"
                        onChange={onChange}
                      >
                        <option>{usuarioUp.tipoUsuario}</option>
                        {usuarioLogado.role === a && <option>ADMINISTRADOR</option>}
                        <option>DIRETOR</option>
                        <option>RH</option>
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4}>
                      <label className="col-form-label" htmlFor="login">
                        Nome para login:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="login"
                        id="login"
                        name="login"
                        disabled="true"
                        autoComplete="false"
                        value={usuarioUp.login}
                        onChange={onChange}
                      />
                    </Col>
                  </Row> 
                  <br/>
                  <Row style={{ textAlign: "center" }}
                className="justify-content-md-center">
                  <Col xs lg="4">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Unidade
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Ações
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {unidadesVinculadas.sort((a, b) => a.unidade.nome > b.unidade.nome ? 1:-1).map((d, indice) => (
                        <tr key={d.id}>
                          <td
                            style={{ maxWidth: "300px", textAlign: "center" }}
                          >
                            {d.unidade.nome}
                          </td>
                          
                            <td style={{ textAlign: "center" }}>
                              {indice>0 && 
                              <button
                                className="btn btn-danger"
                                type="button"
                                title="Desvincular usuário desta unidade."
                                onClick={() => handleShow(setIdUnidadeDesvincular(d.id), setNomeUnidadeDesvincular(d.unidade.nome))}>
                                <i className='pi pi-trash'></i>
                              </button>}
                              {indice===0 && 
                              <button
                                className="btn btn-primary"
                                type="button"
                                title="Desvincular usuário desta unidade."
                                onClick={() => handleShowAlterarUnidade(setIdPosLoginAtualizar(d.id))}>
                                <i className="pi pi-directions"></i>
                              </button>}
                            </td>
                        
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Col>

                  </Row>
                  <br />
                  <Row style={{textAlign: "right"}}>
                    <Col>
                      <button type="button" className="btn btn-primary" onClick={onSubmit}>
                        Atualizar
                      </button>
                    {" "}
                      <button type="button" className="btn btn-primary">
                        <Link
                          style={{ textDecoration: "none", color: "#ffffff" }}
                          to="/usuario/listar"
                        >
                          Voltar
                        </Link>
                      </button>
                    </Col>
                  </Row>
                  </>
                  : <h4 style={{ color: "#f80606", textAlign: "center" }}>Edição não permitida. Favor, para realizar essa ação entre em contato com um Administrador.</h4>}
              </div>
            </div>
          </div>
        </div>

        <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Desvincular Unidade do Usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <h5>Deseja desvincular a unidade <label style={{color: "#fc1605"}}>{nomeUnidadeDesvincular}</label> do usuário em edição?</h5>
            <br />
           
                 

        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            type="button"
            onClick={desvincularUnidade}
          >
            Desvincular
          </button>
          <button
            className="btn btn-primary"
            type="button"
            onClick={handleClose}
          >
            Cancelar
          </button>
          
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAlterarUnidade}
        onHide={handleCloseAlterarUnidade}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Alterar Unidade do Usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <Row>
                <label className="col-form-label" htmlFor="tipoUsuario">
                        Selecione a unidade:
                      </label>
                      <Form.Select
                        id="tipoUsuario"
                        name="tipoUsuario"
                        onChange={(u) => setIdUnidadeAtualizar(u.target.value)}
                      >
                        <option value="">Selecione</option>
                        {unidades.sort((a, b) => a.nome > b.nome ? 1:-1).map((u) => 
                          (
                            <option value={u.id} key={u.id}>{u.nome}</option>
                          )
                        )}
                        
                        
                        
                      </Form.Select>
                </Row>
           
                 

        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            type="button"
            onClick={atualizarUsuarioUnidades}
          >
            Atualizar
          </button>
          <button
            className="btn btn-danger"
            type="button"
            onClick={handleClose}
          >
            Cancelar
          </button>
          
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DadosEdicao;

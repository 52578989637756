/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import UsuarioService from "../../service/usuarioService";

const Desabilitar = ({ id }) => {
  //const { id } = useParams();
  const [usuario, setUsuario] = useState([]);

  // console.log(usuario)
  useEffect(() => {
    const service = new UsuarioService();
    service.buscarPorId(id).then((e) => {
      setUsuario(e.data);
    });
  }, []);

  return (
    <>
      <h5>Deseja relamente desabilitar o usuário: <span style={{color: "#dd5600"}}> {usuario.nomeCompleto}</span> ?</h5>
    </>
  );
};

export default Desabilitar;

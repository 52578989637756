import ApiService from "../app/apiservice";

export default class faltasRhService extends ApiService{

    constructor(){
        super("/faltasrh")
    }

    salvar(dados){
        return this.post("/salvar", dados)
    }

    atualizar(dados){
        return this.put("/atualizar", dados)
    }

    listarFaltas(ano, mes){
        return this.get(`/${ano}/${mes}`)
    }

    apagar(idDeletar){
        return this.delete(`/delete/${idDeletar}`)
    }

    buscarId(id){
        return this.get(`/buscarid/${id}`)
    }
}

import ApiService from "../app/apiservice";

export default class LancamentoTerceirizadoPorHoraService extends ApiService {

    constructor(){
        super('/lancamentoterceirizadohoras')
    }

    listarPorUnidadeAnoMes(idUnidade, ano, mes){
        return this.get(`/${idUnidade}/${ano}/${mes}`)
    }

    vincularTerceirizadoPorHora(dados){
        return this.post('/salvar', dados)
    }

    contarLancamentosMesSeguinte(idUnidade, ano, mes){
        return this.get(`/contar/${idUnidade}/${ano}/${mes}`)
    }

    contarLancamentosMesSelecionado(idUnidade, ano, mes){
        return this.get(`/contarmesselecionado/${idUnidade}/${ano}/${mes}`)
    }

    localizarPorId(id){
        return this.get(`/localizar/${id}`)
    }

    atualizarDadosDoVinculo(dados){
        return this.put('/atualizar', dados)
    }

    salvarReplicacao(idUnidade, ano, mes){
        return this.post(`/replicacaoterceirizadoshora/${idUnidade}/${ano}/${mes}`)
    }

    deletarVinculoTerceirizado(id){
        return this.delete(`/delete/${id}`)
    }

    buscarHistoricoTerceirizado(id){
        return this.get(`/historico/${id}`)
    }
}
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import AnoMes from "../../components/mesAno/anoMes";
import { Row, Col, Container, Modal, Form } from "react-bootstrap";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import calendarioPersonalizado from "../../components/calendario/calendariomulti";
import { ListBox } from 'primereact/listbox';
import { useEffect, useState } from "react";
import ServidorService from "../../service/servidorService";
import faltasRhService from "../../service/faltasRhService";
import { mensagemErro, mensagemSucesso } from "../../components/avisos/toastr";
import EstagiarioService from "../../service/estagiarioService";
import LocalStorageService from "../../service/localStorageService";
import { descriptografar } from "../../components/criptografar";

const dadosServidorSelecionado = {
    matricula: ''
}

const dadosEstagiarioSelecionado = {
    matricula: ''
}

const dadosServidor = {
    servidor: [],
    dataFalta: '',
    descricaoFaltas: '',
    mesLancamento: '',
    anoLancamento: ''
}

const dadosEstagiario = {
    estagiario: [],
    dataFalta: '',
    descricaoFaltas: '',
    mesLancamento: '',
    anoLancamento: ''
}

const dadosServidorParaAtualizar = {
    id: null,
    servidor: [],
    dataFalta: '',
    descricaoFaltas: '',
    mesLancamento: '',
    anoLancamento: ''
}

const dadosEstagiarioParaAtualizar = {
    id: null,
    estagiario: [],
    dataFalta: '',
    descricaoFaltas: '',
    mesLancamento: '',
    anoLancamento: ''
}

const serviceServidores = new ServidorService()
const serviceEstagiarios = new EstagiarioService()
const serviceFaltas = new faltasRhService()

export default function Faltas(){

    const dataCriptografada = LocalStorageService.obterItem('1')
    let dataAtual = new Date(descriptografar(dataCriptografada));
    const [mes, setMes] = useState(dataAtual.getMonth() + 1);
    const [ano, setAno] = useState(dataAtual.getFullYear());
    const [botaoAdicionar, setBotaoAdicionar] = useState(false)
    const [nomeSelecionado, setNomeSelecionado] = useState(dadosServidorSelecionado)
    const [descricaoDasFaltas, setDescricaoDasFaltas] = useState('')

    const [dadosServidorSalvar, setDadosServidorSalvar] = useState(dadosServidor)
    const [dadosServidorAtualizar, setDadosServidorAtualizar] = useState(dadosServidorParaAtualizar)
    const [nomeServidorExcluir, setNomeServidorExcluir] = useState('')

    const [dadosEstagiarioSalvar, setDadosEstagiarioSalvar] = useState(dadosEstagiario)
    const [dadosEstagiarioAtualizar, setDadosEstagiarioAtualizar] = useState(dadosEstagiarioParaAtualizar)
    const [nomeEstagiarioExcluir, setNomeEstagiarioExcluir] = useState('')

    const [idFaltasExcluir, setIdFaltasExcluir] = useState(null)
    const [listarFaltas, setListarFaltas] = useState([])

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showEstagiario, setShowEstagiario] = useState(false);
    const handleCloseEstagiario = () => setShowEstagiario(false);
    const handleShowEstagiario = () => setShowEstagiario(true);

    const [showAtualizar, setShowAtualizar] = useState(false);
    const handleCloseAtualizar = () => setShowAtualizar(false);
    const handleShowAtualizar = () => setShowAtualizar(true);

    const [showAtualizarEstagiario, setShowAtualizarEstagiario] = useState(false);
    const handleCloseAtualizarEstagiario = () => setShowAtualizarEstagiario(false);
    const handleShowAtualizarEstagiario = () => setShowAtualizarEstagiario(true);

    const [showExcluir, setShowExcluir] = useState(false);
    const handleCloseExcluir = () => setShowExcluir(false);
    const handleShowExcluir = () => setShowExcluir(true);

    const [datas, setDatas] = useState([]);
    const [listarServidoresNaCombo, setListarServidoresNaCombo] = useState([])
    const [listarEstagiariosNaCombo, setListarEstagiariosNaCombo] = useState([])

    useEffect(() => {
        if(Number.parseInt(mes) === Number.parseInt((dataAtual.getMonth() + 1)) && Number.parseInt(ano) === Number.parseInt(dataAtual.getFullYear())){
            setBotaoAdicionar(true)
        } else {
            setBotaoAdicionar(false)
        }
        async function CarregarFaltas(){
            const dados = await serviceFaltas.listarFaltas(ano, mes)
            setListarFaltas(dados.data)
        }
        CarregarFaltas()
    },[ano, mes, show, showEstagiario, showAtualizar, showAtualizarEstagiario, showExcluir])

    useEffect(() => {
        async function listarServidores(){
            serviceServidores.buscarTodosAtivos()
            .then((s) => {
                setListarServidoresNaCombo(s.data)
            })
        }
        listarServidores()

        async function listarEstagiarios(){
            serviceEstagiarios.listarSomenteAtivosNaoPaginado()
            .then((s) => {
                setListarEstagiariosNaCombo(s.data)
            })
        }
        listarEstagiarios()

    },[show, showAtualizar, showExcluir])

    const nomeTemplate = (option) => {
        return (
            <div>
                <div style={{textAlign: "initial", marginLeft: '10px'}} >{option.nomeCompleto}</div>
            </div>
        );
    }

    const nomeEstagiarioTemplate = (option) => {
        return (
            <div>
                <div style={{textAlign: "initial", marginLeft: '10px'}} >{option.nome}</div>
            </div>
        );
    }

    function salvar(){
        dadosServidorSalvar.anoLancamento = ano
        dadosServidorSalvar.mesLancamento = mes
        dadosServidorSalvar.dataFalta = datas.toString()
        dadosServidorSalvar.descricaoFaltas = descricaoDasFaltas
        dadosServidorSalvar.servidor = nomeSelecionado
        serviceFaltas.salvar(dadosServidorSalvar)
            .then((s) => {
                mensagemSucesso(s.data)
                limparDescricaoServidor()
            })
            .catch((e) => {
                mensagemErro(e.response.data)
            })
    }

    function salvarEstagiario(){
        dadosEstagiarioSalvar.anoLancamento = ano
        dadosEstagiarioSalvar.mesLancamento = mes
        dadosEstagiarioSalvar.dataFalta = datas.toString()
        dadosEstagiarioSalvar.descricaoFaltas = descricaoDasFaltas
        dadosEstagiarioSalvar.estagiario = nomeSelecionado
        serviceFaltas.salvar(dadosEstagiarioSalvar)
            .then((s) => {
                mensagemSucesso(s.data)
                limparDescricaoEstagiario()
            })
            .catch((e) => {
                mensagemErro(e.response.data)
            })
    }

    function atualizar(){
       dadosServidorSalvar.id = dadosServidorAtualizar.id
        dadosServidorSalvar.anoLancamento = dadosServidorAtualizar.anoLancamento
        dadosServidorSalvar.mesLancamento = dadosServidorAtualizar.mesLancamento
        dadosServidorSalvar.dataFalta = datas.toString()
        dadosServidorSalvar.descricaoFaltas = descricaoDasFaltas
        dadosServidorSalvar.servidor = dadosServidorAtualizar.servidor
        serviceFaltas.atualizar(dadosServidorSalvar)
            .then((s) => {
                mensagemSucesso(s.data)
                limparDescricaoServidor()
            })
            .catch((e) => {
                mensagemErro(e.response.data)
            })
    }

    function atualizarEstagiario(){
        dadosEstagiarioSalvar.id = dadosEstagiarioAtualizar.id
        dadosEstagiarioSalvar.anoLancamento = dadosEstagiarioAtualizar.anoLancamento
        dadosEstagiarioSalvar.mesLancamento = dadosEstagiarioAtualizar.mesLancamento
        dadosEstagiarioSalvar.dataFalta = datas.toString()
        dadosEstagiarioSalvar.descricaoFaltas = descricaoDasFaltas
        dadosEstagiarioSalvar.estagiario = dadosEstagiarioAtualizar.estagiario
         serviceFaltas.atualizar(dadosEstagiarioSalvar)
             .then((s) => {
                 mensagemSucesso(s.data)
                 limparDescricaoEstagiario()
             })
             .catch((e) => {
                 mensagemErro(e.response.data)
             })
    }

    function carregarParaAtualizar(id){
       serviceFaltas.buscarId(id)
            .then((f) => {
                if (f.data.servidor) {
                    setDadosServidorAtualizar(f.data)
                    handleShowAtualizar()
                }
                if (f.data.estagiario) {
                    setDadosEstagiarioAtualizar(f.data)
                    handleShowAtualizarEstagiario()
                }

                setDescricaoDasFaltas(f.data.descricaoFaltas)
                const data = f.data.dataFalta.split(',')
                const dataFormatadas = []
                    for (let index = 0; index < data.length; index++) {
                        const [DD, MM, YYYY] = data[index].split('/')
                        const dataObtida = new Date(new Date(`${YYYY}-${MM}-${DD}`).setDate(new Date(`${YYYY}-${MM}-${DD}`).getDate()+1))
                        dataFormatadas.push(((dataObtida.getDate() <=9 ?"0"+dataObtida.getDate():dataObtida.getDate())) + "/" 
                                            + (((dataObtida.getMonth() + 1) <=9?"0"+(dataObtida.getMonth() + 1):(dataObtida.getMonth() + 1))) + "/" 
                                            + dataObtida.getFullYear())
                    }
                setDatas(dataFormatadas)
            
            })
    }
    function excluirFaltas(){
        serviceFaltas.apagar(idFaltasExcluir)
        .then((s) => {
            mensagemSucesso(s.data)
            handleCloseExcluir()
        })
    }

    function limparDescricaoServidor(){
        setNomeSelecionado(dadosServidorSelecionado)
        setDescricaoDasFaltas('')
        handleClose()
        handleCloseAtualizar()
    }

    function limparDescricaoEstagiario(){
        setNomeSelecionado(dadosServidorSelecionado)
        setDescricaoDasFaltas('')
        handleCloseEstagiario()
        handleCloseAtualizarEstagiario()
    }


    return(
        <>
    <Container>
        <Row className="justify-content-md-center">
            <Col xs lg="4">
                <AnoMes
                htmlForMes="mesLancamento"
                idMes="mesLancamento"
                nameMes="mesLancamento"
                valueMes={mes}
                onChangeMes={(e) => setMes(e.target.value)}
                htmlForAno="anoLancamento"
                idAno="anoLancamento"
                nameAno="anoLancamento"
                valueAno={ano}
                onChangeAno={(e) => setAno(e.target.value)}
                />
            </Col>
            {botaoAdicionar && 
                <Col sm={3} style={{ marginTop: "37px" }}>
                        <button style={{ marginRight: "10px" }} className="btn btn-primary" type="button" title="Adicionar faltas para SERVIDORES" onClick={handleShow}>
                            Servidores
                        </button>
                        <button className="btn btn-primary" type="button" title="Adicionar faltas para ESTAGIÁRIOS" onClick={handleShowEstagiario}>
                            Estagiários
                        </button>
                </Col>
            }
        </Row>
    </Container>
    <hr/>
    <div className="container mt-6">
        <div className="form-group">
            <div className="card md-3">
                <h4 className="card-header">Lançamentos de faltas - Realizadas pelo RH</h4>
                    <table className="table table-hover">
                        <thead>
                            <tr>  
                                <th>##</th>
                                <th scope="col" style={{ textAlign: "center" }}> Matrícula </th>
                                <th scope="col" style={{ textAlign: "center" }}> Servidor </th>
                                <th scope="col" style={{ textAlign: "center" }}> Datas Faltas </th>
                                <th scope="col" style={{ textAlign: "center" }}> Descrição Faltas </th>
                                <th scope="col" style={{ textAlign: "center" }}> Ações </th>
                            </tr>
                        </thead>
                        <tbody>
                            {listarFaltas.map((f, index) => {
                                let nome = f.estagiario === null ? f.servidor.nomeCompleto : f.estagiario.nome
                                let matricula = f.estagiario === null ? f.servidor.matricula : f.estagiario.matricula
                                return(
                                    <tr key={f.id}>
                                        <td>{index+1}</td>
                                        <td style={{textAlign: "center" }}>{matricula}</td>
                                        <td style={{maxWidth: "200px" }}>{nome}</td>
                                        <td style={{maxWidth: "165px" }}>{f.dataFalta} </td>
                                        <td style={{maxWidth: "200px" }}>{f.descricaoFaltas}</td>
                                        {botaoAdicionar &&
                                            <td style={{ textAlign: "center" }}>
                                                <button className="btn btn-primary" title="Editar faltas" onClick={() => carregarParaAtualizar(f.id)}>                             
                                                    <i className='pi pi-pencil'></i>
                                                </button>
                                                {" "}
                                                <button className="btn btn-danger" type="button" title="Excluir faltas do servidor" 
                                                        onClick={() => handleShowExcluir(setIdFaltasExcluir(f.id), setNomeServidorExcluir(nome))}>
                                                    <i className='pi pi-trash'></i>
                                                </button>
                                            </td>
                                        }
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
            </div>
        </div>
    </div>

    <Modal
        closeButton={true}
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        keyboard={false}
        size="xl"
      >
        <Modal.Header className="card-header">
          <Modal.Title>Cadastrar Dados de Faltas SERVIDORES</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container">
            <Row>
                <Col md={2}>
                    <label className="col-form-label"> Matrícula: </label>
                    <input type="number" className="form-control" disabled value={nomeSelecionado.matricula}/>
                </Col>
                <Col md={1}>
                    <br/> <br/><br/>
                    <label className="col-form-label"> Servidor: </label>
                </Col>
                <Col lg={2}>
                    <ListBox value={nomeSelecionado} options={listarServidoresNaCombo} onChange={(e) => setNomeSelecionado(e.target.value)} filter optionLabel="nomeCompleto"
                    itemTemplate={nomeTemplate} style={{ width: '600px', margin: 'auto' }} listStyle={{ maxHeight: '250px', minWith: "50px"}} metaKeySelection/>
                </Col>
            </Row>
            <Row>
              <Col>
                    <label className="col-form-label" htmlFor="datas"> Data(s) da(s) falta(s): </label>
                <Row>
                  <DatePicker
                    className="mb-3"
                    onChange={setDatas}
                    id="datas"
                    name="datas"
                    numberOfMonths={2}
                    multiple
                    mapDays={({ date }) => {
                            let props = {};
                            let isWeekend = [0, 6].includes(date.weekDay.index);
                            if (isWeekend)
                                props.className = "highlight highlight-red";
                                return props;
                        }}
                    sort
                    format="DD/MM/YYYY"
                    locale={calendarioPersonalizado}
                    plugins={[<DatePanel />]}
                    style={{
                      width: "100%",
                      height: "36px",
                      padding: "12px 20px",
                      boxSizing: "border-box",
                    }}
                  />
                </Row>
              </Col>
            </Row>
            <Row>
                <Col>
                    <label className="col-form-label"> Descrição das Faltas: </label>
                    <Form.Control as="textarea" rows={3} value={descricaoDasFaltas} onChange={(d) => setDescricaoDasFaltas(d.target.value)}/>  
                </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" type="button" onClick={salvar} >
            Salvar
          </button>
          <button className="btn btn-danger" type="button" onClick={limparDescricaoServidor} >
            Cancelar
          </button>
        </Modal.Footer>
    </Modal>

    <Modal
        closeButton={true}
        show={showEstagiario}
        onHide={handleCloseEstagiario}
        backdrop="static"
        centered
        keyboard={false}
        size="xl"
      >
        <Modal.Header className="card-header">
          <Modal.Title>Cadastrar Dados de Faltas ESTAGIÁRIOS</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container">
            <Row>
                <Col md={2}>
                    <label className="col-form-label"> Matrícula: </label>
                    <input type="number" className="form-control" disabled value={nomeSelecionado.matricula}/>
                </Col>
                <Col md={1}>
                    <br/> <br/><br/>
                    <label className="col-form-label"> Estagiário: </label>
                </Col>
                <Col lg={2}>
                    <ListBox value={nomeSelecionado} options={listarEstagiariosNaCombo} onChange={(e) => setNomeSelecionado(e.target.value)} filter optionLabel="nome"
                    itemTemplate={nomeEstagiarioTemplate} style={{ width: '600px', margin: 'auto' }} listStyle={{ maxHeight: '250px', minWith: "50px"}} metaKeySelection/>
                </Col>
            </Row>
            <Row>
              <Col>
                    <label className="col-form-label" htmlFor="datas"> Data(s) da(s) falta(s): </label>
                <Row>
                  <DatePicker
                    className="mb-3"
                    onChange={setDatas}
                    id="datas"
                    name="datas"
                    numberOfMonths={2}
                    multiple
                    mapDays={({ date }) => {
                            let props = {};
                            let isWeekend = [0, 6].includes(date.weekDay.index);
                            if (isWeekend)
                                props.className = "highlight highlight-red";
                                return props;
                        }}
                    sort
                    format="DD/MM/YYYY"
                    locale={calendarioPersonalizado}
                    plugins={[<DatePanel />]}
                    style={{
                      width: "100%",
                      height: "36px",
                      padding: "12px 20px",
                      boxSizing: "border-box",
                    }}
                  />
                </Row>
              </Col>
            </Row>
            <Row>
                <Col>
                    <label className="col-form-label"> Descrição das Faltas: </label>
                    <Form.Control as="textarea" rows={3} value={descricaoDasFaltas} onChange={(d) => setDescricaoDasFaltas(d.target.value)}/>  
                </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" type="button" onClick={salvarEstagiario} >
            Salvar
          </button>
          <button className="btn btn-danger" type="button" onClick={limparDescricaoEstagiario} >
            Cancelar
          </button>
        </Modal.Footer>
    </Modal>

    <Modal
        closeButton={true}
        show={showAtualizar}
        onHide={handleCloseAtualizar}
        backdrop="static"
        centered
        keyboard={false}
        size="xl"
      >
        <Modal.Header className="card-header">
          <Modal.Title>Atualizar Dados de Faltas SERVIDOR</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container">
            <Row>
                <Col md={2}>
                    <label className="col-form-label"> Matrícula: </label>
                    <input type="number" className="form-control" disabled value={dadosServidorAtualizar.servidor.matricula}/>
                </Col>
                <Col>
                    <label className="col-form-label"> Servidor: </label>
                    <input type="text" className="form-control" disabled value={dadosServidorAtualizar.servidor.nomeCompleto}/>
                </Col>
                
            </Row>
            <Row>
              <Col>
                    <label className="col-form-label" htmlFor="datas"> Datas das faltas: </label>
                <Row>
                  <DatePicker
                    className="mb-3"
                    onChange={setDatas}
                    value={datas}
                    id="datas"
                    name="datas"
                    numberOfMonths={2}
                    multiple
                    mapDays={({ date }) => {
                            let props = {};
                            let isWeekend = [0, 6].includes(date.weekDay.index);
                            if (isWeekend)
                                props.className = "highlight highlight-red";
                                return props;
                        }}
                    sort
                    format="DD/MM/YYYY"
                    locale={calendarioPersonalizado}
                    plugins={[<DatePanel />]}
                    style={{
                      width: "100%",
                      height: "36px",
                      padding: "12px 20px",
                      boxSizing: "border-box",
                    }}
                  />
                </Row>
              </Col>
            </Row>
            <Row>
                <Col>
                    <label className="col-form-label"> Descrição das Faltas: </label>
                    <Form.Control as="textarea" rows={3} value={descricaoDasFaltas} onChange={(d) => setDescricaoDasFaltas(d.target.value)}/>  
                </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" type="button" onClick={atualizar}>
            Atualizar
          </button>
          <button className="btn btn-danger" type="button" onClick={() => limparDescricaoServidor()} >
            Cancelar
          </button>
        </Modal.Footer>
    </Modal>

    <Modal
        closeButton={true}
        show={showAtualizarEstagiario}
        onHide={handleCloseAtualizarEstagiario}
        backdrop="static"
        centered
        keyboard={false}
        size="xl"
      >
        <Modal.Header className="card-header">
          <Modal.Title>Atualizar Dados de Faltas ESTAGIÁRIO</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container">
            <Row>
                <Col md={2}>
                    <label className="col-form-label"> Matrícula: </label>
                    <input type="number" className="form-control" disabled value={dadosEstagiarioAtualizar.estagiario.matricula}/>
                </Col>
                <Col>
                    <label className="col-form-label"> Estagiário: </label>
                    <input type="text" className="form-control" disabled value={dadosEstagiarioAtualizar.estagiario.nome}/>
                </Col>
                
            </Row>
            <Row>
              <Col>
                    <label className="col-form-label" htmlFor="datas"> Datas das faltas: </label>
                <Row>
                  <DatePicker
                    className="mb-3"
                    onChange={setDatas}
                    value={datas}
                    id="datas"
                    name="datas"
                    numberOfMonths={2}
                    multiple
                    mapDays={({ date }) => {
                            let props = {};
                            let isWeekend = [0, 6].includes(date.weekDay.index);
                            if (isWeekend)
                                props.className = "highlight highlight-red";
                                return props;
                        }}
                    sort
                    format="DD/MM/YYYY"
                    locale={calendarioPersonalizado}
                    plugins={[<DatePanel />]}
                    style={{
                      width: "100%",
                      height: "36px",
                      padding: "12px 20px",
                      boxSizing: "border-box",
                    }}
                  />
                </Row>
              </Col>
            </Row>
            <Row>
                <Col>
                    <label className="col-form-label"> Descrição das Faltas: </label>
                    <Form.Control as="textarea" rows={3} value={descricaoDasFaltas} onChange={(d) => setDescricaoDasFaltas(d.target.value)}/>  
                </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" type="button" onClick={atualizarEstagiario}>
            Atualizar
          </button>
          <button className="btn btn-danger" type="button" onClick={limparDescricaoEstagiario} >
            Cancelar
          </button>
        </Modal.Footer>
    </Modal>

    <Modal
        show={showExcluir}
        onHide={handleCloseExcluir}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header>
          <Modal.Title>Excluir faltas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <h5>Deseja excluir faltas lançadas para <label style={{color: "#fc1605"}}>{nomeServidorExcluir}</label> ?</h5>
                <br /> 
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" type="button" onClick={excluirFaltas}> Excluir </button>
          <button className="btn btn-primary" type="button" onClick={handleCloseExcluir}> Cancelar </button>
        </Modal.Footer>
      </Modal>
        </>
    )

}
import ApiService from "../app/apiservice";


class UsuarioService extends ApiService {

    constructor(){
        super('/usuario')
    }

    autenticar(credenciais){
        return this.post('/autenticacao', credenciais)
    }

    salvar(usuario){
        return this.post('/salvar', usuario);
    }

    listarTodos(pagina){
        return this.get(pagina)
    }

    buscarPorNome(nome){
        return this.get(nome)
    }

    atualizar(dados){
        return this.put('/atualizar', dados)
    }

    desabilitar(dados){
        return this.put('/desabilitar', dados)
    }

    habilitar(id){
        return this.put(`/habilitar/${id}`)
    }

    buscarPorId(id){
        return this.get(`/${id}`)
    }

    atualizarSenha(dados){
        return this.put('/senha', dados)
    }

}

export default UsuarioService;
import { useEffect, useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { mensagemErro, mensagemSucesso } from "../../components/avisos/toastr";
import Card from "../../components/card";
import { descriptografar } from "../../components/criptografar";
import AnoMes from "../../components/mesAno/anoMes";
import assinaturaQuadrosService from "../../service/assinaturaQuadrosService";
import LancamentoCooperadoService from "../../service/lancamentoCooperadoService";
import LancamentoEstagiarioService from "../../service/lancamentoEstagiarioService";
import LancamentoPrestadorService from "../../service/lancamentoPrestadorService";
import LancamentoServidorService from "../../service/lancamentoServidorService";
import LancamentoTerceirizadoPorHoraService from "../../service/lancamentoTerceirizadoPorHoraService";
import LocalStorageService from "../../service/localStorageService";


const serviceAssinaturaQuadro = new assinaturaQuadrosService()
const serviceLancamentoEstagiario = new LancamentoEstagiarioService()
const serviceLancamentoServidores = new LancamentoServidorService()
const serviceLancamentoCooperados = new LancamentoCooperadoService()
const serviceLancamentoTemporarios = new LancamentoPrestadorService()
const serviceTerceirizadoHora = new LancamentoTerceirizadoPorHoraService()

const dadosAssinanteQuadro = {
    usuario: [],
    unidade: [],
	assinadoPor: '',
	tipoQuadroAssinado: '',
	mesLancamento: '',
	anoLancamento: '',
	assinaturaDigital: '',
	status: true,
    dataAssinatura: ''
}


function AssinaturaQuadros() {

    const s = "SERVIDOR"
    const e = "ESTAGIARIO"
    const c = "COOPERADO"
    const t = "TEMPORARIO"
    const tcz = "TERCEIRIZADO"

    const [show, setShow] = useState(false)
    const openShow = () => setShow(true)
    const closeShow = () => setShow(false)

    const [showDeletar, setShowDeletar] = useState(false)
    const openShowDeletar = () => setShowDeletar(true)
    const closeShowDeletar = () => setShowDeletar(false)

    const [descricaoQuadroAssinar, setDescricaoQuadroAssinar] = useState('')
    const [tipoDoQuadro, setTipoDoQuadro] = useState(0)
    const [lancamentoEstagiarios, setLancamentoEstagiarios] = useState(0)
    const [lancamentoServidores, setLancamentoServidores] = useState(0)
    const [lancamentoCooperado, setLancamentoCooperado] = useState(0)
    const [lancamentoTerceirizadosHoras, setLancamentoTerceirizadoHoras] = useState(0)
    const [lancamentoTemporarios, setLancamentoTemporarios] = useState(0)

    const usuarioCriptografado = LocalStorageService.obterItem('2')
    const usuarioLogado = JSON.parse(descriptografar(usuarioCriptografado))
    const unidadeCriptografado = LocalStorageService.obterItem('3')
    const idUnidadeAtual = descriptografar(unidadeCriptografado)

    const dataAtual = new Date()

    const [mes, setMes] = useState(dataAtual.getMonth()+1)
    const [ano, setAno] = useState(dataAtual.getFullYear())

    const [dadosParaAssinatura, setDadosParaAssinatura] = useState(dadosAssinanteQuadro)

    const [carregarAssinaturas, setCarregarAssinaturas] = useState([])

    useEffect(() => {
        async function CarregarAssinaturas(){
            serviceAssinaturaQuadro.listarAssinaturasDaUnidade(Number(idUnidadeAtual), ano, mes)
                .then((s) => {
                    setCarregarAssinaturas(s.data)
                })
        }
        CarregarAssinaturas()
    },[mes, ano, idUnidadeAtual, show, showDeletar])

    function descricaoQuadro(numero){
        if(numero) {
            if (numero === 1) {
                return s
            }
            if (numero === 2) {
                return e
            }
            if (numero === 3) {
                return c
            }
            if (numero === 4) {
                return t
            }
            if (numero === 5){
                return tcz
            }
        }
    }

   function assinarQuadro(){
    dadosParaAssinatura.anoLancamento = ano.toString()
    dadosParaAssinatura.mesLancamento = mes.toString()
    dadosParaAssinatura.unidade = {id: Number(idUnidadeAtual)}
    dadosParaAssinatura.assinadoPor = usuarioLogado.nome
    dadosParaAssinatura.tipoQuadroAssinado = descricaoQuadro(tipoDoQuadro)
    dadosParaAssinatura.usuario = {id: usuarioLogado.id}
    serviceAssinaturaQuadro.assinarQuadro(dadosParaAssinatura)
        .then((s) => {
            mensagemSucesso(s.data)
            closeShow()
        })
        .catch((e) => {
            mensagemErro(e.response.data)
            closeShow()
        }) 
   }
   function apagarAssinatura(){
        let tipo = descricaoQuadro(tipoDoQuadro)
        serviceAssinaturaQuadro.deletarAssinatura(Number(idUnidadeAtual), ano, mes, tipo)
        .then((s) => {
            mensagemSucesso(s.data)
            closeShowDeletar()
        })
        .catch((e) => {
            mensagemErro(e.response.data)
        })
   }

   function confirmarExclusaoAssinatura(descricao) {
    if (descricao) {
        if (descricao === s) {
            setDescricaoQuadroAssinar('Servidores')
            setTipoDoQuadro(1)
            openShowDeletar()      
        }
        if (descricao === e) {
            setDescricaoQuadroAssinar('Estagiários')
            setTipoDoQuadro(2)
            openShowDeletar()
         }
         if (descricao === c) {
            setDescricaoQuadroAssinar('Cooperados')
            setTipoDoQuadro(3)
            openShowDeletar()
         }
         if (descricao === t) {
            setDescricaoQuadroAssinar('Substitutos Temporários')
            setTipoDoQuadro(4)
            openShowDeletar()
         }
         if (descricao === tcz) {
            setDescricaoQuadroAssinar('Terceirizados')
            setTipoDoQuadro(5)
            openShowDeletar()
         }
    }
        
   }

   function verificarQuadrosParaAssinar(tipo){
        if (tipo) {
            if (tipo === s) {
               serviceLancamentoServidores.countLancamentosMesAtual(Number(idUnidadeAtual), ano, mes)
                    .then((s) => {
                        setLancamentoServidores(s.data)
                        setDescricaoQuadroAssinar('Servidores')
                        setTipoDoQuadro(1)
                        openShow()
                    })
            }
            if (tipo === e) {
                serviceLancamentoEstagiario.countLancamentosMesAtual(Number(idUnidadeAtual), ano, mes)
                    .then((s) => {
                        setLancamentoEstagiarios(s.data)
                        setDescricaoQuadroAssinar('Estagiários')
                        setTipoDoQuadro(2)
                        openShow()
                    })
             }
             if (tipo === c) {
                serviceLancamentoCooperados.countLancamentosMesAtual(Number(idUnidadeAtual), ano, mes).then((s) => {setLancamentoCooperado(s.data)})
                setDescricaoQuadroAssinar('Cooperados')
                setTipoDoQuadro(3)
                openShow()
             }
             if (tipo === t) {
                serviceLancamentoTemporarios.countLancamentosMesAtual(Number(idUnidadeAtual), ano, mes)
                    .then((s) => {
                        setLancamentoTemporarios(s.data)
                        setDescricaoQuadroAssinar('Substitutos Temporários')
                        setTipoDoQuadro(4)
                        openShow()
                    })
             }
             if (tipo === tcz) {
                serviceTerceirizadoHora.contarLancamentosMesSelecionado(Number(idUnidadeAtual), ano, mes)
                .then((th) => {
                    setLancamentoTerceirizadoHoras(th.data)
                    setDescricaoQuadroAssinar('Terceirizados')
                    setTipoDoQuadro(5)
                    openShow()
                })
             }
        }
   }

   const formatarData = (dataF) => {
        if(dataF){
            let data = new Date(dataF),
            dia  = data.getUTCDate().toString().padStart(2, '0'),
            mes  = (data.getUTCMonth()+1).toString().padStart(2, '0'),
            ano  = data.getUTCFullYear();
            return dia+"/"+mes+"/"+ano;
        }
    }

    function verificarSeEstaAssinado(tipo){
        for (let index = 0; index < carregarAssinaturas.length; index++) {
            const element = carregarAssinaturas[index].tipoQuadroAssinado;
            if (element === tipo) {
                return true
            }
        }
    }

    return(
        <>
            <Container>
                <Card title="Assinatura Digital de Quadros Mensais">
                        <h5 style={{textAlign: "center"}}>
                            Selecione mês e ano para assinatura
                        </h5>
                        <Row className="justify-content-md-center">
                            <Col xs lg="4" style={{ textAlign: "center", paddingLeft: "50px" }}>
                                <AnoMes
                                    valueMes={mes}
                                    onChangeMes={(e) => setMes(e.target.value)}
                                    valueAno={ano}
                                    onChangeAno={(e) => setAno(e.target.value)}
                                />
                            </Col>
                        </Row>
                       <br/>
                        <Row style={{textAlign: "center"}} className="justify-content-md-center">
                            <Col>
                                <div className="form-group">
                                    <div className="card md-4">
                                        <h5 className="card-header">Assinatura Quadro de Servidores</h5>
                                            <div className="card-body">
                                                <table className="table table-hover mt-4" style={{margin: "auto"}}>
                                                    <thead>
                                                        <tr style={{ textAlign: "center" }}>
                                                            <th> Assinatura </th>
                                                            <th> Data da Assinatura </th>
                                                            <th> Chave Verificador </th>
                                                            <th> Assinado por </th>
                                                            <th>Ação</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody> 
                                                        {carregarAssinaturas.map((a) => {
                                                            return(
                                                                <>                                      
                                                                    {a.tipoQuadroAssinado === s &&
                                                                        <tr key={a.id}>
                                                                            <td style={{maxWidth: '250px'}}> {a.tipoQuadroAssinado === s && a.assinaturaDigital} </td>
                                                                            <td> {formatarData(a.dataAssinatura)} </td>
                                                                            <td> {a.chaveVerificador} </td>
                                                                            <td> {a.assinadoPor} </td>
                                                                            <td>
                                                                            <button onClick={() => confirmarExclusaoAssinatura(a.tipoQuadroAssinado)} title='Apagar Assinatura' className="btn btn-danger">
                                                                                <i className='pi pi-trash'></i>
                                                                            </button>
                                                                        </td>
                                                                        </tr>        
                                                                    }
                                                                </> 
                                                            )
                                                        })}
                                                        {(carregarAssinaturas.length === 0 || !verificarSeEstaAssinado(s)) && 
                                                            <td> 
                                                                <button className="btn btn-danger" type="button" onClick={() => verificarQuadrosParaAssinar(s)}> Verificar para Assinar </button> 
                                                            </td>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                            </Col>
                        </Row>
                        <br/>
                        <Row style={{textAlign: "center"}} className="justify-content-md-center">
                            <Col>
                            <div className="form-group">
                                <div className="card md-4">
                                    <h5 className="card-header">Assinatura Quadro de Estagiários</h5>
                                        <div className="card-body">
                                            <table className="table table-hover mt-4" style={{margin: "auto"}}>
                                                <thead>
                                                    <tr style={{ textAlign: "center" }}>
                                                        <th> Assinatura </th>
                                                        <th> Data da Assinatura </th>
                                                        <th> Chave Verificador </th>
                                                        <th> Assinado por </th>
                                                        <th>Ação</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {carregarAssinaturas.map((a) => {
                                                        return(
                                                            <>                                      
                                                                {a.tipoQuadroAssinado === e &&
                                                                    <tr key={a.id}>
                                                                        <td style={{maxWidth: '250px'}}> {a.tipoQuadroAssinado === e && a.assinaturaDigital} </td>
                                                                        <td> {formatarData(a.dataAssinatura)} </td>
                                                                        <td> {a.chaveVerificador} </td>
                                                                        <td> {a.assinadoPor} </td>
                                                                        <td>
                                                                            <button onClick={() => confirmarExclusaoAssinatura(a.tipoQuadroAssinado)} title='Apagar Assinatura' className="btn btn-danger">
                                                                                <i className='pi pi-trash'></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>        
                                                                }
                                                            </> 
                                                        )
                                                    })}
                                                    {(carregarAssinaturas.length === 0 || !verificarSeEstaAssinado(e)) && 
                                                            <td> 
                                                                <button className="btn btn-danger" type="button" onClick={() => verificarQuadrosParaAssinar(e)}> Verificar para Assinar </button> 
                                                            </td>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <br/>
                        <Row style={{textAlign: "center"}} className="justify-content-md-center">
                            <Col>
                            <div className="form-group">
                                <div className="card md-4">
                                    <h5 className="card-header">Assinatura Quadro de Cooperados</h5>
                                        <div className="card-body">
                                            <table className="table table-hover mt-4" style={{margin: "auto"}}>
                                                <thead>
                                                    <tr style={{ textAlign: "center" }}>
                                                        <th> Assinatura </th>
                                                        <th> Data da Assinatura </th>
                                                        <th> Chave Verificador </th>
                                                        <th> Assinado por </th>
                                                        <th>Ação</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {carregarAssinaturas.map((a) => {
                                                        return(
                                                            <>                                      
                                                                {a.tipoQuadroAssinado === c &&
                                                                    <tr key={a.id}>
                                                                        <td style={{maxWidth: '250px'}}> {a.tipoQuadroAssinado === c && a.assinaturaDigital} </td>
                                                                        <td> {formatarData(a.dataAssinatura)} </td>
                                                                        <td> {a.chaveVerificador} </td>
                                                                        <td> {a.assinadoPor} </td>
                                                                        <td>
                                                                            <button onClick={() => confirmarExclusaoAssinatura(a.tipoQuadroAssinado)} title='Apagar Assinatura' className="btn btn-danger">
                                                                                <i className='pi pi-trash'></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>        
                                                                }
                                                            </> 
                                                        )
                                                    })}
                                                    {(carregarAssinaturas.length === 0 || !verificarSeEstaAssinado(c)) && 
                                                            <td> 
                                                                <button className="btn btn-danger" type="button" onClick={() => verificarQuadrosParaAssinar(c)}> Verificar para Assinar </button> 
                                                            </td>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <br/>
                        <Row style={{textAlign: "center"}} className="justify-content-md-center">
                            <Col>
                            <div className="form-group">
                                <div className="card md-4">
                                    <h5 className="card-header">Assinatura Quadro de Terceirizados</h5>
                                        <div className="card-body">
                                            <table className="table table-hover mt-4" style={{margin: "auto"}}>
                                                <thead>
                                                    <tr style={{ textAlign: "center" }}>
                                                        <th> Assinatura </th>
                                                        <th> Data da Assinatura </th>
                                                        <th> Chave Verificador </th>
                                                        <th> Assinado por </th>
                                                        <th>Ação</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {carregarAssinaturas.map((a) => {
                                                        return(
                                                            <>                                      
                                                                {a.tipoQuadroAssinado === tcz &&
                                                                    <tr key={a.id}>
                                                                        <td style={{maxWidth: '250px'}}> {a.tipoQuadroAssinado === tcz && a.assinaturaDigital} </td>
                                                                        <td> {formatarData(a.dataAssinatura)} </td>
                                                                        <td> {a.chaveVerificador} </td>
                                                                        <td> {a.assinadoPor} </td>
                                                                        <td>
                                                                            <button onClick={() => confirmarExclusaoAssinatura(a.tipoQuadroAssinado)} title='Apagar Assinatura' className="btn btn-danger">
                                                                                <i className='pi pi-trash'></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>        
                                                                }
                                                            </> 
                                                        )
                                                    })}
                                                    {(carregarAssinaturas.length === 0 || !verificarSeEstaAssinado(tcz)) && 
                                                            <td> 
                                                                <button className="btn btn-danger" type="button" onClick={() => verificarQuadrosParaAssinar(tcz)}> Verificar para Assinar </button> 
                                                            </td>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <br/>
                        <Row style={{textAlign: "center"}} className="justify-content-md-center">
                            <Col>
                            <div className="form-group">
                                <div className="card md-4">
                                    <h5 className="card-header">Assinatura Quadro de Substitutos Temporários</h5>
                                        <div className="card-body">
                                            <table className="table table-hover mt-4" style={{margin: "auto"}}>
                                                <thead>
                                                    <tr style={{ textAlign: "center" }}>
                                                        <th> Assinatura </th>
                                                        <th> Data da Assinatura </th>
                                                        <th> Chave Verificador </th>
                                                        <th> Assinado por </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {carregarAssinaturas.map((a) => {
                                                        return(
                                                            <>                                      
                                                                {a.tipoQuadroAssinado === t &&
                                                                    <tr key={a.id}>
                                                                        <td style={{maxWidth: '250px'}}> {a.tipoQuadroAssinado === t && a.assinaturaDigital} </td>
                                                                        <td> {formatarData(a.dataAssinatura)} </td>
                                                                        <td> {a.chaveVerificador} </td>
                                                                        <td> {a.assinadoPor} </td>
                                                                        <td>
                                                                            <button onClick={() => confirmarExclusaoAssinatura(a.tipoQuadroAssinado)} title='Apagar Assinatura' className="btn btn-danger">
                                                                                <i className='pi pi-trash'></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>        
                                                                }
                                                            </> 
                                                        )
                                                    })}
                                                    {(carregarAssinaturas.length === 0 || !verificarSeEstaAssinado(t)) && 
                                                            <td> 
                                                                <button className="btn btn-danger" type="button" onClick={() => verificarQuadrosParaAssinar(t)}> Verificar para Assinar </button> 
                                                            </td>
                                                    }                                                   
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                </Card>
            </Container>
            <Modal show={show} onHide={closeShow} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Assinar Quadros</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {tipoDoQuadro === 1 &&
                    (lancamentoServidores > 0 ?
                        `Existe Quadro de ${descricaoQuadroAssinar} para assinar no mês e ano selecionado, deseja assinar?` 
                    :
                        `Não há Quadro de ${descricaoQuadroAssinar} para assinar no mês e ano selecionado.` ) 
                    }
                    {tipoDoQuadro === 2 &&
                    (lancamentoEstagiarios > 0 ?
                        `Existe Quadro de ${descricaoQuadroAssinar} para assinar no mês e ano selecionado, deseja assinar?` 
                    :
                        `Não há Quadro de ${descricaoQuadroAssinar} para assinar no mês e ano selecionado.` ) 
                    }
                    {tipoDoQuadro === 3 &&
                    (lancamentoCooperado > 0 ?
                        `Existe Quadro de ${descricaoQuadroAssinar} para assinar no mês e ano selecionado, deseja assinar?` 
                    :
                        `Não há Quadro de ${descricaoQuadroAssinar} para assinar no mês e ano selecionado.` ) 
                    }
                    {tipoDoQuadro === 4 &&
                    (lancamentoTemporarios > 0 ?
                        `Existe Quadro de ${descricaoQuadroAssinar} para assinar no mês e ano selecionado, deseja assinar?` 
                    :
                        `Não há Quadro de ${descricaoQuadroAssinar} para assinar no mês e ano selecionado.` ) 
                    }
                    {tipoDoQuadro === 5 &&
                    (lancamentoTerceirizadosHoras > 0 ?
                        `Existe Quadro de ${descricaoQuadroAssinar} para assinar no mês e ano selecionado, deseja assinar?` 
                    :
                        `Não há Quadro de ${descricaoQuadroAssinar} para assinar no mês e ano selecionado.` ) 
                    }
                </Modal.Body>
                <Modal.Footer>
                    {tipoDoQuadro === 1 && lancamentoServidores > 0 ?
                        <button className="btn btn-danger" type="button" onClick={assinarQuadro}> Assinar </button>
                    :
                    tipoDoQuadro === 2 && lancamentoEstagiarios > 0 ?
                        <button className="btn btn-danger" type="button" onClick={assinarQuadro}> Assinar </button>
                    :
                    tipoDoQuadro === 3 && lancamentoCooperado > 0 ?
                        <button className="btn btn-danger" type="button" onClick={assinarQuadro}> Assinar </button>
                    :
                    tipoDoQuadro === 4 && lancamentoTemporarios > 0 ?
                        <button className="btn btn-danger" type="button" onClick={assinarQuadro}> Assinar </button>
                    :
                    tipoDoQuadro === 5 && lancamentoTerceirizadosHoras > 0 ?
                        <button className="btn btn-danger" type="button" onClick={assinarQuadro}> Assinar </button>
                    :
                        <button className="btn btn-primary" type="button" onClick={closeShow}> Fechar </button> 
                    }
                    
                </Modal.Footer>
            </Modal>
            <Modal show={showDeletar} onHide={closeShowDeletar} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Excluir Assinar Quadros</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        Tem certeza de que quer excluir a assinatura do quadro de {descricaoQuadroAssinar}? 

                </Modal.Body>
                <Modal.Footer>
                        <button className="btn btn-danger" type="button" onClick={apagarAssinatura}> Apagar Assinatura </button>
                        <button className="btn btn-primary" type="button" onClick={closeShowDeletar}> Fechar </button>                   
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AssinaturaQuadros
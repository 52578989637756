import { useEffect, useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import { mensagemErro } from "../../../components/avisos/toastr";
import Card from "../../../components/card";
import { descriptografar } from "../../../components/criptografar";
import AnoMes from "../../../components/mesAno/anoMes";
import gerarPdfService from "../../../service/gerarPdfService";
import LocalStorageService from "../../../service/localStorageService";
import UnidadeService from "../../../service/unidadeService";
import LoadingPage from "../../landing/LandingPage";

const serviceU = new UnidadeService()

function QuadrosMensais() {
    const [loading, setLoading] = useState(false)
    let dataAtual = new Date()
    const [mes, setMes] = useState(dataAtual.getMonth() + 1)
    const [ano, setAno] = useState(dataAtual.getFullYear())
    const [unidades, setUnidades] = useState([])
    const [idUnidade, setIdUnidade] = useState(0)
    const usuarioCriptografado = LocalStorageService.obterItem('2')
    const userLog = JSON.parse(descriptografar(usuarioCriptografado))
    const unidadeCriptografado = LocalStorageService.obterItem('3')
    const unidadeSessao = descriptografar(unidadeCriptografado)
    const [unidadeDeEnsino, setUnidadeDeEnsino] = useState(null)
    if(unidadeSessao !== null) {
        serviceU.obterPorId(unidadeSessao)
            .then((e) => {
                setUnidadeDeEnsino(e.data.unidadeEscolar)
            })
            .catch(() => {
                mensagemErro('Unidade de Ensino não identificada. Contate um administrador.')
            })
    }
    const userRH = "RH"
    const userADM = "ADMINISTRADOR"
    
    useEffect(() => {
        async function carregarUnidades(){
            const u = await serviceU.listar()
            setUnidades(u.data)
        }
        carregarUnidades()
    },[idUnidade, mes, ano])
   
   
    //Relatórios gerados pelo usuário DIRETOR
    const downloadPdfQuadroGeralGestao = () => { 
        setLoading(true)
        gerarPdfService(`/lancamentoservidor/relatorios/quadrogeralgestao/${unidadeSessao}/${ano}/${mes}`)
        .then(() => {
			setLoading(false)
		})
    }
    const downloadPdfQuadroGeralProfs = ()  => { 
        setLoading(true)
        gerarPdfService(`/lancamentoservidor/relatorios/quadrogeralprof/${unidadeSessao}/${ano}/${mes}`)
        .then(() => {
			setLoading(false)
		})
    }
    const downloadPdfQuadroAlteracoes = ()  => { 
        setLoading(true)
        gerarPdfService(`/lancamentoservidor/relatorios/quadroalteracoes/${unidadeSessao}/${ano}/${mes}`)
        .then(() => {
			setLoading(false)
		})
    }
    const downloadPdfQuadroAlteracoesFaltas = ()  => { 
        setLoading(true)
        gerarPdfService(`/lancamentoservidor/relatorios/quadroalteracoesfaltas/${unidadeSessao}/${ano}/${mes}`)
        .then(() => {
			setLoading(false)
		})
    }
    const downloadPdfQuadroAulasExcedentes = () => { 
        setLoading(true)
        gerarPdfService(`/lancamentoservidor/relatorios/quadroaulasexcedentes/${unidadeSessao}/${ano}/${mes}`)
        .then(() => {
			setLoading(false)
		})
    }
    const downloadPdfEstagiarios = ()  => { 
        setLoading(true)
        gerarPdfService(`/estagiarios/relatorios/listartodos/${unidadeSessao}/${ano}/${mes}`)
        .then(() => {
			setLoading(false)
		})
    }
    const downloadPdfLancamentoEfetivos = () => { 
        setLoading(true)
        gerarPdfService(`/lancamentoservidor/relatorios/quadrogrupodeapoio/${unidadeSessao}/${ano}/${mes}`)
        .then(() => {
			setLoading(false)
		})
    }
    const downloadPdfLancamentoCooperados = () => { 
        setLoading(true)
        gerarPdfService(`/lancamentocooperado/relatorios/conferenciageralporunidade/${ano}/${mes}/${unidadeSessao}`)
        .then(() => {
			setLoading(false)
		})
    }
    const downloadPdfLancamentoTerceirizados = () => { 
        setLoading(true)
        gerarPdfService(`/lancamentoterceirizadohoras/relatorios/conferenciageralunidades/${ano}/${mes}/${unidadeSessao}`)
        .then(() => {
			setLoading(false)
		})
    }
    const downloadPdfInfoSubstitutosTemporarios = () => { 
        setLoading(true)
        gerarPdfService(`/lancamentoservidor/relatorios/infosubstitutostemporarios/${unidadeSessao}/${ano}/${mes}`)
        .then(() => {
			setLoading(false)
		})
    }
    const downloadPdfQuadroServidoresReadaptados = () => { 
        setLoading(true)
        gerarPdfService(`/lancamentoservidor/relatorios/servidoresreadaptados/${unidadeSessao}/${ano}/${mes}`)
        .then(() => {
			setLoading(false)
		})
    }

    const downloadPdfQuadroServidoresUnidadeNaoEscolar = () => { 
        setLoading(true)
        gerarPdfService(`/lancamentoservidor/relatorios/servidoresrunidadenaoescolar/${unidadeSessao}/${ano}/${mes}`)
        .then(() => {
			setLoading(false)
		})
    }
    const downloadPdfQuadroEstagiariosUnidadeNaoEscolar = () => { 
        setLoading(true)
        gerarPdfService(`/lancamentoestagiario/relatoriounidadenaoescolar/${unidadeSessao}/${ano}/${mes}`)
        .then(() => {
			setLoading(false)
		})
    }

    //Relatórios gerados pelo usuário RH
    const downloadPdfQuadroGeralGestaoRH = ()  => { 
        setLoading(true)
        gerarPdfService(`/lancamentoservidor/relatorios/quadrogeralgestao/${idUnidade}/${ano}/${mes}`)
        .then(() => {
			setLoading(false)
		})
    }
    const downloadPdfQuadroGeralProfsRH = ()  => { 
        setLoading(true)
        gerarPdfService(`/lancamentoservidor/relatorios/quadrogeralprof/${idUnidade}/${ano}/${mes}`)
        .then(() => {
			setLoading(false)
		})
    }
    const downloadPdfQuadroAlteracoesRH = ()  => { 
        setLoading(true)
        gerarPdfService(`/lancamentoservidor/relatorios/quadroalteracoes/${idUnidade}/${ano}/${mes}`)
        .then(() => {
			setLoading(false)
		})
    }
    const downloadPdfQuadroAlteracoesFaltasRH = ()  => { 
        setLoading(true)
        gerarPdfService(`/lancamentoservidor/relatorios/quadroalteracoesfaltas/${idUnidade}/${ano}/${mes}`)
        .then(() => {
			setLoading(false)
		})
    }
    const downloadPdfQuadroAulasExcedentesRH = () => { 
        setLoading(true)
        gerarPdfService(`/lancamentoservidor/relatorios/quadroaulasexcedentes/${idUnidade}/${ano}/${mes}`)
        .then(() => {
			setLoading(false)
		})
    }
    const downloadPdfEstagiariosRH = ()  => { 
        setLoading(true)
        gerarPdfService(`/estagiarios/relatorios/listartodos/${idUnidade}/${ano}/${mes}`)
        .then(() => {
			setLoading(false)
		})
    }
    const downloadPdfLancamentoEfetivosRH = () => { 
        setLoading(true)
        gerarPdfService(`/lancamentoservidor/relatorios/quadrogrupodeapoio/${idUnidade}/${ano}/${mes}`)
        .then(() => {
			setLoading(false)
		})
    }
    const downloadPdfLancamentoCooperadosRH = () => { 
        setLoading(true)
        gerarPdfService(`/lancamentocooperado/relatorios/conferenciageralporunidade/${ano}/${mes}/${idUnidade}`)
        .then(() => {
			setLoading(false)
		})
    }
    const downloadPdfLancamentoTerceirizadosRH = () => { 
        setLoading(true)
        gerarPdfService(`/lancamentoterceirizadohoras/relatorios/conferenciageralunidades/${ano}/${mes}/${idUnidade}`)
        .then(() => {
			setLoading(false)
		})
    }
    const downloadPdfInfoSubstitutosTemporariosRH = () => { 
        setLoading(true)
        gerarPdfService(`/lancamentoservidor/relatorios/infosubstitutostemporarios/${idUnidade}/${ano}/${mes}`)
        .then(() => {
			setLoading(false)
		})
    } 
    const downloadPdfQuadroServidoresReadaptadosRH = () => { 
        setLoading(true)
        gerarPdfService(`/lancamentoservidor/relatorios/servidoresreadaptados/${idUnidade}/${ano}/${mes}`)
        .then(() => {
			setLoading(false)
		})
    }
    const downloadPdfQuadroServidoresGeralNaoEscolarRH = () => { 
        setLoading(true)
        gerarPdfService(`/lancamentoservidor/relatorios/servidoresrunidadenaoescolar/${idUnidade}/${ano}/${mes}`)
        .then(() => {
			setLoading(false)
		})
    }
    const downloadPdfQuadroEstagiariosUnidadeNaoEscolarRH = () => { 
        setLoading(true)
        gerarPdfService(`/lancamentoestagiario/relatoriounidadenaoescolar/${idUnidade}/${ano}/${mes}`)
        .then(() => {
			setLoading(false)
		})
    }

    return(
        <>
            {loading ? <LoadingPage/> : 
                <Container>
                    <Card title="Geração de Quadros Mensais">
                            <h5 style={{textAlign: "center"}}>
                                Selecione mês e ano para emissão de relatório
                            </h5>
                            <Row className="justify-content-md-center">
                                <Col xs lg="4" style={{ textAlign: "center", paddingLeft: "50px" }}>
                                    <AnoMes
                                        valueMes={mes}
                                        onChangeMes={(e) => setMes(e.target.value)}
                                        valueAno={ano}
                                        onChangeAno={(e) => setAno(e.target.value)}
                                    />
                                </Col>
                            </Row>
                            {(userLog.role === userRH || userLog.role === userADM) &&
                            <Row style={{textAlign: "center"}} className="justify-content-md-center">
                                <Col md={6}>
                                    <label className="col-form-label" htmlFor="unidade">Unidade:</label>
                                    <Form.Select value={idUnidade} onChange={(s) => setIdUnidade(s.target.value)}>
                                        <option value="">Selecione</option>
                                        {unidades.sort((a, b) => a.nome > b.nome ? 1:-1).map((u) => (
                                            <option value={u.id} key={u.id}>{u.nome}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                            </Row>}
                            <Row style={{textAlign: "center"}} className="justify-content-md-center">
                                <Col>
                                {(unidadeDeEnsino || userLog.role === userRH || userLog.role === userADM) && 
                                    <table className="table table-hover mt-4" style={{maxWidth: "60%", margin: "auto"}}>
                                            <thead>
                                                <tr style={{ textAlign: "center" }}>
                                                    <th>
                                                        Quadros Unidade Escolar
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Quadro Geral da Gestão
                                                    </td>
                                                    <td>
                                                        {(userLog.role === userRH || userLog.role === userADM) ? <button className="btn btn-primary" type="button" onClick={downloadPdfQuadroGeralGestaoRH}> Gerar</button>
                                                        : <button className="btn btn-primary" type="button" onClick={downloadPdfQuadroGeralGestao}> Gerar </button> }  
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Quadro Geral de Professores
                                                    </td>
                                                    <td>
                                                        {(userLog.role === userRH || userLog.role === userADM) ? <button className="btn btn-primary" type="button" onClick={downloadPdfQuadroGeralProfsRH}> Gerar</button>
                                                        : <button className="btn btn-primary" type="button" onClick={downloadPdfQuadroGeralProfs}> Gerar </button> }   
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Quadro de Alterações de Efetivos e Contratados - Substituições
                                                    </td>
                                                    <td>
                                                        {(userLog.role === userRH || userLog.role === userADM) ? <button className="btn btn-primary" type="button" onClick={downloadPdfQuadroAlteracoesRH}> Gerar</button>
                                                        : <button className="btn btn-primary" type="button" onClick={downloadPdfQuadroAlteracoes}> Gerar </button> }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Quadro de Aulas Excedentes de Efetivos e Contratados
                                                    </td>
                                                    <td>
                                                        {(userLog.role === userRH || userLog.role === userADM) ? <button className="btn btn-primary" type="button" onClick={downloadPdfQuadroAulasExcedentesRH}> Gerar</button>
                                                        : <button className="btn btn-primary" type="button" onClick={downloadPdfQuadroAulasExcedentes}> Gerar </button> }                                                
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Quadro de Controle de Estagiários
                                                    </td>
                                                    <td>
                                                        {(userLog.role === userRH || userLog.role === userADM) ? <button className="btn btn-primary" type="button" onClick={downloadPdfEstagiariosRH}> Gerar</button>
                                                        : <button className="btn btn-primary" type="button" onClick={downloadPdfEstagiarios}> Gerar </button> }    
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Quadro de Controle de Zeladoras/Merendeiras/Vigilantes - Efetivos
                                                    </td>
                                                    <td>
                                                        {(userLog.role === userRH || userLog.role === userADM) ? <button className="btn btn-primary" type="button" onClick={downloadPdfLancamentoEfetivosRH}> Gerar</button>
                                                        : <button className="btn btn-primary" type="button" onClick={downloadPdfLancamentoEfetivos}> Gerar </button> } 
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Quadro de Controle - Cooperados
                                                    </td>
                                                    <td>
                                                        {(userLog.role === userRH || userLog.role === userADM) ? <button className="btn btn-primary" type="button" onClick={downloadPdfLancamentoCooperadosRH}> Gerar</button>
                                                        : <button className="btn btn-primary" type="button" onClick={downloadPdfLancamentoCooperados}> Gerar </button> } 
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Quadro de Controle - Terceirizados
                                                    </td>
                                                    <td>
                                                        {(userLog.role === userRH || userLog.role === userADM) ? <button className="btn btn-primary" type="button" onClick={downloadPdfLancamentoTerceirizadosRH}> Gerar</button>
                                                        : <button className="btn btn-primary" type="button" onClick={downloadPdfLancamentoTerceirizados}> Gerar </button> } 
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        Informações de Professores Substitutos Temporários
                                                    </td>
                                                    <td>
                                                        {(userLog.role === userRH || userLog.role === userADM) ? <button className="btn btn-primary" type="button" onClick={downloadPdfInfoSubstitutosTemporariosRH}> Gerar</button>
                                                        : <button className="btn btn-primary" type="button" onClick={downloadPdfInfoSubstitutosTemporarios}> Gerar </button> } 
                                                    </td>
                                                </tr>
                                            {/*  <tr>
                                                    <td>
                                                        Quadro de Faltas de Efetivos, Contrados e Estagiários
                                                    </td>
                                                    <td>
                                                        {(userLog.role === userRH || userLog.role === userADM) ? <button className="btn btn-primary" type="button" onClick={downloadPdfQuadroAlteracoesFaltasRH}> Gerar</button>
                                                        : <button className="btn btn-primary" type="button" onClick={downloadPdfQuadroAlteracoesFaltas}> Gerar </button> } 
                                                    </td>

                                                </tr> */}
                                                <tr>
                                                    <td>
                                                        Quadro de Servidores em Readaptação
                                                    </td>
                                                    <td>
                                                        {(userLog.role === userRH || userLog.role === userADM) ? <button className="btn btn-primary" type="button" onClick={downloadPdfQuadroServidoresReadaptadosRH}> Gerar</button>
                                                        : <button className="btn btn-primary" type="button" onClick={downloadPdfQuadroServidoresReadaptados}> Gerar </button> } 
                                                    </td>
                                                </tr>
                                            </tbody>
                                    </table>
                                }
                                {!unidadeDeEnsino && 
                                    <table className="table table-hover mt-4" style={{maxWidth: "60%", margin: "auto"}}>
                                            <thead>
                                                <tr style={{ textAlign: "center" }}>
                                                    <th>
                                                        Quadros Unidades Não Escolar
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Quadro Geral de Servidores
                                                    </td>
                                                    <td>
                                                        {(userLog.role === userRH || userLog.role === userADM) ? <button className="btn btn-primary" type="button" onClick={downloadPdfQuadroServidoresGeralNaoEscolarRH}> Gerar</button>
                                                        : <button className="btn btn-primary" type="button" onClick={downloadPdfQuadroServidoresUnidadeNaoEscolar}> Gerar </button> }  
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Quadro Geral de Estagiários
                                                    </td>
                                                    <td>
                                                        {(userLog.role === userRH || userLog.role === userADM) ? <button className="btn btn-primary" type="button" onClick={downloadPdfQuadroEstagiariosUnidadeNaoEscolarRH}> Gerar</button>
                                                        : <button className="btn btn-primary" type="button" onClick={downloadPdfQuadroEstagiariosUnidadeNaoEscolar}> Gerar </button> }  
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Quadro Geral de Cooperados
                                                    </td>
                                                    <td>
                                                        {(userLog.role === userRH || userLog.role === userADM) ? <button className="btn btn-primary" type="button" onClick={downloadPdfLancamentoCooperadosRH}> Gerar</button>
                                                        : <button className="btn btn-primary" type="button" onClick={downloadPdfLancamentoCooperados}> Gerar </button> } 
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Quadro Geral de Terceirizados
                                                    </td>
                                                    <td>
                                                        {(userLog.role === userRH || userLog.role === userADM) ? <button className="btn btn-primary" type="button" onClick={downloadPdfLancamentoTerceirizadosRH}> Gerar</button>
                                                        : <button className="btn btn-primary" type="button" onClick={downloadPdfLancamentoTerceirizados}> Gerar </button> } 
                                                    </td>
                                                </tr>
                                            </tbody>
                                    </table>
                                }

                                </Col>
                            </Row>
                    </Card>
                </Container>
            }
        </>
    )
}

export default QuadrosMensais
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import InputMask from "react-input-mask";
import Form from "react-bootstrap/Form";
import { Row, Col, OverlayTrigger, Popover } from "react-bootstrap";
import { mensagemErro, mensagemSucesso } from "../../components/avisos/toastr";
import ServidorService from "../../service/servidorService";
import CargoService from "../../service/cargoService";
import { bool } from "prop-types";

const atualizarServidorUp = {
  matricula: 0,
  nomeCompleto: "",
  sexo: "",
  email: "",
  rg: "",
  cpf: "",
  pis: "",
  vinculo: "",
  cargaHoraria: "",
  situacao: "",
  dataAdmissao: null,
  dataNascimento: null,
  cargo: [],
  telefone1: "",
  descTel1: "",
  telefone2: "",
  descTel2: "",
  matriculaDefinitiva: false
};

const service = new ServidorService();
const cargosListar = new CargoService();

const DadosEdicaoServidor = ({ id }) => {
  const [servidorUp, setServidorUp] = useState(id ? atualizarServidorUp : null);
  const [cargo, setCargo] = useState([]);
 
  const redirecionar = useHistory();

  const [dataAdm, setDataAdm] = useState(new Date(servidorUp.dataAdmissao));
  const [dataNasc, setDataNasc] = useState(new Date(servidorUp.dataNascimento));
  const [isCheckedMatricula, setIsCheckedMatricula] = useState(false);
  const textoMatProvisoria = 'Servidor com matrícula provisória.'

  const handleOnCheckedMatricula = () => {
    setIsCheckedMatricula(!isCheckedMatricula);
  }

  useEffect(() => {
    if (id) {
          async function carregarServidor() {
            service.buscarPorId(id)
            .then((s) => {
              if (s.data.situacao === "ATIVO") {
                setServidorUp(s.data);
                setDataAdm(s.data.dataAdmissao);
                setDataNasc(s.data.dataNascimento);
              } else if (s.data.situacao === "INATIVO"){
                setServidorUp({...servidorUp, id: null, matricula: 0, nomeCompleto: s.data.nomeCompleto,
                                            situacao: 'ATIVO',
                                            cargo: s.data.cargo,
                                            sexo: s.data.sexo,
                                            email: s.data.email,
                                            rg: s.data.rg,
                                            cpf: s.data.cpf,
                                            pis: s.data.pis,
                                            vinculo: s.data.vinculo,
                                            cargaHoraria: s.data.cargaHoraria,
                                            dataAdmissao: s.data.dataAdmissao,
                                            dataNascimento: s.data.dataNascimento,
                                            telefone1: s.data.telefone1,
                                            descTel1: s.data.descTel1,
                                            telefone2: s.data.telefone2,
                                            descTel2: s.data.descTel2, 
                                            matriculaDefinitiva: s.data.matriculaDefinitiva})
              } else {
                return null
              }


            setIsCheckedMatricula(s.data.matriculaDefinitiva);
            })
          }       
          async function carregarCargos() {
            const response = await cargosListar.listar();
            setCargo(response.data);
          }
        carregarServidor();
        carregarCargos();
        setDataAdm(servidorUp.dataAdmissao);
        setDataNasc(servidorUp.dataNascimento);
        
      }
  }, []);

  function obterIdCargo(e) {
    const id = e.target.value;
    servidorUp.cargo = { id };
  }

  function onChange(e) {
    const { name, value } = e.target;
    setServidorUp({ ...servidorUp, [name]: value });
  }

  function atualizarServidor() {

    if (id) {
      if(servidorUp.dataAdmissao !== null){
        if(checarData(servidorUp.dataAdmissao) === false){
            mensagemErro('Data de admissão tem o formato inválido.');
            return;
        }
      }

    if(servidorUp.dataNascimento !== null){
        if(checarData(servidorUp.dataNascimento) === false){
            mensagemErro('Data de nascimento tem o formato inválido.');
            return;
        }
      }
      servidorUp.cpf = RetiraMascara(servidorUp.cpf)
      servidorUp.pis = RetiraMascara(servidorUp.pis)
      servidorUp.telefone1 = RetiraMascara(servidorUp.telefone1)
      servidorUp.telefone2 = RetiraMascara(servidorUp.telefone2)
      servidorUp.matriculaDefinitiva = isCheckedMatricula;
      service
        .atualizar(id, servidorUp)
        .then(()  => {
          mensagemSucesso("Atualizado com sucesso")
          redirecionar.push("/servidor/listar");
        })
        .catch((error) => {
          mensagemErro(error.response.data);
          //console.log(error.response.data);
        });
      //console.log(servidorUp);
    } else {
      mensagemErro("Sem chave para atualização.");
    }
  }

  function salvar() {
    servidorUp.cpf = RetiraMascara(servidorUp.cpf)
    servidorUp.pis = RetiraMascara(servidorUp.pis)
    servidorUp.telefone1 = RetiraMascara(servidorUp.telefone1)
    servidorUp.telefone2 = RetiraMascara(servidorUp.telefone2)
    service
      .salvar(servidorUp)
      .then((response) => {
        mensagemSucesso("Servidor cadastrado com sucesso.");
        redirecionar.push("/servidor/listar");
      })
      .catch((e) => {
        mensagemErro(e.response.data);
        //console.log(e.response.data);
      });
  }
  function RetiraMascara(Obj) {
    return Obj.replace(/\D/g, '');
}

  function checarData(data) {
    let dataAno = new Date(data);
    if(data !== ""){
        if(dataAno.getFullYear().toString().length === 4){
            return true;
        }else{
            return false;
        }
    }
  }

  return (
    <div>
     
        <div className="container mt-4">
          <div className="form-group">
            <div className="card md-3">
              <h4 className="card-header">{servidorUp.id === null ? "Cadastrar" : "Editar"} Servidor</h4>

              <div className="card-body">
                {!isCheckedMatricula &&
                  <span style={{color: 'red'}}>{textoMatProvisoria}</span>
                }
                <Row>
                  <Col md={2}>
                    <label className="col-form-label" htmlFor="matricula" >
                      Matrícula:
                    </label>

                    <input
                      type="number"
                      className="form-control"
                      placeholder="Matrícula"
                      id="matricula"
                      name="matricula"
                      onChange={onChange}
                      /* disabled={servidorUp.id !== null ? true : false} */
                      value={servidorUp.matricula}
                    />
                  </Col>
                  <Col md={2}>
                      <label style={{fontWeight: 500}} className="col-form-label" htmlFor="matriculaDefiniva">Matrícula Definitiva?</label><br/>
                      <input type="checkbox"
                          style={{width: 70, height: 36}} 
                          id="matriculaDefiniva" 
                          name="matriculaDefiniva"
                          value={servidorUp.matriculaDefinitiva}
                          checked={isCheckedMatricula}
                          onChange={handleOnCheckedMatricula}
                      /> 

                    {['top'].map((placement) => (
                      <OverlayTrigger trigger="click"
                        key={placement}
                        placement={placement}
                        overlay={
                          <Popover id={`popover-positioned-${placement}`}>
                            <Popover.Header as="h3">Atenção</Popover.Header>
                            <Popover.Body>
                              <strong>Esse campo só deve ser marcado, se a matrícula inserida for a definitiva, fornecida pelo RH Geral.</strong>
                            </Popover.Body>
                          </Popover>
                        }
                      >

                      <i className="pi pi-question-circle" style={{fontSize: '2rem'}}>
                        
                      </i>
                      </OverlayTrigger>
                    ))}

                  </Col>
                  <Col md={8}>
                    <label className="col-form-label" htmlFor="nomeCompleto">
                      Nome Completo:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Nome Completo"
                      id="nomeCompleto"
                      name="nomeCompleto"
                      value={servidorUp.nomeCompleto}
                      onChange={onChange}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={11}>
                    <label className="col-form-label" htmlFor="cargo">
                      Cargo:
                    </label>
                    <Form.Select
                      //value={servidorUp.cargo}
                      id="cargo"
                      name="cargo"
                      onChange={obterIdCargo}
                    >
                      <option>{servidorUp.cargo.nome}</option>
                      {cargo.map((c) => (
                        <option value={c.id} key={c.id}>
                          {c.nome}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <label className="col-form-label" htmlFor="email">
                      E-mail:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Digite um email"
                      id="email"
                      name="email"
                      autoComplete="false"
                      value={servidorUp.email}
                      onChange={onChange}
                    />
                  </Col>
                  <Col md={3}>
                    <label className="col-form-label" htmlFor="rg">
                      RG/Orgão Emissor/UF:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Ex.: 0000000000 AAA UF"
                      id="rg"
                      name="rg"
                      autoComplete="false"
                      value={servidorUp.rg}
                      onChange={onChange}
                    />
                  </Col>
                  <Col md={2}>
                    <label className="col-form-label" htmlFor="cpf">
                      CPF:
                    </label>
                    <InputMask
                      mask="999.999.999-99"
                      type="text"
                      className="form-control"
                      placeholder="000.000.000-00"
                      id="cpf"
                      name="cpf"
                      value={servidorUp.cpf}
                      onChange={onChange}
                    />
                  </Col>
                  <Col md={2}>
                    <label className="col-form-label" htmlFor="pis">
                      PIS:
                    </label>
                    <InputMask
                      mask="999.99999.99-9"
                      type="text"
                      className="form-control"
                      placeholder="000.00000.00-0"
                      id="pis"
                      name="pis"
                      value={servidorUp.pis}
                      onChange={onChange}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={3}>
                    <label className="col-form-label" htmlFor="vinculo">
                      Vínculo:
                    </label>
                    <Form.Select
                      id="vinculo"
                      name="vinculo"
                      onChange={onChange}
                      value={servidorUp.vinculo}
                    >
                      <option>Selecione</option>
                      <option value="EFETIVO">Efetivo</option>
                      <option value="CONTRATADO">Contratado</option>
                      <option value="COMISSIONADO">Comissionado</option>
                      <option value="TERCEIRIZADO">Terceirizado</option>
                    </Form.Select>
                  </Col>
                  <Col md={2}>
                    <label className="col-form-label" htmlFor="cargaHoraria">
                      Carga Horária:
                    </label>
                    <Form.Select
                      id="cargaHoraria"
                      name="cargaHoraria"
                      onChange={onChange}
                      value={servidorUp.cargaHoraria}
                    >
                      <option>Selecione</option>
                      <option value="20">20 h/s</option>
                      <option value="30">30 h/s</option>
                      <option value="40">40 h/s</option>
                    </Form.Select>
                  </Col>

                  <Col md={2}>
                    <label className="col-form-label" htmlFor="situacao">
                      Situação:
                    </label>
                    <Form.Select
                      style={servidorUp.situacao === "INATIVO" ? {backgroundColor: "#fc0707"}: null}
                      id="situacao"
                      name="situacao"
                      onChange={onChange}
                      value={servidorUp.situacao}
                    >
                      <option>Selecione</option>
                      <option value="ATIVO">Ativo</option>
                      <option value="INATIVO" style={{backgroundColor: "#fc0707"}}>Inativo</option>
                    </Form.Select>
                  </Col>
                  <Col md={4}>
                    <label className="col-form-label" htmlFor="sexo">
                      Sexo:
                    </label>
                    <Form.Select
                      defaultValue="Selecione"
                      id="sexo"
                      name="sexo"
                      onChange={onChange}
                      value={servidorUp.sexo}
                    >
                      <option value="">Selecione</option>
                      <option value="M">Masculino</option>
                      <option value="F">Feminino</option>
                    </Form.Select>
                  </Col>
                  
                </Row>

                <Row>
                  <Col md={2}>
                    <label className="col-form-label" htmlFor="dataAdmissao">
                      Data de Admissão:
                    </label>
                    <input
                      id="dataAdmissao"
                      name="dataAdmissao"
                      type="date"
                      className="form-control"
                      value={servidorUp.dataAdmissao}
                      onChange={onChange}
                    />
                  </Col>

                  <Col md={2}>
                    <label className="col-form-label" htmlFor="dataNascimento">
                      Data de Nascimento:
                    </label>
                    <input
                      id="dataNascimento"
                      name="dataNascimento"
                      type="date"
                      className="form-control"
                      value={servidorUp.dataNascimento}
                      onChange={onChange}
                    />
                  </Col>
                </Row>

                <br />

                <Row>
                  <div className="form-group">
                    <div className="card md-12">
                      <h6 className="card-header">Telefones</h6>
                      <div className="card-body">
                        <Row>
                          <Col md={3}>
                            <label htmlFor="telefone1">Número 1:</label>
                            <InputMask
                              mask="(99) 9 9999-9999"
                              maskChar={null}
                              type="text"
                              className="form-control"
                              placeholder="(00) 0 0000-0000"
                              id="telefone1"
                              name="telefone1"
                              value={servidorUp.telefone1}
                              onChange={onChange}
                            />
                          </Col>
                          <Col md={5}>
                            <label htmlFor="descTel1">
                              Descrição do telefone 1:
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Digite uma descrição para o número."
                              id="descTel1"
                              name="descTel1"
                              value={servidorUp.descTel1}
                              onChange={onChange}
                            />
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col md={3}>
                            <label htmlFor="telefone2">Número 2:</label>
                            <InputMask
                              mask="(99) 9 9999-9999"
                              maskChar={null}
                              type="text"
                              className="form-control"
                              placeholder="(00) 0 0000-0000"
                              id="telefone2"
                              name="telefone2"
                              value={servidorUp.telefone2}
                              onChange={onChange}
                            />
                          </Col>
                          <Col md={5}>
                            <label htmlFor="descTel2">
                              Descrição do telefone 2:
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Digite uma descrição para o número."
                              id="descTel2"
                              name="descTel2"
                              value={servidorUp.descTel2}
                              onChange={onChange}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Row>

                <br />
                <Row>
                  <Col md={1}>
                  {servidorUp.id === null ? 
                    <button onClick={() => salvar()} className="btn btn-primary">
                      Cadastar
                    </button>
                   : 
                   <button onClick={() => atualizarServidor()} className="btn btn-primary">
                      Atualizar
                  </button>
                  }
                  </Col>
                  <Col md={2}>
                    <button type="button" className="btn btn-danger">
                      <Link
                        style={{ textDecoration: "none", color: "#ffffff" }}
                        to="/servidor/listar"
                      >
                        Cancelar
                      </Link>
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default DadosEdicaoServidor;

import ApiService from '../app/apiservice'


class LogTabelasService extends ApiService {

constructor(){
    super('/logtabelas')
}

buscarPorUnidade(id, dtI, dtF, pagina){
    return this.get(`/buscarporunidade/${id}/dataInicial/${dtI}/dataFinal/${dtF}/page/${pagina}`)
}
    
listarTodos(pagina){
    return this.get(`/pag/${pagina}`)
}

detalhesColaborador(origem, idColaborador){
    return this.get(`/detalhes/origem/${origem}/idColaborador/${idColaborador}`);
}

}

export default LogTabelasService;
import { useParams } from "react-router-dom";
import EdicaoDatas from "./EdicaoDatas";

const IdDatasEditar = () => {
  const { id } = useParams();

  return (
    <>
      <EdicaoDatas id={id ? Number.parseInt(id, 10) : null} />{" "}
    </>
  );
};

export default IdDatasEditar;

import ApiService from "../app/apiservice";

class FgsPagamentosService extends ApiService {

    constructor(){
        super('/fgspag')
    }

    carregarValores(){
        return this.get('/')
    }

    atualizarFgs(dados){
        return this.put('/atualizar', dados)
    }


}

export default FgsPagamentosService
import ApiService from "../app/apiservice";

class FaltasHorasCooperadoService extends ApiService {

    constructor(){
        super('/faltashorasacrescentar')
    }

    obterPorId(id){
        return this.get(`/${id}`)
    }
    
    salvar(faltaOuHorasAcrescentarCooperado){
        return this.post('/', faltaOuHorasAcrescentarCooperado)
    }
    
    listar() {
        return this.get('/')
    }
    
    deletar(id){
        return this.delete(`/${id}`)
    }
    
    atualizar(faltaOuHorasAcrescentarCooperado){
        return this.put(`/${faltaOuHorasAcrescentarCooperado.id}`, faltaOuHorasAcrescentarCooperado)
    }

    buscarFaltasEHorasPorLancamentoCooperado(id){
        return this.get(`/buscarporlanccooperado/${id}`)
    }
}

export default FaltasHorasCooperadoService
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row, Tab, Table, Tabs } from "react-bootstrap";
import { mensagemErro, mensagemSucesso } from "../../components/avisos/toastr";
import Card from "../../components/card";
import LocalStorageService from "../../service/localStorageService";
import ParametrosLancamentosService from "../../service/parametrosLancamentoService";
import ParametrosStatusLancamentoService from "../../service/parametrosStatusLancamentoService";
import UnidadeService from "../../service/unidadeService";
import { descriptografar } from "../../components/criptografar";

function ParametrosLancamentos() {

    const flagStatusLancamentoServidor = 'servidor';
    const flagStatusLancamentoCooperado = 'cooperado';
    const flagStatusLancamentoEstagiario = 'estagiario';
    const flagStatusLancamentoPrestador = 'prestador';
    const flagStatusLancamentoTerceirizadosDia = 'terceirizadoDia';
    const flagStatusLancamentoTerceirizadosHora = 'terceirizadoHora';

    const [flagStatus, setFlagStatus] = useState('');

    const parametrosLancamento = {
        id: null,
        mesLancamento: '',
        anoLancamento: '',
        diaAberturaServidoresEstagiarios: '',
        diaFechamentoServidoresEstagiarios: '',
        diaAberturaCooperados: '',
        diaFechamentoCooperados: '',
        diaAberturaPrestadores: '',
        diaFechamentoPrestadores: '',
        diaAberturaTerceirizadoDia: '',
        diaFechamentoTerceirizadoDia: '',
        diaAberturaTerceirizadoHora: '',
        diaFechamentoTerceirizadoHora: ''
    };

    const parametrosStatusLancamento = {
        id: null,
        unidade: null,
        statusLancamentoServidor: false,
        statusLancamentoEstagiario: false,
        statusLancamentoCooperado: false,
        statusLancamentoPrestador: false,
        statusLancamentoTerceirizado: false,
        statusLancamentoTerceirizadoHora: false
    }

    const [parametro, setParametro] = useState(parametrosLancamento);
    const [parametroStatus, setParametroStatus] = useState(parametrosStatusLancamento);
    const [listaMeses, setListaMeses] = useState([]);
    const [listaStatusLancamentos, setListaStatusLancamentos] = useState([]);
    const [listaUnidadesAvulsas, setListaUnidadesAvulsas] = useState([]);

    function onChangeParametro(e) {
        const {name, value} = e.target;
        setParametro({...parametro, [name]: value});
    }

    function obterIdUnidade(e) {
        const id = Number(e.target.value);
        parametroStatus.unidade = { id };
      }

    const dataCriptografada = LocalStorageService.obterItem('1')
    let dataAtual = new Date(descriptografar(dataCriptografada));

    const mes = dataAtual.getUTCMonth() + 1;
    const ano = dataAtual.getUTCFullYear();

    const [key, setKey] = useState(mes);

    //MODAIS INIT
    const [showCriarParametros, setShowCriarParametros] = useState(false);
    const handleCriarParametrosClose = () => setShowCriarParametros(false);
    const handleCriarParametrosShow = () => setShowCriarParametros(true);

    const [showConf, setShowConf] = useState(false);
    const handleConfclose = () => setShowConf(false);
    const handleConfShow = () => setShowConf(true);

    const [showConfAbrirTodos, setShowConfAbrirTodos] = useState(false);
    const handleConfAbrirTodosClose = () => setShowConfAbrirTodos(false);
    const handleConfAbrirTodosShow = () => setShowConfAbrirTodos(true);

    const [showConfFecharTodos, setShowConfFecharTodos] = useState(false);
    const handleConfFecharTodosClose = () => setShowConfFecharTodos(false);
    const handleConfFecharTodosShow = () => setShowConfFecharTodos(true);

    const [showConfNovaUnidade, setShowConfNovaUnidade] = useState(false);
    const handleConfNovaUnidadeClose = () => setShowConfNovaUnidade(false);
    const handleConfNovaUnidadeShow = () => setShowConfNovaUnidade(true);
    //MODAIS END

    const serviceParametros = new ParametrosLancamentosService();
    const serviceStatusLancamentos = new ParametrosStatusLancamentoService();
    const serviceUnidades = new UnidadeService();

    useEffect(() => {

        async function loadMeses() {
            const response = await serviceParametros.listarPorAno(ano);
            setListaMeses(response.data);
        }
        loadMeses();

        async function loadStatusLancamento() {
            const response = await serviceStatusLancamentos.listarTodosPorAnoMes(ano, key);
            setListaStatusLancamentos(response.data);
        }
        loadStatusLancamento();

    }, [showCriarParametros, showConf, showConfAbrirTodos, showConfFecharTodos, showConfNovaUnidade]);

    useEffect(() => {

        async function loadStatusLancamento() {
            const response = await serviceStatusLancamentos.listarTodosPorAnoMes(ano, key);
            setListaStatusLancamentos(response.data);
        }
        loadStatusLancamento();

    }, [key]);

    useEffect(() => {

        async function loadUnidadesAvulsas() {
            const response = await serviceUnidades.listarUnidadesAvulsas(ano, key);
            setListaUnidadesAvulsas(response.data);
        }
        loadUnidadesAvulsas();

    }, [showConfNovaUnidade]);

    const salvarParametros = () => {
        parametro['mesLancamento'] = mes;
        parametro['anoLancamento'] = ano;

        if(!parametro.diaAberturaServidoresEstagiarios || parametro.diaAberturaServidoresEstagiarios < 1){
            mensagemErro("Todos os campos são obrigatórios!");
        }else if(!parametro.diaFechamentoServidoresEstagiarios || parametro.diaFechamentoServidoresEstagiarios < 1){
            mensagemErro("Todos os campos são obrigatórios!");
        }else if(!parametro.diaAberturaCooperados || parametro.diaAberturaCooperados < 1){
            mensagemErro("Todos os campos são obrigatórios!");
        }else if(!parametro.diaFechamentoCooperados || parametro.diaFechamentoCooperados < 1){
            mensagemErro("Todos os campos são obrigatórios!");
        }else if(!parametro.diaAberturaPrestadores || parametro.diaAberturaPrestadores < 1){
            mensagemErro("Todos os campos são obrigatórios!");
        }else if(!parametro.diaFechamentoPrestadores || parametro.diaFechamentoPrestadores < 1){
            mensagemErro("Todos os campos são obrigatórios!");
        }else if(!parametro.diaFechamentoPrestadores || parametro.diaFechamentoPrestadores < 1){
            mensagemErro("Todos os campos são obrigatórios!");
        }else{
            serviceParametros.salvar(parametro)
                .then(() => {
                    mensagemSucesso("Parâmetros salvos com sucesso!");
                    handleCriarParametrosClose();
                })
        }
    }

    const atualizarParametros = () => {
        if(!parametro.diaAberturaServidoresEstagiarios || parametro.diaAberturaServidoresEstagiarios < 1){
            mensagemErro("Todos os campos são obrigatórios!");
        }else if(!parametro.diaFechamentoServidoresEstagiarios || parametro.diaFechamentoServidoresEstagiarios < 1){
            mensagemErro("Todos os campos são obrigatórios!");
        }else if(!parametro.diaAberturaCooperados || parametro.diaAberturaCooperados < 1){
            mensagemErro("Todos os campos são obrigatórios!");
        }else if(!parametro.diaFechamentoCooperados || parametro.diaFechamentoCooperados < 1){
            mensagemErro("Todos os campos são obrigatórios!");
        }else if(!parametro.diaAberturaPrestadores || parametro.diaAberturaPrestadores < 1){
            mensagemErro("Todos os campos são obrigatórios!");
        }else if(!parametro.diaFechamentoPrestadores || parametro.diaFechamentoPrestadores < 1){
            mensagemErro("Todos os campos são obrigatórios!");
        }else{
            serviceParametros.atualizar(parametro)
                .then(() => {
                    mensagemSucesso("Parâmetros atualizados com sucesso!");
                    handleCriarParametrosClose();
                })
        }
    }

    const abrirEditModal = (id) => {
        handleCriarParametrosShow();

        if(id){
            serviceParametros.obterPorId(id)
            .then((response) => {
                setParametro(response.data);
            })
        }
    }

    const formatarMes =(mes) => {
        return ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"][mes -1];
    }

    const alterarStatus = (id, statusFlag) => {
        handleConfShow();
       
        setFlagStatus(statusFlag);
        serviceStatusLancamentos.obterPorId(id)
        .then((response) => {
            setParametroStatus(response.data);
        }).catch((error) => {
            mensagemErro(error.data)
        })
    }

    function mudarStatus(){
        serviceStatusLancamentos.atualizar(parametroStatus, flagStatus)
        .then(() => {
            mensagemSucesso('Os status de lançamentos da unidade foram alterados.');
            handleConfclose();
        }).catch(() => {
            mensagemErro('Não foi possível atualizar os status.')
        })
    }

    const modalInserirNovaUnidade = () => {
        handleConfNovaUnidadeShow();
    }

    const inserirNovaUnidade = () => {

        if(parametroStatus.unidade && parametroStatus.unidade.id > 0){
            let idUnidade = parametroStatus.unidade.id
            serviceStatusLancamentos.inserirNovaUnidade(ano, key, idUnidade)
            .then(() => {
                mensagemSucesso('Unidade adicionada com sucesso.');
                handleConfNovaUnidadeClose();
            })
        }else{
            mensagemErro('Selecione uma unidade.')
        }
    }

    const modalAbrirTodosStatus = () => {
        handleConfAbrirTodosShow();
    }

    const abrirTodosStatus = (tipoLancamento) => {
        serviceStatusLancamentos.abrirTodosStatus(tipoLancamento, ano, key)
        .then(() => {
            mensagemSucesso('Os status de lançamentos da unidade foram alterados.');
            handleConfAbrirTodosClose();
        }).catch(() =>{
            mensagemErro('Não foi possível abrir os status.')
        })
    }

    const modalFecharTodosStatus = () => {
        handleConfFecharTodosShow();
    }

    const fecharTodosStatus = (tipoLancamento) => {
        serviceStatusLancamentos.fecharTodosStatus(tipoLancamento, ano, key)
        .then(() => {
            mensagemSucesso('Os status de lançamentos da unidade foram alterados.');
            handleConfFecharTodosClose();
        }).catch(() => {
            mensagemErro('Não foi possível fechar os status.')
        })
    }

    function nomeExibirDeAcordoComFlag(flag){
        if (flag === flagStatusLancamentoServidor) {
            return 'Servidor'
        }
        if (flag === flagStatusLancamentoCooperado) {
            return 'Cooperado'
        }
        if (flag === flagStatusLancamentoEstagiario) {
            return 'Estagiário'
        }
        if (flag === flagStatusLancamentoPrestador) {
            return 'Substituto Temporário'
        }
        if (flag === flagStatusLancamentoTerceirizadosDia) {
            return 'Terceirizado - Dia'
        }
        if (flag === flagStatusLancamentoTerceirizadosHora) {
            return 'Terceirizado - Hora'
        }
    }

    return(
        <>
            <Container>
                <Card title="Parâmetros para Abertura e Fechamento dos Lançamentos do Mês">

                    {listaMeses.length < 1 &&
                        <div className="text-center mt-4">
                            <button onClick={() => handleCriarParametrosShow()} className="btn btn-primary">Criar Parâmetros</button>
                        </div>
                    }

                    <Tabs style={{display: 'flex', justifyContent: 'center', marginTop: '15px'}} activeKey={key} onSelect={(k) => setKey(k)}>
                        {listaMeses.map((m) => {
                            return (
                                <Tab eventKey={m.mesLancamento} title={formatarMes(m.mesLancamento)} key={m.id}>
                                    <div className="text-center mt-4">
                                        <button type="button" className="btn btn-primary" onClick={() => abrirEditModal(m.id)}>Editar Parâmetros de {formatarMes(m.mesLancamento)}</button>
                                    </div>

                                    <Row className="justify-content-md-center mt-4" style={{ textAlign: "center" }}>
                                        <Col lg={4} md={12}>
                                            <Card title="Servidores e Estagiários">
                                                <h6>Abertura dos lançamentos:</h6>
                                                   <span style={{fontSize: '20px', fontWeight: "bold"}}>Dia {m.diaAberturaServidoresEstagiarios}</span> 
                                                <h6>Fechamento dos lançamentos:</h6>
                                                    <span style={{fontSize: '20px', fontWeight: "bold"}}>Dia {m.diaFechamentoServidoresEstagiarios}</span>
                                            </Card>
                                        </Col>

                                        <Col lg={3} md={12}>
                                            <Card title="Cooperados">
                                                <h6>Abertura dos lançamentos:</h6>
                                                   <span style={{fontSize: '20px', fontWeight: "bold"}}>Dia {m.diaAberturaCooperados}</span> 
                                                <h6>Fechamento dos lançamentos:</h6>
                                                    <span style={{fontSize: '20px', fontWeight: "bold"}}>Dia {m.diaFechamentoCooperados}</span>
                                            </Card>
                                        </Col>

                                        <Col lg={3} md={12}>
                                            <Card title="Prestadores">
                                                <h6>Abertura dos lançamentos:</h6>
                                                   <span style={{fontSize: '20px', fontWeight: "bold"}}>Dia {m.diaAberturaPrestadores}</span> 
                                                <h6>Fechamento dos lançamentos:</h6>
                                                    <span style={{fontSize: '20px', fontWeight: "bold"}}>Dia {m.diaFechamentoPrestadores}</span>
                                            </Card>
                                        </Col>
                                    </Row><br/>
                                    <Row className="justify-content-md-center" style={{ textAlign: "center" }}>
                                 {/*        <Col lg={3} md={12}>
                                            <Card title="Terceirizados - Dia">
                                                <h6>Abertura dos lançamentos:</h6>
                                                   <span style={{fontSize: '20px', fontWeight: "bold"}}>Dia {m.diaAberturaTerceirizadoDia}</span> 
                                                <h6>Fechamento dos lançamentos:</h6>
                                                    <span style={{fontSize: '20px', fontWeight: "bold"}}>Dia {m.diaFechamentoTerceirizadoDia}</span>
                                            </Card>
                                        </Col> */}

                                        <Col lg={3} md={12}>
                                            <Card title="Terceirizados">
                                                <h6>Abertura dos lançamentos:</h6>
                                                   <span style={{fontSize: '20px', fontWeight: "bold"}}>Dia {m.diaAberturaTerceirizadoHora}</span> 
                                                <h6>Fechamento dos lançamentos:</h6>
                                                    <span style={{fontSize: '20px', fontWeight: "bold"}}>Dia {m.diaFechamentoTerceirizadoHora}</span>
                                            </Card>
                                        </Col>

                                    </Row>
                                    <hr/>
                                    <Card title="Alteração de status de lançamentos por unidade">
                                        <div style={{float: 'right', marginRight: '90px', marginBottom: '20px'}} >

                                            <button 
                                                type="button" 
                                                style={{marginRight: '10px'}}
                                                className="btn btn-success"
                                                onClick={() => modalInserirNovaUnidade()}
                                                >
                                                Inserir Nova Unidade
                                            </button>

                                            <button 
                                                type="button" 
                                                style={{marginRight: '10px'}}
                                                className="btn btn-primary"
                                                onClick={() => modalAbrirTodosStatus()}
                                                >
                                                Abrir Todos
                                            </button>

                                            <button 
                                                type="button" 
                                                className="btn btn-danger"
                                                onClick={() => modalFecharTodosStatus()}
                                                >
                                                Fechar Todos
                                            </button>
                                        </div>

                                        <Table striped bordered hover size="sm" >
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Unidade</th>
                                                    <th className="col-1 text-center">Lançamentos Servidores</th>
                                                    <th className="col-1 text-center">Lançamentos Cooperados</th>
                                                    <th className="col-1 text-center">Lançamentos Estagiarios</th>
                                                    <th className="col-1 text-center">Lançamentos Substitutos</th>
                                                    {/* <th className="text-center" style={{width: '160px'}}>Lançamentos Terceirizados-Dia</th> */}
                                                    <th className="text-center" style={{width: '160px'}}>Lançamentos Terceirizados</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listaStatusLancamentos.map((sl) => {
                                                    return(
                                                        <tr key={sl.id}>
                                                            <td className="text-center">{sl.unidade.nome}</td>
                                                            <td className="text-center">
                                                                {sl.statusLancamentoServidor === true ?
                                                                    <button className="btn btn-danger" title="Fechar Lançamentos" onClick={() => alterarStatus(sl.id, flagStatusLancamentoServidor)}>
                                                                        <i className='pi pi-lock' style={{fontSize: "20px"}}></i>
                                                                    </button>
                                                                    :
                                                                    <button className="btn btn-primary" title="Abrir Lançamentos" onClick={() => alterarStatus(sl.id, flagStatusLancamentoServidor)}>
                                                                        <i className='pi pi-unlock' style={{fontSize: "20px"}}></i>
                                                                    </button>
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                               {sl.statusLancamentoCooperado === true ?
                                                                    <button className="btn btn-danger" title="Fechar Lançamentos" onClick={() => alterarStatus(sl.id, flagStatusLancamentoCooperado)}>
                                                                        <i className='pi pi-lock' style={{fontSize: "20px"}}></i>
                                                                    </button>
                                                                    :
                                                                    <button className="btn btn-primary" title="Abrir Lançamentos" onClick={() => alterarStatus(sl.id, flagStatusLancamentoCooperado)}>
                                                                        <i className='pi pi-unlock' style={{fontSize: "20px"}}></i>
                                                                    </button>
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                                {sl.statusLancamentoEstagiario === true ?
                                                                    <button className="btn btn-danger" title="Fechar Lançamentos" onClick={() => alterarStatus(sl.id, flagStatusLancamentoEstagiario)}>
                                                                        <i className='pi pi-lock' style={{fontSize: "20px"}}></i>
                                                                    </button>
                                                                    :
                                                                    <button className="btn btn-primary" title="Abrir Lançamentos" onClick={() => alterarStatus(sl.id, flagStatusLancamentoEstagiario)}>
                                                                        <i className='pi pi-unlock' style={{fontSize: "20px"}}></i>
                                                                    </button>
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                                {sl.statusLancamentoPrestador === true ?
                                                                    <button className="btn btn-danger" title="Fechar Lançamentos" onClick={() => alterarStatus(sl.id, flagStatusLancamentoPrestador)}>
                                                                        <i className='pi pi-lock' style={{fontSize: "20px"}}></i>
                                                                    </button>
                                                                    :
                                                                    <button className="btn btn-primary" title="Abrir Lançamentos" onClick={() => alterarStatus(sl.id, flagStatusLancamentoPrestador)}>
                                                                        <i className='pi pi-unlock' style={{fontSize: "20px"}}></i>
                                                                    </button>
                                                                }
                                                            </td>
                                                            {/* <td className="text-center">
                                                                {sl.statusLancamentoTerceirizado === true ?
                                                                    <button className="btn btn-danger" title="Fechar Lançamentos" onClick={() => alterarStatus(sl.id, flagStatusLancamentoTercirizadosDia)}>
                                                                        <i className='pi pi-lock' style={{fontSize: "20px"}}></i>
                                                                    </button>
                                                                    :
                                                                    <button className="btn btn-primary" title="Abrir Lançamentos" onClick={() => alterarStatus(sl.id, flagStatusLancamentoTercirizadosDia)}>
                                                                        <i className='pi pi-unlock' style={{fontSize: "20px"}}></i>
                                                                    </button>
                                                                }
                                                            </td> */}
                                                            <td className="text-center">
                                                                {sl.statusLancamentoTerceirizadoHora === true ?
                                                                    <button className="btn btn-danger" title="Fechar Lançamentos" onClick={() => alterarStatus(sl.id, flagStatusLancamentoTerceirizadosHora)}>
                                                                        <i className='pi pi-lock' style={{fontSize: "20px"}}></i>
                                                                    </button>
                                                                    :
                                                                    <button className="btn btn-primary" title="Abrir Lançamentos" onClick={() => alterarStatus(sl.id, flagStatusLancamentoTerceirizadosHora)}>
                                                                        <i className='pi pi-unlock' style={{fontSize: "20px"}}></i>
                                                                    </button>
                                                                }
                                                            </td>                                                                                                                            
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </Card>
                                </Tab>
                            )
                        })}
                    </Tabs>
                </Card>

                <Modal show={showCriarParametros} onHide={handleCriarParametrosClose} backdrop="static" keyboard={false} size="lg">
                    <Modal.Body>
                        <Container>
                            <Card title={parametro.id > 0 ? `Edição de Parâmetros - ${formatarMes(parametro.mesLancamento)}` : "Lançamento de Parâmetros" }>
                                <Form>
                                    <Row className="justify-content-md-center">
                                        <Col lg={5} md={12} className="mt-4">
                                            <h4>Servidores / Estagiários:</h4>
                                        </Col>
                                        <Col lg={3} md={6}>
                                            <label className="col-form-label" htmlFor="diaAberturaServidoresEstagiarios">Dia de Abertura:</label>
                                            <input type="number" 
                                                className="form-control" 
                                                min={1} max={31}
                                                id="diaAberturaServidoresEstagiarios" 
                                                name="diaAberturaServidoresEstagiarios" 
                                                value={parametro.diaAberturaServidoresEstagiarios}
                                                onChange={onChangeParametro}
                                            />
                                        </Col>
                                        <Col lg={3} md={6}>
                                            <label className="col-form-label" htmlFor="diaFechamentoServidoresEstagiarios">Dia de Fechamento:</label>
                                            <input type="number" 
                                                className="form-control" 
                                                min={1} max={31}
                                                id="diaFechamentoServidoresEstagiarios" 
                                                name="diaFechamentoServidoresEstagiarios" 
                                                value={parametro.diaFechamentoServidoresEstagiarios}
                                                onChange={onChangeParametro}
                                            />
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <Row className="justify-content-md-center">
                                        <Col lg={5} md={12} className="mt-4">
                                            <h4>Cooperados:</h4>
                                        </Col>
                                        <Col lg={3} md={6}>
                                            <label className="col-form-label" htmlFor="diaAberturaCooperados">Dia de Abertura:</label>
                                            <input type="number" 
                                                className="form-control" 
                                                min={1} max={31}
                                                id="diaAberturaCooperados" 
                                                name="diaAberturaCooperados" 
                                                value={parametro.diaAberturaCooperados}
                                                onChange={onChangeParametro}
                                            />
                                        </Col>
                                        <Col lg={3} md={6}>
                                            <label className="col-form-label" htmlFor="diaFechamentoCooperados">Dia de Fechamento:</label>
                                            <input type="number" 
                                                className="form-control" 
                                                min={1} max={31}
                                                id="diaFechamentoCooperados" 
                                                name="diaFechamentoCooperados" 
                                                value={parametro.diaFechamentoCooperados}
                                                onChange={onChangeParametro}
                                            />
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <Row className="justify-content-md-center">
                                        <Col lg={5} md={12} className="mt-4">
                                            <h4>Prestadores:</h4>
                                        </Col>
                                        <Col lg={3} md={6}>
                                            <label className="col-form-label" htmlFor="diaAberturaPrestadores">Dia de Abertura:</label>
                                            <input type="number" 
                                                className="form-control" 
                                                min={1} max={31}
                                                id="diaAberturaPrestadores" 
                                                name="diaAberturaPrestadores"
                                                value={parametro.diaAberturaPrestadores}
                                                onChange={onChangeParametro} 
                                            />
                                        </Col>
                                        <Col lg={3} md={6}>
                                            <label className="col-form-label" htmlFor="diaFechamentoPrestadores">Dia de Fechamento:</label>
                                            <input type="number" 
                                                className="form-control" 
                                                min={1} max={31}
                                                id="diaFechamentoPrestadores" 
                                                name="diaFechamentoPrestadores" 
                                                value={parametro.diaFechamentoPrestadores}
                                                onChange={onChangeParametro}
                                            />
                                        </Col>
                                    </Row>
                                    <hr/>
                                    {/* <Row className="justify-content-md-center">
                                        <Col lg={5} md={12} className="mt-4">
                                            <h4>Terceirizados - Dia:</h4>
                                        </Col>
                                        <Col lg={3} md={6}>
                                            <label className="col-form-label" htmlFor="diaAberturaTerceirizadoDia">Dia de Abertura:</label>
                                            <input type="number" 
                                                className="form-control" 
                                                min={1} max={31}
                                                id="diaAberturaTerceirizadoDia" 
                                                name="diaAberturaTerceirizadoDia"
                                                value={parametro.diaAberturaTerceirizadoDia}
                                                onChange={onChangeParametro} 
                                            />
                                        </Col>
                                        <Col lg={3} md={6}>
                                            <label className="col-form-label" htmlFor="diaFechamentoTerceirizadoDia">Dia de Fechamento:</label>
                                            <input type="number" 
                                                className="form-control" 
                                                min={1} max={31}
                                                id="diaFechamentoTerceirizadoDia" 
                                                name="diaFechamentoTerceirizadoDia" 
                                                value={parametro.diaFechamentoTerceirizadoDia}
                                                onChange={onChangeParametro}
                                            />
                                        </Col>
                                    </Row> 
                                    <hr/>*/}
                                    <Row className="justify-content-md-center">
                                        <Col lg={5} md={12} className="mt-4">
                                            <h4>Terceirizados:</h4>
                                        </Col>
                                        <Col lg={3} md={6}>
                                            <label className="col-form-label" htmlFor="diaAberturaTerceirizadoHora">Dia de Abertura:</label>
                                            <input type="number" 
                                                className="form-control" 
                                                min={1} max={31}
                                                id="diaAberturaTerceirizadoHora" 
                                                name="diaAberturaTerceirizadoHora"
                                                value={parametro.diaAberturaTerceirizadoHora}
                                                onChange={onChangeParametro} 
                                            />
                                        </Col>
                                        <Col lg={3} md={6}>
                                            <label className="col-form-label" htmlFor="diaFechamentoTerceirizadoHora">Dia de Fechamento:</label>
                                            <input type="number" 
                                                className="form-control" 
                                                min={1} max={31}
                                                id="diaFechamentoTerceirizadoHora" 
                                                name="diaFechamentoTerceirizadoHora" 
                                                value={parametro.diaFechamentoTerceirizadoHora}
                                                onChange={onChangeParametro}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="text-center mt-4">
                                        {parametro.id > 0 ?
                                            <button 
                                                type="button" 
                                                onClick={atualizarParametros}
                                                className="btn btn-primary"> Atualizar 
                                            </button> 
                                        :
                                            <button 
                                                type="button" 
                                                onClick={salvarParametros}
                                                className="btn btn-primary"> Salvar 
                                            </button> 
                                        }
                                    </div>
                                </Form>
                            </Card>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-secondary" onClick={handleCriarParametrosClose}>
                            Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showConf} onHide={handleConfclose} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <h3>Confirmação</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Você deseja alterar o status de todos os lançamentos de {nomeExibirDeAcordoComFlag(flagStatus)} dessa unidade?</h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={mudarStatus}>
                            Confirmar
                        </Button>
                        <Button className="btn btn-secondary" onClick={handleConfclose}>
                            Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showConfNovaUnidade} onHide={handleConfNovaUnidadeClose} backdrop="static" keyboard={false} size="lg">
                    <Modal.Body>
                        <Card title="Inserir unidade">
                            <Form>
                                <label className="col-form-label" htmlFor="unidade">Unidade:</label>
                                <Form.Select
                                    defaultValue="Selecione"
                                    id="unidade"
                                    name="nome"
                                    onChange={obterIdUnidade}
                                >
                                    <option value={0}>Selecione a unidade</option>
                                    {listaUnidadesAvulsas.map((u) => (
                                        <option value={u.id} key={u.id}>{u.nome}</option>
                                    ))}
                                </Form.Select>

                                <br/>
                                <div className="text-center mt-2">
                                    <button 
                                        type="button" 
                                        onClick={inserirNovaUnidade} 
                                        className="btn btn-primary"> Inserir 
                                    </button> 
                                </div>
                            </Form>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-secondary" onClick={handleConfNovaUnidadeClose}>
                            Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showConfAbrirTodos} onHide={handleConfAbrirTodosClose} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <h4>Selecione o tipo de lançamento que deseja abrir.</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <Table>
                            <tbody>
                                <tr>
                                    <td><h5>Servidores</h5></td>
                                    <td><button style={{marginBottom: "5px"}} className="btn btn-primary" onClick={() => abrirTodosStatus(flagStatusLancamentoServidor)}>Abrir</button></td>
                                </tr>
                                <tr>
                                    <td><h5>Cooperados</h5></td>
                                    <td><button style={{marginBottom: "5px"}} className="btn btn-primary" onClick={() => abrirTodosStatus(flagStatusLancamentoCooperado)}>Abrir</button></td>
                                </tr>

                                <tr>
                                    <td><h5>Estagiários</h5></td>
                                    <td><button style={{marginBottom: "5px"}} className="btn btn-primary" onClick={() => abrirTodosStatus(flagStatusLancamentoEstagiario)}>Abrir</button></td>
                                </tr>
                                <tr>
                                    <td><h5>Prestadores</h5></td>
                                    <td><button style={{marginBottom: "5px"}} className="btn btn-primary" onClick={() => abrirTodosStatus(flagStatusLancamentoPrestador)}>Abrir</button></td>
                                </tr>
                                <tr>
                                    <td><h5>Terceirizados Horas</h5></td>
                                    <td><button style={{marginBottom: "5px"}} className="btn btn-primary" onClick={() => abrirTodosStatus(flagStatusLancamentoTerceirizadosHora)}>Abrir</button></td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-secondary" onClick={handleConfAbrirTodosClose}>
                            Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showConfFecharTodos} onHide={handleConfFecharTodosClose} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <h4>Selecione o tipo de lançamento que deseja fechar.</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <Table>
                            <tbody>
                                <tr>
                                    <td><h5>Servidores</h5></td>
                                    <td><button style={{marginBottom: "5px"}} className="btn btn-danger" onClick={() => fecharTodosStatus(flagStatusLancamentoServidor)}>Fechar</button></td>
                                </tr>
                                <tr>
                                    <td><h5>Cooperados</h5></td>
                                    <td><button style={{marginBottom: "5px"}} className="btn btn-danger" onClick={() => fecharTodosStatus(flagStatusLancamentoCooperado)}>Fechar</button></td>
                                </tr>

                                <tr>
                                    <td><h5>Estagiários</h5></td>
                                    <td><button style={{marginBottom: "5px"}} className="btn btn-danger" onClick={() => fecharTodosStatus(flagStatusLancamentoEstagiario)}>Fechar</button></td>
                                </tr>
                                <tr>
                                    <td><h5>Prestadores</h5></td>
                                    <td><button style={{marginBottom: "5px"}} className="btn btn-danger" onClick={() => fecharTodosStatus(flagStatusLancamentoPrestador)}>Fechar</button></td>
                                </tr>
                                <tr>
                                    <td><h5>Terceirizados Horas</h5></td>
                                    <td><button style={{marginBottom: "5px"}} className="btn btn-danger" onClick={() => fecharTodosStatus(flagStatusLancamentoTerceirizadosHora)}>Fechar</button></td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-secondary" onClick={handleConfFecharTodosClose}>
                            Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </>
    )
}

export default ParametrosLancamentos
import React from "react";
import { useParams } from "react-router-dom";

import DadosEdicaoServidor from "./DadosEdicao";


const EditarServidor = ( ) => {
  const { id } = useParams();

  return (
      
      <DadosEdicaoServidor id={id ? Number.parseInt(id, 10) : null} />
  );

  
}

export default EditarServidor;

import { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import anoService from "../../service/anoService";
import numAlunosService from "../../service/numAlunosService";
import Modal from "react-bootstrap/Modal";
import { mensagemErro, mensagemSucesso } from "../../components/avisos/toastr";
import UnidadeService from "../../service/unidadeService";


const serviceAno = new anoService();
const serviceNA = new numAlunosService();
const serviceU = new UnidadeService()

function PeriodoNumAlunos() {
  const [anoListar, setAnoListar] = useState([]);
  const [unidadesListar, setUnidadesListar] = useState([]);

  let dataAtual = new Date();
  const [anoCarregar, setAnoCarregar] = useState(dataAtual.getFullYear());

  //Modal de alterações
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [listaUnidadeAno, setListaUnidadeAno] = useState([]);
  const [nomeUnidade, setNomeUnidade] = useState("");
  const [mesAbrir, setMesAbrir] = useState(null);
  const [idUnidUp, setIdUnidUp] = useState(null);
  const [mudarBotao, setMudarBotao] = useState(0);

  //Modal adicionar nova unidade
  const [showAdicionarUnidade, setShowAdicionarUnidade] = useState(false);
  const handleCloseAdicionarUnidade = () => setShowAdicionarUnidade(false);
  const handleShowAdicionarUnidade = () => setShowAdicionarUnidade(true);
  const [idUnidadeAdicionar, setIdUnidadeAdicionar] = useState(null)
  const [listaTodasUnidades, setListaTodasUnidades] = useState([])

  //Modal de Abrir/Fechar todos
  const [showAbrirTodos, setShowAbrirTodos] = useState(false);
  const handleCloseAbrirTodos = () => setShowAbrirTodos(false);
  const handleShowAbrirTodos = () => setShowAbrirTodos(true);
  const [ano, setAno] = useState("");
  const [mes, setMes] = useState("");
  const [mudarTextoBotao, setMudarTextoBotao] = useState(null);

  useEffect(() => {
    async function CarregarAnos() {
      const anos = await serviceAno.listar();
      setAnoListar(anos.data);
    }

    async function CarregarUnidade() {
      const unidades = await serviceNA.listaUnidade(anoCarregar);
      setUnidadesListar(unidades.data);
    }

    CarregarUnidade();
    CarregarAnos();
    //console.log(anoCarregar)
    //console.log(unidadesListar)
  }, [anoCarregar, show, showAbrirTodos, showAdicionarUnidade, unidadesListar.length]);

  function abrirTodos() {
    serviceNA
      .liberarTodos(ano, mes)
      .then((s) => {
        mensagemSucesso(s.data);
        handleCloseAbrirTodos();
      })
      .catch((e) => {
        mensagemErro(e.response.data);
      });
  }

  function fecharTodos() {
    serviceNA
      .fecharTodos(ano, mes)
      .then((s) => {
        mensagemSucesso(s.data);
        handleCloseAbrirTodos();
      })
      .catch((e) => {
        mensagemErro(e.response.data);
      });
  }

  function mudarBotaoTextoModal() {
    //console.log(mudarTextoBotao);
    handleShowAbrirTodos();
  }

  function selecionaMes(numMes) {
    if (numMes === null || numMes === "") {
      return false;
    } else {
      //console.log(idUnidUp)
      //console.log(anoCarregar)
      //console.log(numMes)
      serviceNA
        .verificarAbertaOuFechada(idUnidUp, anoCarregar, numMes)
        .then((e) => {
          setMudarBotao(e.data);
          //console.log(e.data)
          //console.log(mudarBotao)
        });
    }
  }

  function listarUnidade(idUnidade, ano, descUnidade) {
    setNomeUnidade(descUnidade);
    setIdUnidUp(idUnidade);
    //console.log(idUnidade)
    //console.log(ano)
    serviceNA.listarHistoricoDaUnidade(idUnidade, ano).then((s) => {
      setListaUnidadeAno(s.data);
      //console.log(listaUnidadeAno)
    });
    handleShow();
  }

  function liberarAtualizacao() {
    //console.log(mesAbrir)
    //console.log(idUnidUp)
    //console.log(anoCarregar)
    serviceNA
      .liberarPorUnidade(idUnidUp, anoCarregar, mesAbrir)
      .then((s) => {
        mensagemSucesso(s.data);
        //console.log(s.data)
        setMudarBotao(0);
        handleClose();
      })
      .catch((e) => {
        //console.log(e.data)
        mensagemErro(e.data);
      });
  }

  function carregarTodasUnidades(){
    handleShowAdicionarUnidade()
    serviceU.listar()
    .then((s) => {
      setListaTodasUnidades(s.data)
    })
  }

  function adicionarNovaUnidadeNoAnoLetivo(){
    serviceNA.adicionarNovaUnidade(anoCarregar, Number.parseInt(idUnidadeAdicionar))
    .then((s) => {
      mensagemSucesso(s.data)
      handleCloseAdicionarUnidade()
    })
    .catch((e) => {
      mensagemErro(e.response.data)
    })

  }


  return (
    <>
      <div className="container mt-6">
        <div className="form-group">
          <div className="card md-3">
            <h4 className="card-header">
              Abertura e Fechamento de Número de Alunos
            </h4>

            <Container>
              <>
                <br />
                
                <Row className="justify-content-md-center">
                  
                  <Col md="2">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() =>
                        mudarBotaoTextoModal(setMudarTextoBotao(1))
                      }
                    >
                      Abrir Todos
                    </button>
                  </Col>
                  <Col md="2">
                    <button
                      className="btn btn-danger"
                      type="button"
                      onClick={() =>
                        mudarBotaoTextoModal(setMudarTextoBotao(0))
                      }
                    >
                      Fechar Todos
                    </button>
                  </Col>
                  <Col md="2">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => carregarTodasUnidades()}
                    >
                      Adicionar Unidade
                    </button>
                  </Col>
                </Row>
                <br />
                
                <Row className="justify-content-md-center">
                <Col xs lg="2">
                
                    <Form.Select
                      value={anoCarregar}
                      onChange={(a) => setAnoCarregar(a.target.value)}
                    >
                      {anoListar.map((l) => (
                        <option
                          style={{ textAlign: "center" }}
                          key={l.id}
                          value={l.ano}
                        >
                          {l.ano}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
                <br />
                <h5 style={{ textAlign: "center" }}>Lista de Unidade</h5>
                <Row
                  style={{ textAlign: "center" }}
                  className="justify-content-md-center"
                >
                  <Col xs lg="8">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Unidade
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Ano
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Ação
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {unidadesListar.map((d, index) => (
                          <tr key={d.id}>
                            <td
                              style={{ maxWidth: "300px", textAlign: "center" }}
                            >
                              {d[0]}
                            </td>

                            <td style={{ textAlign: "center" }}>{d[1]}</td>

                            <td style={{ textAlign: "center" }}>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => listarUnidade(d[2], d[1], d[0])}
                              >
                                Editar Liberação
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </>
            </Container>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Abrir/Fechar atualização de nº de alunos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <label className="col-form-label">
              ATENÇÃO: O mês da lista que estiver em vermelho, está com período
              ABERTO para modificação.
            </label>
          </Row>
          <br />
          <Row className="justify-content-md-center">
            <Col xs lg="4" style={{ textAlign: "end" }}>
              <br/>
              <label className="col-form-label">{nomeUnidade}</label>
            </Col>
            <Col xs lg="2">
              
            <label style={{ textAlign: "center" }} className="col-form-label">Mês:</label>
              <Form.Select
                style={{ textAlign: "center" }}
                onChange={(e) => setMesAbrir(e.target.value)}
                onClick={(c) => selecionaMes(c.target.value)}
              >
                <option value=""></option>
                {listaUnidadeAno.sort((a, b) => Number.parseInt(a.mesLancamento) > Number.parseInt(b.mesLancamento) ? 1:-1).map((l) =>
                  l.statusInformacao ? (
                    <option style={{ backgroundColor: "red" }} key={l.id}>
                      {l.mesLancamento}
                    </option>
                  ) : (
                    <option key={l.id}>{l.mesLancamento}</option>
                  )
                )}
              </Form.Select>
            </Col>
            <Col style={{ paddingTop: "5%" }} Col xs lg="3">
              {mudarBotao === 1 ? (
                <button
                  className="btn btn-danger"
                  type="button"
                  onClick={() => liberarAtualizacao()}
                >
                  Trancar Alteração
                </button>
              ) : mudarBotao === 2 ? (
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => liberarAtualizacao()}
                >
                  Liberar Alteração
                </button>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            type="button"
            onClick={handleClose}
          >
            Cancelar
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAbrirTodos}
        onHide={handleCloseAbrirTodos}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          {mudarTextoBotao === 1 ? (
            <Modal.Title>
              Liberar atualização para todas as unidades
            </Modal.Title>
          ) : mudarTextoBotao === 0 ? (
            <Modal.Title>Fechar atualização para todas as unidades</Modal.Title>
          ) : (
            ""
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <label style={{ textAlign: "center" }} className="col-form-label">
              {mudarTextoBotao === 1
                ? "Selecione o Mês e o Ano para Liberação"
                : mudarTextoBotao === 0
                ? "Selecione o Mês e o Ano para Fechamento"
                : ""}
            </label>
          </Row>
          <br />
          <Row className="justify-content-md-center">
            <Col xs lg="2" style={{ textAlign: "end" }}>
              <label className="col-form-label">Ano:</label>
            </Col>
            <Col xs lg="2">
              <Form.Select
                style={{ textAlign: "center" }}
                onChange={(e) => setAno(e.target.value)}
              >
                <option value=""></option>
                {anoListar.map((l) => (
                  <option
                    style={{ textAlign: "center" }}
                    key={l.id}
                    value={l.ano}
                  >
                    {l.ano}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col xs lg="1" style={{ textAlign: "end" }}>
              <label className="col-form-label">Mês:</label>
            </Col>
            <Col xs lg="2">
              <Form.Select
                style={{ textAlign: "left" }}
                onChange={(e) => setMes(e.target.value)}
              >
                <option value=""></option>
                <option value="1">Janeiro</option>
                <option value="2">Fevereiro</option>
                <option value="3">Março</option>
                <option value="4">Abril</option>
                <option value="5">Maio</option>
                <option value="6">Junho</option>
                <option value="7">Julho</option>
                <option value="8">Agosto</option>
                <option value="9">Setembro</option>
                <option value="10">Outubro</option>
                <option value="11">Novembro</option>
                <option value="12">Dezembro</option>
              </Form.Select>
            </Col>
            <Col Col xs lg="2">
              {mudarTextoBotao === 1 ? (
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => abrirTodos()}
                >
                  Liberar
                </button>
              ) : mudarTextoBotao === 0 ? (
                <button
                  className="btn btn-danger"
                  type="button"
                  onClick={() => fecharTodos()}
                >
                  Trancar
                </button>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            type="button"
            onClick={handleCloseAbrirTodos}
          >
            Cancelar
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAdicionarUnidade}
        onHide={handleCloseAdicionarUnidade}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Adicionar Unidade ao ano letivo: {anoCarregar}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-md-center">
            <Col xs lg="4" style={{ textAlign: "end" }}>
              <label className="col-form-label">Selecione a Unidade:</label>
            </Col>
            <Col xs lg="7">
              <Form.Select
                onChange={(e) => setIdUnidadeAdicionar(e.target.value)}
              >
                <option value="">Selecione</option>
                {listaTodasUnidades.map((l) =>
                   (
                    <option key={l.id} value={l.id}>
                      {l.nome}
                    </option>
                   )
                )}
              </Form.Select>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            type="button"
            onClick={(e) => adicionarNovaUnidadeNoAnoLetivo()}
          >
            Adicionar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PeriodoNumAlunos;

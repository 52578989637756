import ApiService from '../app/apiservice'


class LancamentoPrestadorService extends ApiService {

    constructor(){
        super('/lancamentoprestador')
    }

    
    salvar(dados){
        return this.post('/salvar', dados)
    }

    listarTodos(pagina) {
        return this.get(pagina)
    }

    listarPorUnidAnoMes(idUnid, ano, mes){
        return this.get(`/unidade/${idUnid}/ano/${ano}/mes/${mes}`)
    }

    deletar(id){
        return this.delete(`/del/${id}`)
    }

    atualizar(id, dados){
        return this.put(`/atualizar/${id}`, dados)
    }

    buscarPorId(id){
        return this.get(`/${id}`)
    }

   /*  replicarLancamentos(idUnidade, ano, mes){
        return this.get(`/replicarlancamentos/unidade/${idUnidade}/ano/${ano}/mes/${mes}`)
    }

    countLancamentosMesSeguinte(idUnidade, ano, mesSeguinte){
        return this.get(`/countlancamentosmesseguinte/unidade/${idUnidade}/ano/${ano}/mes/${mesSeguinte}`)
    } */

    countLancamentosMesAtual(idUnidade, ano, mesSeguinte){
        return this.get(`/countlancamentosmesatual/unidade/${idUnidade}/ano/${ano}/mes/${mesSeguinte}`)
    }

    buscarHistoricoPrestador(id){
        return this.get(`/historico/${id}`)
    }

    liberarEdicaoLancamento(ano, mes, unidade, prestador){
        return this.get(`/liberaredicao/${ano}/${mes}/${unidade}/${prestador}`)
    }

}

export default LancamentoPrestadorService;
import LocalStorageService from "./localStorageService";
import jwt from 'jsonwebtoken';
import ApiService from "../app/apiservice";
import { cp } from "../components/criptografar";

const DATA_LOGIN = '1'
const USUARIO = '2'
const UNIDADE_ATUAL = '3'
const TOKEN_ACCESS = '4'

const CryptoJS = require('crypto-js')


export default class AuthenticatorService {


        static criptografar (texto) {
            let encriptar = CryptoJS.AES.encrypt(texto, cp).toString()
            return encriptar
        }

        static descriptografar (texto) {
            let bytes = CryptoJS.AES.decrypt(texto, cp)
            let descript = bytes.toString(CryptoJS.enc.Utf8)
            return descript
        }
    

    static isUsuarioAutenticado(){
        const tokenCriptografado = LocalStorageService.obterItem(TOKEN_ACCESS)
        const token = tokenCriptografado ? AuthenticatorService.descriptografar(tokenCriptografado) : null
        if(!token){
            return false;
        }
        const decodedToken = jwt.decode(token) 
        const expiration = decodedToken.exp
        const isTokenInvalido = Date.now() >= (expiration * 1000)

        return !isTokenInvalido;
    }

    static removerUsuarioAutenticado(){
        LocalStorageService.removerItem(USUARIO);
        LocalStorageService.removerItem(TOKEN_ACCESS);
        LocalStorageService.removerItem(DATA_LOGIN);
        LocalStorageService.removerItem(UNIDADE_ATUAL);
    }

    static logar(usuario, token){
        let dateSelected = new Date();
        LocalStorageService.adicionarItem(TOKEN_ACCESS, AuthenticatorService.criptografar(token))
        LocalStorageService.adicionarItem(DATA_LOGIN, AuthenticatorService.criptografar(dateSelected.toUTCString()))
        LocalStorageService.adicionarItem(USUARIO, AuthenticatorService.criptografar(JSON.stringify(usuario)))
        ApiService.registrarToken(token)
    }

    static refreshSession() {
        const usuarioCriptografado = LocalStorageService.obterItem(USUARIO)
        const usuarioLogado = JSON.parse(AuthenticatorService.descriptografar(usuarioCriptografado))
        const tokenCriptografado = LocalStorageService.obterItem(TOKEN_ACCESS)
        const token = tokenCriptografado ? AuthenticatorService.descriptografar(tokenCriptografado) : null
        AuthenticatorService.logar(usuarioLogado, token)
        return usuarioLogado
    }
}
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AnoMes from "../../components/mesAno/anoMes";
import ReactPaginate from "react-paginate";
import { Row, Col, Container, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import PrestadorService from "../../service/prestadorService";
import LancamentoPrestadorService from "../../service/lancamentoPrestadorService";
import Modal from "react-bootstrap/Modal";
import {mensagemAlerta, mensagemErro, mensagemSucesso, } from "../../components/avisos/toastr";
import styled from "styled-components";
import calculosRecibosService from "../../service/calculosRecibosService";
import LocalStorageService from "../../service/localStorageService";
import ParametrosStatusLancamentoService from "../../service/parametrosStatusLancamentoService";
import LoadingPage from "../landing/LandingPage";
import { descriptografar } from "../../components/criptografar";
import assinaturaQuadrosService from "../../service/assinaturaQuadrosService";

const LabelNomeModal = styled.label`
  color: red;
`;
const dadosParaVinculo = {
  prestadorServicos: [],
  unidade: [],
  mesLancamento: "",
  anoLancamento: "",
};

const dadosCalcRec = {
  prestadorServico: {},
  totalAulas: 0,
  valorBruto: 0,
  inss: 0,
  irrf: 0,
  valorLiquido: 0,
  valorLiquidoPorExtenso: "reais",
  mesLancamento: "",
  anoLancamento: "",
  faixaIrrf: "0%",
  faixaInss: "0%",
};

function LancamentoPrestadores() {

  const dataCriptografada = LocalStorageService.obterItem('1')
  let dataAtual = new Date(descriptografar(dataCriptografada));
  const unidadeCriptografado = LocalStorageService.obterItem('3')
  let idUnidade = descriptografar(unidadeCriptografado)

  const [mes, setMes] = useState(dataAtual.getMonth() + 1);
  const [ano, setAno] = useState(dataAtual.getFullYear());
  const [lancamento, setLancamento] = useState([]);
  const [atualizarLista, setAtualizarLista] = useState(false);
  const [loading, setLoading] = useState(false);
  const [botaoVincularStatus, setBotaoVincularStatus] = useState();

  //Dados para edição do Lançamento
  const [idLancamento, setIdLancamento] = useState(null);

  //modal vincular prestador
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [prestadores, setPrestadores] = useState([]);
  const [totalPrestadores, setTotalPrestadores] = useState(0);

  //modal deletar
  const [showModalDeletar, setModalDeletar] = useState(false);
  const handleCloseDeletar = () => setModalDeletar(false);
  const handleShowDeletar = () => setModalDeletar(true);
  const [nomeExcluirLanc, setNomeExcluirLanc] = useState();

  //paginação
  const [totalPaginas, setTotalPaginas] = useState([]);
  const [paginaAtual, setPaginaAtual] = useState(0);
  const [nomeBuscar, setNomeBuscar] = useState("");
  const [numeroDePaginas, setNumeroDePaginas] = useState(0);
  const [totalPrestadoresPorPagina, setTotalPrestadoresPorPagina] = useState(0);
  const pesquisou = () => setPaginaAtual(0);
  function handlePageClick(data) {
    setPaginaAtual(data.selected);
  }

  const redirecionar = useHistory();

  const serviceCR = new calculosRecibosService();
  const serviceLP = new LancamentoPrestadorService();
  const service = new PrestadorService();
  const servicePSL = new ParametrosStatusLancamentoService()
  const serviceAssinaturaDigital = new assinaturaQuadrosService()
  //const serviceDatasLancamento = new datasSubPrestadorService();

  useEffect(() => {
    async function botaoVincular(){
      const r = await servicePSL.verificarParametroStatusLancamento(idUnidade, ano, mes, 'lancamentoPrestador')
      setBotaoVincularStatus(r.data)
    }
    botaoVincular()
    //console.log(botaoVincularStatus)
  },[mes, ano])

  useEffect(() => {
    
    async function carregarLancamentosPrestadores() {
      const resposta = await serviceLP.listarPorUnidAnoMes(idUnidade, ano, mes);
      setLancamento(resposta.data);
    }

    
    //const service = new PrestadorService();
    async function carregarPrestadorBusca() {
      const response = await service.buscarPorNome(
        `/pornome/${nomeBuscar}/page/${paginaAtual}`
      );

      //setTotalPaginas(response.totalPages)
      setTotalPrestadores(response.data.totalElements);
      setPrestadores(response.data.content);

      setTotalPrestadoresPorPagina(response.data.size);
      setNumeroDePaginas(response.data.totalPages);

      const paginas = response.data.totalPages;

      const arrayPaginas = [];
      //cria um array de paginas na quantidade vinda do backend
      for (let p = 1; p <= paginas; p++) {
        arrayPaginas.push(p);
      }
      setTotalPaginas(arrayPaginas);
    }

    async function carregarPrestadores() {
      const response = await service.listarTodos(`/pag/${paginaAtual}`);
      setTotalPrestadores(response.data.totalElements);
      setPrestadores(response.data.content);
      setTotalPrestadoresPorPagina(response.data.size);
      setNumeroDePaginas(response.data.totalPages);

      const paginas = response.data.totalPages;

      const arrayPaginas = [];
      //cria um array de paginas na quantidade vinda do backend
      for (let p = 1; p <= paginas; p++) {
        arrayPaginas.push(p);
      }

      setTotalPaginas(arrayPaginas);
    }

    carregarLancamentosPrestadores();
 

    if (nomeBuscar === "") {
      carregarPrestadores();
    } else {
      carregarPrestadorBusca();
    }
    //console.log(botaoVincular);
    
  }, [
    mes,
    ano,
    nomeBuscar,
    idLancamento,
    dadosParaVinculo,
    show,
    showModalDeletar,
    atualizarLista,
    loading,
    lancamento.length,
  ]);

  /* //ESCONDER O BOTÃO DE REPLICAR
  useEffect(() => {
    //debugger;

    const date = Date.now();
    const today = new Date(date);

    //Mês Atual
    let mesAtual = (today.getMonth() + 1);
    let mesAtualFormatado = parseInt(mesAtual);
    console.log("Mês atual é: " + mesAtualFormatado);

    //Mês Informado
    let mesFormatado = parseInt(mes)
    let mesSeguinte = mesFormatado + 1;

    //fazer um count dos lancamentos do mes seguinte, se tiver, ocultar o botão "Replicar"
    async function buscarLancamentosMesSeguinte(){
        const response = await serviceLP.countLancamentosMesSeguinte(idUnidade, ano, mesSeguinte);
        setCountLancamentosMesSeguinte(response.data);
    }
    buscarLancamentosMesSeguinte();

    if(mesAtual === mesFormatado){
        setMostrarBtnReplicar(true);
    }else{
        setMostrarBtnReplicar(false);
    }

    console.log(mostrarBtnReplicar);

}, [countLancamentosMesSeguinte, mes, showConfReplica]);

  const replicar = () => {
    service.replicarLancamentos(idUnidade, ano, mes)
    .then(() => {
        mensagemSucesso("A replicação foi um sucesso!");
        handleConfReplicaClose();
    })
  } */

  function mudarstatus() {
    if (atualizarLista) {
      setAtualizarLista(false);
    } else if (!atualizarLista) {
      setAtualizarLista(true);
    }
  }

  function vincularPrestador(id) {
    setLoading(true);
    dadosParaVinculo.prestadorServicos = { id };
    dadosParaVinculo.unidade = { id: idUnidade };
    dadosParaVinculo.mesLancamento = mes;
    dadosParaVinculo.anoLancamento = ano;
    //console.log(dadosParaVinculo);
    serviceLP.salvar(dadosParaVinculo)
    .then((e) => {
      dadosCalcRec.mesLancamento = mes.toString();
      dadosCalcRec.anoLancamento = ano.toString();
      dadosCalcRec.prestadorServico = { id };
      serviceCR.salvar(dadosCalcRec);   
        setLoading(false);
        setTimeout(function(){
          window.location.reload()
        },10)
        mensagemSucesso(e.data);
      mudarstatus();
      handleClose();
    })
    .catch((e) => {
      mensagemErro(e.response.data)
    })
    setLoading(false);
    mudarstatus();
    handleClose();
  }

  function nomeModalExcluir(id) {
    serviceAssinaturaDigital.verificarSeAssinado(Number(idUnidade), ano, mes, "TEMPORARIO")
    .then((s) => {
        if (s.data > 0) {
            mensagemErro("O Quadro de Substituto Temporário está ASSINADO, para prosseguir com alterações nos Lançamentos de Substitutos Temporários deve apagar a assinatura.")
        }
        if (s.data === 0) {
          serviceLP.buscarPorId(id).then((response) => {

            setNomeExcluirLanc(response.data);
          });
          handleShowDeletar()
        }
    })
  }

  function handleClickExcluir() {
    //console.log("id excluir: " + nomeExcluirLanc.id)
    serviceLP
      .deletar(nomeExcluirLanc.id)
      .then((response) => {
        mensagemSucesso(response.data);
        mudarstatus();
        handleCloseDeletar();
      })
      .catch((error) => {
        mensagemErro(error.response.data);
      });
  }

  function cadastrarNovo() {
    redirecionar.push("/prestador/cad");
  }

  function fecharCadastrar() {
    handleClose();
    cadastrarNovo();
  }

  function editarLancamento(idEditar){
    redirecionar.push(`/prestador/edlanc/${idEditar}`)
  }

  return (
    <>
      <Container>
        <Row className="justify-content-md-center">
          <Col xs lg="4">
            <AnoMes
              htmlForMes="mesLancamento"
              idMes="mesLancamento"
              nameMes="mesLancamento"
              valueMes={mes}
              onChangeMes={(e) => setMes(e.target.value)}
              htmlForAno="anoLancamento"
              idAno="anoLancamento"
              nameAno="anoLancamento"
              valueAno={ano}
              onChangeAno={(e) => setAno(e.target.value)}
            />
          </Col>

          <Col sm={1} style={{ marginTop: "37px" }}>
            {botaoVincularStatus && 
              <button
                className="btn btn-primary"
                type="button"
                onClick={handleShow}
                title="vincular Substituto Temporário no Mês e Ano selecionado"
              >
                Vincular
              </button>
            }
          </Col>
        </Row>
      </Container>

      <hr />

      <div className="container mt-6">
        <div className="form-group">
          <div className="card md-3">
            <h4 className="card-header">Lançamentos Substitutos Temporários</h4>

            <table className="table table-hover">
              <thead>
                <tr>
                  <th>##</th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    Substituto Temporário
                  </th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    Mês/Ano
                  </th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    Ações
                  </th>
                </tr>
              </thead>
              {loading && <div>Carregando...</div>}
              {!loading && (
                <tbody>
                  {lancamento.map((l, index) => {
                    return (
                      <tr key={l.id}>
                        <td>{index + 1}</td>
                        <td style={{ maxWidth: "300px", textAlign: "center" }}>
                          {l.prestadorServicos.nome}
                        </td>
                        <td
                          style={{ textAlign: "center" }}
                        >{`${l.mesLancamento}/${l.anoLancamento}`}</td>
                        <td style={{ textAlign: "center" }}>
                          {l.statusLancamento.toString() === "false" ? (
                            <button
                              className="btn btn-primary"
                              disabled
                              style={{
                                width: "70px",
                                height: "30px",
                                padding: "1px",
                              }}
                              title="Editar e/ou Adicionar Lançamentos"
                            >             
                                Editar
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary"
                              style={{
                                width: "70px",
                                height: "30px",
                                padding: "1px",
                              }}
                              title="Editar e/ou Adicionar Lançamentos"
                              onClick={() => editarLancamento(l.id)}
                            >                             
                                Editar
                            </button>
                          )}
                          {l.statusLancamento.toString() === "false" ? (
                            <button
                              style={{ height: "30px", paddingTop: "0px" }}
                              className="btn btn-danger"
                              disabled
                              type="button"
                              onClick={(e) => nomeModalExcluir(l.id)}
                              title="AVISO: Só será excluido se não conter nenhum lançamento"
                            >
                              Excluir
                            </button>
                          ) : (
                            <button
                              style={{ height: "30px", paddingTop: "0px" }}
                              className="btn btn-danger"
                              type="button"
                              onClick={(e) => nomeModalExcluir(l.id)}
                              title="AVISO: Só será excluido se não conter nenhum lançamento"
                            >
                              Excluir
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>

            <div className="card-body"></div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        fullscreen="xxl-down"
      >
        <Modal.Title id="lancamentoModal" className="card-header">
          Substitutos Temporários
        </Modal.Title>

        <Modal.Body>
          <div>
            <Row>
              <Col md={8}>
                <label htmlFor="nomeBuscar">Nome:</label>
                <input
                  id="nomeBuscar"
                  name="nomeBuscar"
                  type="text"
                  className="form-control"
                  placeholder="Digite o nome para pesquisar..."
                  value={nomeBuscar}
                  onChange={(buscar) => setNomeBuscar(buscar.target.value)}
                />
              </Col>

              <Col md={2} style={{ paddingTop: "22px" }}>
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={fecharCadastrar}
                >
                  Cadastrar
                </button>
              </Col>
              <Col md={2} style={{ paddingTop: "22px" }}>
                <button className="btn btn-danger" onClick={handleClose}>
                  Fechar
                </button>
              </Col>
            </Row>
          </div>
          <div>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col" style={{ maxWidth: "300px" }}>
                    Nome
                  </th>
                  <th scope="col">CPF</th>

                  <th scope="col">Formação ou Área de Atuação</th>
                  <th scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {prestadores.map((p) => {
                  return (
                    <tr key={p.id}>
                      <td>{p.nome}</td>
                      <td>{p.cpf}</td>

                      <td>{p.formacao}</td>
                      <td>
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={(e) => vincularPrestador(p.id)}
                        >
                          Vincular
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <ReactPaginate
              previousLabel={"Voltar"}
              nextLabel={"Avançar"}
              breakLabel={"..."}
              pageCount={numeroDePaginas}
              marginPagesDisplayed={3}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              initialPage={pesquisou}
            />
          </div>
          <div className="mt-4">
            <div className="text-center"></div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          Pesquise o substituto e vincule a sua unidade para informar os
          lançamentos.
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModalDeletar}
        onHide={handleCloseDeletar}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Excluir Lançamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>
            Tem certeza que deseja excluir o Substituto Temporário: <br />
            <LabelNomeModal>
              {!nomeExcluirLanc ? null : nomeExcluirLanc.prestadorServicos.nome}
            </LabelNomeModal>{" "}
            do lancamento referente ao mês{" "}
            <LabelNomeModal>
              {!nomeExcluirLanc ? null : nomeExcluirLanc.mesLancamento}/
              {!nomeExcluirLanc ? null : nomeExcluirLanc.anoLancamento}{" "}
            </LabelNomeModal>{" "}
          </h6>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            type="button"
            onClick={handleCloseDeletar}
          >
            Não
          </button>
          <button
            onClick={() => handleClickExcluir()}
            className="btn btn-danger"
            type="button"
          >
            Sim
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LancamentoPrestadores;

import React from "react";
import { useParams } from "react-router-dom";

import DadosEdicao from "../usuario/DadosEdicao";

const Editar = () => {
  const { id } = useParams();

  return <DadosEdicao id={id ? Number.parseInt(id, 10) : null} />;
};

export default Editar;

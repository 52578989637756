import { useEffect, useState } from "react"
import { Button, Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { mensagemErro, mensagemSucesso } from "../../components/avisos/toastr";
import InputMask from "react-input-mask";
import Card from "../../components/card";
import TerceirizadoService from "../../service/terceirizadoService";

import styles from '../unidade/listarUnidade.module.css'
import LancamentoTerceirizadoPorHoraService from "../../service/lancamentoTerceirizadoPorHoraService";


function ListarTerceirizados() {

    const values = {
        id: null,
        nome: '',
        cpf: '',
        pix: '',
        telefone: '',
        banco: '',
        agencia: '',
        conta: ''
    }

    const [terceirizado, setTerceirizado] = useState(values);

    function onChange(e) {
        const { name, value } = e.target;
        setTerceirizado({...terceirizado, [name]: value })
    }

    const [showModalHistoricoTerceirizado, setShowModalHistoricoTerceirizado] = useState(false);
    const handleCloseHistoricoTerceirizado = () => setShowModalHistoricoTerceirizado(false);
    const handleShowHistoricoTerceirizado = () => setShowModalHistoricoTerceirizado(true);

    const [nomeExibicao, setNomeExibicao] = useState();
    const [lancamentosTerceirizado, setLancamentosTerceirizado] = useState([]);


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showConf, setShowConf] = useState(false);
    const handleConfclose = () => setShowConf(false);
    const handleConfShow = () => setShowConf(true);

    const [terceirizados, setTerceirizados] = useState([]);
    const [imputId, setImputId] = useState();

    const [paginaAtual, setPaginaAtual] = useState(0);
    const [nomeBuscar, setNomeBuscar] = useState("");
    const [numeroDePaginas, setNumeroDePaginas] = useState(0);

    const pesquisou = () => setPaginaAtual(0);

    const service = new TerceirizadoService();
    const serviceLancamentoTerceirizado = new LancamentoTerceirizadoPorHoraService();

    useEffect(() => {

        async function loadTerceirizadosBusca() {
            const response = await service.listarTodos(`/terceirizadopornome/${nomeBuscar}/page/${paginaAtual}`);
            setTerceirizados(response.data.content);
            setNumeroDePaginas(response.data.totalPages);

            const arrayPaginas = [];
            //cria um array de paginas na quantidade vinda do backend
            for (let p = 1; p <= numeroDePaginas; p++) {
              arrayPaginas.push(p);
            }
        }

        async function loadTerceirizados() {
            const response = await service.listarTodos(`/pag/${paginaAtual}`);
            setTerceirizados(response.data.content);
            setNumeroDePaginas(response.data.totalPages);

            const arrayPaginas = [];
            //cria um array de paginas na quantidade vinda do backend
            for (let p = 1; p <= numeroDePaginas; p++) {
              arrayPaginas.push(p);
            }
        }

        if (nomeBuscar === "") {
            loadTerceirizados();
        } else {
            loadTerceirizadosBusca();
        }

    }, [paginaAtual, nomeBuscar, show, showConf]);

    function handlePageClick(data) {
        setPaginaAtual(data.selected);
    }

    const abrirEditModal = (id) => {
        handleShow()

        if(id){
            service.obterPorId(id)
            .then((response) => {
               setTerceirizado(response.data)
            })
        }
    }

    const cadastrar = () => {
        if(!terceirizado.nome || terceirizado.nome.trim() === ''){
            mensagemErro("O campo nome é obrigatório.");
            return;
        }

         if(!terceirizado.cpf || terceirizado.cpf === ''){
            mensagemErro("O campo CPF é obrigatório.");
            return;
        }

         if(!terceirizado.cpf || terceirizado.pix.trim() === ''){
            mensagemErro("O campo pix é obrigatório.");
            return;
        }

        if(!terceirizado.cpf || terceirizado.telefone === ''){
            mensagemErro("O campo telefone é obrigatório.");
            return;
        }

        if(!terceirizado.cpf || terceirizado.banco.trim() === ''){
            mensagemErro("O campo dados bancários é obrigatório.");
            return;
        }

        if(!terceirizado.agencia || terceirizado.agencia.trim() === ''){
            mensagemErro("O campo agência é obrigatório.");
            return;
        }

        if(!terceirizado.conta || terceirizado.conta.trim() === ''){
            mensagemErro("O campo consta é obrigatório.");
            return;
        }

        terceirizado.cpf = RetiraMascara(terceirizado.cpf)
        terceirizado.telefone = RetiraMascara(terceirizado.telefone)
        service.salvar(terceirizado)
            .then(() => {
                handleClose();
                mensagemSucesso("Terceirizado cadastrado com sucesso!")
                limparDados();
            }).catch((error) => {
                mensagemErro(error.response.data.mensagem)
            })
    }

    const atualizar = () => {
        if(!terceirizado.nome || terceirizado.nome.trim() === ''){
            mensagemErro("O campo nome é obrigatório.");
            return;
        }

         if(!terceirizado.cpf || terceirizado.cpf.trim() === ''){
            mensagemErro("O campo CPF é obrigatório.");
            return;
        }

        if(!terceirizado.pix || terceirizado.pix.trim() === ''){
            mensagemErro("O campo PIX é obrigatório.");
            return;
        }

        if(!terceirizado.telefone || terceirizado.telefone === ''){
            mensagemErro("O campo telefone é obrigatório.");
            return;
        }

        if(!terceirizado.banco || terceirizado.banco.trim() === ''){
            mensagemErro("O campo banco é obrigatório.");
            return;
        }

        if(!terceirizado.agencia || terceirizado.agencia.trim() === ''){
            mensagemErro("O campo agência é obrigatório.");
            return;
        }

        if(!terceirizado.conta || terceirizado.conta.trim() === ''){
            mensagemErro("O campo conta é obrigatório.");
            return;
        }

        terceirizado.cpf = RetiraMascara(terceirizado.cpf)
        terceirizado.telefone = RetiraMascara(terceirizado.telefone)
        service.atualizar(terceirizado)
          .then(() => {
              handleClose();
            mensagemSucesso('Terceirizado atualizado com sucesso.')
            limparDados();
          
          }).catch((e) => {
            mensagemErro(e.response.data)
          })
    }

    function buscarHistoricoTerceirizado(id, nome){
        setNomeExibicao(nome);
        serviceLancamentoTerceirizado.buscarHistoricoTerceirizado(id).then((response) => {
            setLancamentosTerceirizado(response.data);
        
        })
    }

    /* const deletar = (id) => {
        service.delete(id)
            .then(() => {
                mensagemSucesso('Terceirizado removido com sucesso.')
                handleConfclose()
            }).catch(() => {
                mensagemErro("Não foi possível remover o registro.")
            })
    } */

    const limparDados = () => {
        setTerceirizado({
            nome: '',
            cpf: '',
            pix: '',
            telefone: '',
            banco: '',
            agencia: '',
            conta: ''
        })

        handleClose();
    }

    function RetiraMascara(Obj) {
        return Obj.replace(/\D/g, '');
      }

    return (
        <Container className="mt-4">
            <Card title="Listagem de Terceirizados">
                <div className={styles.divBusca}>
                    <div className="input-group "> 
                        <div >
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Pesquisar pelo nome"
                                aria-label="Recipient's username"
                                aria-describedby="button-addon2"
                                value={nomeBuscar}
                                onChange={(buscar) => setNomeBuscar(buscar.target.value)}
                            />
                        </div>
                    </div>
                    <div className={styles.btnAdd}>
                        <button onClick={handleShow} type="button" className="btn btn-primary">Adicionar</button>
                    </div>
                </div>

                <Table striped bordered hover size="md">
                    <thead>
                        <tr>
                            <th>##</th>
                            <th>Nome</th>
                            <th>CPF</th>
                            <th>Pix</th>
                            <th>Telefone</th>
                            <th>Banco</th>
                            <th>Ag/Conta</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {terceirizados.map((t, index) =>{
                            return(
                                <tr key={t.id}>
                                    <td>{index+1}</td>
                                    <td className='col-md-4'>{t.nome}</td>
                                    <td>{t.cpf}</td>
                                    <td>{t.pix}</td>
                                    <td>{t.telefone}</td>
                                    <td>{t.banco}</td>
                                    <td>{t.agencia} {t.agencia ? '/' : ''} {t.conta}</td>
                                    <td style={{textAlign: 'center', padding: '3px'}}>
                                        <button className="btn btn-primary" title='Editar' onClick={() => abrirEditModal(t.id)} style={{marginRight: '5px'}}>
                                            <i className='pi pi-pencil'></i>
                                        </button>
                                        <button onClick={() => handleShowHistoricoTerceirizado(buscarHistoricoTerceirizado(t.id, t.nome))} className="btn btn-success" type="button" title="Histórico do Terceirizado">
                                            <i className='pi pi-history'></i>
                                        </button>
                                       {/*  <button onClick={() => handleConfShow(setImputId(`/${t.id}`))} className="btn btn-danger" title='Excluir'>
                                            <i className='pi pi-trash'></i>
                                        </button> */}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                {terceirizados.length > 0 &&
                    <ReactPaginate
                        previousLabel={"Voltar"}
                        nextLabel={"Avançar"}
                        breakLabel={"..."}
                        pageCount={numeroDePaginas}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                        initialPage={pesquisou}
                    />
                }
            </Card> 

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Body>
                    <Container>
                        <Card title="Cadastro de Terceirizados">
                            <Form>
                                <Row>
                                    <Col>
                                        <label className="col-form-label" htmlFor="nome">Nome do Terceirizado:</label>
                                        <input type="text" 
                                            className="form-control" 
                                            placeholder="Informe o nome"
                                            value={terceirizado.nome} 
                                            onChange={onChange}
                                            id="nome" 
                                            name="nome"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="col-form-label" htmlFor="cpf">CPF do Terceirizado:</label>
                                        <InputMask
                                            mask="999.999.999-99" 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Informe o CPF"
                                            value={terceirizado.cpf} 
                                            onChange={onChange}
                                            id="cpf" 
                                            name="cpf"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="col-form-label" htmlFor="pix">Pix do Terceirizado:</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Informe o PIX"
                                            value={terceirizado.pix} 
                                            onChange={onChange}
                                            id="pix" 
                                            name="pix"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="col-form-label" htmlFor="telefone">Telefone do Terceirizado:</label>
                                        <InputMask
                                            mask="(99) 9 9999-9999" 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="(00) 0 0000-0000"
                                            value={terceirizado.telefone} 
                                            onChange={onChange}
                                            id="telefone" 
                                            name="telefone"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="col-form-label" htmlFor="banco">Banco:</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Informe banco"
                                            value={terceirizado.banco} 
                                            onChange={onChange}
                                            id="banco" 
                                            name="banco"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="col-form-label" htmlFor="agencia">Agência:</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Informe a agência"
                                            value={terceirizado.agencia} 
                                            onChange={onChange}
                                            id="agencia" 
                                            name="agencia"
                                        />
                                    </Col>
                                    <Col>
                                        <label className="col-form-label" htmlFor="conta">Conta:</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Informe a conta"
                                            value={terceirizado.conta} 
                                            onChange={onChange}
                                            id="conta" 
                                            name="conta"
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </Card>

                        <div className="mt-4">
                            <div className="text-center">
                                {terceirizado.id > 0 ?
                                    <button onClick={atualizar} className="btn btn-primary">Atualizar</button> 
                                    :
                                    <button onClick={cadastrar} className="btn btn-primary">Cadastrar</button> 
                                }
                            </div>
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-secondary" onClick={limparDados}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* <Modal show={showConf} onHide={handleConfclose} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <h3>Confirmação</h3>
                </Modal.Header>
                <Modal.Body>
                    <h5>Você realmente deseja excluir o registro?</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-danger" onClick={() => deletar(imputId)}>
                        Confirmar
                    </Button>
                    <Button className="btn btn-secondary" onClick={handleConfclose}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal> */}

            <Modal show={showModalHistoricoTerceirizado} onHide={handleCloseHistoricoTerceirizado} backdrop="static" keyboard={false} size="lg">
                <Modal.Body>
                    <Card title="Histórico do Terceirizado">
                        <h3 style={{color: 'gray'}}>{nomeExibicao}</h3>
                        <Table id="tabela_lancamentos_faltas" striped bordered hover size="md">
                            <thead>
                                <tr>
                                    <th>Ano</th>
                                    <th>Mês</th>
                                    <th>Unidade</th>
                                    <th>Turno</th>
                                </tr>
                            </thead>
                            <tbody>
                                {lancamentosTerceirizado.map((lt) => {
                                    return (
                                        <tr key={lt.id}>
                                            <td>{lt.anoLancamento}</td>
                                            <td>{lt.mesLancamento}</td>
                                            <td>{lt.unidade.nome}</td>
                                            <td>{lt.turno}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-secondary" onClick={handleCloseHistoricoTerceirizado}>
                        Fechar
                    </Button>
                </Modal.Footer>
		    </Modal>
        </Container> 
    )
}

export default ListarTerceirizados
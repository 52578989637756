import ApiService from "../app/apiservice";

class TerceirizadoService extends ApiService {
    constructor() {
        super('/terceirizados')
    }

    buscarPorNome(nome){
        return this.get(nome)
    }
    
    listarTodos(pagina){
        return this.get(pagina)
    }
    
    obterPorId(id){
        return this.get(`/${id}`)
    }
    
    salvar(terceirizado){
        return this.post('/', terceirizado)
    }
    
    listar() {
        return this.get('/')
    }
    
    deletar(id){
        return this.delete(`/${id}`)
    }
    
    atualizar(terceirizado){
        return this.put(`/${terceirizado.id}`, terceirizado)
    }
}

export default TerceirizadoService;
import React from "react";
import { withRouter } from "react-router-dom";
import UsuarioService from "../../service/usuarioService";
import { mensagemErro } from '../../components/avisos/toastr'
import { AuthContext } from "../../provedorAutenticacao";
import Card from "../../components/card";
import FormGroup from '../../components/form-group'
//import LocalStorageService from "../../service/localStorageService";


class Login extends React.Component {

    state = {
        login: '',
        senha: '',
    }

    constructor(){
        super();
        this.service = new UsuarioService()
    }

    entrar = () => {
        if(!this.state.login || this.state.login.trim() === ""){
            mensagemErro("Informe o login.")
            return;
        }
        if(!this.state.senha || this.state.senha.trim() === ""){
            mensagemErro("Informe a senha.")
            return;
        }
        this.service.autenticar({
            login: this.state.login,
            senha: this.state.senha
        }).then((response) => {
           this.context.iniciarSessao(response.data)       
            //Seta o mês de lançamento como data atual.
            //let dateSelected = new Date();
            //LocalStorageService.adicionarItem('data_lancamento', dateSelected.toUTCString())           
            this.props.history.push('/ini')
           
        }).catch( error => {
            if (error.response.data.status === '') {
                    mensagemErro('Código: ' + error.response.data.status + ' - ' + error.response.data);
                console.log(error.response.data)
            } else {
                mensagemErro(error.response.data);
            }
        });
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-6" style={{margin: 'auto'}}>
                    <Card title="RH Semec - Entrar">
                        <div className="row">
                            <span>{mensagemErro}</span>
                        </div>
                        <div className="row" >
                            <FormGroup label="Login: *" htmlFor="login">
                                <input type="text" 
                                    className="form-control" 
                                    id="login" 
                                    placeholder="Digite o login"
                                    value={this.state.login}
                                    onChange={e => this.setState({login: e.target.value})} />
                            </FormGroup>
                        </div>
                        <br />
                        <div className="row" >
                            <FormGroup label="Senha: *" htmlFor="senha">
                                <input type="password" 
                                    className="form-control" 
                                    id="senha" 
                                    placeholder="Digite a senha"
                                    value={this.state.senha}
                                    onChange={e => this.setState({senha: e.target.value})} />
                            </FormGroup>
                                        
                        </div>
                        <div className="mt-4" style={{textAlign: 'center'}}>
                            <button onClick={this.entrar} type="button" className="btn btn-primary">Logar</button>
                        </div>
                    </Card>
                </div>
            </div>
        );
    }
}

Login.contextType = AuthContext

export default withRouter(Login);

import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { AuthConsumer }from "../../provedorAutenticacao";
import { useHistory } from "react-router-dom";
import LocalStorageService from "../../service/localStorageService";
import posLoginService from "../../service/posLoginService";
import UsuarioService from "../../service/usuarioService";
import { criptografar, descriptografar } from "../../components/criptografar";


const usuario = {
    nomeCompleto: '',
    matricula: 0,
    unidade: [],
    email: '',
    telefone: '',
    tipoUsuario: '',
    login: ''
}

const serviceUsuario = new UsuarioService()
const servicePosLogin = new posLoginService()
function PosLogin(props){

    const usuarioCriptografado = LocalStorageService.obterItem('2')
    const usuarioLogado = JSON.parse(descriptografar(usuarioCriptografado))
    const [unidadesVinculadas, setUnidadesVinculadas] = useState([])
    const [usuarioUnidade, setUsuarioUnidade] = useState(usuario)
    let redirecionar = useHistory()

    
    useEffect(() => {
        serviceUsuario.buscarPorId(usuarioLogado.id)
        .then((s) => {
            setUsuarioUnidade(s.data)
            servicePosLogin.listarUnidades(Number(s.data.matricula))
            .then((s) => {
                setUnidadesVinculadas(s.data)
            })
        })
        
    },[])
    
    function atualizarUnidade(idUnidade){
        removerUnidade()
        usuarioUnidade.unidade = {id: idUnidade}
        serviceUsuario.atualizar(usuarioUnidade)
        .then((s) => {
            LocalStorageService.adicionarItem('3', criptografar(JSON.stringify(s.data.unidade.id)))
            redirecionar.push('/home') 
            window.location.reload()
        })
    }
    
    function removerUnidade(){
        LocalStorageService.removerItem('3')
    }
    
    const userAuth = props.isUsuarioAutenticado;

    return(
        <>
            {userAuth &&
                <div className="container mt-4">
                    <div className="form-group">
                        <div className="card md-3">
                                <h4 className="card-header">Unidades Vinculadas</h4>
                            <div className="card-body">                    
                                    <h5 style={{textAlign: "center"}}>Selecione a Unidade para prosseguir com o acesso</h5>
                                        <br/>
                                    <Row style={{ textAlign: "center" }} className="justify-content-md-center">
                                        <Col xs lg="11">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style={{ textAlign: "center" }}> Unidade </th>
                                                        <th scope="col" style={{ textAlign: "center" }}> Ações </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {unidadesVinculadas.sort((a, b) => a.unidade.nome > b.unidade.nome ? 1:-1).map((d) => (
                                                        <tr key={d.id}>
                                                            <td style={{ maxWidth: "300px", textAlign: "center" }}> {d.unidade.nome} </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                <button
                                                                    className="btn btn-primary"
                                                                    type="button"
                                                                    title="Acessar Unidade."
                                                                    onClick={() => atualizarUnidade(d.unidade.id)}>
                                                                    <i className='pi pi-sign-in'></i>
                                                                </button>
                                                            </td>  
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>
                                    <div style={{ textAlign: "end" }}> <button className="btn btn-danger" onClick={props.deslogar} >Sair</button> </div>
                            </div>
                        </div>
                    </div>
                </div>
            }     
        </>
    )
}

export default () => (
    <AuthConsumer>
        { (context) => (<PosLogin isUsuarioAutenticado={context.isAutenticado} deslogar={context.encerrarSessao}/>) }
    </AuthConsumer>
)
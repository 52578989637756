import ApiService from "../app/apiservice"
import ErroValidacao from "../app/ErroValidacao";

class EstagiarioService extends ApiService {

    constructor(){
        super('/estagiarios')
    }

    buscarPorNome(nome){
        return this.get(nome)
    }

    buscarPorNomeAtivo(nome, pagina){
        return this.get(`/estagiariopornomeativos/${nome}/page/${pagina}`)
    }
    
    listarTodos(pagina){
        return this.get(pagina)
    }

    listarTodosAtivos(pagina){
        return this.get(`/pag/ativos/${pagina}`)
    }

    listarSomenteAtivosNaoPaginado(){
        return this.get('/ativoslista')
    }

    obterPorId(id){
        return this.get(`/${id}`)
    }
    
    salvar(estagiario){
        return this.post('/', estagiario)
    }

    atualizar(estagiario){
        return this.put(`/${estagiario.id}`, estagiario)
    }

    habilitarEstagiario(dados){
        return this.put('/habilitar', dados)
    }

    deletar(id){
        return this.delete(`/${id}`)
    }
    
    listar() {
        return this.get('/')
    }

    listarCh(){
        return this.get('/ch')
    }

    validar(estagiario){
        const erros = [];

        if(!estagiario.matricula || estagiario.matricula === 0){
            erros.push("Matrícula é obrigatório")
        }
        if(!estagiario.nome || estagiario.nome.trim() === ''){
            erros.push("O nome é obrigatório")
        }
        if(!estagiario.email || estagiario.email.trim() === ''){
            erros.push("O email é obrigatório")
        }
        if(!estagiario.cpf || estagiario.cpf.trim() === ''){
            erros.push("O CPF é obrigatório")
        }
        if(!estagiario.rg || estagiario.rg.trim() === ''){
            erros.push("O RG é obrigatório")
        }
        if(!estagiario.pis || estagiario.pis.trim() === ''){
            erros.push("O PIS é obrigatório")
        }
        if(!estagiario.cargaHoraria){
            erros.push("A carga horária é obrigatória")
        }

        if(!estagiario.instituicaoEnsino || estagiario.instituicaoEnsino.trim() === ''){
            erros.push("A Instituição de Ensino é obrigatória")
        }

        if(!estagiario.nivelEscolaridade || estagiario.nivelEscolaridade.trim() === ''){
            erros.push("O nível de escolarida é obrigatório")
        }

        if(erros && erros.length > 0){
            throw new ErroValidacao(erros);
        }
    }

    validarBack(estagiario, error){
        const erros = [];
            if(!estagiario.nome){
                erros.push(error.response.data.erros.nome)
            }
            if(!estagiario.email){
                erros.push(error.response.data.erros.email)
            }
            if(!estagiario.cpf){
                erros.push(error.response.data.erros.cpf)
            }
            if(!estagiario.rg){
                erros.push(error.response.data.erros.rg)
            }
            if(!estagiario.pis){
                erros.push(error.response.data.erros.pis)
            }
            if(!estagiario.cargaHoraria){
                erros.push(error.response.data.erros.cargaHoraria)
            }

            if(erros && erros.length > 0){
                throw new ErroValidacao(erros);
            }
    }
}

export default EstagiarioService
import Button from 'react-bootstrap/Button'
import React, { useEffect, useState } from "react"
import { Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import { mensagemErro, mensagemSucesso } from "../../components/avisos/toastr";
import Card from "../../components/card"
import FuncaoService from '../../service/funcaoService'
import ReactPaginate from "react-paginate";

import styles from '../unidade/listarUnidade.module.css'

function ListarFuncoes() {

    const initialValue = {
        nome: '',
        status: null
    }
    
    const [values, setValues] = useState(initialValue);

    function onChange(e) {
        const { name, value } = e.target;
        setValues({...values, [name]: value })
    }

    const [show, setShow] = useState(false);
    const [showConf, setShowConf] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleConfclose = () => setShowConf(false);
    const handleConfShow = () => setShowConf(true);

    const [funcao, setFuncoes] = useState([]);
    const [inputId, setInputId] = useState();

    const service = new FuncaoService();

    //Parte relacionada a paginação e busca por nome 
    //const [totalFuncoes, setTotalFuncoes] = useState(0);
    //const [totalPaginas, setTotalPaginas] = useState([]);
    const [paginaAtual, setPaginaAtual] = useState(0);
    const [nomeBuscar, setNomeBuscar] = useState("");
    const [numeroDePaginas, setNumeroDePaginas] = useState(0);

    //implementar futuramente função para quando pesquisar setar a primeira pagina
    const pesquisou = () => setPaginaAtual(0);

    useEffect(() => {

        async function loadFuncoesPaginacao(){
            const response = await service.buscarPorNome(`/funcaopornome/${nomeBuscar}/page/${paginaAtual}`);
            //setTotalFuncoes(response.data.totalElements);
            setFuncoes(response.data.content);
            setNumeroDePaginas(response.data.totalPages);

            const arrayPaginas = [];
            //cria um array de paginas na quantidade vinda do backend
            for (let p = 1; p <= numeroDePaginas; p++) {
                arrayPaginas.push(p);
            }
            //setTotalPaginas(arrayPaginas);

        }

        async function carregarFuncoes() {
            const response = await service.listarTodos(`/pag/${paginaAtual}`);
            //setTotalPaginas(response.totalPages)
            //setTotalFuncoes(response.data.totalElements);
            setFuncoes(response.data.content);
            setNumeroDePaginas(response.data.totalPages);
        
            const arrayPaginas = [];
            //cria um array de paginas na quantidade vinda do backend
            for (let p = 1; p <= numeroDePaginas; p++) {
                arrayPaginas.push(p);
            }
            //setTotalPaginas(arrayPaginas);
        
        }
            
        if (nomeBuscar === "") {
            carregarFuncoes();
        } else {
            loadFuncoesPaginacao();
        }

    }, [paginaAtual, nomeBuscar, showConf, show]);

    function handlePageClick(data) {
        setPaginaAtual(data.selected);
    }

    const limparDados = () => {
        setValues({
            nome: '',
            inputId: null
        })
        handleClose();
    }

    const abrirEditModal = (id) => {
        handleShow();

        if(id){
            service.obterPorId(id)
            .then((response) => {
               setValues(response.data)
            })
        }
    }

    const cadastrar = () => {
        const msgs = []
    
        if(!values.nome){
          msgs.push('O campo nome é obrigatório.')
          mensagemErro(msgs)
          return false;
        }
    
        service.salvar(values)
          .then(() => {
            mensagemSucesso('Função cadastrada com sucesso.')
            limparDados();
          }).catch(error => {
            mensagemErro(error.response.data.erros.nome)
          })
    }
    
    const atualizar = () => {
        const msgs = []
    
        if(!values.nome || values.nome.trim() === ''){
          msgs.push('O campo nome é obrigatório.')
          mensagemErro(msgs)
          return false;
        }
    
        service.atualizar(values)
          .then(() => {
            mensagemSucesso('Função atualizada com sucesso.')
            limparDados();
          }).catch(error => {
            mensagemErro(error.response.data.mensagem)
          })
    }

    const desabilitar = () => {
        values.status = false
        service.atualizar(values)
          .then(() => {
            mensagemSucesso('Função desabilitada com sucesso.')
            limparDados();
            handleConfclose()
          }).catch(error => {
            mensagemErro(error.response.data.mensagem)
          })
    }

    const habilitar = () => {
        values.status = true
        service.atualizar(values)
          .then(() => {
            mensagemSucesso('Função habilitada com sucesso.')
            limparDados();
            handleConfclose()
          }).catch(error => {
            mensagemErro(error.response.data.mensagem)
          })
    }

    function mudarStatusFuncao(idDesabilitar){
        service.obterPorId(idDesabilitar)
        .then((s) => {
            setValues(s.data)
            handleConfShow()
        })
    }

    return(
        <Container className="mt-4">
            <Card title="Listagem de Funções">
                <div className={styles.divBusca}>
                    <div className="input-group "> 
                        <div >
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Pesquisar pelo nome"
                                aria-label="Recipient's username"
                                aria-describedby="button-addon2"
                                value={nomeBuscar}
                                onChange={(buscar) => setNomeBuscar(buscar.target.value)}
                            />
                        </div>
                    </div>
                    <div className={styles.btnAdd}>
                        <button onClick={handleShow} type="button" className="btn btn-primary">Adicionar</button>
                    </div>
                </div>
                
                <Table striped bordered hover size="md">
                    <thead>
                        <tr>
                            <th>##</th>
                            <th>Nome da Função</th>
                        </tr>
                    </thead>
                    <tbody>
                        {funcao.map((f, index) => {
                            return(
                                <tr key={f.id}>
                                    <td>{index+1}</td>
                                    <td className='col-md-10'>{f.nome}</td>
                                        {f.status ?                                          
                                            <td style={{textAlign: 'center', padding: '3px'}}>
                                                <button className="btn btn-primary" title='Editar' onClick={() => abrirEditModal(f.id)} style={{marginRight: '5px'}}>
                                                    <i className='pi pi-pencil'></i>
                                                </button>
                                                <button onClick={() => mudarStatusFuncao(f.id)} title='Desabilitar' className="btn btn-danger">
                                                    <i className='pi pi-lock'></i>
                                                </button>
                                            </td>
                                                :
                                            <td style={{textAlign: 'center', padding: '3px'}}>
                                                <button onClick={() => mudarStatusFuncao(f.id)} title='Habilitar' className="btn btn-primary">
                                                    <i className='pi pi-unlock'></i>
                                                </button>
                                            </td>
                                        }
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                {funcao.length > 0 &&
                    <ReactPaginate
                        previousLabel={"Voltar"}
                        nextLabel={"Avançar"}
                        breakLabel={"..."}
                        pageCount={numeroDePaginas}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                        initialPage={pesquisou}
                    />
                }
            </Card>    

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Body>
                    <Container className="mt-4">
                        <Card title="Cadastro de Funções">
                            <Form>
                                <Row>
                                    <Col>
                                        <label className="col-form-label" htmlFor="nome">Nome da Função:</label>
                                        <input type="text" 
                                            className="form-control" 
                                            id="nome" 
                                            name="nome"
                                            value={values.nome}
                                            onChange={onChange}
                                            placeholder="Informe o nome da função" />
                                    </Col>
                                </Row>
                            </Form>
                        </Card>

                        <div className="mt-4">
                            <div className="text-center">
                                {values.id > 0 ?
                                    <button onClick={atualizar} className="btn btn-primary">Atualizar</button> 
                                    :
                                    <button onClick={cadastrar} className="btn btn-primary">Cadastrar</button> 
                                }
                            </div>
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-secondary" onClick={limparDados}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showConf} onHide={handleConfclose} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <h3>Confirmação</h3>
                </Modal.Header>
                <Modal.Body>
                    <h5>Você realmente deseja {values.status ? "desabilitar" : "habilitar"} o a função?</h5>
                </Modal.Body>
                <Modal.Footer>
                    {values.status ?
                        <Button className="btn btn-danger" onClick={desabilitar}>
                            Confirmar
                        </Button>
                    :
                        <Button className="btn btn-primary" onClick={habilitar}>
                            Confirmar
                        </Button>
                    }
                    <Button className="btn btn-secondary" onClick={handleConfclose}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>  
    )
}

export default ListarFuncoes